import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Folder} from '../../models/folder/folder';
import {FolderService} from '../../services/server/folder/folder.service';
import {Measure} from '../../models/measure/measure';
import {Router} from '@angular/router';
import {ColumnInformation} from '../../models/lws-table/column-information';
import {Listing} from '../../businesslogic/listing/Listing';
import {TranslateService} from '@ngx-translate/core';
import {MeasureService} from '../../services/server/measure/measure.service';
import {AccountService} from '../../services/server/account/account.service';
import {FoldersListDialogComponent} from '../folders-list-dialog/folders-list-dialog.component';
import {Segment} from '../../models/segment/segment';
import {Version} from '../../models/version/version';
import {VersionService} from '../../services/server/version/version.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-measures-list',
    templateUrl: './measures-list.component.html',
    styleUrls: ['./measures-list.component.scss']
})
export class MeasuresListComponent extends Listing implements OnInit, AfterViewInit {
    @Input() public folder: Folder;
    @Input() public readOnly: boolean;
    @Input() private segment: Segment;
    @Input() private selectedVersion: Version;
    public foldersForMoveDialog: Folder[] = [];

    /**
     * the columns of the table to display
     */
    public displayedColumns: ColumnInformation[] = [
        new ColumnInformation('name', 'Name', 'text', 'name'),
        new ColumnInformation('targetDescription', 'Ziel', 'text', 'targetDescription'),
        new ColumnInformation('stateName', 'Status', 'text', 'state'),
    ];

    constructor(public router: Router,
                public folderService: FolderService,
                public measureService: MeasureService,
                public accountService: AccountService,
                public dialog: MatDialog,
                public labelTranslator: TranslateService,
                public versionService: VersionService) {

        super(dialog, labelTranslator);
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.init();
    }

    protected loadData(pageIndex: number, itemsPerPage: number, sortColumn: string, sortDesc: boolean) {
        this.isLoading = true;
        this.folderService.getMeasures(this.folder.id, pageIndex, itemsPerPage, sortColumn, sortDesc).then(
            (result: { items: Measure[], numberOfAllItems: number }) => {
                this.isLoading = false;

                for (const meeasure of result.items) {

                    this.labelTranslator.get(meeasure.getTechnicalStateName()).subscribe(
                        (translation: string) => {
                            meeasure.stateName = translation;
                        });
                }

                this.showItems(result);
            },
            () => {
                this.isLoading = false;
            }
        );

    }

    protected getDisplayedColumns() {
        return this.displayedColumns;
    }

    /**
     * deletes the given item
     */
    protected delete(selection) {
        this.getDetailService().delete(selection.id, this.folder.id).then(success => {
            this.isLoading = false;

            this.refresh();
        });
    }

    protected getListService() {
        return this.folderService;
    }

    /**
     * Returns the service which is used to work with the detail items of the list
     */
    protected getDetailService() {
        return this.measureService;
    }

    /**
     * Creates a new model and only returns the model.
     */
    protected createNewModelInstance() {
        const newMeasure: Measure = new Measure();
        newMeasure.id = 0;
        return newMeasure;
    }

    /**
     * load the folders for the selected version and segment
     *
     * @param versionId the if of the selected version
     * @param segmentId the if of the selected segment
     */
    private loadFolderFromVersionAndSegment(versionId: number, segmentId: number, onCompleteLoaded: () => void) {
        if (versionId == null || segmentId == null) {
            this.foldersForMoveDialog = [];

            return;
        }

        this.versionService.getFolders(versionId, segmentId).then(
            (result: { items: Folder[], numberOfAllItems: number }) => {
                // load subfolders

                this.foldersForMoveDialog = [];

                let processedSubfolders = 0;
                for (const folder of result.items) {

                    this.foldersForMoveDialog.push(folder);

                    this.folderService.getSubfolders(folder.id).then(
                        (childResult: { items: Folder[], numberOfAllItems: number }) => {
                            folder.subFolders = [];

                            if (childResult.items && childResult.items.length > 0) {
                                folder.subFolders = childResult.items;
                            }

                            processedSubfolders++;

                            if (processedSubfolders === result.items.length) {
                                onCompleteLoaded();
                            }
                        }
                    );
                }
            }
        );
    }

    /**
     * deletes the given item
     */
    protected move(measure: Measure) {
        this.loadFolderFromVersionAndSegment(this.selectedVersion.id, this.segment.id, () => {
            const dialogRef = this.dialog.open(FoldersListDialogComponent, {
                data: {
                    folders: this.foldersForMoveDialog
                }
            });

            dialogRef.afterClosed().subscribe(selectedFolder => {
                this.measureService.move(measure.id, selectedFolder.id).then(
                    (success: boolean) => {
                        this.refresh();
                    }
                );
            });
        });
    }
}