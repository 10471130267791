<section [style.background-image]="'url(' + customerBackgroundImagePath + ')'">

    <form *ngIf="changePasswordForm != null" class="form--change-password" (ngSubmit)="onSubmit()"
          [formGroup]="changePasswordForm">

        <mat-card [ngClass]="changePasswordFormCssClasses">

            <mat-card-header>
                <mat-card-title>{{'changePasswordComponent_Title'|translate}}</mat-card-title>
            </mat-card-header>

            <mat-card-content>

                <app-lws-alert [title]="'changePasswordComponent_Notice_Title'|translate"
                               [message]="'changePasswordComponent_Notice_Text'|translate"
                               class="mt-1 mb-1"
                               [color]="'info'"></app-lws-alert>

                <mat-list>

                    <mat-list-item>
                        <mat-form-field [floatLabel]="true">
                            <input matInput
                                   placeholder="{{'changePasswordComponent_CurrentPasswordInput_Placeholder'|translate}}"
                                   required type="password" formControlName="currentPassword">
                        </mat-form-field>
                    </mat-list-item>

                    <mat-list-item>
                        <mat-form-field [floatLabel]="true">
                            <input matInput
                                   placeholder="{{'changePasswordComponent_PasswordInput_Placeholder'|translate}}"
                                   required type="password" formControlName="password">
                        </mat-form-field>
                    </mat-list-item>

                    <mat-list-item>
                        <mat-form-field [floatLabel]="true">
                            <input matInput
                                   placeholder="{{'changePasswordComponent_PasswordRepeatInput_Placeholder'|translate}}"
                                   required type="password" formControlName="passwordRepeat">
                        </mat-form-field>
                    </mat-list-item>

                </mat-list>

            </mat-card-content>

            <mat-card-actions>
                <button type="submit"
                        [disabled]="!changePasswordForm.valid || (this.changePasswordForm.value['password'] != this.changePasswordForm.value['passwordRepeat'])"
                        mat-button class="change-password-button">
                    {{'changePasswordComponent_FormSubmitButton_Content'|translate}}
                    <mat-spinner diameter="24" *ngIf="isLoading"></mat-spinner>
                </button>
            </mat-card-actions>

            <div *ngIf="isDebug == true">
                <p>Form Value: {{changePasswordForm.value | json}}</p>
                <p>Form Valid: {{changePasswordForm.valid}}</p>
                <p>Form Status: {{changePasswordForm.status}}</p>
            </div>

        </mat-card>

    </form>

</section>
