<section>
    <app-lws-detail-header [backButton]="true" [addButton]="true" [title]="windowTitle"></app-lws-detail-header>

    <main>
        <app-question-detail-form
                *ngIf="question && question.id && folder"
                [question]="question"
                [folder]="folder"
                [segment]="segment"
                [unit]="unit"
                [readOnlyByLockingUser]="isReadOnlyView"
                (questionSaved)="questionSaved();"></app-question-detail-form>
    </main>
</section>
