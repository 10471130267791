<section>

    <!-- the page header -->
    <app-lws-detail-header [backButton]="true"></app-lws-detail-header>

    <!-- view for new companies -->
    <main>

        <p>{{'pageNotFoundPage_TitleText'|translate}}</p>

    </main>

</section>
