import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Measure} from '../../models/measure/measure';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../models/folder/folder';
import {AccountService} from '../../services/server/account/account.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MeasureService} from '../../services/server/measure/measure.service';
import {DetailForm} from '../../businesslogic/detail-forms/DetailForm';
import {MeasurePlaning} from '../../models/measure/measure-planing';
import {ColumnInformation} from '../../models/lws-table/column-information';
import {LwsTableInlineEditComponent} from '../lws-table-inline-edit/lws-table-inline-edit.component';
import {MeasureResponsiblePerson} from '../../models/measure/measure-responsible-person';
import {UserSelectionComponent} from '../user-selection/user-selection.component';
import {MeasureBwaComponent} from '../measure-bwa/measure-bwa.component';
import {BusinessAnalysis} from '../../models/measure/business-analysis';
import * as moment from 'moment';
import {Segment} from '../../models/segment/segment';
import {Company} from '../../models/company/company';
import {LockService} from '../../services/server/lock/lock.service';
import {LockType} from '../../services/server/lock/lock-type.enum';
import {Observable, Subscription, timer} from 'rxjs';
import {QuestionService} from '../../services/server/question/question.service';
import {FolderService} from '../../services/server/folder/folder.service';
import {Year} from '../../models/year/year';
import {VersionService} from '../../services/server/version/version.service';

@Component({
    selector: 'app-measure-detail-form',
    templateUrl: './measure-detail-form.component.html',
    styleUrls: ['./measure-detail-form.component.scss']
})
export class MeasureDetailFormComponent extends DetailForm implements OnInit, AfterViewInit, OnDestroy {
    @Input() measure: Measure = null;
    @Input() defaultSegment: Segment = null;
    @Input() private folder: Folder = null;
    @Input() private company: Company = null;
    @Input() public readOnlyByLockingUser: boolean = false;
    @ViewChild(LwsTableInlineEditComponent) private planingTable: LwsTableInlineEditComponent;
    @ViewChild('responsiblePersonsTable') private responsiblePersonsTable: LwsTableInlineEditComponent;
    public planingGridTitle: string = null;
    public responsiblePersonsTitle: string = null;
    public foldersLevel1: Folder[];

    private lockRefreshTimer: Observable<number> = timer(0, LockService.lockRefreshTimer * 1000);
    private lockRefreshSubscription: Subscription = null;

    /**
     * columns measure planing
     */
    public measurePlaningDisplayedColumns: ColumnInformation[] = [
        new ColumnInformation('taskname', 'Taskname', 'textarea'),
        new ColumnInformation('state', 'Status&nbsp;der&nbsp;Task&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;', 'select'),
        new ColumnInformation('budget', 'BWA € ', 'currency', null, false, false, 999999999),
        new ColumnInformation('actual', 'BWA actual', 'currency', null, false, false, 999999999),
        new ColumnInformation('budgetPt', 'Budget/PT', 'number', null, false, false, 9999),
        new ColumnInformation('actualPt', 'Actual/PT', 'number', null, false, false, 9999),
        new ColumnInformation('notes', 'Hinweise / Bemerkungen', 'textarea'),
    ];

    public measureResponsiblePersonsDisplayedColumns: ColumnInformation[] = [
        new ColumnInformation('username', 'Name', 'text', null, true),
        new ColumnInformation('planDays', 'Plan / Personentage', 'number', null, false, false, 9999),
        new ColumnInformation('planRestDays', 'Plan / Rest-Pers.tage', 'number', null, false, false, 9999),
        new ColumnInformation('sumOfPerson', 'Summe über alle Maßnahmen', 'number', null, true),
        new ColumnInformation('userId', '', 'number', null, true, true),
    ];

    /**
     * the structure of the form
     */
    public form: FormGroup = this.formBuilder.group({
        name: [
            '',
            Validators.compose([
                Validators.required,
                Validators.maxLength(80)
            ])
        ],
        state: [
            '',
            Validators.compose([
                Validators.required
            ])
        ],
        startdate: [
            '',
            Validators.compose([])
        ],
        enddate: [
            '',
            Validators.compose([])
        ],
        targetDescription: [
            '',
            Validators.compose([])
        ],
        isDescription: [
            '',
            Validators.compose([
                Validators.required,
            ])
        ],
        useDescription: [
            '',
            Validators.compose([])
        ],
        interoperabilityFolderId: [
            '',
            Validators.compose([])
        ],
        netDamageMeasure: [
            '',
            Validators.compose([Validators.min(0), Validators.max(100)])
        ],
        probabilityOfOccurrence: [
            '',
            Validators.compose([Validators.min(0), Validators.max(100)])
        ]

    });

    constructor(public formBuilder: FormBuilder,
                public snackBar: MatSnackBar,
                public router: Router,
                public route: ActivatedRoute,
                public measureService: MeasureService,
                public accountService: AccountService,
                public translator: TranslateService,
                public dialog: MatDialog,
                public lockService: LockService,
                public folderService: FolderService,
                public versionService: VersionService) {
        super(snackBar, translator);
    }

    ngOnDestroy() {
        if (!this.readOnlyByLockingUser) {
            if (this.measure != null && this.measure.id > 0) {
                this.lockService.unlock(this.measure.id, LockType.MEASURE);
            }
        }

        if (this.lockRefreshSubscription != null) {
            this.lockRefreshSubscription.unsubscribe();
        }
    }

    ngOnInit() {
        this.translator.get('MeasurePlaning').subscribe(
            (translation: string) => {
                this.planingGridTitle = translation;
            });

        this.translator.get('Responsible').subscribe(
            (translation: string) => {
                this.responsiblePersonsTitle = translation;
            });


        this.loadModel();

        if (this.measure != null && this.measure.businessAnalysis != null) {
            const businessAnalysis = new BusinessAnalysis();
            Object.assign(businessAnalysis, this.measure.businessAnalysis);
            this.measure.businessAnalysis = businessAnalysis;

            this.measure.businessAnalysis.calcSums();
        }

        if (this.lockRefreshSubscription != null) {
            this.lockRefreshSubscription = this.lockRefreshTimer.subscribe(() => {
                this.lockService.lock(this.measure.id, LockType.MEASURE);
            });
        }

    }

    ngAfterViewInit() {
        this.planingTable.addClick.subscribe((callback) => {
            this.addClick(callback);
        });


        this.responsiblePersonsTable.addClick.subscribe((callback) => {
            this.addPersonClick(callback);
        });

        this.loadInteroperabilityFolders();
    }

    private loadInteroperabilityFolders() {

        if(this.folder) {
            this.versionService.getFolders(this.folder.version.id, this.folder.segment.id).then(
                (value: { items: Folder[], numberOfAllItems: number }) => {
                    this.foldersLevel1 = value.items;
                },
                () => {

                }
            ) ;
        }
    }

    /**
     * executed if the user clicked on the login-button
     */
    private onSubmit() {
        if (this.form.valid) {
            let measure: Measure = ((this.measure == null) ? new Measure() : this.measure);
            this.transferViewToModel(measure);

            if (this.folder != null) {
                if (measure.folders === null) {
                    measure.folders = [];
                }
                if (measure.folders.filter(x => x.id !== this.folder.id).length <= 0) {
                    measure.folders.push(this.folder);
                }
            }

            measure = this.measurePlaningsToModel(measure);
            measure = this.responsiblePersonsToModel(measure);

            this.doSave(measure);
        }
    }

    /**
     * transfers the values of the editable grid in the model
     */
    private measurePlaningsToModel(measure: Measure) {
        measure.measurePlanings = [];

        const measurePlanings = this.planingTable.getTableData();

        for (const measurePlaningData of measurePlanings) {
            const measurePlaning = new MeasurePlaning();
            Object.assign(measurePlaning, measurePlaningData);
            measure.measurePlanings.push(measurePlaning);
        }

        return measure;
    }

    /**
     * transfers the values of the editable grid in the model
     */
    private responsiblePersonsToModel(measure: Measure) {
        measure.measureResponsiblePersons = [];

        const responsiblePersons = this.responsiblePersonsTable.getTableData();

        for (const responsiblePersonData of responsiblePersons) {
            const responsiblePerson = new MeasureResponsiblePerson();
            Object.assign(responsiblePerson, responsiblePersonData);
            measure.measureResponsiblePersons.push(responsiblePerson);
        }

        return measure;
    }

    protected getService() {
        return this.measureService;
    }

    /**
     * loads the form model
     */
    private loadModel() {
        if (this.measure == null) {
            this.measure = new Measure();
            this.measure.segmentName = this.defaultSegment.name;
        }

        this.showModelInView(this.measure);
    }

    protected getForm() {
        return this.form;
    }

    private addClick(callback: (newRow) => void) {
        callback(new MeasurePlaning());
    }

    /**
     * Opens the dialog for user selection
     */
    private addPersonClick(callback: (newRow) => void) {
        const userSelectionDialog = this.dialog.open(UserSelectionComponent, {
            width: '350px',
            data: {
                companyId: this.company.id
            }
        });

        userSelectionDialog.afterClosed().subscribe(result => {
            if (result != null && result.length > 0) {

                // Selected user. format: id|name
                const userParts = result.split('|');

                const measureResponsiblePerson = new MeasureResponsiblePerson();
                measureResponsiblePerson.userId = userParts[0];
                measureResponsiblePerson.username = userParts[1];
                callback(measureResponsiblePerson);
            }
        });
    }

    /**
     *
     */
    public changeBwa() {
        let copiedBusinessAnalysis: BusinessAnalysis = new BusinessAnalysis();
        copiedBusinessAnalysis = Object.assign(copiedBusinessAnalysis, this.measure.businessAnalysis);

        const bwaDialog = this.dialog.open(MeasureBwaComponent, {
            width: '800px',
            disableClose: true,
            data: {
                businessAnalysis: copiedBusinessAnalysis
            }
        });

        bwaDialog.afterClosed().subscribe((businessAnalysis: BusinessAnalysis) => {
            if (businessAnalysis != null || businessAnalysis != undefined) {
                this.measure.businessAnalysis = businessAnalysis;
            }
        });
    }

    /**
     * Handle the event
     */
    public dateChanged() {
        if (this.getForm().value.startdate != null && this.getForm().value.enddate != null) {
            if (moment(this.getForm().value.startdate).isAfter(this.getForm().value.enddate)) {
                this.getForm().controls['startdate'].setErrors({incorrect: true});
                this.getForm().controls['enddate'].setErrors({incorrect: true});
            } else {
                this.getForm().controls['startdate'].setErrors(null);
                this.getForm().controls['enddate'].setErrors(null);
            }
        }
    }

    public isValid() {
        if (this.responsiblePersonsTable != null && this.planingTable != null) {
            return this.form.valid && this.planingTable.isValid() && this.responsiblePersonsTable.isValid();
        } else {
            return false;
        }
    }
}
