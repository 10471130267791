<section>

    <form *ngIf="form != null" class="form" (ngSubmit)="onSubmit()" [formGroup]="form">

        <mat-form-field [floatLabel]="true">
            <mat-label>{{'versionsCopyForm_CompanySelect_Label'|translate}}</mat-label>
            <mat-select formControlName="company" required (selectionChange)="onCompanyChanged($event.value)">
                <mat-option *ngFor="let companyForSelect of companiesForSelect" [value]="companyForSelect">
                    {{companyForSelect.groupName}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field [floatLabel]="true">
            <mat-label>{{'versionsCopyForm_UnitNameInput_Placeholder'|translate}}</mat-label>
            <mat-select formControlName="unitName" required (selectionChange)="onUnitChanged($event.value)">
                <mat-option *ngFor="let unitForSelect of unitsForSelect" [value]="unitForSelect">
                    {{unitForSelect.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field [floatLabel]="true">
            <mat-label>{{'versionsCopyForm_YearNameInput_Placeholder'|translate}}</mat-label>
            <mat-select formControlName="yearName" required (selectionChange)="loadVersionsOfYear($event.value)">
                <mat-option *ngFor="let yearForSelect of yearsForSelect" [value]="yearForSelect">
                    {{yearForSelect.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field [floatLabel]="true">
            <mat-label>{{'versionsCopyForm_VersionNameInput_Placeholder'|translate}}</mat-label>
            <mat-select formControlName="versionName" required>
                <mat-option *ngFor="let versionForSelect of versionsForSelect" [value]="versionForSelect">
                    {{versionForSelect.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button type="submit" [disabled]="!form.valid" mat-stroked-button class="submit-button" color="primary">
            {{'formButtons_save'|translate}}
            <mat-spinner diameter="24" *ngIf="isLoading"></mat-spinner>
        </button>

    </form>

</section>
