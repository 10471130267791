import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccountService} from '../../services/server/account/account.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {Unit} from '../../models/unit/unit';
import {UnitService} from '../../services/server/unit/unit.service';
import {CompanyService} from '../../services/server/company/company.service';
import {Company} from '../../models/company/company';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';

@Component({
    selector: 'app-unit-detail-form',
    templateUrl: './unit-detail-form.component.html',
    styleUrls: ['./unit-detail-form.component.scss']
})
export class UnitDetailFormComponent implements OnInit {
    /**
     *
     */
    @Input() unit: Unit = null;
    /**
     *
     */
    @Input() private company: Company = null;
    /**
     *
     */
    public isLoading: boolean = false;

    /**
     * the structure of the login-form
     */
    public form: FormGroup = this.formBuilder.group({
        name: ['', Validators.compose([
            Validators.required,
            Validators.maxLength(80)
        ])],
        successFactor1: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor2: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor3: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor4: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor5: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor6: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor7: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor8: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor9: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor10: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor11: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor12: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor13: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor14: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor15: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor16: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor17: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor18: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor19: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        successFactor20: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        sales: ['', Validators.compose([
            Validators.min(0)
        ])],
    });

    constructor(public formBuilder: FormBuilder,
                public accountService: AccountService,
                public snackBar: MatSnackBar,
                public router: Router,
                public route: ActivatedRoute,
                public companyService: CompanyService,
                public unitService: UnitService,
                public location: Location,
                public translator: TranslateService) {

    }

    /**
     * executed if the user clicked on the login-button
     */
    private onSubmit() {
        if (this.form.valid && this.company != null) {
            this.isLoading = true;

            const unit: Unit = ((this.unit == null) ? new Unit() : this.unit);
            unit.name = this.form.value['name'];
            unit.successFactor1 = this.form.value['successFactor1'];
            unit.successFactor2 = this.form.value['successFactor2'];
            unit.successFactor3 = this.form.value['successFactor3'];
            unit.successFactor4 = this.form.value['successFactor4'];
            unit.successFactor5 = this.form.value['successFactor5'];
            unit.successFactor6 = this.form.value['successFactor6'];
            unit.successFactor7 = this.form.value['successFactor7'];
            unit.successFactor8 = this.form.value['successFactor8'];
            unit.successFactor9 = this.form.value['successFactor9'];
            unit.successFactor10 = this.form.value['successFactor10'];
            unit.successFactor11 = this.form.value['successFactor11'];
            unit.successFactor12 = this.form.value['successFactor12'];
            unit.successFactor13 = this.form.value['successFactor13'];
            unit.successFactor14 = this.form.value['successFactor14'];
            unit.successFactor15 = this.form.value['successFactor15'];
            unit.successFactor16 = this.form.value['successFactor16'];
            unit.successFactor17 = this.form.value['successFactor17'];
            unit.successFactor18 = this.form.value['successFactor18'];
            unit.successFactor19 = this.form.value['successFactor19'];
            unit.successFactor20 = this.form.value['successFactor20'];
            unit.sales = this.form.value['sales'];

            unit.group = ((this.unit != null && this.unit.group != null) ? this.unit.group : this.company.group);

            this.unitService.save(unit).then(
                (unitId) => {
                    this.isLoading = false;

                    if (unitId !== null && unitId > 0) {
                        // success
                        this.location.back();
                    } else {
                        // error
                    }
                });
        }
    }

    /**
     * loads the form model from the company
     */
    private loadModel() {
        if (this.unit == null) {
            return;
        }

        this.form.setValue({
            name: this.unit.name,
            successFactor1: this.unit.successFactor1,
            successFactor2: this.unit.successFactor2,
            successFactor3: this.unit.successFactor3,
            successFactor4: this.unit.successFactor4,
            successFactor5: this.unit.successFactor5,
            successFactor6: this.unit.successFactor6,
            successFactor7: this.unit.successFactor7,
            successFactor8: this.unit.successFactor8,
            successFactor9: this.unit.successFactor9,
            successFactor10: this.unit.successFactor10,
            successFactor11: this.unit.successFactor11,
            successFactor12: this.unit.successFactor12,
            successFactor13: this.unit.successFactor13,
            successFactor14: this.unit.successFactor14,
            successFactor15: this.unit.successFactor15,
            successFactor16: this.unit.successFactor16,
            successFactor17: this.unit.successFactor17,
            successFactor18: this.unit.successFactor18,
            successFactor19: this.unit.successFactor19,
            successFactor20: this.unit.successFactor20,
            sales: this.unit.sales
        });
    }

    ngOnInit() {
        this.loadModel();
    }
}
