import {Component, OnInit, ViewChild} from '@angular/core';
import {LwsDetailHeaderComponent} from '../../components/lws-detail-header/lws-detail-header.component';
import {TranslateService} from '@ngx-translate/core';
import {CacheService} from '../../services/client/cache/cache.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    @ViewChild(LwsDetailHeaderComponent) private header: LwsDetailHeaderComponent;
    
    constructor(public translator: TranslateService,
                public cacheService: CacheService) {
        
    }
    
    ngOnInit() {
        this.translator.get('sidenav_PagesDashboard_Title').subscribe((titleTranslation: string) => {
            this.header.title = titleTranslation;
        });
    }
}
