<section>

    <!-- the page header -->
    <app-lws-detail-header [backButton]="true" [title]="title"></app-lws-detail-header>
    <app-lws-bread-crumb [breadcrumbs]="breadcrumbs"></app-lws-bread-crumb>

    <!-- view for new units -->
    <main *ngIf="isNew" class="version--detail-new">

        <app-version-detail-form></app-version-detail-form>

    </main>

    <!-- view for existing units -->
    <main *ngIf="!isNew && version" class="version--detail-existing">

        <app-version-detail-form [version]="version"></app-version-detail-form>

    </main>

</section>
