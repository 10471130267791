import {ModelInterface} from '../model-interface';
import {JSONConverter} from '../JSONConverter';

export class MeasureResponsiblePerson implements ModelInterface {
    private _id: number = 0;

    private _userId: number = 0;

    private _username: string = '';

    private _planDays: number;

    private _planRestDays: number;

    private _sumOfPerson: number;

    // a list of properties-keys to ignore at jsonconvert
    _ignoreProperties: string[] = [];

    constructor() {

    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get userId(): number {
        return this._userId;
    }

    set userId(value: number) {
        this._userId = value;
    }

    get username(): string {
        return this._username;
    }

    set username(value: string) {
        this._username = value;
    }

    get planDays(): number {
        return this._planDays;
    }

    set planDays(value: number) {
        this._planDays = value;
    }

    get planRestDays(): number {
        return this._planRestDays;
    }

    set planRestDays(value: number) {
        this._planRestDays = value;
    }

    get sumOfPerson(): number {
        return this._sumOfPerson;
    }

    set sumOfPerson(value: number) {
        this._sumOfPerson = value;
    }

    /**
     * returns the object as a valid json (without the underscore a the property name begin)
     */
    toJSON() {
        return new JSONConverter().toJSON(this);
    }

    /**
     * creates a object from the given data
     *
     * @param data the data to create a model
     */
    static hydrate(data: any): MeasureResponsiblePerson {
        const model: MeasureResponsiblePerson = new MeasureResponsiblePerson();

        Object.assign(model, data);

        return model;
    }
}
