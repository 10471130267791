<section [hidden]="selectedMeasure != null">
    <mat-toolbar color="basic">
        <mat-toolbar-row>
            <span>{{'MyMeasures'|translate}}</span>
        </mat-toolbar-row>
    </mat-toolbar>


    <div class="table-container mat-elevation-z8">
        <div class="table-loading-shade" *ngIf="isLoading">
            <mat-spinner *ngIf="isLoading" [diameter]="48"></mat-spinner>
        </div>

        <div class="table-container">
            <app-lws-table [datasource]="dataSource"
                           [displayedColumns]="displayedColumns"
                           [layout]="'full'"
                           [enableActions]="true"
                           [enableEditAction]="true"></app-lws-table>
        </div>

        <div class="footer-bottom">
            <mat-paginator [pageSizeOptions]="[25, 50, 100]" [length]="resultsLength"></mat-paginator>
        </div>
    </div>

</section>


<section *ngIf="selectedMeasure != null">

    <section *ngIf="selectedMeasure != null">
        <app-measure-detail-form *ngIf="selectedMeasure.id == 0"
                                 [company]="company"
                                 (detailFormSaved)="measureSaved();"></app-measure-detail-form>
        <app-measure-detail-form *ngIf="selectedMeasure.id > 0"
                                 [measure]="selectedMeasure"
                                 [company]="company"
                                 [folder]="selectedMeasure.folders[0]"
                                 [readOnlyByLockingUser]="isReadOnlyView"
                                 (detailFormSaved)="measureSaved();"></app-measure-detail-form>
    </section>

</section>
