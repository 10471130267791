import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LwsMaterialModule} from './material-module';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppComponent} from './app.component';
import {AppConfig} from './app.config';


import {LwsTableComponent} from './components/lws-table/lws-table.component';
import {LwsDetailHeaderComponent} from './components/lws-detail-header/lws-detail-header.component';
import {LwsDialogComponent} from './components/lws-dialog/lws-dialog.component';
import {LwsInputDialogComponent} from './components/lws-input-dialog/lws-input-dialog.component';

import {UsersListComponent} from './components/users-list/users-list.component';
import {CompanyDetailFormComponent} from './components/company-detail-form/company-detail-form.component';
import {UserDetailFormComponent} from './components/user-detail-form/user-detail-form.component';
import {UnitsListComponent} from './components/units-list/units-list.component';
import {UnitDetailFormComponent} from './components/unit-detail-form/unit-detail-form.component';
import {YearDetailFormComponent} from './components/year-detail-form/year-detail-form.component';
import {VersionDetailFormComponent} from './components/version-detail-form/version-detail-form.component';
import {FoldersListComponent} from './components/folders-list/folders-list.component';
import {FolderDetailFormComponent} from './components/folder-detail-form/folder-detail-form.component';
import {CompaniesListComponent} from './components/companies-list/companies-list.component';
import {AssessmentOverviewComponent} from './components/assessment-overview/assessment-overview.component';
import {QuestionsListComponent} from './components/questions-list/questions-list.component';
import {MeasuresListComponent} from './components/measures-list/measures-list.component';
import {MeasureDetailFormComponent} from './components/measure-detail-form/measure-detail-form.component';
import {QuestionDetailFormComponent} from './components/question-detail-form/question-detail-form.component';
import {FoldersListDialogComponent} from './components/folders-list-dialog/folders-list-dialog.component';
import {VersionsCopyFormComponent} from './components/versions-copy-form/versions-copy-form.component';
import {LwsAlertComponent} from './components/lws-alert/lws-alert.component';
import {LwsBreadCrumbComponent} from './components/lws-bread-crumb/lws-bread-crumb.component';
import {UserSelectionComponent} from './components/user-selection/user-selection.component';

import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {LoginComponent} from './pages/login/login.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {ChangePasswordComponent} from './pages/change-password/change-password.component';
import {CompaniesDetailComponent} from './pages/companies-detail/companies-detail.component';
import {UsersDetailComponent} from './pages/users-detail/users-detail.component';
import {UnitsDetailComponent} from './pages/units-detail/units-detail.component';
import {YearsComponent} from './pages/years/years.component';
import {YearsDetailComponent} from './pages/years-detail/years-detail.component';
import {VersionsComponent} from './pages/versions/versions.component';
import {VersionsDetailComponent} from './pages/versions-detail/versions-detail.component';
import {FoldersComponent} from './pages/folders/folders.component';
import {FoldersDetailComponent} from './pages/folders-detail/folders-detail.component';
import {AssessmentsComponent} from './pages/assessments/assessments.component';
import {CompaniesComponent} from './pages/companies/companies.component';
import {VersionsCopyComponent} from './pages/versions-copy/versions-copy.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import {MomentUtcDateAdapter} from './config/MomentUtcDateAdapter';
import {LwsTableInlineEditComponent} from './components/lws-table-inline-edit/lws-table-inline-edit.component';
import {MeasureBwaComponent} from './components/measure-bwa/measure-bwa.component';
import {GridsterModule} from 'angular-gridster2';
// locale for the registration
import localeDeExtra from '@angular/common/locales/extra/de';
import {CurrencyPipe, registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {AssignedMeasuresComponent} from './components/dashboard/assigned-measures/assigned-measures.component';
import {RightsCheck} from './businesslogic/RightsCheck';
import {ReportingDashboardComponent} from './pages/reporting-dashboard/reporting-dashboard.component';
import {ReportingGadgetComponent} from './components/reporting-dashboard/reporting-gadget/reporting-gadget.component';
import {ReportingGadgetConfigComponent} from './components/reporting-dashboard/reporting-gadget-config/reporting-gadget-config.component';
import {GadgetCatalogComponent} from './components/reporting-dashboard/gadget-catalog/gadget-catalog.component';
import {HierarchyFilterComponent} from './components/reporting-dashboard/hierarchy-filter/hierarchy-filter.component';
import {AnsweresCompanyMarketComponent} from './components/reporting-dashboard/gadget-types/answeres-company-market/answeres-company-market.component';
import {NgxEchartsModule} from 'ngx-echarts';

import * as echarts from 'echarts';


import {ReportingGadgets} from './services/client/reporting-gadgets';
import { AnswerListComponent } from './components/reporting-dashboard/gadget-types/answer-list/answer-list.component';
import { QuestionDetailComponent } from './pages/question-detail/question-detail.component';
import { PortfolioComponent } from './components/reporting-dashboard/gadget-types/portfolio/portfolio.component';
import { MeasureEvaluationComponent } from './components/reporting-dashboard/gadget-types/measure-evaluation/measure-evaluation.component';
import { BwaEvaluationComponent } from './components/reporting-dashboard/gadget-types/bwa-evaluation/bwa-evaluation.component';
import { QuestionExportComponent } from './components/reporting-dashboard/gadget-types/question-export/question-export.component';
import { AnswersRiskComplianceComponent } from './components/reporting-dashboard/gadget-types/answers-risk-compliance/answers-risk-compliance.component';

/*import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';*/




import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule} from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTableModule} from '@angular/material/table';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatSelectModule} from '@angular/material/select';
import {MatListModule} from '@angular/material/list';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';








registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

const appRoutes: Routes = [
    {path: 'companies', component: CompaniesComponent},
    {path: 'dashboard', component: DashboardComponent},
    {path: 'reporting-dashboard', component: ReportingDashboardComponent},
    {path: 'account/login', component: LoginComponent},
    {path: 'account/changepassword', component: ChangePasswordComponent},
    {path: 'companies/:id', component: CompaniesDetailComponent},
    {path: 'companies/users/:id', component: UsersDetailComponent},
    {path: 'companies/units/:id', component: UnitsDetailComponent},
    {path: 'companies/units/:id/years', component: YearsComponent},
    {path: 'companies/years/:id', component: YearsDetailComponent},
    {path: 'companies/years/:id/versions', component: VersionsComponent},
    {path: 'companies/versions/:id', component: VersionsDetailComponent},
    {path: 'companies/versions/:id/folders', component: FoldersComponent, data: {isSubFolder: false}},
    {path: 'companies/versions/:id/copy', component: VersionsCopyComponent},
    {path: 'companies/folders/:id/subFolders', component: FoldersComponent, data: {isSubFolder: true}},
    {path: 'companies/folders/:id', component: FoldersDetailComponent},
    {path: 'companies/folders/:folderId/copy', component: VersionsCopyComponent},
    {path: 'assessments', component: AssessmentsComponent},
    {path: 'folders/:folderId/questions/:questionId', component: QuestionDetailComponent},
    {
        path: '',
        redirectTo: '/account/login',
        pathMatch: 'full'
    },
    {path: 'folders/:folderId/questions/:questionId', component: FoldersDetailComponent},
    {path: '**', component: PageNotFoundComponent}
];

export function initializeApp(appConfig: AppConfig) {
    return () => appConfig.load();
}

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        LoginComponent,
        PageNotFoundComponent,
        LwsTableComponent,
        ChangePasswordComponent,
        CompaniesListComponent,
        CompaniesDetailComponent,
        LwsDetailHeaderComponent,
        UsersListComponent,
        CompanyDetailFormComponent,
        LwsDialogComponent,
        UsersDetailComponent,
        UserDetailFormComponent,
        UnitsListComponent,
        UnitsDetailComponent,
        UnitDetailFormComponent,
        YearsComponent,
        YearsDetailComponent,
        YearDetailFormComponent,
        VersionsComponent,
        VersionsDetailComponent,
        VersionDetailFormComponent,
        FoldersComponent,
        FoldersListComponent,
        FoldersDetailComponent,
        FolderDetailFormComponent,
        LwsInputDialogComponent,
        AssessmentsComponent,
        CompaniesComponent,
        AssessmentOverviewComponent,
        QuestionsListComponent,
        MeasuresListComponent,
        MeasureDetailFormComponent,
        QuestionDetailFormComponent,
        FoldersListDialogComponent,
        VersionsCopyComponent,
        VersionsCopyFormComponent,
        LwsAlertComponent,
        LwsBreadCrumbComponent,
        LwsTableInlineEditComponent,
        UserSelectionComponent,
        MeasureBwaComponent,
        AssignedMeasuresComponent,
        ReportingDashboardComponent,
        ReportingGadgetComponent,
        ReportingGadgetConfigComponent,
        GadgetCatalogComponent,
        HierarchyFilterComponent,
        AnsweresCompanyMarketComponent,
        AnswerListComponent,
        QuestionDetailComponent,
        PortfolioComponent,
        MeasureEvaluationComponent,
        BwaEvaluationComponent,
        QuestionExportComponent,
        AnswersRiskComplianceComponent
    ],
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {enableTracing: false} // <-- debugging purposes only
        ),
        GridsterModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FormsModule,
        LwsMaterialModule,
        NgxEchartsModule.forRoot({
             echarts
         }),
    ],
    providers: [
        AppConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfig], multi: true
        },
        FormBuilder,
        {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
        {provide: LOCALE_ID, useValue: 'de-DE'},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        {provide: DateAdapter, useClass: MomentUtcDateAdapter},
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}},
        RightsCheck,
        CurrencyPipe,
        ReportingGadgets
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        LwsDialogComponent,
        LwsInputDialogComponent,
        FoldersListDialogComponent,
        UserSelectionComponent,
        MeasureBwaComponent,
        GadgetCatalogComponent
    ]
})
export class AppModule {
}
