import {Injectable} from '@angular/core';
import {AccountService} from '../account/account.service';
import {RestService} from '../rest/rest.service';
import {EventService} from '../../client/event/event.service';
import {TranslateService} from '@ngx-translate/core';
import {ErrorHandlingService} from '../../client/error-handling/error-handling.service';
import {Version} from '../../../models/version/version';
import {HttpErrorResponse} from '@angular/common/http';
import {RequestBodyType} from '../rest/request-body-type.enum';
import {Folder} from '../../../models/folder/folder';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class VersionService {

    constructor(private accountService: AccountService,
                private restService: RestService,
                private eventService: EventService,
                private snackBar: MatSnackBar,
                private translator: TranslateService,
                private errorHandlingService: ErrorHandlingService) {

    }

    /**
     * returns all versions
     */
    public getAll(): Promise<Version[]> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.get('/versions', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const versions: Version[] = [];

                for (const responseItem of response) {
                    versions.push(Version.hydrate(responseItem));
                }

                return versions;
            },
            () => {
                return null;
            });
    }

    /**
     * deletes the version with the given id
     *
     * @param id the id of the version to delete
     */
    public delete(id: number): Promise<boolean> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.delete(
            '/versions/' + id,
            parameter,
            true).toPromise()
        .then((response: any) => {
            return true;
        }).catch((httpError: any) => {
            this.errorHandlingService.handleError('versionService_DeleteRequest', httpError);

            return false;
        });
    }

    /**
     * returns the requested version
     *
     * @param id the id of the requested version
     */
    public getDetail(id: number): Promise<Version> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.get('/versions/' + id, parameter).toPromise().then(
            (result) => {
                const version: Version = Version.hydrate(result.body);

                return version;
            },
            (httpError: HttpErrorResponse) => {
                this.errorHandlingService.handleError('versionService_DetailRequest', httpError);

                return null;
            });
    }

    /**
     * saves the given version
     *
     * @param version the version to save
     */
    public save(version: Version): Promise<number> {
        return this.restService.post(
            '/versions/' + version.id,
            version,
            true).toPromise()
        .then((id: number) => {
            this.eventService.Trigger('version:create:success', id);

            this.translator.get('versionService_SaveRequest_SuccessMessage').subscribe(
                (translation: string) => {
                    this.snackBar.open(translation, '', {
                        duration: (5 * 1000), // display 5 seconds
                        panelClass: 'snackbar-lws-success'
                    });
                });

            return id;
        }).catch((httpError: HttpErrorResponse) => {
            this.errorHandlingService.handleError('versionService_DeleteRequest', httpError);

            return null;
        });
    }

    /**
     * copies the given version
     *
     * @param version the version to save
     * @param yearName the target year-name for the new version
     */
    public copy(version: Version, yearName: string): Promise<number> {
        return this.translator.get('versionService_CopyRequest_NewVersionCopyName', {versionName: version.name})
        .toPromise().then((translation: string) => {
            version.name = translation;

            const parameter: { key: string, value: string }[] = [];
            parameter.push({key: 'yearName', value: yearName}); // required
            parameter.push({key: 'versionName', value: version.name}); // optional

            return this.restService.postForm(
                '/versions/' + version.id + '/copy',
                parameter,
                RequestBodyType.FormUrlEncoded).toPromise()
            .then((id: number) => {
                this.eventService.Trigger('version:copy:success', id);
                this.eventService.Trigger('version:create:success', id);

                return id;
            }).catch((httpError: HttpErrorResponse) => {
                this.errorHandlingService.handleError('versionService_CopyRequest', httpError);

                return null;
            });
        });
    }

    /**
     * returns all folders

     */
    public getFolders(id: number,
                      segmentId: number,
                      page: number = 0,
                      count: number = 50,
                      sort: string = null,
                      sortDesc: boolean = false): Promise<{
        items: Folder[],
        numberOfAllItems: number
    }> {
        const parameter: { key: string, value: string }[] = [];
        if (page && page > 0) {
            parameter.push({key: 'page', value: page.toString()});
        }
        if (count && count > 0) {
            parameter.push({key: 'count', value: count.toString()});
        }
        if (sort && sort.length > 0) {
            parameter.push({key: 'sortColumn', value: sort.toString()});
            parameter.push({key: 'sortDesc', value: sortDesc.toString()});
        }

        return this.restService.get('/versions/' + id + '/segments/' + segmentId + '/folders', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const folders: Folder[] = [];

                for (const responseItem of response) {
                    folders.push(Folder.hydrate(responseItem));
                }

                return {
                    items: folders,
                    numberOfAllItems: result.headers.get('x-items-count')
                };
            },
            () => {
                return null;
            });
    }

    /**
     * returns all versions
     */
    public getVersions(id: number): Promise<Version[]> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.get('/years/' + id + '/versions', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const versions: Version[] = [];

                for (const responseItem of response) {
                    versions.push(Version.hydrate(responseItem));
                }

                return versions;
            },
            () => {
                return null;
            });
    }

    /**
     * copies the given version to a given company with the given names
     *
     * @param sourceVersionId the source version to copy
     * @param targetCompanyId the target company
     * @param targetUnitName the new unit name
     * @param targetVersionName the new version name
     * @param targetYearName the new year name
     */
    public copyFromTemplate(sourceVersionId: number,
                            folderId: number,
                            targetCompanyId: number,
                            targetUnitName: string,
                            targetVersionName: string,
                            targetYearName: string): Promise<boolean> {
        const parameter: { key: string, value: string }[] = [];
        parameter.push({key: 'targetCompanyId', value: targetCompanyId.toString()});
        parameter.push({key: 'targetUnitName', value: targetUnitName});
        parameter.push({key: 'targetVersionName', value: targetVersionName});
        parameter.push({key: 'targetYearName', value: targetYearName});

        const url = folderId === 0 ?  '/versions/' + sourceVersionId + '/copyFromTemplate' :
                                        '/folders/' + folderId + '/copyFromTemplate' ;
        return this.restService.postForm(
            url,
            parameter,
            RequestBodyType.FormUrlEncoded).toPromise()
        .then((success: boolean) => {

            this.eventService.Trigger('version:copytemplate:success', success);

            return success;
        }).catch((httpError: HttpErrorResponse) => {
            this.errorHandlingService.handleError('versionService_CopyFromTemplateRequest', httpError);

            return null;
        });
    }

    public getAllVersionNamesUnderYearNames(companyId: number, yearName: string) {
        const parameter: { key: string, value: string }[] = [];
        parameter.push({key: 'companyId', value: companyId.toString()});
        parameter.push({key: 'yearName', value: yearName});

        return this.restService.get('/versions/names', parameter).toPromise().then(
            (result) => {
                return (result.body as []);
            },
            () => {
                return null;
            });
    }
}
