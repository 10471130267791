import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {LwsDetailHeaderComponent} from '../../components/lws-detail-header/lws-detail-header.component';
import {Folder} from '../../models/folder/folder';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Location} from '@angular/common';
import {EventService} from '../../services/client/event/event.service';
import {TranslateService} from '@ngx-translate/core';
import {FolderService} from '../../services/server/folder/folder.service';

@Component({
    selector: 'app-folders-detail',
    templateUrl: './folders-detail.component.html',
    styleUrls: ['./folders-detail.component.scss']
})
export class FoldersDetailComponent implements OnInit, AfterViewInit {
    @ViewChild(LwsDetailHeaderComponent) private header: LwsDetailHeaderComponent;
    public folder: Folder = null;
    public isNew: boolean = true;
    public title: string = '';
    public isLoading: boolean = false;
    public breadcrumbs: string[] = [];
    
    constructor(public route: ActivatedRoute,
                public folderService: FolderService,
                public router: Router,
                public location: Location,
                public eventService: EventService,
                public translator: TranslateService) {
        this.translator.setDefaultLang('de');
        this.subscribeEvents();
    }
    
    /**
     * subscribes to needed events
     */
    private subscribeEvents() {
        this.eventService.Subscribe('folder:create:success', (id: number) => {
            this.isNew = false;
            this.loadFolderById(id);
        });
    }
    
    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            const id: number = +params.get('id');
            
            if (id > 0) {
                this.isNew = false;
                this.loadFolderById(id);
            } else {
                this.translator.get('folderDetail_NewFolderForm_TitleContent').subscribe((translation: string) => {
                    this.title = translation;
                    this.loadBreadcrumbsAndAdd(translation);
                });
            }
        });
    }
    
    ngAfterViewInit() {
        this.addHeaderSubscribes();
    }
    
    /**
     * add the subscribes to the action-buttons
     */
    private addHeaderSubscribes() {
        this.header.backClick.subscribe(() => {
            this.location.back();
        });
    }
    
    /**
     *
     * @param id the id of the folder
     */
    private loadFolderById(id: number) {
        this.isLoading = true;
        
        this.folderService.getDetail(id).then(
            (folder) => {
                this.isLoading = false;
                
                if (folder == null) {
                    this.location.back();
                }
                
                this.folder = folder;
                this.title = this.folder.name;
                this.loadBreadcrumbsAndAdd(this.folder.name);
            },
            () => {
                this.isLoading = false;
            });
    }
    
    /**
     * load the latest breadcrumbs and add the given as a new one
     *
     * @param breadcrumb the breadcrumb to add
     */
    private loadBreadcrumbsAndAdd(breadcrumb?: string) {
        const me = this;
        
        if (me.breadcrumbs === null || me.breadcrumbs === undefined) {
            me.breadcrumbs = [];
        }
        
        this.route.queryParams.subscribe((queryParams) => {
            if (typeof queryParams.breadcrumbs === 'string') {
                me.breadcrumbs.push(queryParams.breadcrumbs);
            } else {
                me.breadcrumbs = (queryParams.breadcrumbs as string[]);
            }
            
            if (breadcrumb !== null && breadcrumb !== undefined && breadcrumb.length > 0) {
                me.breadcrumbs.push(breadcrumb);
            }
        });
    }
}
