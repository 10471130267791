<mat-toolbar color="primary">

    <mat-toolbar-row>

        <button mat-icon-button *ngIf="backButton"
                [matTooltip]="'lwsDetailHeader_Back_ToolTipText'|translate"
                (click)="backButtonClick()">
            <mat-icon class="header-icon">arrow_back</mat-icon>
        </button>


        <span class="header-spacer"></span>

        <span>{{title}}</span>

        <span class="header-spacer"></span>

        <button mat-icon-button *ngIf="addButton"
                [matTooltip]="'lwsDetailHeader_Add_ToolTipText'|translate"
                (click)="addButtonClick()">
            <mat-icon class="header-icon">add</mat-icon>
        </button>

    </mat-toolbar-row>

</mat-toolbar>
