import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LwsTableComponent} from '../../components/lws-table/lws-table.component';
import {LwsDetailHeaderComponent} from '../../components/lws-detail-header/lws-detail-header.component';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {YearService} from '../../services/server/year/year.service';
import {UnitService} from '../../services/server/unit/unit.service';
import {Location} from '@angular/common';
import {Unit} from '../../models/unit/unit';
import {Year} from '../../models/year/year';
import {LwsDialogComponent} from '../../components/lws-dialog/lws-dialog.component';
import {ColumnInformation} from '../../models/lws-table/column-information';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-years',
    templateUrl: './years.component.html',
    styleUrls: ['./years.component.scss']
})
export class YearsComponent implements OnInit, AfterViewInit {
    @ViewChild(LwsTableComponent) private table: LwsTableComponent;
    @ViewChild(MatPaginator) private paginator: MatPaginator;
    @ViewChild(LwsDetailHeaderComponent) private header: LwsDetailHeaderComponent;
    /**
     * the title of this view
     */
    public title: string = '';
    /**
     * the parent unit
     */
    public unit: Unit = null;
    /**
     *
     */
    public isLoading: boolean = false;
    /**
     * the length of the current loading
     */
    public resultsLength = 0;
    /**
     * the datasource
     */
    public dataSource: Year[] = [];
    /**
     * the breadcrumbs to display
     */
    public breadcrumbs: string[] = [];
    /**
     * the columns of the table to display
     */
    public displayedColumns: ColumnInformation[] = [
        new ColumnInformation('id', '#', 'number', 'id'),
        new ColumnInformation('name', 'Name', 'text', 'name'),
    ];

    constructor(public route: ActivatedRoute,
                public yearService: YearService,
                public router: Router,
                public dialog: MatDialog,
                public translator: TranslateService,
                public location: Location,
                public unitService: UnitService) {

    }

    ngOnInit() {
        this.route.paramMap.subscribe(
            (params: ParamMap) => {
                const id: number = +params.get('id');

                if (id > 0) {
                    this.loadUnitById(id);
                }
            }
        );
    }

    /**
     *
     */
    ngAfterViewInit() {
        const me = this;

        this.header.addClick.subscribe(() => {
            this.createNewYear();
        });

        this.header.backClick.subscribe(() => {
            this.location.back();
        });

        this.table.editClick.subscribe((year: Year) => {
            this.router.navigate(['/companies/years/' + year.id], {
                queryParams: {
                    unitId: this.unit.id,
                    breadcrumbs: this.breadcrumbs
                }
            });
        });

        this.table.openClick.subscribe((year: Year) => {
            this.router.navigate(['/companies/years/' + year.id + '/versions'], {
                queryParams: {
                    breadcrumbs: this.breadcrumbs
                }
            });
        });

        this.table.deleteClick.subscribe((year: Year) => {
            this.deleteYear(year);
        });

        this.table.loaded.subscribe(() => {
            this.table.sortChange.subscribe((data: { column: string, direction: string }) => {
                this.paginator.pageIndex = 0;
                this.load(data.column, data.direction);
            });
            this.paginator.page.subscribe(() => {
                this.load();
            });

            this.load();
        });
    }

    /**
     * load the latest breadcrumbs and add the given as a new one
     *
     * @param breadcrumb the breadcrumb to add
     */
    private loadBreadcrumbsAndAdd(breadcrumb?: string) {
        const me = this;

        if (me.breadcrumbs === null || me.breadcrumbs === undefined) {
            me.breadcrumbs = [];
        }

        this.route.queryParams.subscribe((queryParams) => {
            if (typeof queryParams.breadcrumbs === 'string') {
                me.breadcrumbs.push(queryParams.breadcrumbs);
            } else {
                me.breadcrumbs = (queryParams.breadcrumbs as string[]);
            }

            if (breadcrumb !== null && breadcrumb !== undefined && breadcrumb.length > 0) {
                me.breadcrumbs.push(breadcrumb);
            }
        });
    }

    /**
     * deletes the given year
     *
     * @param year the year to delete
     */
    private deleteYear(year: Year) {
        this.translator.get('years_DeleteAction_MessageContent').subscribe((messageTranslation: string) => {
            this.translator.get('years_DeleteAction_TitleContent').subscribe((titleTranslation: string) => {
                const dialogRef = this.dialog.open(LwsDialogComponent, {
                    data: {
                        cancelButton: true,
                        okButton: true,

                        message: messageTranslation,
                        title: titleTranslation
                    }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result === 'ok') {
                        this.isLoading = true;

                        this.yearService.delete(year.id).then(success => {
                            this.isLoading = false;

                            this.load();
                        });
                    }
                });
            });
        });
    }

    /**
     * load the table-service content
     */
    private load(column: string = '', direction: string = '') {
        this.isLoading = true;

        const sort: string = LwsTableComponent.getSortColumnByName(this.displayedColumns, column);
        const sortDesc: boolean = ((direction.toLowerCase() === 'desc') ? true : false);

        if (this.unit != null) {
            this.unitService.getYears(this.unit.id, this.paginator.pageIndex, this.paginator.pageSize, sort, sortDesc).then(
                (result: { items: Year[], numberOfAllItems: number }) => {
                    this.isLoading = false;

                    if (result.items) {
                        this.dataSource = result.items;
                        this.resultsLength = result.numberOfAllItems;
                    }
                },
                (error) => {
                    this.isLoading = false;
                });
        } else {
            this.yearService.getAll(this.paginator.pageIndex, this.paginator.pageSize, sort, sortDesc).then(
                (years) => {
                    this.isLoading = false;

                    if (years) {
                        this.dataSource = years;
                        this.resultsLength = years.length;
                    }
                },
                (error) => {
                    this.isLoading = false;
                });
        }
    }

    /**
     *
     * @param id the id of the company
     */
    private loadUnitById(id: number) {
        this.isLoading = true;

        this.unitService.getDetail(id).then(
            (unit) => {
                this.isLoading = false;

                if (unit == null) {
                    this.location.back();
                }

                this.unit = unit;

                this.translator.get('years_PageTitle_TitleText', {unitName: this.unit.name}).subscribe((translation: string) => {
                    this.title = translation;
                    this.loadBreadcrumbsAndAdd(this.unit.name);
                });
            },
            () => {
                this.isLoading = false;
            });
    }

    /**
     * opens the detail view to create a new company
     */
    private createNewYear() {
        this.router.navigate(['/companies/years/0'], {
            queryParams: {
                unitId: this.unit.id,
                breadcrumbs: this.breadcrumbs
            }
        });
    }
}
