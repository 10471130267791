import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {GadgetCatalogItem} from '../../../models/reporting-dashboard/gadget-catalog-item';
import {AccountService} from '../../../services/server/account/account.service';

@Component({
    selector: 'app-gadget-catalog',
    templateUrl: './gadget-catalog.component.html',
    styleUrls: ['./gadget-catalog.component.scss']
})
export class GadgetCatalogComponent {

    public gadgets: GadgetCatalogItem[] = [
        {
            name: 'Fieberkurve Antworten Unternehmen / Markt',
            type: 'TYPE_ANSWERES_COMPANY_MARKET',
        },
        {
            name: 'Auflistung Fragen',
            type: 'TYPE_ANSWER_LIST',
        },
        {
            name: 'Portfolio',
            type: 'TYPE_PORTFOLIO',
        },
        {
            name: 'Auswertung von Maßnahmen',
            type: 'TYPE_MEASURES',
        },
        {
            name: 'BWA-Auswertung',
            type: 'TYPE_BWA',
        },
        {
            name: 'Antworten Risiko / Compliance',
            type: 'TYPE_ANSWERES_RISK_COMPLIANCE',
        },
        {
            name: 'Excel-Export Fragen',
            type: 'TYPE_EXPORT_QUESTIONS',
        }
    ];
    public displayedColumns: string[] = ['name', 'actions'];

    constructor(
        public dialogRef: MatDialogRef<GadgetCatalogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

    }

    onNoClick(): void {
        this.dialogRef.close({});
    }

    addGadget(gadget: GadgetCatalogItem) {
        this.dialogRef.close({
            gadget: gadget
        });
    }

}
