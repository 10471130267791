import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Filter} from '../../../models/reporting-dashboard/filter';
import {ReportingGadget} from '../../../models/reporting-dashboard/reporting-gadget';
import {GadgetService} from '../../../services/server/reporting-dashboard/gadget.service';
import {TranslateService} from '@ngx-translate/core';
import {ReportingGadgetConfig} from '../../../models/reporting-dashboard/reporting-gadget-config';
import {ReportingGadgets} from '../../../services/client/reporting-gadgets';

@Component({
    selector: 'app-hierarchy-filter',
    templateUrl: './hierarchy-filter.component.html',
    styleUrls: ['./hierarchy-filter.component.scss']
})
export class HierarchyFilterComponent implements OnInit {

    @Input() gadget: ReportingGadget;
    @Input() segments: string[];
    @Output() selectionChanged = new EventEmitter();
    public flatFilters: Filter[] = [];
    public selectedIdentifier: string;

    public filters: Filter[] = [];

    constructor(public gadgetService: GadgetService,
                public translate: TranslateService,
                private reportingGadgetsLogic: ReportingGadgets) {
    }

    ngOnInit() {
        this.reload();
    }

    public filterChanged(identifier: string) {

        let config = this.reportingGadgetsLogic.getConfigValue(this.gadget.config, ReportingGadgetConfig.HIERARCHY_FILTER);
        if (config == null) {

            config = new ReportingGadgetConfig();
            config.key = ReportingGadgetConfig.HIERARCHY_FILTER;
            this.gadget.config.push(config);
        }
        config.value = identifier;
        this.selectionChanged.emit(identifier);
    }

    private toFlatFilters(filters: Filter[]) {

        this.flatFilters = [];

        const noFilter = new Filter();
        noFilter.identifier = '';
        noFilter.level = 0;
        this.translate.get('Company').subscribe(
            result => {
                noFilter.name = result;
            }
        );

        this.flatFilters.push(noFilter);

        for (const filterUnits of filters) {
            filterUnits.name = '' + filterUnits.name;
            filterUnits.level = 1;
            this.flatFilters.push(filterUnits);

            for (const filterSegments of filterUnits.childs) {
                if(!this.segments  || this.segments.length <= 0 || this.segments.includes(filterSegments.name)) {

                    filterSegments.name = '' + filterSegments.name;
                    filterSegments.level = 2;
                    this.flatFilters.push(filterSegments);
                    for (const filterFolder1 of filterSegments.childs) {
                        filterFolder1.name = '' + filterFolder1.name;
                        filterFolder1.level = 3;
                        this.flatFilters.push(filterFolder1);

                        for (const filterFolder2 of filterFolder1.childs) {
                            filterFolder2.name = '' + filterFolder2.name;
                            filterFolder2.level = 4;
                            this.flatFilters.push(filterFolder2);
                        }
                    }
                }
            }
        }

    }

    public reload() {

        if (this.gadget.id != null && this.gadget.id > 0 && this.gadget.year && this.gadget.companyId) {
            this.gadgetService.getHierarchyFilters(this.gadget.companyId, this.gadget.id).then((filters) => {
                    this.filters = filters;
                    this.toFlatFilters(filters);

                    const configValue = this.reportingGadgetsLogic.getConfigValue(this.gadget.config, ReportingGadgetConfig.HIERARCHY_FILTER);
                    if (configValue != null) {
                        this.selectedIdentifier = configValue.value;
                    }
                },
                () => {

                });
        }
    }

}
