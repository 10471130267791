export class ColumnInformation {

    /**
     * the key of the model field
     */
    private _column: string = '';
    /**
     * the title to display
     */
    private _title: string = '';
    /**
     * the type of the column data
     */
    private _type: string = '';
    /**
     * the sql column name to sort
     */
    private _sortColumn: string = '';

    /**
     * If set to true, the column of the table is read only
     */
    private _readonly: boolean = false;
    
    /**
     * If set to true, the column is not visible
     */
    private _hidden: boolean = false;
    
    /**
     * the max value for an input field (in inline table)
     */
    private _maxValue: number = 999999999;

    constructor(column: string, title: string, type: string, sortColumn?: string, readonly?: boolean, hidden?: boolean, maxValue?: number) {
        this.column = column;
        this.title = title;
        this.type = type;
        this.sortColumn = sortColumn;
        this.readonly = readonly;
        this.hidden = hidden;
        
        if(maxValue != undefined && maxValue != null) {
            this.maxValue = maxValue;
        }
    }


    get column(): string {
        return this._column;
    }
    
    set column(value: string) {
        this._column = value;
    }
    
    get title(): string {
        return this._title;
    }
    
    set title(value: string) {
        this._title = value;
    }
    
    get type(): string {
        return this._type;
    }
    
    set type(value: string) {
        this._type = value;
    }
    
    get sortColumn(): string {
        return this._sortColumn;
    }
    
    set sortColumn(value: string) {
        this._sortColumn = value;
    }

    get readonly(): boolean {
        return this._readonly;
    }

    set readonly(value: boolean) {
        this._readonly = value;
    }

    get hidden(): boolean {
        return this._hidden;
    }

    set hidden(value: boolean) {
        this._hidden = value;
    }
    
    get maxValue(): number {
        return this._maxValue;
    }
    
    set maxValue(value: number) {
        this._maxValue = value;
    }
}
