<h1 mat-dialog-title>{{'PleaseSelectAUser' | translate}}</h1>
<div mat-dialog-content>

  <mat-form-field>
    <mat-select [(ngModel)]="userSelection">
      <mat-option *ngFor="let user of users" [value]="user.id + '|' + user.name">
        {{user.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'lwsDialogButtons_cancel' | translate}}</button>
  <button mat-button [mat-dialog-close]="userSelection" cdkFocusInitial>{{'lwsDialogButtons_ok' | translate}}</button>
</div>
