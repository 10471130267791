import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../services/server/user/user.service';
import {EventService} from '../../services/client/event/event.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {AppConfig} from '../../app.config';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    public readonly customerBackgroundImagePath: string = AppConfig.settings.customer.wallpaperPath;
    /**
     * set true if debug the form
     */
    public isDebug: boolean = false;
    /**
     * additional form classes for animated, etc.
     */
    public changePasswordFormCssClasses: string = '';
    public isLoading: boolean = false;

    /**
     * the structure of the login-form
     */
    public changePasswordForm: FormGroup = this.formBuilder.group({
        // the current password field
        currentPassword: ['', Validators.compose([
            Validators.required
        ])],
        // the password field
        password: ['', Validators.compose([
            Validators.required,
            Validators.minLength(10)
        ])],
        // the password field
        passwordRepeat: ['', Validators.compose([
            Validators.required,
            Validators.minLength(10)
        ])],
    });

    constructor(public formBuilder: FormBuilder,
                public userService: UserService,
                public snackBar: MatSnackBar,
                public eventService: EventService,
                public translator: TranslateService,
                public router: Router) {
    }

    /**
     * executed if the user clicked on the login-button
     */
    private onSubmit() {
        const me = this;

        if (this.changePasswordForm.valid) {
            this.isLoading = true;

            this.userService.changePassword(
                this.changePasswordForm.value['currentPassword'],
                this.changePasswordForm.value['password']
            ).then(
                (success) => {
                    this.isLoading = false;

                    if (success) {
                        me.router.navigateByUrl('/dashboard');
                    } else {
                        this.changePasswordFormCssClasses = 'animated shake';

                        // remove the animation classes
                        setTimeout(() => {
                            this.changePasswordFormCssClasses = '';
                        }, 1000);
                    }
                }
            );
        }
    }

    ngOnInit() {

    }
}
