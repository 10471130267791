import {ModelInterface} from '../model-interface';
import {JSONConverter} from '../JSONConverter';
import {Folder} from '../folder/folder';
import {States} from './States';
import {MeasurePlaning} from './measure-planing';
import {MeasureResponsiblePerson} from './measure-responsible-person';
import {BusinessAnalysis} from './business-analysis';

export class Measure implements ModelInterface {

    private _id: number = 0;
    private _name: string = '';
    private _startdate: Date;
    private _enddate: Date;
    private _targetDescription: string = '';
    private _isDescription: string = '';
    private _useDescription: string = '';
    private _state: number = 0;
    private _folders: Folder[] = [];
    private _measurePlanings: MeasurePlaning[] = [];
    private _measureResponsiblePersons: MeasureResponsiblePerson[] = [];
    private _businessAnalysis: BusinessAnalysis = null;
    private _stateName: string = 'unknown';
    private _interoperabilityFolderId: number;
    private _netDamageMeasure: number;
    private _probabilityOfOccurrence: number;
    private _segmentName: string;
    
    // a list of properties-keys to ignore at jsonconvert
    _ignoreProperties: string[] = [];

    constructor() {

    }
    
    get id(): number {
        return this._id;
    }
    
    set id(value: number) {
        this._id = value;
    }
    
    get name(): string {
        return this._name;
    }
    
    set name(value: string) {
        this._name = value;
    }

    get startdate(): Date {
        return this._startdate;
    }

    set startdate(value: Date) {
        this._startdate = value;
    }

    get enddate(): Date {
        return this._enddate;
    }

    set enddate(value: Date) {
        this._enddate = value;
    }

    get targetDescription(): string {
        return this._targetDescription;
    }

    set targetDescription(value: string) {
        this._targetDescription = value;
    }

    get isDescription(): string {
        return this._isDescription;
    }

    set isDescription(value: string) {
        this._isDescription = value;
    }

    get useDescription(): string {
        return this._useDescription;
    }

    set useDescription(value: string) {
        this._useDescription = value;
    }

    get state(): number {
        return this._state;
    }

    set state(value: number) {
        this._state = value;
    }

    set stateName(value: string) {
        this._stateName = value;
    }

    get stateName(): string {
        return this._stateName;
    }

    public getTechnicalStateName() {

        let technicalStateName = 'unknown';

        if (this.state === States.PREPARATION) {
            technicalStateName = 'STATE_PREPARATION';
        } else if (this.state === States.ACTIVE) {
            technicalStateName = 'STATE_ACTIVE';
        } else if (this.state === States.RISK_TIME) {
            technicalStateName = 'STATE_RISK_TIME';
        } else if (this.state === States.RISK_RESSOURCE) {
            technicalStateName = 'STATE_RISK_RESSOURCE';
        } else if (this.state === States.RISK_BUDGET) {
            technicalStateName = 'STATE_RISK_BUDGET';
        } else if (this.state === States.RISK_SCOPE) {
            technicalStateName = 'STATE_RISK_SCOPE';
        } else if (this.state === States.RSIK_QUALITY) {
            technicalStateName = 'STATE_RSIK_QUALITY';
        } else if (this.state === States.PASSIV) {
            technicalStateName = 'STATE_PASSIV';
        } else if (this.state === States.COMPLETED) {
            technicalStateName = 'STATE_COMPLETED';
        }

        return technicalStateName;
    }

    get folders(): Folder[] {
        return this._folders;
    }

    set folders(value: Folder[]) {
        this._folders = value;
    }

    get measurePlanings(): MeasurePlaning[] {
        return this._measurePlanings;
    }

    set measurePlanings(value: MeasurePlaning[]) {
        this._measurePlanings = value;
    }

    get measureResponsiblePersons(): MeasureResponsiblePerson[] {
        return this._measureResponsiblePersons;
    }

    set measureResponsiblePersons(value: MeasureResponsiblePerson[]) {
        this._measureResponsiblePersons = value;
    }

    get businessAnalysis(): BusinessAnalysis {
        return this._businessAnalysis;
    }

    set businessAnalysis(value: BusinessAnalysis) {
        this._businessAnalysis = value;
    }

    /**
     * returns the object as a valid json (without the underscore a the property name begin)
     */
    toJSON() {
        return new JSONConverter().toJSON(this);
    }
    
    /**
     * creates a object from the given data
     *
     * @param data the data to create a model
     */
    static hydrate(data: any): Measure {
        const model: Measure = new Measure();

        Object.assign(model, data);

        return model;
    }

    get interoperabilityFolderId(): number {
        return this._interoperabilityFolderId;
    }

    set interoperabilityFolderId(value: number) {
        this._interoperabilityFolderId = value;
    }

    get netDamageMeasure(): number {
        return this._netDamageMeasure;
    }

    set netDamageMeasure(value: number) {
        this._netDamageMeasure = value;
    }

    get probabilityOfOccurrence(): number {
        return this._probabilityOfOccurrence;
    }

    set probabilityOfOccurrence(value: number) {
        this._probabilityOfOccurrence = value;
    }

    get segmentName(): string {
        return this._segmentName;
    }

    set segmentName(value: string) {
        this._segmentName = value;
    }
}
