import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ColumnInformation} from '../../models/lws-table/column-information';
import {MatSort} from "@angular/material/sort";

@Component({
    selector: 'app-lws-table',
    templateUrl: './lws-table.component.html',
    styleUrls: ['./lws-table.component.scss']
})
export class LwsTableComponent implements OnInit, AfterViewInit {
    /**
     *
     */
    @ViewChild(MatSort) private sort: MatSort;
    /**
     *
     */
    public layoutSelected: string = 'default';
    /**
     *
     */
    public columns;
    /**
     * the datasource to bind on the table
     */
    @Input() datasource;
    /**
     * a list of columns that should be displayed in the table
     */
    @Input() displayedColumns: { column: string, title: string, type: string }[] = [];
    /**
     * the layout for the table
     *
     * default: no layout
     * full: 100% width
     */
    @Input() layout: string;
    /**
     * if true, the action column is displayed
     */
    @Input() enableActions: boolean = false;
    /**
     * if true, the open column is displayed
     */
    @Input() enableOpenAction: boolean = false;
    /**
     * if true, the edit column is displayed
     */
    @Input() enableEditAction: boolean = false;
    /**
     * if true, the edit column is displayed
     */
    @Input() enableCopyAction: boolean = false;
    /**
     * if true, the edit column is displayed
     */
    @Input() enableMoveAction: boolean = false;
    /**
     * if true, the delete column is displayed
     */
    @Input() enableDeleteAction: boolean = false;

    /**
     * If true, export column is displayed
     */
    @Input() enableExportAction: boolean = false;


    /**
     * if true, the row listen on a single click
     */
    @Input() enableSingleClickAction: boolean = false;
    /**
     * if true, the row listen on a double click
     */
    @Input() enableDoubleClickAction: boolean = false;

    @Output() loaded = new EventEmitter();
    @Output() editClick = new EventEmitter();
    @Output() exportClick = new EventEmitter();
    @Output() copyClick = new EventEmitter();
    @Output() moveClick = new EventEmitter();
    @Output() deleteClick = new EventEmitter();
    @Output() openClick = new EventEmitter();
    @Output() sortChange = new EventEmitter<{ column: string, direction: string }>();
    @Output() itemSingleClick = new EventEmitter();
    @Output() itemDoubleClick = new EventEmitter();

    constructor() {

    }

    /**
     *
     */
    ngOnInit() {
        this.LoadLayoutValue(this.layout);
        this.GenerateColumns(this.displayedColumns);
    }

    /**
     *
     */
    ngAfterViewInit() {
        const me = this;

        this.sort.sortChange.subscribe(() => {
            me.sortChange.emit({column: me.sort.active, direction: me.sort.direction});
        });

        setTimeout(() => {
            // trigger the loaded event
            me.loaded.emit();
        }, 250);
    }

    /**
     *
     * @param columns contains the columns to display
     */
    public GenerateColumns(columns: { column: string, title: string, type: string }[]) {
        this.columns = [];

        for (const columnEntry of columns) {
            this.columns.push(columnEntry.column);
        }

        if (this.enableActions === true) {
            this.columns.push('actions');
        }
    }

    /**
     * load the layout-classname for the table
     *
     * @param layoutValue the given layout-value
     */
    public LoadLayoutValue(layoutValue: string) {
        if (layoutValue === undefined || layoutValue.length <= 0) {
            return;
        }

        switch (layoutValue.toLowerCase()) {
            case 'full': {
                this.layoutSelected = 'full';
            }
                break;
            default: {
                this.layoutSelected = 'default';
            }
                break;
        }
    }

    /**
     * executed if the user clicked on the edit action button
     *
     * @param row the whole dataset for the clicked row
     */
    private editAction(row) {
        console.log('edit');
        this.editClick.emit(row);
    }

    /**
     * executed if the user clicked on the copy action button
     *
     * @param row the whole dataset for the clicked row
     */
    private copyAction(row) {
        this.copyClick.emit(row);
    }

    /**
     * executed if the user clicked on the move action button
     *
     * @param row the whole dataset for the clicked row
     */
    private moveAction(row) {
        this.moveClick.emit(row);
    }

    /**
     * executed if the user clicked on the delete action button
     *
     * @param row the whole dataset for the clicked row
     */
    private deleteAction(row) {
        this.deleteClick.emit(row);
    }

    /**
     * executed if the user clicked on the open action button
     *
     * @param row the whole dataset for the clicked row
     */
    private openAction(row) {
        this.openClick.emit(row);
    }

    /**
     * Handle export
     */
    private exportAction(row) {
        this.exportClick.emit(row);
    }

    /**
     * executed if the user clicked on the open action button. Process the actions single click, then open and at least edit.
     *
     * @param row the whole dataset for the clicked row
     */
    private openItemBySingleClick(row) {
        if (this.enableSingleClickAction) {
            this.itemSingleClick.emit(row);
        } else if (this.enableOpenAction) {
            this.openAction(row);
        } else if (this.enableEditAction) {
            this.editAction(row);
        }
    }

    /**
     * executed if the user clicked on the open action button
     *
     * @param row the whole dataset for the clicked row
     */
    private openItemByDoubleClick(row) {
        if (this.enableDoubleClickAction) {
            this.itemDoubleClick.emit(row);
        }
    }

    /**
     * returns the sql name of the column to sort
     *
     * @param columns the list of columns
     * @param columnName the given column-name
     */
    public static getSortColumnByName(columns: ColumnInformation[], columnName: string): string {
        if (columnName !== null && columnName.length > 0) {
            const columnDataItems: ColumnInformation[] = columns.filter(x => x.column === columnName);

            if (columnDataItems !== null && columnDataItems.length > 0) {
                const columnData: ColumnInformation = columnDataItems[0];
                return columnData.sortColumn;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }
}
