<mat-card [ngClass]="cssClass">

    <mat-card-header *ngIf="title && title.length > 0">
        <mat-card-title>{{title}}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <div [innerHTML]="message"></div>
    </mat-card-content>

</mat-card>
