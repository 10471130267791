import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {LwsDetailHeaderComponent} from '../../components/lws-detail-header/lws-detail-header.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CompaniesListComponent} from '../../components/companies-list/companies-list.component';

export interface BreadCrumb {
    label: string;
    url: string;
}


@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit, AfterViewInit {
    @ViewChild(LwsDetailHeaderComponent) private header: LwsDetailHeaderComponent;
    @ViewChild(CompaniesListComponent) private companiesList: CompaniesListComponent;
    public breadcrumbs: string[] = [];

    public headerTitle: string;

    constructor(public router: Router,
                public route: ActivatedRoute,
                public translator: TranslateService) {
        this.translator.get('sidenav_PagesCompanies_Title').subscribe((translation: string) => {
            this.headerTitle = translation;
            this.breadcrumbs.push(translation);
        });
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        const me = this;

        this.header.addClick.subscribe(() => {
            this.createNewCompany();
        });
        this.companiesList.editClick.subscribe((data) => {
            me.router.navigate(['/companies/' + data.company.id], {
                queryParams: {
                    breadcrumbs: me.breadcrumbs
                }
            });
        });
    }

    /**
     * opens the detail view to create a new company
     */
    private createNewCompany() {
        this.router.navigate(['/companies/0'], {
            queryParams: {
                breadcrumbs: this.breadcrumbs
            }
        });
    }
}
