import {Component, Inject, OnInit} from '@angular/core';
import {BusinessAnalysis} from '../../models/measure/business-analysis';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {CurrencyPipe} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-measure-bwa',
    templateUrl: './measure-bwa.component.html',
    styleUrls: ['./measure-bwa.component.scss']
})
export class MeasureBwaComponent implements OnInit {
    public userSelection: string;
    public businessAnalysis: BusinessAnalysis;

    private revenuesFormControl = new FormControl('', [Validators.min(0)]);
    private revenuesPaFormControl = new FormControl('', [Validators.min(0)]);
    private feUeFormControl = new FormControl('', [Validators.min(0)]);
    private feUePaFormControl = new FormControl('', [Validators.min(0)]);
    private ownWorkFormControl = new FormControl('', [Validators.min(0)]);
    private ownWorkPaFormControl = new FormControl('', [Validators.min(0)]);
    private goodsHoppingFormControl = new FormControl('', [Validators.min(0)]);
    private goodsHoppingPaFormControl = new FormControl('', [Validators.min(0)]);
    private otherIncomeFormControl = new FormControl('', [Validators.min(0)]);
    private otherIncomePaFormControl = new FormControl('', [Validators.min(0)]);
    private personnelCostsFormControl = new FormControl('', [Validators.min(0)]);
    private personnelCostsPaFormControl = new FormControl('', [Validators.min(0)]);
    private roomCostsFormControl = new FormControl('', [Validators.min(0)]);
    private roomCostsPaFormControl = new FormControl('', [Validators.min(0)]);
    private companyTaxFormControl = new FormControl('', [Validators.min(0)]);
    private companyTaxPaFormControl = new FormControl('', [Validators.min(0)]);
    private insuranceCostsFormControl = new FormControl('', [Validators.min(0)]);
    private insuranceCostsPaFormControl = new FormControl('', [Validators.min(0)]);
    private specialCostsFormControl = new FormControl('', [Validators.min(0)]);
    private specialCostsPaFormControl = new FormControl('', [Validators.min(0)]);
    private vehicleCostsFormControl = new FormControl('', [Validators.min(0)]);
    private vehicleCostsPaFormControl = new FormControl('', [Validators.min(0)]);
    private marketingCostsFormControl = new FormControl('', [Validators.min(0)]);
    private marketingCostsPaFormControl = new FormControl('', [Validators.min(0)]);
    private depreciationCostsFormControl = new FormControl('', [Validators.min(0)]);
    private depreciationCostsPaFormControl = new FormControl('', [Validators.min(0)]);
    private depreciationFormControl = new FormControl('', [Validators.min(0)]);
    private depreciationPaFormControl = new FormControl('', [Validators.min(0)]);
    private repairCostsFormControl = new FormControl('', [Validators.min(0)]);
    private repairCostsPaFormControl = new FormControl('', [Validators.min(0)]);
    private otherCostsFormControl = new FormControl('', [Validators.min(0)]);
    private otherCostsPaFormControl = new FormControl('', [Validators.min(0)]);
    private interestExpenseFormControl = new FormControl('', [Validators.min(0)]);
    private interestExpensePaFormControl = new FormControl('', [Validators.min(0)]);
    private otherExpenditureFormControl = new FormControl('', [Validators.min(0)]);
    private otherExpenditurePaFormControl = new FormControl('', [Validators.min(0)]);
    private interestIncomeFormControl = new FormControl('', [Validators.min(0)]);
    private interestIncomePaFormControl = new FormControl('', [Validators.min(0)]);
    private otherYieldControl = new FormControl('', [Validators.min(0)]);
    private otherYieldPaFormControl = new FormControl('', [Validators.min(0)]);
    private calcCostsFormControl = new FormControl('', [Validators.min(0)]);
    private calcCostsPaFormControl = new FormControl('', [Validators.min(0)]);

    private invalid: boolean;

    constructor(public formBuilder: FormBuilder,
                public dialogRef: MatDialogRef<MeasureBwaComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { businessAnalysis: BusinessAnalysis },
                public currencyPipe: CurrencyPipe) {
    }

    ngOnInit() {
        this.businessAnalysis = new BusinessAnalysis();

        if (this.data.businessAnalysis != null) {
            this.businessAnalysis = this.data.businessAnalysis;
        }
        this.businessAnalysis.calcSums();
    }

    /**
     *
     */
    onNoClick(): void {
        this.dialogRef.close(null);
    }

    /**
     *
     */
    onSaveClick(): void {
        this.dialogRef.close(this.businessAnalysis);
    }

    /**
     *
     */
    bwaChanged() {
        this.businessAnalysis.calcSums();
    }

    /**
     * saves the question by an user
     */
    public isBWAValidForSave() {
        if (!this.areFormControlsValid()) {
            return false;
        }

        if (this.invalid) {
            return false;
        }

        return true;
    }

    /**
     *
     * @returns {boolean}
     */
    public areFormControlsValid(): boolean {

        if (!this.ownWorkFormControl.valid) {
            return false;
        }
        if (!this.ownWorkPaFormControl.valid) {
            return false;
        }
        if (!this.goodsHoppingFormControl.valid) {
            return false;
        }
        if (!this.goodsHoppingPaFormControl.valid) {
            return false;
        }
        if (!this.otherIncomeFormControl.valid) {
            return false;
        }
        if (!this.otherIncomePaFormControl.valid) {
            return false;
        }
        if (!this.personnelCostsFormControl.valid) {
            return false;
        }
        if (!this.personnelCostsPaFormControl.valid) {
            return false;
        }
        if (!this.roomCostsFormControl.valid) {
            return false;
        }
        if (!this.roomCostsPaFormControl.valid) {
            return false;
        }
        if (!this.revenuesFormControl.valid) {
            return false;
        }
        if (!this.revenuesPaFormControl.valid) {
            return false;
        }
        if (!this.feUeFormControl.valid) {
            return false;
        }
        if (!this.feUePaFormControl.valid) {
            return false;
        }
        if (!this.companyTaxFormControl.valid) {
            return false;
        }
        if (!this.companyTaxPaFormControl.valid) {
            return false;
        }
        if (!this.insuranceCostsFormControl.valid) {
            return false;
        }
        if (!this.insuranceCostsPaFormControl.valid) {
            return false;
        }
        if (!this.specialCostsFormControl.valid) {
            return false;
        }
        if (!this.specialCostsPaFormControl.valid) {
            return false;
        }
        if (!this.vehicleCostsFormControl.valid) {
            return false;
        }
        if (!this.vehicleCostsPaFormControl.valid) {
            return false;
        }
        if (!this.marketingCostsFormControl.valid) {
            return false;
        }
        if (!this.marketingCostsPaFormControl.valid) {
            return false;
        }
        if (!this.depreciationCostsFormControl.valid) {
            return false;
        }
        if (!this.depreciationCostsPaFormControl.valid) {
            return false;
        }
        if (!this.depreciationFormControl.valid) {
            return false;
        }
        if (!this.depreciationPaFormControl.valid) {
            return false;
        }
        if (!this.repairCostsFormControl.valid) {
            return false;
        }
        if (!this.repairCostsPaFormControl.valid) {
            return false;
        }
        if (!this.otherCostsFormControl.valid) {
            return false;
        }
        if (!this.otherCostsPaFormControl.valid) {
            return false;
        }
        if (!this.interestExpenseFormControl.valid) {
            return false;
        }
        if (!this.interestExpensePaFormControl.valid) {
            return false;
        }
        if (!this.otherExpenditureFormControl.valid) {
            return false;
        }
        if (!this.otherExpenditurePaFormControl.valid) {
            return false;
        }
        if (!this.interestIncomeFormControl.valid) {
            return false;
        }
        if (!this.interestIncomePaFormControl.valid) {
            return false;
        }
        if (!this.otherYieldControl.valid) {
            return false;
        }
        if (!this.otherYieldPaFormControl.valid) {
            return false;
        }
        if (!this.calcCostsFormControl.valid) {
            return false;
        }
        if (!this.calcCostsPaFormControl.valid) {
            return false;
        }

        return true;
    }

    public currencyInputChanged(value) {
        this.invalid = false;

        if (value == null) {
            value = '';
        }

        const num = value
            .replace('.', '')
            .replace(',', '.')
            .replace(/[$]/g, '')
            .replace('€', '');

        if (num != null) {
            if (num.match(/\-?[0-9]+([\.|,][0-9]{1,2})?/) == null) {
                this.invalid = true;
            }
        }

        const numberValue = Number(num);
        return numberValue;
    }
}
