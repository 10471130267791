import {ModelInterface} from '../model-interface';
import {Unit} from '../unit/unit';
import {Group} from '../group/group';

export class Year implements ModelInterface {
    private _id: number = 0;
    private _name: string = '';
    private _unit: Unit = null;
    
    // a list of properties-keys to ignore at jsonconvert
    _ignoreProperties: string[] = [];
    
    constructor() {
    
    }
    
    get id(): number {
        return this._id;
    }
    
    set id(value: number) {
        this._id = value;
    }
    
    get name(): string {
        return this._name;
    }
    
    set name(value: string) {
        this._name = value;
    }
    
    get unit(): Unit {
        return this._unit;
    }
    
    set unit(value: Unit) {
        this._unit = value;
    }
    
    /**
     * returns the object as a valid json (without the underscore a the property name begin)
     */
    toJSON() {
        return {
            id: this.id,
            name: this.name,
            unit: ((this.unit == null) ? null : this.unit.toJSON()),
        };
    }
    
    /**
     * creates a object from the given data
     *
     * @param data the data to create a model
     */
    static hydrate(data: any): Year {
        const model: Year = new Year();
        
        model.id = data.id;
        model.name = data.name;
        
        if (data.unit) {
            model.unit = Unit.hydrate(data.unit);
        }
        
        return model;
    }
}
