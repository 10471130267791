import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {AccountService} from '../account/account.service';
import {RestService} from '../rest/rest.service';
import {EventService} from '../../client/event/event.service';
import {TranslateService} from '@ngx-translate/core';
import {ErrorHandlingService} from '../../client/error-handling/error-handling.service';
import {Question} from '../../../models/question/question';
import {RequestBodyType} from '../rest/request-body-type.enum';
import {Answer} from '../../../models/answer/answer';
import {QuestionWithPath} from '../../../models/question/question-with-path';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class QuestionService {

    constructor(private accountService: AccountService,
                private restService: RestService,
                private eventService: EventService,
                private snackBar: MatSnackBar,
                private translator: TranslateService,
                private errorHandlingService: ErrorHandlingService) {

    }

    /**
     * returns the requested version
     *
     * @param id the id of the requested version
     */
    public getDetail(id: number): Promise<Question> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.get('/questions/' + id, parameter).toPromise().then(
            (result) => {
                const question: Question = Question.hydrate(result.body);

                return question;
            },
            (httpError: HttpErrorResponse) => {
                this.errorHandlingService.handleError('questionService_DetailRequest', httpError);

                return null;
            });
    }

    /**
     * saves the given question
     *
     * @param question the question to save
     */
    public save(question: Question): Promise<number> {
        return this.restService.post(
            '/questions/' + question.id,
            question,
            true).toPromise()
        .then((id: number) => {
            this.eventService.Trigger('question:create:success', id);

            this.translator.get('questionService_SaveRequest_SuccessMessage').subscribe(
                (translation: string) => {
                    this.snackBar.open(translation, '', {
                        duration: (5 * 1000), // display 5 seconds
                        panelClass: 'snackbar-lws-success'
                    });
                });

            return id;
        }).catch((httpError: HttpErrorResponse) => {
            this.errorHandlingService.handleError('questionService_SaveRequest', httpError);

            return null;
        });
    }

    /**
     * deletes the item with the given id
     *
     * @param id the id of the item to delete
     * @param folderId the id of the containing folder
     */
    public delete(id: number, folderId: number): Promise<boolean> {
        const parameter: { key: string, value: string }[] = [];
        parameter.push({key: 'folderId', value: folderId.toString()});

        return this.restService.delete(
            '/questions/' + id,
            parameter,
            true).toPromise()
        .then((response: any) => {

            this.translator.get('questionService_DeleteRequest_SuccessMessage').subscribe(
                (translation: string) => {
                    this.snackBar.open(translation, '', {
                        duration: (5 * 1000), // display 5 seconds
                        panelClass: 'snackbar-lws-success'
                    });
                });
            return true;
        }).catch((httpError: any) => {
            this.errorHandlingService.handleError('questionService_DeleteRequest', httpError);

            return false;
        });
    }

    /**
     * moves the given question to the given folder
     *
     * @param questionId the id of the question to move
     * @param targetFolderId the id of the target folder
     */
    public move(questionId: number, targetFolderId: number): Promise<boolean> {
        const parameter: { key: string, value: string }[] = [];
        parameter.push({key: 'targetFolderId', value: targetFolderId.toString()});

        return this.restService.postForm(
            '/questions/' + questionId + '/move',
            parameter,
            RequestBodyType.FormUrlEncoded).toPromise()
        .then((response: any) => {

            this.translator.get('questionService_MoveRequest_SuccessMessage').subscribe(
                (translation: string) => {
                    this.snackBar.open(translation, '', {
                        duration: (5 * 1000), // display 5 seconds
                        panelClass: 'snackbar-lws-success'
                    });
                });
            return true;
        }).catch((httpError: any) => {
            this.errorHandlingService.handleError('questionService_MoveRequest', httpError);

            return false;
        });
    }

    /**
     * saves the given answer
     *
     * @param questionId the id of the parent question
     * @param folderId the id of the parent folder (second layer)
     * @param answer the answer to save
     */
    public saveAnswer(questionId: number, folderId: number, answer: Answer): Promise<number> {
        return this.restService.post(
            '/questions/' + questionId + '/answer?folderId=' + folderId,
            answer,
            true).toPromise()
        .then((id: number) => {
            this.eventService.Trigger('answer:create:success', id);

            this.translator.get('questionService_AnswerSaveRequest_SuccessMessage').subscribe(
                (translation: string) => {
                    this.snackBar.open(translation, '', {
                        duration: (5 * 1000), // display 5 seconds
                        panelClass: 'snackbar-lws-success'
                    });
                });

            return id;
        }).catch((httpError: HttpErrorResponse) => {
            this.errorHandlingService.handleError('questionService_AnswerSaveRequest', httpError);

            return null;
        });
    }

    /**
     * returns the answer of the requested question
     *
     * @param questionId the id of the parent question
     * @param folderId the id of the parent folder (second layer)
     */
    public getAnswerDetail(questionId: number, folderId: number): Promise<Answer> {
        const parameter: { key: string, value: string }[] = [];
        parameter.push({key: 'folderId', value: folderId.toString()});

        return this.restService.get('/questions/' + questionId + '/answer', parameter).toPromise().then(
            (result) => {
                const answer: Answer = Answer.hydrate(result.body);

                return answer;
            },
            (httpError: HttpErrorResponse) => {
                this.errorHandlingService.handleError('questionService_AnswerDetailRequest', httpError);

                return null;
            });
    }

    public getAllFiltered(
        companyId: number,
        yearName: string,
        versionName: string,
        filterType: string,
        page: number = 0,
        count: number = 50,
        sort: string = null,
        sortDesc: boolean = false): Promise<{
        items: QuestionWithPath[],
        numberOfAllItems: number
    }> {
        const parameter: { key: string, value: string }[] = [];
        parameter.push({key: 'companyId', value: companyId.toString()});
        parameter.push({key: 'yearName', value: yearName});
        parameter.push({key: 'versionName', value: versionName});
        parameter.push({key: 'filterType', value: filterType});

        if (page && page > 0) {
            parameter.push({key: 'page', value: page.toString()});
        }
        if (count && count > 0) {
            parameter.push({key: 'count', value: count.toString()});
        }
        if (sort && sort.length > 0) {
            parameter.push({key: 'sortColumn', value: sort.toString()});
            parameter.push({key: 'sortDesc', value: sortDesc.toString()});
        }


        return this.restService.get('/questions', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const questions: QuestionWithPath[] = [];

                for (const responseItem of response) {
                    questions.push(QuestionWithPath.hydrate(responseItem));
                }


                return {
                    items: questions,
                    numberOfAllItems: Number(result.headers.get('x-items-count'))
                };
            },
            (httpError: HttpErrorResponse) => {
                this.errorHandlingService.handleError('questionService_AnswerDetailRequest', httpError);
                return {
                    items: [],
                    numberOfAllItems: 0
                };
            });
    }

}
