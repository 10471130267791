import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Question} from '../../models/question/question';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../models/folder/folder';
import {AccountService} from '../../services/server/account/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {FolderService} from '../../services/server/folder/folder.service';
import {QuestionService} from '../../services/server/question/question.service';
import {Segment} from '../../models/segment/segment';
import {Unit} from '../../models/unit/unit';
import {Answer} from '../../models/answer/answer';
import {CompanyService} from '../../services/server/company/company.service';
import {Company} from '../../models/company/company';
import {RightsCheck} from '../../businesslogic/RightsCheck';
import {LockService} from '../../services/server/lock/lock.service';
import {LockType} from '../../services/server/lock/lock-type.enum';
import {Observable, Subscription, timer} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
    selector: 'app-question-detail-form',
    templateUrl: './question-detail-form.component.html',
    styleUrls: ['./question-detail-form.component.scss']
})
export class QuestionDetailFormComponent implements OnInit, OnDestroy {
    @Input() question: Question = null;
    @Input() public folder: Folder = null;
    @Input() public segment: Segment = null;
    @Input() public unit: Unit = null;
    @Input() public readOnlyByLockingUser: boolean = false;
    @Output() questionSaved = new EventEmitter<number>();
    public isLoading: boolean = false;
    public questionRelevanceValue: { title: string, id: number } = null;
    public successFactors: string[] = [];
    public relevanceValues: { title: string, id: number }[] = [];
    public amountOfDamages: string[] = [];
    public probabilityOfOccurrenceValues: number[] = [0, 25, 50, 75, 100];
    public answer: Answer;
    public factor: number;

    private lastRatingValue: number = 1;
    private lastRelevanceValue;
    private riskAvoidFormControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
    private riskReduceFormControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
    private riskShuffleOffFormControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
    private riskTakeOverFormControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
    private lockRefreshTimer: Observable<number> = timer(0, LockService.lockRefreshTimer * 1000);
    private lockRefreshSubscription: Subscription = null;

    /**
     * the structure of the form
     */
    public form: FormGroup = this.formBuilder.group({
        question: ['', Validators.compose([
            Validators.required
        ])],
        factor: ['', Validators.compose([
            Validators.required,
            Validators.min(1),
            Validators.max(100)
        ])],
        infotext: ['', Validators.compose([])],
    });

    constructor(public formBuilder: FormBuilder,
                public accountService: AccountService,
                public snackBar: MatSnackBar,
                public router: Router,
                public route: ActivatedRoute,
                public folderService: FolderService,
                public questionService: QuestionService,
                public translatorService: TranslateService,
                public companiesService: CompanyService,
                public rightsCheck: RightsCheck,
                public lockService: LockService) {

    }

    ngOnDestroy() {
        if (this.readOnlyByLockingUser !== true) {
            if (this.question != null && this.question.id > 0) {
                this.lockService.unlock(this.question.id, LockType.QUESTION);
            }
        }

        if (this.lockRefreshSubscription != null) {
            this.lockRefreshSubscription.unsubscribe();
        }
    }

    ngOnInit() {
        this.loadFactor();
        this.loadResponseDataForChanceQuestion();
        this.loadSuccessFactorFromUnitSuccessFactorQuestion();
        this.loadAmountOfDamages();
        this.loadModel();
        this.loadAnswerForThisQuestion();

        if (this.lockRefreshSubscription != null) {
            this.lockRefreshSubscription = this.lockRefreshTimer.subscribe(() => {
                this.lockService.lock(this.question.id, LockType.QUESTION);
            });
        }

    }


    /**
     * Submit for question save
     */
    private onSubmit() {
        // only admins can save the question
        if (!this.canUserSave()) {
            return;
        }

        if (this.form.valid && this.folder != null) {
            this.isLoading = true;

            const question: Question = ((this.question == null) ? new Question() : this.question);
            question.factor = this.form.value['factor'];
            question.infotext = this.form.value['infotext'];
            question.question = this.form.value['question'];

            if (question.folders === null) {
                question.folders = [];
            }

            if (question.folders.filter(x => x.id !== this.folder.id).length <= 0) {
                question.folders.push(this.folder);
            }

            this.questionService.save(question).then(
                (questionId) => {
                    this.isLoading = false;

                    this.questionSaved.emit(questionId);
                },
                () => {
                    this.isLoading = false;
                });
        }
    }

    /**
     *
     */
    private loadAnswerForThisQuestion() {
        if (this.question != null && this.question.id > 0) {
            this.questionService.getAnswerDetail(this.question.id, this.folder.id).then(
                (answer: Answer) => {
                    if (answer) {
                        this.answer = answer;

                        for (const relevanceEntry of this.relevanceValues) {
                            if (relevanceEntry.id === answer.relevance) {
                                this.questionRelevanceValue = relevanceEntry;
                            }
                        }

                        if (this.answer.factor != null && this.answer.factor > 0) {
                            this.factor = this.answer.factor;
                        }

                        if (this.question != null) {
                            const infotext = this.question.infotext;
                            const question = this.question.question;

                            this.form.setValue({
                                infotext: infotext,
                                question: question,
                                factor: this.factor
                            });
                        }

                        if (this.answer.rating == null || this.answer.rating < 0) {
                            this.answer.rating = 1;
                        }
                    }
                }
            );
        }
    }

    /**
     *
     */
    private loadFactor() {
        if (this.question != null && this.question.factor != null && this.question.factor > 0) {
            this.factor = this.question.factor;
        } else {
            this.factor = 50;
        }
    }

    /**
     * loads the form model from the company
     */
    private loadModel() {
        let infotext = '';
        let question = '';

        this.answer = new Answer();
        this.answer.rating = 1;

        if (this.question != null) {
            infotext = this.question.infotext;
            question = this.question.question;
        }

        this.form.setValue({
            infotext: infotext,
            question: question,
            factor: this.factor
        });
    }

    /**
     * loads the success factor from the current unit
     */
    private loadSuccessFactorFromUnitSuccessFactorQuestion() {
        this.successFactors = [];

        this.addNoneEmptyValueToList(this.unit.successFactor1, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor2, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor3, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor4, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor5, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor6, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor7, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor8, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor9, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor10, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor11, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor12, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor13, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor14, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor15, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor16, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor17, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor18, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor19, this.successFactors);
        this.addNoneEmptyValueToList(this.unit.successFactor20, this.successFactors);
    }

    private loadAmountOfDamages() {

        this.companiesService.getCompanyByUnitId(this.unit.id).then(
            (company: Company) => {
                this.amountOfDamages = [];

                this.addNoneEmptyValueToList(company.amountOfLoss1, this.amountOfDamages);
                this.addNoneEmptyValueToList(company.amountOfLoss2, this.amountOfDamages);
                this.addNoneEmptyValueToList(company.amountOfLoss3, this.amountOfDamages);
                this.addNoneEmptyValueToList(company.amountOfLoss4, this.amountOfDamages);

            });
    }

    /**
     * checks the given factor value and adds it to the ui-list
     *
     * @param successFactor the factor to add
     */
    private addNoneEmptyValueToList(value: string, list) {
        if (value == null || value.trim().length <= 0) {
            return;
        }

        list.push(value);
    }

    /**
     * loads the data for the chance select based on the current segment
     */
    private loadResponseDataForChanceQuestion() {
        if (this.segment === null || this.segment === undefined) {
            return;
        }

        this.relevanceValues = [];

        this.relevanceValues = [
            {title: 'Low', id: 1},   // low
            {title: 'Medium', id: 2},      // medium
            {title: 'High', id: 3}      // high
        ];
    }

    /**
     *
     */
    private abortForm() {
        this.questionSaved.emit(null);
    }

    /**
     * saves the question by an user
     */
    private saveAnswerByUser() {
        if (!this.isQuestionByUserValidForSave(this.segment)) {
            this.translatorService.get('questionDetailForm_SaveRequest_ValidationError').subscribe(
                (translation: string) => {
                    this.snackBar.open(translation, '', {
                        duration: (5 * 1000), // display 5 seconds
                        panelClass: 'snackbar-lws-error'
                    });
                });
            return;
        }

        /*
        // not needed for save - it'll be ignored by the java json converter. it comes via the url parameter (questionId and folderId)
        answer.folder = this.folder;
        answer.question = this.question;
        /* */
        this.answer = this.addValuesOfFormToAnswerBySegment(this.segment, this.answer);


        this.questionService.saveAnswer(this.question.id, this.folder.id, this.answer);
    }

    /**
     * add the values by the current segment to the given answer
     *
     * @param segment the current segment
     * @param answer the answer to fill
     */
    private addValuesOfFormToAnswerBySegment(segment: Segment, answer: Answer): Answer {

        if (segment.isMarket() || segment.isCompany()) {
            if (this.questionRelevanceValue != null) {
                answer.relevance = this.questionRelevanceValue.id;
            } else {
                answer.relevance = null;
            }
        }
        if (!(segment.isCompany() || segment.isMarket())) {
            answer.selectionSuccessFactor = null;
        }

        answer.factor = this.form.value['factor'];

        return answer;
    }

    /**
     * checks the question form that its valid to save by an user
     *
     * @param segment the current segment
     */
    private isQuestionByUserValidForSave(segment: Segment): boolean {
        if (segment.isCompany()) {
            return this.isCompanyQuestionFormValid();
        } else if (segment.isMarket()) {
            return this.isMarketQuestionFormValid();
        } else if (segment.isRisk()) {
            return this.checkRiskQuestionForm();
        } else if (segment.isCompliance()) {
            return this.checkComplianceQuestionForm();
        } else {
            return false;
        }

        return false;
    }

    /**
     * checks that the form is valid for the company segment
     */
    private isCompanyQuestionFormValid(): boolean {
        if (!this.answer.isNotRelevant && this.questionRelevanceValue == null) {
            return false;
        }

        if (this.answer.selectionSuccessFactor === undefined
            || this.answer.selectionSuccessFactor == null
            || this.answer.selectionSuccessFactor.trim().length <= 0) {
            return false;
        }

        return true;
    }

    /**
     * checks that the form is valid for the market segment
     */
    private isMarketQuestionFormValid(): boolean {
        if (!this.answer.isNotRelevant && this.questionRelevanceValue == null) {
            return false;
        }

        if (this.answer.selectionSuccessFactor === undefined
            || this.answer.selectionSuccessFactor == null
            || this.answer.selectionSuccessFactor.trim().length <= 0) {
            return false;
        }

        return true;
    }

    /**
     * checks that the form is valid for the risk segment
     */
    private checkRiskQuestionForm(): boolean {
        if (!this.riskAvoidFormControl.valid) {
            return false;
        }
        if (!this.riskReduceFormControl.valid) {
            return false;
        }
        if (!this.riskShuffleOffFormControl.valid) {
            return false;
        }
        if (!this.riskTakeOverFormControl.valid) {
            return false;
        }
        if ((this.answer.riskAvoid + this.answer.riskReduce + this.answer.riskShuffleOff + this.answer.riskTakeOver) > 100) {
            return false;
        }

        return true;
    }

    private checkComplianceQuestionForm(): boolean {
        return this.checkRiskQuestionForm();
    }

    /**
     * if the user checks the "not relevant" checkbox the slider should go to 0
     */
    private answerIsNotRelevantChanged(matCheckbox: MatCheckboxChange) {
        if (this.answer != null) {
            if (matCheckbox.checked) {
                this.lastRatingValue = this.answer.rating;
                this.lastRelevanceValue = this.questionRelevanceValue;

                this.answer.rating = 0;
                this.questionRelevanceValue = null;
            } else {
                this.answer.rating = this.lastRatingValue;
                this.questionRelevanceValue = this.lastRelevanceValue;
            }
        }
    }

    private isQuestionReadOnly(): boolean {


        if (this.readOnlyByLockingUser) {
            return true;
        }

        if (this.accountService.User.isAdmin()) {
            return false;
        } else {
            if (this.segment.isRisk() && this.accountService.User.hasRight('RISK_WRITE')
               || this.segment.isCompliance() && this.accountService.User.hasRight('COMPLIANCE_WRITE')) {
                return false;
            }
        }

        return true;
    }

    public isAnswereReadonly(): boolean {
        if (this.readOnlyByLockingUser) {
            return true;
        }

        if (this.accountService.User.isAdmin()) {
            return false;
        }

        return (this.rightsCheck.getAccessRightToSegment(this.accountService.User, this.segment) <= 1);
    }




    private canUserSave(): boolean {
        if (this.accountService.User.isAdmin()) {
            return true;
        } else if (this.segment.isRisk() && this.accountService.User.hasRight('RISK_WRITE')) {
            return true;
        } else if (this.segment.isCompliance() && this.accountService.User.hasRight('COMPLIANCE_WRITE')) {
            return true;
        }

        return false;
    }
}
