<section>

    <mat-toolbar color="basic">

        <mat-toolbar-row>

            <span>{{'usersList_Table_TitleText'|translate}}</span>

            <span class="toolbar-spacer"></span>

            <button mat-icon-button (click)="addUserButtonClick()"
                    [matTooltip]="'lwsDetailHeader_Add_ToolTipText'|translate">
                <mat-icon class="header-icon">add</mat-icon>
            </button>

        </mat-toolbar-row>

    </mat-toolbar>

    <div class="table-container mat-elevation-z8">
        <div class="table-loading-shade" *ngIf="isLoading">
            <mat-spinner *ngIf="isLoading" [diameter]="48"></mat-spinner>
        </div>

        <div class="table-container">
            <app-lws-table [datasource]="dataSource"
                           [displayedColumns]="displayedColumns"
                           [layout]="'full'"
                           [enableActions]="true"
                           [enableEditAction]="true"
                           [enableDeleteAction]="true"></app-lws-table>
        </div>

        <div class="footer-bottom">
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" [length]="resultsLength"></mat-paginator>
        </div>
    </div>

</section>
