<section [style.background-image]="'url(' + customerBackgroundImagePath + ')'">

    <form *ngIf="loginForm != null" class="form--login" (ngSubmit)="onSubmit()" [formGroup]="loginForm">

        <mat-card [ngClass]="loginFormCssClasses">

            <mat-card-header>
                <mat-card-title>{{'loginComponent_Title'|translate}}</mat-card-title>
            </mat-card-header>

            <mat-card-content>

                <mat-list>

                    <mat-list-item>
                        <mat-form-field [floatLabel]="true">
                            <input matInput placeholder="{{'loginComponent_EmailInput_Placeholder'|translate}}" required
                                   minlength="6" maxlength="200" type="email" formControlName="email">
                        </mat-form-field>
                    </mat-list-item>

                    <mat-list-item>
                        <mat-form-field [floatLabel]="true">
                            <input matInput placeholder="{{'loginComponent_PasswordInput_Placeholder'|translate}}"
                                   minlength="10" required type="password" formControlName="password">
                        </mat-form-field>
                    </mat-list-item>

                </mat-list>

            </mat-card-content>

            <mat-card-actions>
                <button type="submit" [disabled]="!loginForm.valid" mat-button class="login-button">
                    {{'loginComponent_FormSubmitButton_Content'|translate}}
                    <mat-spinner diameter="24" *ngIf="isLoading"></mat-spinner>
                </button>
            </mat-card-actions>

            <div *ngIf="isDebug == true">
                <p>Form Value: {{loginForm.value | json}}</p>
                <p>Form Valid: {{loginForm.valid}}</p>
                <p>Form Status: {{loginForm.status}}</p>
            </div>

        </mat-card>

    </form>

</section>
