import {Injectable} from '@angular/core';
import {NavigationItem} from 'src/app/models/navigation/navigation_item';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    /**
     * this is the list of the navigation-items. take this data to build the menu!
     */
    private navigationItems: NavigationItem[] = [];
    
    constructor(public translator: TranslateService) {
        this.loadNavigationItems();
    }
    
    /**
     * loads the navigation menu for the sidenav
     */
    private loadNavigationItems() {
        this.translator.setDefaultLang('de');

        this.translator.get('sidenav_PagesDashboard_Title').subscribe((translation: string) => {
            this.addNavigationEntry(translation, '/dashboard', 'dashboard', ['USER']);
        });

        this.translator.get('sidenav_ReportingDashboard_Title').subscribe((translation: string) => {
            this.addNavigationEntry(translation, '/reporting-dashboard', 'dashboard', ['REPORTING_DASHBOARD_READ', 'ADMIN']);
        });

        this.translator.get('sidenav_PagesAssessments_Title').subscribe((translation: string) => {
            this.addNavigationEntry(translation, '/assessments', 'format_list_numbered', ['USER', 'ADMIN']);
        });

        this.translator.get('sidenav_PagesCompanies_Title').subscribe((translation: string) => {
            this.addNavigationEntry(translation, '/companies', 'domain', ['ADMIN']);
        });
    }
    
    /**
     * adds a navigation-entry with the given data
     *
     * @param name the displayed name of the menu-entry
     * @param href the link for the menu entry
     * @param icon the icon to display
     * @param authorities the authorities hich are enabled for this entry
     */
    public addNavigationEntry(name: string, href: string, icon: string, authorities: string[] = []) {
        const navEntry: NavigationItem = new NavigationItem();
        
        navEntry.icon = icon;
        navEntry.href = href;
        navEntry.name = name;
        navEntry.authorities = authorities;
        
        this.navigationItems.push(navEntry);
    }
    
    get NavigationItems(): NavigationItem[] {
        return this.navigationItems;
    }
    
    set NavigationItems(value: NavigationItem[]) {
        this.navigationItems = value;
    }
}
