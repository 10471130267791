<mat-toolbar class="filter-panel" *ngIf="isFiltereable">
    <app-hierarchy-filter [gadget]="gadget" (selectionChanged)="filterSelectionChanged()"></app-hierarchy-filter>

    <fieldset style="text-align:center" class="fieldset--selections">

        <mat-list-item class="has--three-columns">
            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.name"
                          (change)="filterSelectionChanged()">{{'measureDetailForm_NameInput_Placeholder'|translate}}</mat-checkbox>
            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.isDescription"
                          (change)="filterSelectionChanged()">{{'Is'|translate}}</mat-checkbox>
            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.useDescription"
                          (change)="filterSelectionChanged()">{{'Use'|translate}}</mat-checkbox>

        </mat-list-item>

        <mat-list-item class="has--three-columns">

            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.probabilityOfOccurrence"
                          (change)="filterSelectionChanged()">{{'ProbabilityOfOccurrence'|translate}}</mat-checkbox>
            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.netDamageMeasure"
                          (change)="filterSelectionChanged()">{{'NetDamageMeasure'|translate}}</mat-checkbox>
            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.startdate"
                          (change)="filterSelectionChanged()">{{'StartDate'|translate}}</mat-checkbox>


        </mat-list-item>

        <mat-list-item class="has--three-columns">

            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.enddate"
                          (change)="filterSelectionChanged()">{{'EndDate'|translate}}</mat-checkbox>
            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.interoperability"
                          (change)="filterSelectionChanged()">{{'Interoperability'|translate}}</mat-checkbox>

            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.revenues"
                          (change)="filterSelectionChanged()">{{'revenues'|translate}}</mat-checkbox>

        </mat-list-item>

        <mat-list-item class="has--three-columns">

            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.tasknames"
                          (change)="filterSelectionChanged()">{{'MeasurePlaning'|translate}}</mat-checkbox>

            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.budget"
                          (change)="filterSelectionChanged()">{{'budget'|translate}}</mat-checkbox>

            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.bwaActual"
                          (change)="filterSelectionChanged()">{{'bwaActual'|translate}}</mat-checkbox>

        </mat-list-item>

        <mat-list-item class="has--three-columns">

            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.budgetPt"
                          (change)="filterSelectionChanged()">{{'BudgetPt'|translate}}</mat-checkbox>

            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.bwaActualPt"
                          (change)="filterSelectionChanged()">{{'ActualPt'|translate}}</mat-checkbox>

            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.revenuesPa"
                          (change)="filterSelectionChanged()">{{'revenuesPa'|translate}}</mat-checkbox>

        </mat-list-item>


        <mat-list-item class="has--three-columns">

            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.notes"
                          (change)="filterSelectionChanged()">{{'notes'|translate}}</mat-checkbox>

            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.status"
                          (change)="filterSelectionChanged()">{{'StateOfTask'|translate}}</mat-checkbox>

            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.measureResponsiblePersons"
                          (change)="filterSelectionChanged()">{{'Verantwortlicher'|translate}}</mat-checkbox>
        </mat-list-item>

        <mat-list-item class="has--three-columns">

            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.state"
                          (change)="filterSelectionChanged()">{{'StateOfMeasure'|translate}}</mat-checkbox>

            <mat-checkbox color="primary" class="is--column is--left"
                          [(ngModel)]="measureColumns.progress"
                          (change)="filterSelectionChanged()">{{'Progress'|translate}}</mat-checkbox>
        </mat-list-item>
    </fieldset>
</mat-toolbar>

<mat-toolbar>
    <button mat-button color="primary"
            (click)="export()"
            [disabled]="!this.gadget.year || !this.gadget.companyId">{{ 'downloadExcelFile' | translate }}</button>
</mat-toolbar>
