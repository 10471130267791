import {ReportingGadgetConfig} from '../../models/reporting-dashboard/reporting-gadget-config';

export class ReportingGadgets {

    public getConfigValue(config: ReportingGadgetConfig[], configKey: string) {
        for (const configItem of config) {
            if (configItem.key === configKey) {
                return configItem;
            }
        }

        return null;
    }

    public getOrCreateConfigValue(config: ReportingGadgetConfig[], configKey: string) {
        let exists: boolean = false;
        for (const configItem of config) {
            if (configItem.key === configKey) {
                exists = true;
                return configItem;
            }
        }

        if (!exists) {
            const configItem = new ReportingGadgetConfig();
            configItem.key = configKey;
            config.push(configItem);
        }

        return null;
    }

    public setConfigValue(config: ReportingGadgetConfig[], key: string, value: string) {

        let configValue = this.getConfigValue(config, key);

        if (configValue == null) {
            configValue = new ReportingGadgetConfig();
            configValue.key = key;

            config.push(configValue);
        }

        configValue.value = value;

    }
}
