import {Injectable} from '@angular/core';
import {AccountService} from '../account/account.service';
import {RestService} from '../rest/rest.service';
import {EventService} from '../../client/event/event.service';
import {TranslateService} from '@ngx-translate/core';
import {ErrorHandlingService} from '../../client/error-handling/error-handling.service';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Year} from '../../../models/year/year';
import {Version} from '../../../models/version/version';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class YearService {

    constructor(private accountService: AccountService,
                private restService: RestService,
                private eventService: EventService,
                private snackBar: MatSnackBar,
                private translator: TranslateService,
                private errorHandlingService: ErrorHandlingService) {

    }

    /**
     * returns all years
     *
     * @param page the requested page index
     * @param count the number of items per page
     * @param sort the column to sort
     * @param sortDesc should the sort be descending
     */
    public getAll(page: number = 0, count: number = 50, sort: string = null, sortDesc: boolean = false): Promise<Year[]> {
        const parameter: { key: string, value: string }[] = [];
        if (page && page > 0) {
            parameter.push({key: 'page', value: page.toString()});
        }
        if (count && count > 0) {
            parameter.push({key: 'count', value: count.toString()});
        }
        if (sort && sort.length > 0) {
            parameter.push({key: 'sortColumn', value: sort.toString()});
            parameter.push({key: 'sortDesc', value: sortDesc.toString()});
        }

        return this.restService.get('/years', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const years: Year[] = [];

                for (const responseItem of response) {
                    years.push(Year.hydrate(responseItem));
                }

                return years;
            },
            () => {
                return null;
            });
    }

    /**
     * deletes the year with the given id
     *
     * @param id the id of the year to delete
     */
    public delete(id: number): Promise<boolean> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.delete(
            '/years/' + id,
            parameter,
            true).toPromise()
        .then((response: any) => {
            return true;
        }).catch((httpError: any) => {
            this.errorHandlingService.handleError('yearService_DeleteRequest', httpError);

            return false;
        });
    }

    /**
     * returns the requested year
     *
     * @param id the id of the requested year
     */
    public getDetail(id: number): Promise<Year> {
        const parameter: { key: string, value: string }[] = [];

        if (id > 0) {
            return this.restService.get('/years/' + id, parameter).toPromise().then(
                (result) => {
                    const year: Year = Year.hydrate(result.body);

                    return year;
                },
                (httpError: HttpErrorResponse) => {
                    this.errorHandlingService.handleError('yearService_DetailRequest', httpError);

                    return null;
                });
        }
    }

    /**
     * saves the given unit
     *
     * @param year the year to save
     */
    public save(year: Year): Promise<number> {

        return this.restService.post(
            '/years/' + year.id,
            year,
            true).toPromise()
        .then((id: number) => {
            this.eventService.Trigger('year:create:success', id);

            this.translator.get('yearService_SaveRequest_SuccessMessage').subscribe(
                (translation: string) => {
                    this.snackBar.open(translation, '', {
                        duration: (5 * 1000), // display 5 seconds
                        panelClass: 'snackbar-lws-success'
                    });
                });

            return id;
        }).catch((httpError: HttpErrorResponse) => {
            this.errorHandlingService.handleError('yearService_DeleteRequest', httpError);

            return null;
        });
    }

    /**
     * returns all versions
     *
     * @param id the id of the parent year
     * @param page the requested page index
     * @param count the number of items per page
     * @param sort the column to sort
     * @param sortDesc should the sort be descending
     */
    public getVersions(id: number,
                       page: number = 0,
                       count: number = 50,
                       sort: string = null,
                       sortDesc: boolean = false): Promise<{
        items: Version[],
        numberOfAllItems: number
    }> {
        const parameter: { key: string, value: string }[] = [];
        if (page && page > 0) {
            parameter.push({key: 'page', value: page.toString()});
        }
        if (count && count > 0) {
            parameter.push({key: 'count', value: count.toString()});
        }
        if (sort && sort.length > 0) {
            parameter.push({key: 'sortColumn', value: sort.toString()});
            parameter.push({key: 'sortDesc', value: sortDesc.toString()});
        }

        if (id > 0) {
            return this.restService.get('/years/' + id + '/versions', parameter).toPromise().then(
                (result) => {
                    const response: any[] = (result.body as []);
                    const versions: Version[] = [];

                    for (const responseItem of response) {
                        versions.push(Version.hydrate(responseItem));
                    }

                    return {
                        items: versions,
                        numberOfAllItems: result.headers.get('x-items-count')
                    };
                },
                () => {
                    return null;
                });
        }
    }

    /**
     * returns the requested year
     *
     * @param versionId the id of the requested year
     */
    public export(versionId: number): Promise<HttpResponse<any>> {
        const parameter: { key: string, value: string }[] = [];

        if (versionId > 0) {
            return this.restService.getExport('/versions/' + versionId + '/fullexport/', parameter, true, false).toPromise().then(
                (result) => {
                    return result;
                },
                (httpError: HttpErrorResponse) => {
                    this.errorHandlingService.handleError('ErrorOnExport', httpError);

                    return null;
                });
        }
    }

    public exportGroup(groupId: number): Promise<HttpResponse<any>> {
        const parameter: { key: string, value: string }[] = [];

        if (groupId > 0) {
            return this.restService.getExport('/groups/' + groupId + '/fullexport/', parameter, true, false).toPromise().then(
                (result) => {
                    return result;
                },
                (httpError: HttpErrorResponse) => {
                    this.errorHandlingService.handleError('ErrorOnExport', httpError);

                    return null;
                });
        }
    }

    public getAllYearNames(companyId: number) {
        const parameter: { key: string, value: string }[] = [];
        parameter.push({key: 'companyId', value: companyId.toString()});

        return this.restService.get('/years/names', parameter).toPromise().then(
            (result) => {
                return (result.body as []);
            },
            () => {
                return null;
            });
    }
}
