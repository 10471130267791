
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<mat-sidenav-container class="sidenav-container" [hasBackdrop]="false" autosize>

    <mat-sidenav #sidenavElement class="sidenav" #sidenav mode="side" opened="true" *ngIf="accountService.IsAuth()">

        <div [className]="'logo--container' + ((isSidenavExpanded) ? ' is--large' : ' is--small')">
            <a routerLink="/" class="logo--link" title="{{'appTitle'|translate}}">
                <img src="{{logoLargePath}}" alt="{{'appTitle'|translate}}"
                     class="logo"
                     *ngIf="isSidenavExpanded">
                <img src="{{logoSmallPath}}" alt="{{'appTitle'|translate}}"
                     class="logo"
                     *ngIf="!isSidenavExpanded">
            </a>
        </div>

        <mat-nav-list class="navigation--list"
                      *ngIf="navigationService && navigationService.NavigationItems && navigationService.NavigationItems.length > 0">

            <div *ngFor="let navEntry of navigationService.NavigationItems">
                <a mat-list-item routerLink="{{navEntry.href}}"
                   *ngIf="accountService.User && accountService.User.hasOneAuthorityOfList(navEntry.authorities)"
                   class="navigation--item"
                   routerLinkActive="is--active">
                    <mat-icon>{{navEntry.icon}}</mat-icon>
                    <span *ngIf="isSidenavExpanded">{{navEntry.name}}</span>
                </a>
            </div>

        </mat-nav-list>

        <section class="sidenav--bottom-container">

            <mat-card class="account--card" *ngIf="isSidenavExpanded">

                <mat-card-header>
                    <mat-card-title>
                        {{accountService.User.name}}
                        <small *ngIf="accountService.User.isAdmin()">(a)</small>
                        <small *ngIf="!accountService.User.isAdmin()">(u)</small>
                    </mat-card-title>
                </mat-card-header>

                <mat-card-actions>

                    <button block mat-button (click)="accountService.logout();">
                        <mat-icon>power_settings_new</mat-icon>
                        <span>{{'sidenav_AccountCardLogoutButton_Content'|translate}}</span>
                    </button>

                    <button full mat-button routerLink="/account/changepassword">
                        <mat-icon>vpn_key</mat-icon>
                        <span>{{'sidenav_AccountCardChangePasswordButton_Content'|translate}}</span>
                    </button>

                </mat-card-actions>

            </mat-card>

            <button mat-button (click)="sidenavMenuExpand(!isSidenavExpanded)" class="sidenav--toogle-button">
                <mat-icon *ngIf="!isSidenavExpanded">chevron_right</mat-icon>
                <mat-icon *ngIf="isSidenavExpanded">chevron_left</mat-icon>
            </button>

        </section>

    </mat-sidenav>

    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>

</mat-sidenav-container>
