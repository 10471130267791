import { Component, OnInit } from '@angular/core';
import {GadgetService} from "../../../../services/server/reporting-dashboard/gadget.service";
import {BaseGadget} from "../base-gadget";
import {ReportingGadgetConfig} from "../../../../models/reporting-dashboard/reporting-gadget-config";
import {HttpResponse} from "@angular/common/http";
import * as FileSaver from 'file-saver';
import {MeasureColumns} from "../../../../models/reporting-dashboard/measure-columns";
import {ReportingGadgets} from '../../../../services/client/reporting-gadgets';


@Component({
  selector: 'app-measure-evaluation',
  templateUrl: './measure-evaluation.component.html',
  styleUrls: ['./measure-evaluation.component.scss']
})
export class MeasureEvaluationComponent extends BaseGadget implements OnInit {

    public measureColumns: MeasureColumns;

    public columnOrder = [
        '_name',
        '_isDescription',
        '_useDescription',
        '_probabilityOfOccurrence',
        '_netDamageMeasure',
        '_startdate',
        '_enddate',
        '_interoperability',
        '_revenues',
        '_revenuesPa',
        '_tasknames',
        '_budget',
        '_bwaActual',
        '_budgetPt',
        '_bwaActualPt',
        '_notes',
        '_status',
        '_measureResponsiblePersons',
        '_state',
        '_progress',

    ];

  constructor(private gadgetService: GadgetService,
              private reportingGadgetsLogic: ReportingGadgets) {
      super();
  }

  ngOnInit() {
      super.ngOnInit();
      if(!this.measureColumns) {
          this.measureColumns = new MeasureColumns();
      }
  }

    public export() {
        this.gadgetService.export(this.gadget.companyId, this.gadget.id, this.gadget.config).then(
            (response: HttpResponse<any>) => {
                const fileName: string = 'export.xlsx';
                FileSaver.saveAs(response.body, fileName);
            }
        );
    }

    protected loadFilters(configs: ReportingGadgetConfig[]) {

        this.measureColumns = new MeasureColumns();

        const configValue = this.reportingGadgetsLogic.getConfigValue(configs, ReportingGadgetConfig.MEASURE_COLUMNS);
        if (configValue) {
            const columns: string[] = configValue.value.split(ReportingGadgetConfig.MULTISELECT_SEPARATOR);


            for(const column of columns) {

                this.measureColumns[column] = true;
            }
        }



    }

    protected loadGadgetReport() {
    }

    protected saveFilters() {

      let columns = '';
      for(const columnName of this.columnOrder) {

          if (this.measureColumns[columnName]) {
              if (columns.length > 0) {
                  columns += ReportingGadgetConfig.MULTISELECT_SEPARATOR;
              }
              columns += columnName.slice(1);
          }
      }

      this.reportingGadgetsLogic.setConfigValue(this.gadget.config, ReportingGadgetConfig.MEASURE_COLUMNS, columns);

    }
}
