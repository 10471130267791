import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Company} from '../../models/company/company';
import {Segment} from '../../models/segment/segment';
import {SegmentService} from '../../services/server/segment/segment.service';
import {Year} from '../../models/year/year';
import {Version} from '../../models/version/version';
import {CompanyService} from '../../services/server/company/company.service';
import {Unit} from '../../models/unit/unit';
import {UnitService} from '../../services/server/unit/unit.service';
import {VersionService} from '../../services/server/version/version.service';
import {Folder} from '../../models/folder/folder';
import {FolderService} from '../../services/server/folder/folder.service';
import {Question} from '../../models/question/question';
import {Measure} from '../../models/measure/measure';
import {AccountService} from '../../services/server/account/account.service';
import {MeasureDetailFormComponent} from '../measure-detail-form/measure-detail-form.component';
import {MeasuresListComponent} from '../measures-list/measures-list.component';
import {QuestionsListComponent} from '../questions-list/questions-list.component';
import {LockService} from '../../services/server/lock/lock.service';
import {LockType} from '../../services/server/lock/lock-type.enum';
import {CacheService} from '../../services/client/cache/cache.service';
import {MatTabChangeEvent, MatTabGroup} from "@angular/material/tabs";

@Component({
    selector: 'app-assessment-overview',
    templateUrl: './assessment-overview.component.html',
    styleUrls: ['./assessment-overview.component.scss']
})
export class AssessmentOverviewComponent implements OnInit {
    @ViewChild(MatTabGroup) public tabGroup: MatTabGroup;
    @ViewChild(MeasuresListComponent) public measureList: MeasuresListComponent;
    @ViewChild(QuestionsListComponent) public questionList: QuestionsListComponent;
    @ViewChild(MeasureDetailFormComponent) public measureDetail: MeasureDetailFormComponent;
    @ViewChild('segmentTabGroup') private segmentTabGroup: MatTabGroup;

    /**
     * the given company model
     */
    @Input() public company: Company = null;
    /**
     * a list of loaded segments
     */
    public segments: Segment[] = [];
    /**
     * the currently active segment
     */
    public _activeSegment: Segment = null;
    /**
     * a list of loaded units (based on the company)
     */
    public units: Unit[] = [];
    /**
     * the selected unit
     */
    public selectedUnit: Unit = null;
    /**
     * a list of loaded years (based on the selected unit)
     */
    public years: Year[] = [];
    /**
     * the selected year
     */
    public selectedYear: Year = null;
    /**
     * a list of loaded versions (based on the selected year)
     */
    public versions: Version[] = [];
    /**
     * the selected version
     */
    public _selectedVersion: Version = null;
    /**
     * a list of loaded folders (based on the selected version)
     */
    public folders: Folder[] = [];
    public folder: Folder = null;
    public selectedQuestion: Question = null;
    public selectedMeasure: Measure = null;
    public isReadOnlyView: boolean = false;
    private activeSegmentIndex: number = 0;
    private activeMeasureQuestionIndex: number = 0;
    public readonly CACHE_ACTIVE_SEGMENT_KEY = 'assessmentOverviewActiveSegmentIndex';
    public readonly CACHE_ACTIVE_MEASURE_QUESTION_KEY = 'assessmentOverviewActiveMeasureQuestionIndex';

    constructor(public segmentService: SegmentService,
                public companyService: CompanyService,
                public versionService: VersionService,
                public folderService: FolderService,
                public accountService: AccountService,
                public unitsService: UnitService,
                public lockService: LockService,
                public cacheService: CacheService) {

    }

    get selectedVersion(): Version {
        return this._selectedVersion;
    }

    set selectedVersion(value: Version) {
        this._selectedVersion = value;
        this.selectedQuestion = null;
        this.selectedMeasure = null;

        // reload the content for the selected segment
        if (this.selectedVersion != null && this.activeSegment != null) {
            this.loadFolderFromVersionAndSegment(this.selectedVersion.id, this.activeSegment.id);
        }
    }

    get activeSegment(): Segment {
        return this._activeSegment;
    }

    set activeSegment(value: Segment) {
        this._activeSegment = value;
        this.selectedQuestion = null;
        this.selectedMeasure = null;

        // reload the content for the selected segment
        if (this.selectedVersion != null && this.activeSegment != null) {
            this.loadFolderFromVersionAndSegment(this.selectedVersion.id, this.activeSegment.id);
        }
    }

    ngOnInit() {
        this.loadEnvironment();
    }

    /**
     * loads the segments for the ui
     */
    private loadEnvironment() {
        this.loadSegments();
        this.loadUnitsOfCompany(this.company.id);
    }

    /**
     * loads the units for the given company
     *
     * @param companyId the id of the requested company
     */
    private loadUnitsOfCompany(companyId: number) {
        this.companyService.getUnits(companyId).then(
            (result: { items: Unit[], numberOfAllItems: number }) => {
                this.units = result.items;
            },
            () => {

            }
        );
    }

    /**
     * executed if the user has selected an unit
     *
     * @param selectedUnitId the id of the selected unit
     */
    private unitSelectionChanged(selectedUnitId: number) {
        this.selectedUnit = this.units.filter(x => x.id === selectedUnitId)[0];
        this.selectedYear = null;
        this.years = [];
        this.selectedVersion = null;
        this.versions = [];
        this.folders = [];
        this.folder = null;
        this.selectedQuestion = null;
        this.selectedMeasure = null;

        this.loadYearsOfUnit(selectedUnitId);
    }

    /**
     * loads the years for the given unit
     *
     * @param unitId the id of the requested unit
     */
    private loadYearsOfUnit(unitId: number) {
        this.unitsService.getYears(unitId).then(
            (result: { items: Year[], numberOfAllItems: number }) => {
                this.years = result.items;
            },
            () => {

            }
        );
    }

    /**
     * executed if the user has selected a year
     *
     * @param selectedYearId the id of the selected year
     */
    private yearSelectionChanged(selectedYearId: number) {
        this.selectedYear = this.years.filter(x => x.id === selectedYearId)[0];
        this.selectedVersion = null;
        this.versions = [];
        this.folders = [];
        this.selectedQuestion = null;
        this.selectedMeasure = null;
        this.folder = null;

        this.loadVersionsOfYear(selectedYearId);
    }

    /**
     * loads the versions for the given year
     *
     * @param yearId the id of the requested year
     */
    private loadVersionsOfYear(yearId: number) {
        this.versionService.getVersions(yearId).then(
            (versions: Version[]) => {
                this.versions = versions;
            },
            () => {

            }
        );
    }

    /**
     * executed if the user has selected a version
     *
     * @param selectedVersionId the id of the selected version
     */
    private versionSelectionChanged(selectedVersionId: number) {
        this.selectedVersion = this.versions.filter(x => x.id === selectedVersionId)[0];
        this.selectedQuestion = null;
        this.selectedMeasure = null;
        this.folder = null;

        // need to wait for the ui reaction
        window.setTimeout(() => {
            this.activeSegment = this.segments[this.tabGroup.selectedIndex];

            this.tabGroup.selectedIndexChange.subscribe(
                (index: number) => {
                    this.activeSegment = this.segments[index];
                }
            );
        }, 50);
    }

    /**
     * loads the segments for the ui
     */
    private loadSegments() {
        this.segmentService.getAll().then(
            (segments) => {
                this.segments = segments;
            },
            () => {

            }
        );
    }

    /**
     * executed if the user selected a folder
     *
     * @param folder the selected folder model
     */
    private folderSelectButtonClicked(folder: Folder) {
        const me = this;
        this.resetFolderSelection();

        // need to wait for the ui reaction
        window.setTimeout(() => {
            me.folder = folder;
        }, 50);
    }

    /**
     * load the folders for the selected version and segment
     *
     * @param versionId the if of the selected version
     * @param segmentId the if of the selected segment
     */
    private loadFolderFromVersionAndSegment(versionId: number, segmentId: number) {
        if (versionId == null || segmentId == null) {
            this.folders = [];

            return;
        }

        this.versionService.getFolders(versionId, segmentId).then(
            (result: { items: Folder[], numberOfAllItems: number }) => {
                // load subfolders

                this.folders = [];

                for (const folder of result.items) {

                    this.folders.push(folder);

                    this.folderService.getSubfolders(folder.id).then(
                        (result: { items: Folder[], numberOfAllItems: number }) => {
                            folder.subFolders = [];

                            if (result.items && result.items.length > 0) {
                                folder.subFolders = result.items;
                            }
                        }
                    );
                }
            }
        );
    }

    /**
     * executed if the list returns the selected question
     *
     * @param question the selected question model
     */
    private questionSelected(question: Question) {
        this.lockService.lock(question.id, LockType.QUESTION).then(
            (isLockSuccess: boolean) => {
                if (isLockSuccess) {
                    this.isReadOnlyView = false;
                } else {
                    this.isReadOnlyView = true;
                }

                this.selectedQuestion = question;
            }
        );
    }

    /**
     * executed if the list returns the selected measure
     *
     * @param measure the selected measure model
     */
    private measureSelected(measure: Measure) {
        this.lockService.lock(measure.id, LockType.MEASURE).then(
            (isLockSuccess: boolean) => {
                if (isLockSuccess) {
                    this.isReadOnlyView = false;
                } else {
                    this.isReadOnlyView = true;
                }

                this.selectedMeasure = measure;
            }
        );
    }

    /**
     * executed if the question is saved
     */
    private questionSaved() {
        this.selectedQuestion = null;
        window.setTimeout(() => {
            this.questionList.refresh();
        }, 1000);
    }

    /**
     * executed if the measure is saved
     */
    private measureSaved($event) {
        this.selectedMeasure = null;
        window.setTimeout(() => {
            this.measureList.refresh();
        }, 1000);
    }

    /**
     * resets the current folder selection
     */
    private resetFolderSelection() {
        this.folder = null;
        this.selectedQuestion = null;
        this.selectedMeasure = null;
        this.activeMeasureQuestionIndex = 0;
    }

    /**
     * executed if the tab selection of segments has changed
     */
    private segmentTabGroupTabChanged() {
        this.activeSegmentIndex = this.segmentTabGroup.selectedIndex;
        this.cacheService.addCacheValue(this.CACHE_ACTIVE_SEGMENT_KEY, this.activeSegmentIndex);

        this.resetFolderSelection();
    }

    /**
     * executed if the tab selection of segments has changed
     */
    private questionMeasureTabGroupTabChanged(event: MatTabChangeEvent) {
        this.activeMeasureQuestionIndex = event.index;
        this.cacheService.addCacheValue(this.CACHE_ACTIVE_MEASURE_QUESTION_KEY, this.activeMeasureQuestionIndex);
    }
}

