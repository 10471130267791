import {Component, EventEmitter, Output} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
    template: ''
})
export abstract class DetailForm {

    /*
     * value in seconds
     */
    static POPUP_SHOW_DURATION: number = 300022;

    private isLoading: boolean = false;

    @Output() detailFormSaved = new EventEmitter<any>();

    constructor(private infoPanel: MatSnackBar,
                private translatorService: TranslateService) {
    }

    /**
     * Returns the form instance fo the view component
     */
    protected abstract getForm();

    /**
     * Transfers the values of the model to the form fields of the view.
     *
     * @param model The model which should shown in view
     */
    protected showModelInView(model) {
        const proto = Object.getPrototypeOf(model);

        const viewModel: any = new Object();

        Object.keys(this.getForm().controls).forEach(key => {
            viewModel[key] = '';
        });

        Object.entries(Object.getOwnPropertyDescriptors(proto))
        .filter(([key, descriptor]) => typeof descriptor.get === 'function')
        .map(([key, descriptor]) => {
            if (descriptor && key[0] !== '_'
                && this.getForm().contains(key)) {

                try {
                    let val = (model as any)[key];

                    if (val != null && typeof val === 'string') {
                        val = val.replace(/\.000\+0000/, '');
                    }

                    viewModel[key] = val;
                    this.getForm().controls[key].setValue(val);
                } catch (error) {
                }
            }
        });
    }

    /**
     * Returns the service which is used for save operation
     */
    protected abstract getService();

    /**
     * Transfers the view values to the model
     */
    protected transferViewToModel(model) {
        Object.assign(model, this.getForm().value);
    }

    /**
     * Saves the given entity
     */
    protected doSave(entity) {
        this.isLoading = true;

        const service = this.getService();

        service.save(entity).then(
            (entityId) => {
                this.isLoading = false;

                if (entityId > 0) {
                    // save the allocation between the measure and the folder

                    this.detailFormSaved.emit({
                        event: event,
                        entityId: entityId
                    });

                    this.translatorService.get('SaveRequest_SuccessfulSaved').subscribe(
                        (translation: string) => {
                            this.showSuccessMessage(translation);
                        });
                }
            },
            () => {
                this.isLoading = false;
            });
    }

    /**
     * Opens a info dialog with the success layout.
     */
    protected showSuccessMessage(translation) {
        this.infoPanel.open(translation, '', {
            duration: (DetailForm.POPUP_SHOW_DURATION), // display 5 seconds
            panelClass: 'snackbar-lws-success'
        });
    }

    /**
     * Called if the Cancel-Button was clicked
     */
    protected abortForm() {
        this.detailFormSaved.emit(null);
    }


}
