<section>

    <!-- the page header -->
    <app-lws-detail-header [backButton]="true" [title]="title"></app-lws-detail-header>
    <app-lws-bread-crumb [breadcrumbs]="breadcrumbs"></app-lws-bread-crumb>

    <!-- view for new companies -->
    <main *ngIf="isNew" class="company--detail-new">

        <app-company-detail-form></app-company-detail-form>

    </main>

    <!-- view for existing companies -->
    <main *ngIf="!isNew && company" class="company--detail-existing">

        <mat-tab-group mat-align-tabs="left">

            <mat-tab label="{{'companyDetail_TabStrategicBusinessUnits_TitleText'|translate}}"
                     *ngIf="accountService.User.isAdmin">

                <app-units-list [company]="company" [breadcrumbs]="breadcrumbs"></app-units-list>

            </mat-tab>

            <mat-tab label="{{'companyDetail_TabOverview_TitleText'|translate}}">

                <app-company-detail-form [company]="company"></app-company-detail-form>

                <div class="spacer--top"></div>

                <app-users-list [company]="company" [breadcrumbs]="breadcrumbs"></app-users-list>

            </mat-tab>

        </mat-tab-group>

    </main>

</section>
