import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from './services/client/navigation/navigation.service';
import {AccountService} from './services/server/account/account.service';

import {DomSanitizer} from '@angular/platform-browser';
import {AppConfig} from './app.config';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, AfterViewInit {
    @ViewChild('sidenavElement') private sidenavElement;
    private mobileQuery: MediaQueryList;
    private isSidenavExpanded: boolean = false;
    private tabletMaxWidth: number = 1024;
    private mobileQueryListener: (e) => void;
    public readonly logoLargePath: string = AppConfig.settings.customer.logoLarge;
    public readonly logoSmallPath: string = AppConfig.settings.customer.logoSmall;

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private media: MediaMatcher,
                public navigationService: NavigationService,
                public accountService: AccountService,
                private router: Router,
                private matIconRegistry: MatIconRegistry,
                private sanitizer: DomSanitizer,
                private translate: TranslateService) {
        this.translate.setDefaultLang('de');

        this.loaded();
    }

    ngAfterViewInit() {
        this.loadResolutionChangeHandler();
    }

    /**
     * loads the event-handler for the resolution change event
     */
    private loadResolutionChangeHandler() {
        this.mobileQuery = this.media.matchMedia('(max-width: ' + this.tabletMaxWidth + 'px)'); // max-width of vertical tablet
        this.mobileQueryListener = (e) => {
            this.changeDetectorRef.detectChanges();

            if (e.matches) {
                // lower than tablet landscape
                this.sidenavMenuExpand(false);
            } else {
                // grather than tablet landscape
                this.sidenavMenuExpand(true);
            }
        };
        this.mobileQuery.addListener(this.mobileQueryListener);
    }

    /**
     * executed after the AppComponent is loaded
     */
    private loaded() {
        // sidenav
        const width = document.getElementsByTagName('html')[0].offsetWidth;
        if (width <= this.tabletMaxWidth) {
            this.sidenavMenuExpand(false);
        } else {
            this.sidenavMenuExpand(true);
        }

        // account service
        if (!this.accountService.IsAuth()) {
            this.router.navigateByUrl('/account/login');
        }

        this.loadCustomIcons();
    }

    /**
     * load custom icons to the angular material icon service
     */
    private loadCustomIcons() {
        this.matIconRegistry.addSvgIcon(
            'iconmonstr-smiley-2',
            this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icons/iconmonstr-smiley-2.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'iconmonstr-smiley-4',
            this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icons/iconmonstr-smiley-4.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'iconmonstr-smiley-6',
            this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icons/iconmonstr-smiley-6.svg')
        );
    }

    /**
     * expands the sidenav menu
     *
     * @param expand if true, set the variable to true - the menu expands automatically
     */
    public sidenavMenuExpand(expand: boolean) {
        this.isSidenavExpanded = expand;

        if (this.sidenavElement) {
            this.loadSidenavClasses();
        } else {
            this.waitForSidenav();
        }
    }

    /**
     * waits that the sidenav is loaded in the ui and executes after that additional code
     */
    private waitForSidenav() {
        window.setTimeout(() => {
            if (this.sidenavElement) {
                this.loadSidenavClasses();
            } else {
                this.waitForSidenav();
            }
        }, 100);
    }

    /**
     * loads the css classes for the sidenav
     */
    private loadSidenavClasses() {
        if (this.isSidenavExpanded) {
            this.sidenavElement._elementRef.nativeElement.classList.add('is--large');
            this.sidenavElement._elementRef.nativeElement.classList.remove('is--small');
        } else {
            this.sidenavElement._elementRef.nativeElement.classList.add('is--small');
            this.sidenavElement._elementRef.nativeElement.classList.remove('is--large');
        }
    }

    /**
     * execurted if the component will be destroyed
     */
    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this.mobileQueryListener);
    }
}
