<section>

    <form *ngIf="form != null" class="form" (ngSubmit)="onSubmit()" [formGroup]="form">

        <mat-list>

            <mat-list-item>

                <mat-form-field [floatLabel]="true">
                    <input matInput placeholder="{{'yearDetailForm_NameInput_Placeholder'|translate}}" required
                           type="text" formControlName="name" [maxLength]="80">
                </mat-form-field>

            </mat-list-item>

            <mat-list-item>

                <button type="submit" [disabled]="!form.valid" mat-stroked-button class="submit-button" color="primary">
                    {{'formButtons_save'|translate}}
                    <mat-spinner diameter="24" *ngIf="isLoading"></mat-spinner>
                </button>

            </mat-list-item>

        </mat-list>

    </form>

</section>
