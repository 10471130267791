import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Location} from '@angular/common';

@Component({
    selector: 'app-lws-detail-header',
    templateUrl: './lws-detail-header.component.html',
    styleUrls: ['./lws-detail-header.component.scss']
})
export class LwsDetailHeaderComponent implements OnInit {
    @Input() title: string = '';
    @Input() backButton: boolean = false;
    @Output() backClick = new EventEmitter();
    @Input() addButton: boolean = false;
    @Output() addClick = new EventEmitter();
    
    constructor(public location: Location) {
    
    }
    
    ngOnInit() {
    
    }
    
    /**
     * executed if the user clicked on the delete action button
     *
     * @param row the whole dataset for the clicked row
     */
    private backButtonClick(row) {
        this.backClick.emit(row);
    }
    
    /**
     * executed if the user clicked on the delete action button
     *
     * @param row the whole dataset for the clicked row
     */
    private addButtonClick(row) {
        this.addClick.emit(row);
    }
}
