import {Injectable} from '@angular/core';
import {IAppConfig} from './models/appconfig/app-config.model';
import {environment} from '../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AppConfig {
    static settings: IAppConfig;
    
    constructor(private http: HttpClient) {
    }
    
    load() {
        const jsonFile = `assets/config/config.${environment.name}.json`;
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response: IAppConfig) => {
                AppConfig.settings = (response as IAppConfig);
                resolve();
            }).catch((response: any) => {
                reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}
