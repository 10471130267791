import * as moment from 'moment';
import {Moment, MomentInput} from 'moment';
import {ModelInterface} from './model-interface';

export class JSONConverter {
    /**
     * returns the object as a valid json (without the underscore a the property name begin)
     */
    toJSON(model: ModelInterface) {
        const proto = Object.getPrototypeOf(model);
        const jsonObj: any = new Object();
        
        Object.entries(Object.getOwnPropertyDescriptors(proto))
        .filter(([key, descriptor]) => typeof descriptor.get === 'function')
        .map(([key, descriptor]) => {
            if (descriptor
                && key[0] !== '_'
                && model._ignoreProperties.filter(x => x == key).length <= 0) {
                try {
                    let val = (model as any)[key];
                    
                    if (val != null && moment.isMoment(val)) {
                        const momentValue: Moment = (val as Moment);
                        val = val.format('YYYY-MM-DD');
                    }
                    
                    jsonObj[key] = val;
                } catch (error) {
                    console.error(`Error calling getter ${key}`, error);
                }
            }
        });
        
        
        return jsonObj;
    }
}

