import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Question} from '../../models/question/question';
import {Folder} from '../../models/folder/folder';
import {Segment} from '../../models/segment/segment';
import {Unit} from '../../models/unit/unit';
import {ActivatedRoute, Router} from '@angular/router';
import {LwsDetailHeaderComponent} from '../../components/lws-detail-header/lws-detail-header.component';
import {Location} from '@angular/common';
import {QuestionService} from '../../services/server/question/question.service';
import {FolderService} from '../../services/server/folder/folder.service';

@Component({
    selector: 'app-question-detail',
    templateUrl: './question-detail.component.html',
    styleUrls: ['./question-detail.component.scss']
})
export class QuestionDetailComponent implements OnInit, AfterViewInit {

    @ViewChild(LwsDetailHeaderComponent) private header: LwsDetailHeaderComponent;

    public question: Question;
    public folder: Folder;
    public segment: Segment;
    public unit: Unit;
    public isReadOnlyView: boolean;
    public windowTitle: string;

    private folderId: number;
    private questionId: number;

    constructor(public route: ActivatedRoute,
                public location: Location,
                public questionService: QuestionService,
                public folderService: FolderService) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.folderId = params.folderId;
            this.questionId = params.questionId;

            this.questionService.getDetail(this.questionId).then(
                (question: Question) => {
                    this.question = question;
                    this.unit = question.unit;
                    this.windowTitle = question.question;
                });

            this.folderService.getDetail(this.folderId).then((folder: Folder) => {
                this.folder = folder;
                this.segment = Segment.hydrate(folder.segment);
            });


        });
    }

    ngAfterViewInit() {
        this.header.backClick.subscribe(() => {
            this.location.back();
        });
    }

    public questionSaved() {

    }

}
