import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {LwsDetailHeaderComponent} from '../../components/lws-detail-header/lws-detail-header.component';
import {Version} from '../../models/version/version';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Location} from '@angular/common';
import {EventService} from '../../services/client/event/event.service';
import {TranslateService} from '@ngx-translate/core';
import {VersionService} from '../../services/server/version/version.service';

@Component({
    selector: 'app-versions-detail',
    templateUrl: './versions-detail.component.html',
    styleUrls: ['./versions-detail.component.scss']
})
export class VersionsDetailComponent implements OnInit, AfterViewInit {
    @ViewChild(LwsDetailHeaderComponent) private header: LwsDetailHeaderComponent;
    public version: Version = null;
    public isNew: boolean = true;
    public title: string = '';
    public isLoading: boolean = false;
    public breadcrumbs: string[] = [];
    
    constructor(public route: ActivatedRoute,
                public versionService: VersionService,
                public router: Router,
                public location: Location,
                public eventService: EventService,
                public translator: TranslateService) {
        this.translator.setDefaultLang('de');
        this.subscribeEvents();
    }
    
    private subscribeEvents() {
        this.eventService.Subscribe('version:create:success', (id: number) => {
            this.isNew = false;
            this.loadVersionById(id);
        });
    }
    
    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            const id: number = +params.get('id');
            
            if (id > 0) {
                this.isNew = false;
                this.loadVersionById(id);
            } else {
                this.translator.get('versionsDetail_NewYearForm_TitleContent').subscribe((translation: string) => {
                    this.title = translation;
                    
                    this.loadBreadcrumbsAndAdd(translation);
                });
            }
        });
    }
    
    ngAfterViewInit() {
        this.addHeaderSubscribes();
    }
    
    /**
     * add the subscribes to the action-buttons
     */
    private addHeaderSubscribes() {
        this.header.backClick.subscribe(() => {
            this.location.back();
        });
    }
    
    /**
     *
     * @param id the id of the version
     */
    private loadVersionById(id: number) {
        this.isLoading = true;
        
        this.versionService.getDetail(id).then(
            (version) => {
                this.isLoading = false;
                
                if (version == null) {
                    this.location.back();
                }
                
                this.version = version;
                this.title = this.version.name;
                
                this.loadBreadcrumbsAndAdd(this.version.name);
            },
            () => {
                this.isLoading = false;
            });
    }
    
    /**
     * load the latest breadcrumbs and add the given as a new one
     *
     * @param breadcrumb the breadcrumb to add
     */
    private loadBreadcrumbsAndAdd(breadcrumb?: string) {
        const me = this;
        
        if (me.breadcrumbs === null || me.breadcrumbs === undefined) {
            me.breadcrumbs = [];
        }
        
        this.route.queryParams.subscribe((queryParams) => {
            if (typeof queryParams.breadcrumbs === 'string') {
                me.breadcrumbs.push(queryParams.breadcrumbs);
            } else {
                me.breadcrumbs = (queryParams.breadcrumbs as string[]);
            }
            
            if (breadcrumb !== null && breadcrumb !== undefined && breadcrumb.length > 0) {
                me.breadcrumbs.push(breadcrumb);
            }
        });
    }
}
