import {Component, Inject, OnInit} from '@angular/core';
import {User} from '../../models/user/user';
import {CompanyService} from '../../services/server/company/company.service';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-user-selection',
    templateUrl: './user-selection.component.html',
    styleUrls: ['./user-selection.component.scss']
})
export class UserSelectionComponent implements OnInit {

    public users: User[];

    /**
     * Selected user. format: id|name
     */
    public userSelection: string;

    constructor(public dialogRef: MatDialogRef<UserSelectionComponent>,
                public companyService: CompanyService,
                @Inject(MAT_DIALOG_DATA) public data: { companyId }) {
    }

    ngOnInit() {

      this.companyService.getUsers(this.data.companyId, 0, 100000, null, false).then(
          (result: { items: User[], numberOfAllItems: number }) => {

            if (result.items && result.items.length > 0) {
              this.users = result.items;
            }
          },
          () => {
          });
    }

    onNoClick(): void {
      this.dialogRef.close();
    }
}
