import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GadgetService} from '../../../../services/server/reporting-dashboard/gadget.service';
import {ReportingGadgetConfig} from '../../../../models/reporting-dashboard/reporting-gadget-config';
import {Data} from '../../../../models/reporting-dashboard/data';
import {Unit} from '../../../../models/unit/unit';
import {CompanyService} from '../../../../services/server/company/company.service';
import {ReportingGadgets} from '../../../../services/client/reporting-gadgets';
import {BaseGadget} from '../base-gadget';
import {EChartsOption} from 'echarts';

@Component({
    selector: 'app-answeres-company-market',
    templateUrl: './answeres-company-market.component.html',
    styleUrls: ['./answeres-company-market.component.scss']
})
export class AnsweresCompanyMarketComponent extends BaseGadget implements OnInit {

    public chartData: EChartsOption = null;

    public selectedRelevanceValue: { title: string, id: number }[] = [];
    public relevanceValues: { title: string, id: number }[] = [
        {title: 'Low', id: 1},
        {title: 'Medium', id: 2},
        {title: 'High', id: 3}
    ];



    public successFactors: Set<string> = new Set<string>();
    public selectedSuccessFactor: string[] = [];

    public selectedAnswereValues: string[] = [];
    public answereValues: string[] = ['1', '2', '3', '4', '5'];


    private units: Unit[] = [];

    constructor(public gadgetService: GadgetService,
                private companyService: CompanyService,
                private reportingGadgetsLogic: ReportingGadgets) {
        super();
    }

    ngOnInit() {
        this.loadSuccessFactors();
        super.ngOnInit();
    }


    private loadSuccessFactors() {
        this.companyService.getUnits(this.gadget.companyId).then((resultData) => {
                this.units = resultData.items;

                const hierarchyConfig = this.reportingGadgetsLogic.getConfigValue(
                    this.gadget.config,
                    ReportingGadgetConfig.HIERARCHY_FILTER);

                this.successFactors.clear();

                if (hierarchyConfig && hierarchyConfig.value && hierarchyConfig.value.length > 0) {
                    const filterParts = hierarchyConfig.value.split('.');
                    const unitId: number = Number(filterParts[0]);

                    this.units.forEach(unit => {
                        if (unit.id === unitId) {
                            this.successFactorsToMap(this.successFactors, unit);
                        }
                    });
                } else {
                    this.units.forEach(unit => {
                        this.successFactorsToMap(this.successFactors, unit);
                    });
                }
            },
            () => {

            });
    }

    private successFactorsToMap(successFactors: Set<string>, unit: Unit) {

        if (unit.successFactor1) {
            successFactors.add(unit.successFactor1);
        }

        if (unit.successFactor2) {
            successFactors.add(unit.successFactor2);
        }

        if (unit.successFactor3) {
            successFactors.add(unit.successFactor3);
        }

        if (unit.successFactor4) {
            successFactors.add(unit.successFactor4);
        }

        if (unit.successFactor5) {
            successFactors.add(unit.successFactor6);
        }

        if (unit.successFactor6) {
            successFactors.add(unit.successFactor6);
        }

        if (unit.successFactor7) {
            successFactors.add(unit.successFactor7);
        }

        if (unit.successFactor8) {
            successFactors.add(unit.successFactor8);
        }

        if (unit.successFactor9) {
            successFactors.add(unit.successFactor9);
        }

        if (unit.successFactor10) {
            successFactors.add(unit.successFactor10);
        }

        if (unit.successFactor11) {
            successFactors.add(unit.successFactor11);
        }

        if (unit.successFactor12) {
            successFactors.add(unit.successFactor12);
        }

        if (unit.successFactor13) {
            successFactors.add(unit.successFactor13);
        }

        if (unit.successFactor14) {
            successFactors.add(unit.successFactor14);
        }

        if (unit.successFactor15) {
            successFactors.add(unit.successFactor15);
        }

        if (unit.successFactor16) {
            successFactors.add(unit.successFactor16);
        }

        if (unit.successFactor17) {
            successFactors.add(unit.successFactor17);
        }

        if (unit.successFactor18) {
            successFactors.add(unit.successFactor18);
        }

        if (unit.successFactor19) {
            successFactors.add(unit.successFactor19);
        }

        if (unit.successFactor20) {
            successFactors.add(unit.successFactor20);
        }

        return successFactors;
    }

    protected loadFilters(configs: ReportingGadgetConfig[]) {
        if (configs) {
            this.loadRelevanceFilter(configs);
            this.loadSuccessFactorFilter(configs);
            this.loadAnswerValuesFilter(configs);
        }
    }

    private loadRelevanceFilter(configs: ReportingGadgetConfig[]) {
        const configValue = this.reportingGadgetsLogic.getConfigValue(configs, ReportingGadgetConfig.RELEVANCE);
        if (configValue) {
            const valueIds: string[] = configValue.value.split(ReportingGadgetConfig.MULTISELECT_SEPARATOR);

            this.relevanceValues.forEach(value => {
                if (valueIds.includes(value.id + '')) {
                    this.selectedRelevanceValue.push(value);
                    return false;
                }
            });
        }
    }

    private loadSuccessFactorFilter(configs: ReportingGadgetConfig[]) {
        const configValue = this.reportingGadgetsLogic.getConfigValue(configs, ReportingGadgetConfig.SUCCESS_FACTOR);
        if (configValue) {
            this.selectedSuccessFactor = configValue.value.split(ReportingGadgetConfig.MULTISELECT_SEPARATOR);
        }
    }

    private loadAnswerValuesFilter(configs: ReportingGadgetConfig[]) {
        const configValue = this.reportingGadgetsLogic.getConfigValue(configs, ReportingGadgetConfig.ANSWER_VALUE);
        if (configValue) {
            this.selectedAnswereValues = configValue.value.split(ReportingGadgetConfig.MULTISELECT_SEPARATOR);
        }
    }

    public hierarchyFilterChanged() {
        this.loadSuccessFactors();

        this.selectedSuccessFactor = [];
    }



    protected saveFilters() {

        let selectedRelevanceValueAsString: string = '';
        this.selectedRelevanceValue.forEach(item => {
            if (selectedRelevanceValueAsString.length > 0) {
                selectedRelevanceValueAsString += ReportingGadgetConfig.MULTISELECT_SEPARATOR;
            }
            selectedRelevanceValueAsString += item.id;
        });
        this.reportingGadgetsLogic.setConfigValue(this.gadget.config, ReportingGadgetConfig.RELEVANCE, selectedRelevanceValueAsString);

        this.reportingGadgetsLogic.setConfigValue(
            this.gadget.config,
            ReportingGadgetConfig.SUCCESS_FACTOR,
            this.selectedSuccessFactor.join(ReportingGadgetConfig.MULTISELECT_SEPARATOR));

        this.reportingGadgetsLogic.setConfigValue(
            this.gadget.config,
            ReportingGadgetConfig.ANSWER_VALUE,
            this.selectedAnswereValues.join(ReportingGadgetConfig.MULTISELECT_SEPARATOR));
    }



    protected loadGadgetReport() {
        if (this.gadget.id != null && this.gadget.id > 0) {
            this.gadgetService.getResults(this.gadget.companyId, this.gadget.id, this.gadget.config).then((resultData) => {
                    this.chartData = this.generateChartData(resultData);
                },
                () => {

                });
        }
    }

    private generateChartData(dataResults: Data[]): EChartsOption {

        const yAxis: string[] = [];
        const xAxis: number[] = [];

        dataResults.forEach(data => {
            yAxis.push(data.label);
            xAxis.push(data.value);
        });

        return {
            tooltip: {
                show: true,
                // trigger: 'axis',
                triggerOn: 'mousemove',
                formatter: params => {
                    return '' + params.name + '<br/>' + params.data;
                }
            },
            xAxis: {
                type: 'value',
                min: 1,
                max: 5,
                axisLabel: {
                    formatter: (value, index) => {

                        if (value ===  1) {
                            value = value + '🙁';
                        } else if (value === 3) {
                            value = value + '😐';
                        } else if (value === 5) {
                            value = value + '🙂';
                        }

                        return value;
                    }
                }
            },
            yAxis: {
                position: 'left',
                type: 'category',

                axisLabel: {

                    padding: [1, 1, 1, 1],
                    borderRadius : 50,
                    interval: -1,
                    formatter: (value, index) => {
                        if (value.length > 50) {

                            const words: string[] = value.split(' ');
                            let formattedLabel: string = '';
                            let charCounter = 0;
                            let i = 0;
                            words.forEach(word => {
                                if (i > 0) {
                                    formattedLabel += ' ';
                                }
                                if (charCounter + word.length > 50) {
                                    formattedLabel += '\n';
                                    charCounter = 0;
                                }
                                formattedLabel += word;
                                charCounter += word.length;
                                i++;
                            });
                            return formattedLabel;
                        } else {
                            return value;
                        }

                    }
                },
                data: yAxis,
            },
            series: [
                {
                    data: xAxis,
                    type: 'line',
                    lineStyle: {color: '#cd013d'}
                },
            ],
            grid: {
                left: '5%',
                bottom: '10%',
                right: '5%',
                top: '5%',
                containLabel: true
            }
        };
    }

}
