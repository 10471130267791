import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../models/user/user';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccountService} from '../../services/server/account/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/server/user/user.service';
import {Company} from '../../models/company/company';
import {CompanyService} from '../../services/server/company/company.service';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {Privilege} from '../../models/privilege/privilege';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-user-detail-form',
    templateUrl: './user-detail-form.component.html',
    styleUrls: ['./user-detail-form.component.scss']
})
export class UserDetailFormComponent implements OnInit {
    @Input() user: User = null;
    @Input() public company: Company = null;
    public isLoading: boolean = false;

    /**
     * the structure of the login-form
     */
    public form: FormGroup = this.formBuilder.group({
        username: ['', Validators.compose([
            Validators.required,
            Validators.maxLength(200),
            // better email regex => https://emailregex.com/ and better than Validators.email
            Validators.pattern('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])')
        ])],
        firstname: ['', Validators.compose([
            Validators.required,
            Validators.maxLength(45)
        ])],
        lastname: ['', Validators.compose([
            Validators.required,
            Validators.maxLength(45)
        ])],
        password: ['', Validators.compose([
            Validators.minLength(10)
        ])],
        passwordRepeat: ['', Validators.compose([
            Validators.minLength(10)
        ])],
    });

    /**
     * the options for the rights management
     */
    public companyRightOptions: { title: string, value: number }[] = [
        {title: 'nicht berechtigt', value: 0},
        {title: 'lesen', value: 1},
        {title: 'lesen & beantworten', value: 2}
    ];
    public companyRightValue: number = 0;

    public marketRightOptions: { title: string, value: number }[] = [
        {title: 'nicht berechtigt', value: 0},
        {title: 'lesen', value: 1},
        {title: 'lesen & beantworten', value: 2}
    ];
    public marketRightValue: number = 0;

    public measureRightOptions: { title: string, value: number }[] = [
        {title: 'nicht berechtigt', value: 0},
        {title: 'lesen', value: 1},
        {title: 'lesen & beantworten', value: 2}
    ];
    public measureRightValue: number = 0;

    public riskRightOptions: { title: string, value: number }[] = [
        {title: 'nicht berechtigt', value: 0},
        {title: 'lesen', value: 1},
        {title: 'lesen & beantworten', value: 2},
        {title: 'Fragen erstellen, lesen & beantworten', value: 3}
    ];
    public riskRightValue: number = 0;

    public complianceRightOptions: { title: string, value: number }[] = [
        {title: 'nicht berechtigt', value: 0},
        {title: 'lesen', value: 1},
        {title: 'lesen & beantworten', value: 2},
        {title: 'Fragen erstellen, lesen & beantworten', value: 3}
    ];

    public complianceRightValue: number = 0;


    public dashboardRightOptions: { title: string, value: number }[] = [
        {title: 'nicht berechtigt', value: 0},
        {title: 'lesen', value: 1},
        {title: 'filtern', value: 2},
        {title: 'konfigurieren', value: 3}
    ];
    public privilegeDashboardRightValue: number = 0;


    constructor(public formBuilder: FormBuilder,
                public accountService: AccountService,
                public snackBar: MatSnackBar,
                public router: Router,
                public route: ActivatedRoute,
                public userService: UserService,
                public location: Location,
                public translator: TranslateService) {
    }

    /**
     * checks that the two password values are the same
     */
    private isPasswordValid(): boolean {
        if (!this.isEmptyPasswords()
            && (this.form.value['password'] === this.form.value['passwordRepeat'])) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * returns true if a password value is empty or null
     */
    private isEmptyPasswords(): boolean {
        if (this.form.value['password'] === null || this.form.value['password'].trim().length <= 0
            || this.form.value['passwordRepeat'] === null || this.form.value['passwordRepeat'].trim().length <= 0) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * executed if the user clicked on the login-button
     */
    private onSubmit() {
        const me = this;

        if (this.form.valid) {
            this.isLoading = true;

            let user: User = ((this.user == null) ? new User() : this.user);
            user.username = this.form.value['username'];
            user.firstname = this.form.value['firstname'];
            user.lastname = this.form.value['lastname'];

            // password is required for new user
            if (this.isEmptyPasswords() === true && this.user == null) {
                this.translator.get('userDetailForm_PasswordInput_EmptyErrorMessage').subscribe(
                    (translation: string) => {
                        this.snackBar.open(translation, '', {
                            duration: (5 * 1000), // display 5 seconds
                            panelClass: 'snackbar-lws-error'
                        });
                    });

                this.isLoading = false;
                return;
            }

            if (this.isPasswordValid()) {
                user.password = this.form.value['password'];
            } else if (
                (this.user == null && !this.isPasswordValid())
                || (this.user != null && !this.isEmptyPasswords())
            ) {
                this.translator.get('userDetailForm_PasswordInput_NotSameErrorMessage').subscribe(
                    (translation: string) => {
                        this.snackBar.open(translation, '', {
                            duration: (5 * 1000), // display 5 seconds
                            panelClass: 'snackbar-lws-error'
                        });
                    });

                this.isLoading = false;
                return;
            }

            user.company = this.company;

            user = this.addUserPrivileges(user);

            this.userService.save(user).then(success => {
                this.isLoading = false;

                if (success) {
                    me.location.back();
                }
            });
        }
    }

    /**
     * adds the privileges of the current form to the user model
     */
    private addUserPrivileges(user: User): User {
        user.privilegeCompanyRightValue = this.companyRightValue;
        user.privilegeMarketRightValue = this.marketRightValue;
        user.privilegeRiskRightValue = this.riskRightValue;
        user.privilegeMeasureRightValue = this.measureRightValue;
        user.privilegeComplianceRightValue = this.complianceRightValue;
        user.privilegeDashboardRightValue = this.privilegeDashboardRightValue;

        return user;
    }

    /**
     * loads the form model from the company
     */
    private loadModel() {
        if (this.user == null) {
            return;
        }

        this.companyRightValue = this.user.privilegeCompanyRightValue;
        this.marketRightValue = this.user.privilegeMarketRightValue;
        this.measureRightValue = this.user.privilegeMeasureRightValue;
        this.riskRightValue = this.user.privilegeRiskRightValue;
        this.complianceRightValue = this.user.privilegeComplianceRightValue;
        this.privilegeDashboardRightValue = this.user.privilegeDashboardRightValue;

        this.form.setValue({
            username: this.user.username,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            password: '',
            passwordRepeat: ''
        });
    }

    ngOnInit() {
        this.loadModel();
    }
}
