import {Component, Input, OnInit} from '@angular/core';
import {Year} from '../../models/year/year';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccountService} from '../../services/server/account/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {YearService} from '../../services/server/year/year.service';
import {VersionService} from '../../services/server/version/version.service';
import {Version} from '../../models/version/version';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-version-detail-form',
    templateUrl: './version-detail-form.component.html',
    styleUrls: ['./version-detail-form.component.scss']
})
export class VersionDetailFormComponent implements OnInit {
    @Input() version: Version = null;
    public year: Year = null;
    public isLoading: boolean = false;

    /**
     * the structure of the login-form
     */
    public form: FormGroup = this.formBuilder.group({
        name: ['', Validators.compose([
            Validators.required,
            Validators.maxLength(80)
        ])],
    });

    constructor(public formBuilder: FormBuilder,
                public accountService: AccountService,
                public snackBar: MatSnackBar,
                public router: Router,
                public route: ActivatedRoute,
                public versionService: VersionService,
                public yearService: YearService,
                public location: Location,
                public translator: TranslateService) {

    }

    /**
     * executed if the user clicked on the submit-button
     */
    private onSubmit() {
        const me = this;
        if (this.form.valid && this.year != null) {
            this.isLoading = true;

            const version: Version = ((this.version == null) ? new Version() : this.version);
            version.name = this.form.value['name'];

            version.year = ((this.version != null && this.version.year != null) ? this.version.year : this.year);

            this.versionService.save(version).then(
                (versionId: number) => {
                    me.isLoading = false;

                    if (versionId > 0) {
                        me.location.back();
                    }
                },
                () => {
                    me.isLoading = false;
                });
        }
    }

    /**
     * loads the form model
     */
    private loadModel() {
        if (this.version == null) {
            return;
        }

        this.form.setValue({
            name: this.version.name
        });
    }

    ngOnInit() {
        this.loadModel();

        this.route.queryParams.subscribe((queryParams) => {
            const yearId: number = +queryParams.yearId;
            this.isLoading = true;

            this.yearService.getDetail(yearId).then(
                (year) => {
                    this.isLoading = false;

                    this.year = year;
                },
                (error) => {
                    this.isLoading = false;
                });
        });
    }
}
