import {Component, Input, OnInit} from '@angular/core';
import {Unit} from '../../models/unit/unit';
import {Year} from '../../models/year/year';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccountService} from '../../services/server/account/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UnitService} from '../../services/server/unit/unit.service';
import {YearService} from '../../services/server/year/year.service';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-year-detail-form',
    templateUrl: './year-detail-form.component.html',
    styleUrls: ['./year-detail-form.component.scss']
})
export class YearDetailFormComponent implements OnInit {
    @Input() year: Year = null;
    public unit: Unit = null;
    public isLoading: boolean = false;

    /**
     * the structure of the login-form
     */
    public form: FormGroup = this.formBuilder.group({
        name: ['', Validators.compose([
            Validators.required,
            Validators.maxLength(80)
        ])],
    });

    constructor(public formBuilder: FormBuilder,
                public accountService: AccountService,
                public snackBar: MatSnackBar,
                public router: Router,
                public route: ActivatedRoute,
                public yearService: YearService,
                public unitService: UnitService,
                public location: Location,
                public translator: TranslateService) {

    }

    /**
     * executed if the user clicked on the login-button
     */
    private onSubmit() {
        const me = this;

        if (this.form.valid && this.unit != null) {
            this.isLoading = true;

            const year: Year = ((this.year == null) ? new Year() : this.year);
            year.name = this.form.value['name'];

            year.unit = ((this.year != null && this.year.unit != null) ? this.year.unit : this.unit);

            this.yearService.save(year).then(
                (yearId: number) => {
                    me.isLoading = false;

                    if (yearId > 0) {
                        me.location.back();
                    }
                },
                () => {
                    me.isLoading = false;
                });
        }
    }

    /**
     * loads the form model from the company
     */
    private loadModel() {
        if (this.year == null) {
            return;
        }

        this.form.setValue({
            name: this.year.name
        });
    }

    ngOnInit() {
        this.loadModel();

        this.route.queryParams.subscribe((queryParams) => {
            const unitId: number = +queryParams.unitId;
            this.isLoading = true;

            this.unitService.getDetail(unitId).then(
                (unit) => {
                    this.isLoading = false;

                    this.unit = unit;
                },
                (error) => {
                    this.isLoading = false;
                });
        });
    }
}
