import {Injectable} from '@angular/core';
import {AccountService} from '../account/account.service';
import {RestService} from '../rest/rest.service';
import {EventService} from '../../client/event/event.service';
import {TranslateService} from '@ngx-translate/core';
import {ErrorHandlingService} from '../../client/error-handling/error-handling.service';
import {Unit} from '../../../models/unit/unit';
import {HttpErrorResponse} from '@angular/common/http';
import {Year} from '../../../models/year/year';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class UnitService {

    constructor(private accountService: AccountService,
                private restService: RestService,
                private eventService: EventService,
                private snackBar: MatSnackBar,
                private translator: TranslateService,
                private errorHandlingService: ErrorHandlingService) {

    }

    /**
     * returns all units
     *
     * @param page the requested page index
     * @param count the number of items per page
     * @param sort the column to sort
     * @param sortDesc should the sort be descending
     */
    public getAll(page: number = 0, count: number = 50, sort: string = null, sortDesc: boolean = false): Promise<Unit[]> {
        const parameter: { key: string, value: string }[] = [];
        if (page && page > 0) {
            parameter.push({key: 'page', value: page.toString()});
        }
        if (count && count > 0) {
            parameter.push({key: 'count', value: count.toString()});
        }
        if (sort && sort.length > 0) {
            parameter.push({key: 'sortColumn', value: sort.toString()});
            parameter.push({key: 'sortDesc', value: sortDesc.toString()});
        }

        return this.restService.get('/units', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const units: Unit[] = [];

                for (const responseItem of response) {
                    units.push(Unit.hydrate(responseItem));
                }

                return units;
            },
            () => {
                return null;
            });
    }

    /**
     * deletes the user with the given id
     *
     * @param id the id of the user to delete
     */
    public delete(id: number): Promise<boolean> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.delete(
            '/units/' + id,
            parameter,
            true).toPromise()
        .then((response: any) => {
            return true;
        }).catch((httpError: any) => {
            this.errorHandlingService.handleError('unitService_DeleteRequest', httpError);

            return false;
        });
    }

    /**
     * returns the requested unit
     *
     * @param id the id of the requested unit
     */
    public getDetail(id: number): Promise<Unit> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.get('/units/' + id, parameter).toPromise().then(
            (result) => {
                const unit: Unit = Unit.hydrate(result.body);

                return unit;
            },
            (httpError: HttpErrorResponse) => {
                this.errorHandlingService.handleError('unitService_DetailRequest', httpError);

                return null;
            });
    }

    /**
     * saves the given unit
     *
     * @param unit the unit to save
     */
    public save(unit: Unit): Promise<number> {

        return this.restService.post(
            '/units/' + unit.id,
            unit,
            true).toPromise()
        .then((id: number) => {
            this.eventService.Trigger('unit:create:success', id);

            this.translator.get('unitService_SaveRequest_SuccessMessage').subscribe(
                (translation: string) => {
                    this.snackBar.open(translation, '', {
                        duration: (5 * 1000), // display 5 seconds
                        panelClass: 'snackbar-lws-success'
                    });
                });

            return id;
        }).catch((httpError: HttpErrorResponse) => {
            this.errorHandlingService.handleError('unitService_SaveRequest', httpError);

            return null;
        });
    }

    /**
     * returns all years of the given unit
     *
     * @param id the id of the unit
     * @param page the requested page index
     * @param count the number of items per page
     * @param sort the column to sort
     * @param sortDesc should the sort be descending
     */
    public getYears(id: number, page: number = 0, count: number = 50, sort: string = null, sortDesc: boolean = false): Promise<{
        items: Year[],
        numberOfAllItems: number
    }> {
        const parameter: { key: string, value: string }[] = [];
        if (page && page > 0) {
            parameter.push({key: 'page', value: page.toString()});
        }
        if (count && count > 0) {
            parameter.push({key: 'count', value: count.toString()});
        }
        if (sort && sort.length > 0) {
            parameter.push({key: 'sortColumn', value: sort.toString()});
            parameter.push({key: 'sortDesc', value: sortDesc.toString()});
        }

        return this.restService.get('/units/' + id + '/years', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const years: Year[] = [];

                for (const responseItem of response) {
                    years.push(Year.hydrate(responseItem));
                }

                return {
                    items: years,
                    numberOfAllItems: result.headers.get('x-items-count')
                };
            },
            () => {
                return null;
            });
    }
}
