<form (ngSubmit)="saveConfiguration()" [formGroup]="form">

    <section>
        <h2>{{ 'Configuration' | translate }}</h2>

        <mat-form-field appearance="standard">
            <mat-label>{{ 'Title' | translate }}</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="standard">
            <mat-label>{{ 'BusinessYear' | translate }}</mat-label>

            <mat-select  formControlName="year" (selectionChange)="loadVersions($event.value);">
                <mat-option *ngFor="let year of years" [value]="year">
                    {{year}}
                </mat-option>
            </mat-select>

        </mat-form-field>

        <mat-form-field appearance="standard">
            <mat-label>{{ 'Version' | translate }}</mat-label>

            <mat-select  formControlName="version">
                <mat-option *ngFor="let version of versions" [value]="version">
                    {{version}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-checkbox formControlName="released" id="released">{{ 'Released' | translate }}</mat-checkbox>
    </section>

    <mat-toolbar>
        <button mat-button color="primary"
                (click)="saveConfiguration()">{{ 'formButtons_save' | translate }}</button>
    </mat-toolbar>
</form>

