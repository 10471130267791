export class States {

    static PREPARATION: number = 0;
    static ACTIVE: number = 1;
    static RISK_TIME: number = 2;
    static RISK_RESSOURCE: number = 3;
    static RISK_BUDGET: number = 4;
    static RISK_SCOPE: number = 5;
    static RSIK_QUALITY: number = 6;
    static PASSIV: number = 7;
    static COMPLETED: number = 8;


}

