import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccountService} from '../../services/server/account/account.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Company} from '../../models/company/company';
import {CompanyService} from '../../services/server/company/company.service';
import {Group} from '../../models/group/group';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';

@Component({
    selector: 'app-company-detail-form',
    templateUrl: './company-detail-form.component.html',
    styleUrls: ['./company-detail-form.component.scss']
})
export class CompanyDetailFormComponent implements OnInit {
    /**
     *
     */
    @Input() company: Company = null;
    /**
     *
     */
    public isLoading: boolean = false;

    /**
     * the structure of the login-form
     */
    public form: FormGroup = this.formBuilder.group({
        name: ['', Validators.compose([
            Validators.required,
            Validators.maxLength(80)
        ])],
        amountOfLoss1: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        amountOfLoss2: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        amountOfLoss3: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
        amountOfLoss4: ['', Validators.compose([
            Validators.maxLength(80)
        ])],
    });

    constructor(public formBuilder: FormBuilder,
                public accountService: AccountService,
                public snackBar: MatSnackBar,
                public router: Router,
                public companyService: CompanyService,
                public location: Location,
                public translator: TranslateService) {

    }

    /**
     * executed if the user clicked on the login-button
     */
    private onSubmit() {
        if (this.form.valid) {
            this.isLoading = true;

            const company: Company = ((this.company == null) ? new Company() : this.company);
            const group: Group = ((this.company == null) ? new Group() : this.company.group);
            group.name = this.form.value['name'];
            company.group = group;
            company.amountOfLoss1 = this.form.value['amountOfLoss1'];
            company.amountOfLoss2 = this.form.value['amountOfLoss2'];
            company.amountOfLoss3 = this.form.value['amountOfLoss3'];
            company.amountOfLoss4 = this.form.value['amountOfLoss4'];

            this.companyService.save(company).then(
                (companyId: number) => {
                    this.isLoading = false;

                    if (companyId > 0) {
                        this.location.back();
                    }
                },
                () => {
                    this.isLoading = false;
                });
        }
    }

    /**
     * loads the form model from the company
     */
    private loadModel() {
        if (this.company == null) {
            return;
        }

        this.form.setValue({
            name: this.company.group.name,
            amountOfLoss1: this.company.amountOfLoss1,
            amountOfLoss2: this.company.amountOfLoss2,
            amountOfLoss3: this.company.amountOfLoss3,
            amountOfLoss4: this.company.amountOfLoss4
        });
    }

    ngOnInit() {
        this.loadModel();
    }
}
