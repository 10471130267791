import {Component, Input, OnInit} from '@angular/core';
import {Version} from '../../models/version/version';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Company} from '../../models/company/company';
import {CompanyService} from '../../services/server/company/company.service';
import {VersionService} from '../../services/server/version/version.service';
import {Unit} from '../../models/unit/unit';
import {Year} from '../../models/year/year';
import {UnitService} from '../../services/server/unit/unit.service';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-versions-copy-form',
    templateUrl: './versions-copy-form.component.html',
    styleUrls: ['./versions-copy-form.component.scss']
})
export class VersionsCopyFormComponent implements OnInit {
    @Input() public version: Version = null;
    @Input() public folderId: number = null;
    public isLoading: boolean = false;
    public companiesForSelect: Company[] = [];
    public unitsForSelect: Unit[] = [];
    public yearsForSelect: Year[] = [];
    public versionsForSelect: Version[] = [];

    /**
     * the structure of the form
     */
    public form: FormGroup = this.formBuilder.group({
        company: ['', Validators.compose([
            Validators.required
        ])],
        unitName: ['', Validators.compose([
            Validators.required,
            Validators.maxLength(80)
        ])],
        yearName: ['', Validators.compose([
            Validators.required,
            Validators.maxLength(80)
        ])],
        versionName: ['', Validators.compose([
            Validators.required,
            Validators.maxLength(80)
        ])],
    });

    constructor(public formBuilder: FormBuilder,
                public snackBar: MatSnackBar,
                public router: Router,
                public route: ActivatedRoute,
                public companyService: CompanyService,
                public unitsService: UnitService,
                public versionService: VersionService,
                public translator: TranslateService) {

    }

    ngOnInit() {
        this.loadCompaniesForSelect();
    }

    /**
     * loads the companies for the select
     */
    private loadCompaniesForSelect() {
        this.companyService.getAll().then(
            (result: { items: Company[], numberOfAllItems: number }) => {
                this.companiesForSelect = result.items;
            }
        );
    }

    /**
     * executed if the user clicked on the submit-button
     */
    private onSubmit() {

        console.log('submit');
        if (this.form.valid ) {
            this.isLoading = true;

            const versionId = this.version == null ?  null : this.version.id ;

            this.versionService.copyFromTemplate(versionId,
                this.folderId,
                (this.form.value['company'] as Company).id,
                this.form.value['unitName'].name,
                this.form.value['versionName'].name,
                this.form.value['yearName'].name
            ).then(
                (success) => {
                    this.isLoading = false;

                    if (success) {
                        // save the allocation between the measure and the folder

                        this.translator.get('versionsCopyForm_SaveRequest_SuccessMessage').subscribe(
                            (translation: string) => {
                                this.snackBar.open(translation, '', {
                                    duration: (5 * 1000), // display 5 seconds
                                    panelClass: 'snackbar-lws-success'
                                });
                            });
                    }
                },
                () => {
                    this.isLoading = false;
                });
        }
    }

    private loadVersionsOfYear(year: Year) {
        this.versionService.getVersions(year.id).then(
            (versions: Version[]) => {
                this.versionsForSelect = versions;
            }
        );
    }

    private loadYearsOfUnit(unit: Unit) {
        this.unitsService.getYears(unit.id).then(
            (result: { items: Year[], numberOfAllItems: number }) => {
                this.yearsForSelect = result.items;
            }

        );
    }
    private loadUnitsOfCompany(company: Company) {

        this.companyService.getUnits(company.id).then(
            (result: { items: Unit[], numberOfAllItems: number }) => {
                this.unitsForSelect = result.items;
            }
        );

    }

    private onUnitChanged(unit: Unit){
        this.loadYearsOfUnit(unit);
        this.versionsForSelect = [];
    }

    private onCompanyChanged(company: Company){
        this.loadUnitsOfCompany(company);
        this.versionsForSelect = [];
        this.yearsForSelect = [];
    }
}
