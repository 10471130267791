<app-lws-detail-header
        [addButton]="isConfigureable"
        (addClick)="openGadgetSelection()"
        title="{{ 'sidenav_ReportingDashboard_Title' | translate }}"></app-lws-detail-header>


<mat-toolbar *ngIf="admin">
    <mat-form-field *ngIf="companies.length > 0" class="company-select">
        <mat-label>{{ 'Company' | translate }}</mat-label>
        <mat-select (selectionChange)="loadDashboardForCompany($event.value);">
            <mat-option *ngFor="let company of companies" [value]="company.id">
                {{company.groupName}}
            </mat-option>
        </mat-select>
    </mat-form-field>

</mat-toolbar>

<gridster [options]="options">
    <app-reporting-gadget
            *ngFor="let gadget of gadgets"
            [gadget]="gadget"
            (removeGadgetHandler)="removeItem(gadget)"
            (saveGadgetHandler)="saveGadgets($event)"></app-reporting-gadget>
</gridster>
