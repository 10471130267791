<section>

    <mat-toolbar color="basic">

        <mat-toolbar-row>

            <span>{{'measuresList_Table_TitleText'|translate}}</span>

            <span class="toolbar-spacer"></span>

            <button mat-icon-button (click)="onAddClicked()"
                    [matTooltip]="'lwsDetailHeader_Add_ToolTipText'|translate"
                    *ngIf="!readOnly && accountService.User.privilegeMeasureRightValue > 1">
                <mat-icon class="header-icon">add</mat-icon>
            </button>

        </mat-toolbar-row>

    </mat-toolbar>


    <div class="table-container mat-elevation-z8">
        <div class="table-loading-shade" *ngIf="isLoading">
            <mat-spinner *ngIf="isLoading" [diameter]="48"></mat-spinner>
        </div>

        <div class="table-container">
            <app-lws-table [datasource]="dataSource"
                           [displayedColumns]="displayedColumns"
                           [layout]="'full'"
                           [enableActions]="!readOnly ? true : false"
                           [enableEditAction]="!readOnly ? true : false"
                           [enableMoveAction]="!readOnly ? true : false"
                           [enableDeleteAction]="!readOnly && accountService.User.privilegeMeasureRightValue > 1 ? true : false"></app-lws-table>
        </div>

        <div class="footer-bottom">
            <mat-paginator [pageSizeOptions]="[25, 50, 100]" [length]="resultsLength"></mat-paginator>
        </div>
    </div>

</section>
