import { Component, OnInit } from '@angular/core';
import {GadgetService} from "../../../../services/server/reporting-dashboard/gadget.service";
import {ReportingGadgets} from "../../../../services/client/reporting-gadgets";
import {BaseGadget} from "../base-gadget";
import {ReportingGadgetConfig} from "../../../../models/reporting-dashboard/reporting-gadget-config";
import {HttpResponse} from "@angular/common/http";
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-bwa-evaluation',
  templateUrl: './bwa-evaluation.component.html',
  styleUrls: ['./bwa-evaluation.component.scss']
})
export class BwaEvaluationComponent extends BaseGadget implements OnInit {

  constructor(private gadgetService: GadgetService,
              private reportingGadgetsLogic: ReportingGadgets) {
      super();
  }

  ngOnInit() {
      super.ngOnInit();
  }

    protected loadFilters(configs: ReportingGadgetConfig[]) {
    }

    protected loadGadgetReport() {
    }

    protected saveFilters() {
    }

    public export() {
        this.gadgetService.export(this.gadget.companyId, this.gadget.id, this.gadget.config).then(
            (response: HttpResponse<any>) => {
                const fileName: string = 'export.xlsx';
                FileSaver.saveAs(response.body, fileName);
            }
        );
    }

}
