import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Folder} from '../../models/folder/folder';
import {FolderService} from '../../services/server/folder/folder.service';
import {Question} from '../../models/question/question';
import {LwsTableComponent} from '../lws-table/lws-table.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {LwsDialogComponent} from '../lws-dialog/lws-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {QuestionService} from '../../services/server/question/question.service';
import {FoldersListDialogComponent} from '../folders-list-dialog/folders-list-dialog.component';
import {ColumnInformation} from '../../models/lws-table/column-information';
import {Version} from '../../models/version/version';
import {AccountService} from '../../services/server/account/account.service';
import {Segment} from '../../models/segment/segment';
import {VersionService} from '../../services/server/version/version.service';

@Component({
    selector: 'app-questions-list',
    templateUrl: './questions-list.component.html',
    styleUrls: ['./questions-list.component.scss']
})
export class QuestionsListComponent implements OnInit, AfterViewInit {

    @ViewChild(LwsTableComponent) private table: LwsTableComponent;
    @ViewChild(MatPaginator) private paginator: MatPaginator;

    @Input() private folder: Folder;
    @Input() public segment: Segment;
    @Input() private selectedVersion: Version;

    @Output() questionSelected = new EventEmitter<Question>();

    public isLoading: boolean = false;
    /**
     * the length of the current loading
     */
    public resultsLength = 0;
    /**
     * the datasource
     */
    public dataSource: Question[] = [];

    /**
     * Folders for move
     */
    public foldersForMoveDialog: Folder[] = [];

    /**
     * the columns of the table to display
     */
    public displayedColumns: ColumnInformation[] = [
        new ColumnInformation('question', 'Frage', 'text', 'question'),
        new ColumnInformation('infotext', 'Infotext', 'text', 'infotext'),

        new ColumnInformation('answerRating', 'Bewertung', 'text', 'infotext'),
        new ColumnInformation('factor', 'Faktor', 'text', 'infotext'),
    ];

    constructor(public router: Router,
                public folderService: FolderService,
                public questionService: QuestionService,
                public dialog: MatDialog,
                public translator: TranslateService,
                public accountService: AccountService,
                public versionService: VersionService) {

    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        const me = this;

        this.table.moveClick.subscribe((question: Question) => {
            this.moveQuestion(question);
        });

        this.table.editClick.subscribe((question: Question) => {
            this.questionSelected.emit(question);
        });

        this.table.deleteClick.subscribe((question: Question) => {
            this.delete(question.id, this.folder.id);
        });

        this.table.loaded.subscribe(() => {
            this.table.sortChange.subscribe((data: { column: string, direction: string }) => {
                this.paginator.pageIndex = 0;
                this.load(data.column, data.direction);
            });
            this.paginator.page.subscribe(() => {
                this.load();
            });

            this.load();
        });
    }

    /**
     * opens the detail view to create a new company
     */
    private addQuestionButtonClick() {
        const newQuestion: Question = new Question();
        newQuestion.id = 0;

        this.questionSelected.emit(newQuestion);
    }

    /**
     * load the table content
     *
     * @param column the column name to sort
     * @param direction the direction of sort (asc, desc)
     */
    private load(column: string = 'question', direction: string = 'asc') {
        this.isLoading = true;

        const sort: string = LwsTableComponent.getSortColumnByName(this.displayedColumns, column);
        const sortDesc: boolean = ((direction.toLowerCase() === 'desc') ? true : false);



        this.folderService.getQuestions(this.folder.id, this.paginator.pageIndex, this.paginator.pageSize, sort, sortDesc).then(
            (result: { items: Question[], numberOfAllItems: number }) => {
                this.isLoading = false;

                this.dataSource = result.items;
                this.generateStyleClassesForAnswerStates(this.dataSource);
                this.resultsLength = result.numberOfAllItems;
            },
            () => {
                this.isLoading = false;
            }
        );
    }

    /**
     * opens a dialog with folders and moves the given question to the selected folder
     *
     * @param question the question to move
     */
    private moveQuestion(question: Question) {
        this.loadFolderFromVersionAndSegment(this.selectedVersion.id, this.segment.id, () => {
            const dialogRef = this.dialog.open(FoldersListDialogComponent, {
                data: {
                    folders: this.foldersForMoveDialog
                }
            });

            dialogRef.afterClosed().subscribe(selectedFolder => {
                this.questionService.move(question.id, selectedFolder.id).then(
                    (success: boolean) => {
                        this.load();
                    }
                );
            });
        });
    }

    /**
     * load the folders for the selected version and segment
     *
     * @param versionId the if of the selected version
     * @param segmentId the if of the selected segment
     */
    private loadFolderFromVersionAndSegment(versionId: number, segmentId: number, onCompleteLoaded: () => void) {
        if (versionId == null || segmentId == null) {
            this.foldersForMoveDialog = [];

            return;
        }

        this.versionService.getFolders(versionId, segmentId).then(
            (result: { items: Folder[], numberOfAllItems: number }) => {
                // load subfolders

                this.foldersForMoveDialog = [];

                let processedSubfolders = 0;
                for (const folder of result.items) {

                    this.foldersForMoveDialog.push(folder);

                    this.folderService.getSubfolders(folder.id).then(
                        (childResult: { items: Folder[], numberOfAllItems: number }) => {
                            folder.subFolders = [];

                            if (childResult.items && childResult.items.length > 0) {
                                folder.subFolders = childResult.items;
                            }

                            processedSubfolders++;

                            if (processedSubfolders === result.items.length) {
                                onCompleteLoaded();
                            }
                        }
                    );
                }
            }
        );
    }

    /**
     * deletes the item with the given id
     *
     * @param id the id of the item to delete
     * @param id the id of the containing folder
     */
    private delete(id: number, folderId: number) {
        this.translator.get('questionsList_DeleteAction_MessageContent').subscribe((messageTranslation: string) => {
            this.translator.get('questionsList_DeleteAction_TitleContent').subscribe((titleTranslation: string) => {
                const dialogRef = this.dialog.open(LwsDialogComponent, {
                    data: {
                        cancelButton: true,
                        okButton: true,

                        message: messageTranslation,
                        title: titleTranslation
                    }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result === 'ok') {
                        this.isLoading = true;

                        this.questionService.delete(id, folderId).then(success => {
                            this.isLoading = false;

                            this.load();
                        });
                    }
                });
            });
        });
    }

    /**
     * Refresh the current view data
     */
    public refresh() {
        this.load();
    }

    private generateStyleClassesForAnswerStates(questions: Question[]) {
        if (questions != null) {
            for (const question of questions) {
                if (question.answereState === Question.ANSWERE_STATE_IN_CURRENT_VERSION) {
                    question.styleClass = 'answere-state-current-version';
                } else if (question.answereState === Question.ANSWERE_STATE_ONLY_PREVIOUS) {
                    question.styleClass = 'answere-state-previous-version';
                } else {
                    question.styleClass = 'answere-state-none';
                }
            }
        }
    }
}
