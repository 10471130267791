import {ModelInterface} from '../model-interface';

export class Segment implements ModelInterface {
    private _id: number = 0;
    private _name: string = '';

    // a list of properties-keys to ignore at jsonconvert
    _ignoreProperties: string[] = [];

    constructor() {

    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    /**
     * returns true if the segment name is 'compliance'
     */
    isCompliance(): boolean {
        return (this.name.toLocaleLowerCase() == 'compliance');
    }

    /**
     * returns true if the segment name is 'unternehmen'
     */
    isCompany(): boolean {
        return (this.name.toLocaleLowerCase() == 'unternehmen');
    }

    /**
     * returns true if the segment name is 'markt'
     */
    isMarket(): boolean {
        return (this.name.toLocaleLowerCase() == 'markt');
    }

    /**
     * returns true if the segment name is 'risiko'
     */
    isRisk(): boolean {
        return (this.name.toLocaleLowerCase() == 'risiko');
    }

    /**
     * returns the object as a valid json (without the underscore a the property name begin)
     */
    toJSON() {
        return {
            id: this.id,
            name: this.name,
        };
    }

    /**
     * creates a object from the given data
     *
     * @param data the data to create a model
     */
    static hydrate(data: any): Segment {
        const model: Segment = new Segment();

        model.id = data.id;
        model.name = data.name;

        return model;
    }
}
