import {Component, OnInit, ViewChild} from '@angular/core';
import {Unit} from '../../models/unit/unit';
import {Version} from '../../models/version/version';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {VersionService} from '../../services/server/version/version.service';
import {Location} from '@angular/common';
import {LwsDetailHeaderComponent} from '../../components/lws-detail-header/lws-detail-header.component';

@Component({
    selector: 'app-versions-copy',
    templateUrl: './versions-copy.component.html',
    styleUrls: ['./versions-copy.component.scss']
})
export class VersionsCopyComponent implements OnInit {
    @ViewChild(LwsDetailHeaderComponent) private header: LwsDetailHeaderComponent;
    public title: string = '';
    public version: Version = null;
    public isLoading: boolean = false;
    public folderId: number = null;

    constructor(public route: ActivatedRoute,
                public location: Location,
                public versionService: VersionService) {

    }

    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            const id: number = +params.get('id');
            this.folderId = +params.get('folderId');

            if (id > 0) {
                this.loadVersionById(id);
            }
        });
    }

    ngAfterViewInit() {
        this.addHeaderSubscribes();
    }

    /**
     * add the subscribes to the action-buttons
     */
    private addHeaderSubscribes() {
        this.header.backClick.subscribe(() => {
            this.location.back();
        });
    }

    /**
     *
     * @param id the id of the version
     */
    private loadVersionById(id: number) {
        this.isLoading = true;

        this.versionService.getDetail(id).then(
            (version: Version) => {
                this.isLoading = false;

                if (version == null) {
                    this.location.back();
                }

                this.version = version;
            },
            () => {
                this.isLoading = false;
            });
    }
}
