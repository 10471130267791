import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AccountService} from '../../services/server/account/account.service';
import {EventService} from '../../services/client/event/event.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandlingService} from '../../services/client/error-handling/error-handling.service';
import {AppConfig} from '../../app.config';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public readonly customerBackgroundImagePath: string = AppConfig.settings.customer.wallpaperPath;
    /**
     * set true if debug the form
     */
    public isDebug: boolean = false;
    
    /**
     * the model to save the form data
     *
     * @note: used for the loginForm
     */
    public model: { email: string, password: string } = {email: '', password: ''};
    
    /**
     * additional form classes for animated, etc.
     */
    public loginFormCssClasses: string = '';
    /**
     *
     */
    public isLoading: boolean = false;
    
    /**
     * the structure of the login-form
     */
    public loginForm: FormGroup = this.formBuilder.group({
        // the email field
        email: ['', Validators.compose([
            Validators.required,
            Validators.maxLength(200),
            // better email regex => https://emailregex.com/ and better than Validators.email
            Validators.pattern('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])')
        ])],
        // the password field
        password: ['', Validators.compose([
            Validators.required,
            Validators.minLength(10)
        ])],
    });
    
    constructor(public formBuilder: FormBuilder,
                public accountService: AccountService,
                public eventService: EventService,
                public router: Router,
                public errorHandlingService: ErrorHandlingService) {
        this.subscribeEvents();
    }
    
    /**
     * subscribe to needed events
     */
    private subscribeEvents() {
        const me = this;
    }
    
    /**
     * execute if the login failed
     */
    private onAccountLoginError() {
        this.loginFormCssClasses = 'animated shake';
        
        // remove the animation classes
        setTimeout(() => {
            this.loginFormCssClasses = '';
        }, 1000);
    }
    
    /**
     * executed if the user clicked on the login-button
     */
    private onSubmit() {
        if (this.loginForm.valid) {
            this.isLoading = true;
            
            this.accountService.login(this.loginForm.value['email'], this.loginForm.value['password']).then(
                () => {
                    this.isLoading = false;
                },
                (error: HttpErrorResponse) => {
                    this.isLoading = false;
                    
                    this.errorHandlingService.handleError('loginComponent_OAuthRequest', error);
                    this.onAccountLoginError();
                }
            );
        }
    }
    
    ngOnInit() {
        if (this.accountService.IsAuth()) {
            if (this.accountService.User.isAdmin()) {
                this.router.navigateByUrl('/assessments');
            } else {
                this.router.navigateByUrl('/dashboard');
            }
        }
    }
}
