
export class Filter {

    public name: string;

    public unitId: number;

    public segmentId: number;

    public folder1Id: number;

    public folder2Id: number;

    public identifier: string;

    public childs: Filter[] = [];

    public level: number = 0;
}
