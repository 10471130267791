import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {BaseGadget} from '../base-gadget';
import {GadgetService} from '../../../../services/server/reporting-dashboard/gadget.service';
import {CompanyService} from '../../../../services/server/company/company.service';
import {ReportingGadgets} from '../../../../services/client/reporting-gadgets';
import {ReportingGadgetConfig} from '../../../../models/reporting-dashboard/reporting-gadget-config';
import {Question} from '../../../../models/question/question';
import {ColumnInformation} from '../../../../models/lws-table/column-information';
import {LwsTableComponent} from '../../../lws-table/lws-table.component';
import {QuestionService} from '../../../../services/server/question/question.service';
import {Folder} from '../../../../models/folder/folder';
import {Router} from '@angular/router';
import {Unit} from '../../../../models/unit/unit';
import {QuestionWithPath} from '../../../../models/question/question-with-path';
import {MatPaginator} from '@angular/material/paginator';

@Component({
    selector: 'app-answer-list',
    templateUrl: './answer-list.component.html',
    styleUrls: ['./answer-list.component.scss']
})
export class AnswerListComponent extends BaseGadget implements OnInit, AfterViewInit {

    @ViewChild(LwsTableComponent) private table: LwsTableComponent;
    @ViewChild(MatPaginator) private paginator: MatPaginator;

    public selectedFilterType: { title: string, key: string };
    public filterType: { title: string, key: string }[] = [
        {title: 'NotAnswered', key: 'FILTER_TYPE_NOT_ANSWERED'},
        {title: 'NotRelevant', key: 'FILTER_TYPE_NOT_RELEVANT'},
    ];

    public isLoading: boolean;
    public dataSource: QuestionWithPath[] = [];
    public resultsLength = 0;

    public displayedColumns: ColumnInformation[] = [
        new ColumnInformation('question', 'Frage', 'text', 'question'),
        new ColumnInformation('path', 'Ebene', 'text', 'path' )
    ];

    constructor(public gadgetService: GadgetService,
                private companyService: CompanyService,
                private reportingGadgetsLogic: ReportingGadgets,
                private questionService: QuestionService,
                public router: Router) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();

        this.table.openClick.subscribe((question: QuestionWithPath) => {
            this.openQuestionDetail(question, question.folder2Id);
        });
    }

    protected loadFilters(configs: ReportingGadgetConfig[]) {
        if (configs) {
            this.loadFilterType(configs);
        }
    }

    private loadFilterType(configs: ReportingGadgetConfig[]) {
        const configValue = this.reportingGadgetsLogic.getConfigValue(configs, ReportingGadgetConfig.FILTER_TYPE);
        if (configValue) {
            this.filterType.forEach(value => {
                if (configValue.value === value.key) {
                    this.selectedFilterType = value;
                    return false;
                }
            });
        }
    }

    protected saveFilters() {
        if (this.selectedFilterType != null) {
            this.reportingGadgetsLogic.setConfigValue(this.gadget.config, ReportingGadgetConfig.FILTER_TYPE, this.selectedFilterType.key);
        } else {
            this.reportingGadgetsLogic.setConfigValue(this.gadget.config, ReportingGadgetConfig.FILTER_TYPE, '');
        }
    }

    protected loadGadgetReport() {
        this.load();
    }

    /**
     * load the table content
     *
     * @param column the column name to sort
     * @param direction the direction of sort (asc, desc)
     */
    private load(column: string = 'question', direction: string = 'asc') {
        this.isLoading = true;

        const sort: string = LwsTableComponent.getSortColumnByName(this.displayedColumns, column);
        const sortDesc: boolean = ((direction.toLowerCase() === 'desc') ? true : false);

        this.questionService.getAllFiltered(
            this.gadget.companyId,
            this.gadget.year,
            this.gadget.version,
            this.selectedFilterType.key,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            sort,
            sortDesc).then(
            (result: { items: QuestionWithPath[], numberOfAllItems: number }) => {
                this.isLoading = false;

                this.dataSource = result.items;
                this.resultsLength = result.numberOfAllItems;

            },
            () => {
                this.isLoading = false;
            }
        );
    }

    public openQuestionDetail(question: QuestionWithPath, folderId: number) {

        this.router.navigate(['/folders/' + folderId + '/questions/' + question.id], {
            queryParams: {
                // versionId: this.version.id,
                // breadcrumbs: this.breadcrumbs
            }
        });
    }
}
