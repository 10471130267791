<table mat-table [dataSource]="datasource" matSort matSortActive="created"
       matSortDisableClear matSortDirection="desc"
       [className]="'lws-table lws-table--layout-' + layoutSelected + ''">

    <ng-container *ngFor="let displayColumn of displayedColumns" matColumnDef="{{displayColumn.column}}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{displayColumn.title}}</th>

        <td mat-cell
            *matCellDef="let row"
            (click)="openItemBySingleClick(row)"
            [className]="row['styleClass'] ? row['styleClass'] + ' mat-cell' : ' mat-cell'"
            [matTooltip]="row[displayColumn.column]">
            <span *ngIf="displayColumn.type == 'text'">{{row[displayColumn.column]}}</span>
            <span *ngIf="displayColumn.type == 'number'">{{row[displayColumn.column]}}</span>
            <span *ngIf="displayColumn.type == 'date'">{{row[displayColumn.column] | date}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions" *ngIf="enableActions == true">
        <th mat-header-cell *matHeaderCellDef>{{'lwsTable_Actions_Text'|translate}}</th>
        <td mat-cell *matCellDef="let row"
            [className]="row['styleClass'] ? row['styleClass'] + ' mat-cell cdk-column-actions' : ' mat-cell cdk-column-actions'">

            <button *ngIf="enableOpenAction == true" mat-icon-button class="row-action"
                    [matTooltip]="'lwsTable_Open_ToolTipText'|translate"
                    (click)="openAction(row)">
                <mat-icon>open_in_new</mat-icon>
            </button>

            <button *ngIf="enableExportAction == true" mat-icon-button class="row-action"
                    [matTooltip]="'lwsTable_Export_ToolTipText'|translate"
                    (click)="exportAction(row)">
                <mat-icon>cloud_download</mat-icon>
            </button>

            <button *ngIf="enableCopyAction == true" mat-icon-button class="row-action"
                    [matTooltip]="'lwsTable_Copy_ToolTipText'|translate"
                    (click)="copyAction(row)">
                <mat-icon>file_copy</mat-icon>
            </button>

            <button *ngIf="enableMoveAction == true" mat-icon-button class="row-action"
                    [matTooltip]="'lwsTable_Move_ToolTipText'|translate"
                    (click)="moveAction(row)">
                <mat-icon>redo</mat-icon>
            </button>

            <button *ngIf="enableEditAction == true" mat-icon-button class="row-action"
                    [matTooltip]="'lwsTable_Edit_ToolTipText'|translate"
                    (click)="editAction(row)">
                <mat-icon>edit</mat-icon>
            </button>

            <button *ngIf="enableDeleteAction == true" mat-icon-button class="row-action"
                    [matTooltip]="'lwsTable_Delete_ToolTipText'|translate"
                    color="warn" (click)="deleteAction(row)">
                <mat-icon>delete</mat-icon>
            </button>

        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
</table>
