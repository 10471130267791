import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {LwsDetailHeaderComponent} from '../../components/lws-detail-header/lws-detail-header.component';
import {TranslateService} from '@ngx-translate/core';
import {FormControl} from '@angular/forms';
import {Company} from '../../models/company/company';
import {CompaniesListComponent} from '../../components/companies-list/companies-list.component';
import {AccountService} from '../../services/server/account/account.service';

@Component({
    selector: 'app-assessments',
    templateUrl: './assessments.component.html',
    styleUrls: ['./assessments.component.scss']
})
export class AssessmentsComponent implements OnInit, AfterViewInit {
    private searchFormControl: FormControl = new FormControl();
    @ViewChild(LwsDetailHeaderComponent) private header: LwsDetailHeaderComponent;
    @ViewChild(CompaniesListComponent) private companiesList: CompaniesListComponent;
    public company: Company = null;
    public title: string = '';
    
    constructor(public translator: TranslateService,
                public accountService: AccountService) {
        
    }
    
    ngOnInit() {
        // only admins should load the company list
        if (!this.accountService.User.isAdmin()) {
            this.company = this.accountService.User.company;
        }
        this.loadTitle();
    }
    
    /**
     *
     */
    ngAfterViewInit() {
        const me = this;
        
        if (this.accountService.User.isAdmin()
            && this.companiesList) {
            this.companiesList.openClick.subscribe((data) => {
                const selectedCompany: Company = data.company;
                this.company = selectedCompany;
                this.loadTitle();
            });
        }
    }
    
    /**
     * loads the title for this page
     */
    loadTitle() {
        this.translator.get('sidenav_PagesAssessments_Title').subscribe((titleTranslation: string) => {
            this.title = titleTranslation + ((this.company) ? ' - ' + this.company.group.name : '');
        });
    }
}
