import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-lws-input-dialog',
    templateUrl: './lws-input-dialog.component.html',
    styleUrls: ['./lws-input-dialog.component.scss']
})
export class LwsInputDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<LwsInputDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    // definition
                    title: string,
                    message: string,
                    okButton: boolean,
                    cancelButton: boolean,
                    button1Button: boolean,
                    button2Button: boolean,
                    button1ButtonTranslationKey: string,
                    button2ButtonTranslationKey: string,
                    value: string
                } = {
                    // default values
                    title: '',
                    message: '',
                    okButton: false,
                    cancelButton: false,
                    button1Button: false,
                    button1ButtonTranslationKey: '',
                    button2Button: false,
                    button2ButtonTranslationKey: '',
                    value: ''
                }) {

    }

    ngOnInit() {

    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
