import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LwsDialogComponent} from '../lws-dialog/lws-dialog.component';
import {LwsTableComponent} from '../lws-table/lws-table.component';
import {Company} from '../../models/company/company';
import {UnitService} from '../../services/server/unit/unit.service';
import {Unit} from '../../models/unit/unit';
import {ColumnInformation} from '../../models/lws-table/column-information';
import {CompanyService} from '../../services/server/company/company.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-units-list',
    templateUrl: './units-list.component.html',
    styleUrls: ['./units-list.component.scss']
})
export class UnitsListComponent implements OnInit, AfterViewInit {
    @ViewChild(LwsTableComponent) public table: LwsTableComponent;
    @ViewChild(MatPaginator) public paginator: MatPaginator;
    @Input() public company: Company;
    @Input() public breadcrumbs: string[];
    public isLoading: boolean = false;
    /**
     * the length of the current loading
     */
    public resultsLength = 0;
    /**
     * the datasource
     */
    public dataSource: Unit[] = [];
    /**
     * the columns of the table to display
     */
    public displayedColumns: ColumnInformation[] = [
        new ColumnInformation('id', '#', 'number', 'id'),
        new ColumnInformation('name', 'Name', 'text', 'name'),
    ];

    constructor(public router: Router,
                public unitService: UnitService,
                public companyService: CompanyService,
                public dialog: MatDialog,
                public translator: TranslateService) {

    }

    ngOnInit() {

    }

    /**
     *
     */
    ngAfterViewInit() {
        const me = this;

        this.table.deleteClick.subscribe((row: Unit) => {
            this.delete(row.id);
        });

        this.table.editClick.subscribe((unit: Unit) => {
            me.router.navigate(['/companies/units/' + unit.id], {
                queryParams: {
                    companyId: this.company.id,
                    breadcrumbs: this.breadcrumbs
                }
            });
        });

        this.table.openClick.subscribe((unit: Unit) => {
            this.openBusniessYearsForUnit(unit.id);
        });

        this.table.loaded.subscribe(() => {
            this.table.sortChange.subscribe((data: { column: string, direction: string }) => {
                this.paginator.pageIndex = 0;
                this.load(data.column, data.direction);
            });
            this.paginator.page.subscribe(() => {
                this.load();
            });

            this.load();
        });
    }

    /**
     * deletes the item with the given id
     *
     * @param id the id of the item to delete
     */
    private delete(id: number) {
        this.translator.get('unitsList_DeleteAction_MessageContent').subscribe((messageTranslation: string) => {
            this.translator.get('unitsList_DeleteAction_TitleContent').subscribe((titleTranslation: string) => {
                const dialogRef = this.dialog.open(LwsDialogComponent, {
                    data: {
                        cancelButton: true,
                        okButton: true,

                        message: messageTranslation,
                        title: titleTranslation
                    }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result === 'ok') {
                        this.isLoading = true;

                        this.unitService.delete(id).then(success => {
                            this.isLoading = false;

                            this.load();
                        });
                    }
                });
            });
        });
    }

    /**
     * load the table content
     */
    private load(column: string = '', direction: string = '') {
        this.isLoading = true;

        const sort: string = LwsTableComponent.getSortColumnByName(this.displayedColumns, column);
        const sortDesc: boolean = ((direction.toLowerCase() === 'desc') ? true : false);

        if (this.company != null) {
            this.companyService.getUnits(this.company.id, this.paginator.pageIndex, this.paginator.pageSize, sort, sortDesc).then(
                (result: { items: Unit[], numberOfAllItems: number }) => {
                    this.isLoading = false;

                    if (result.items && result.items.length > 0) {
                        this.dataSource = result.items;
                        this.resultsLength = result.numberOfAllItems;
                    }
                },
                (error) => {
                    this.isLoading = false;
                });
        } else {
            this.unitService.getAll(this.paginator.pageIndex, this.paginator.pageSize, sort, sortDesc).then(
                (units) => {
                    this.isLoading = false;

                    if (units) {
                        this.dataSource = units;
                        this.resultsLength = units.length;
                    }
                },
                (error) => {
                    this.isLoading = false;
                });
        }
    }

    /**
     * opens the detail view to create a new unit
     */
    public addUnitButtonClick() {
        this.router.navigate(['/companies/units/0'], {
            queryParams: {
                companyId: this.company.id,
                breadcrumbs: this.breadcrumbs
            }
        });
    }

    /**
     * opens the detail view to create a new unit
     */
    private openBusniessYearsForUnit(unitId: number) {
        this.router.navigate(['/companies/units/' + unitId + '/years'], {
            queryParams: {
                breadcrumbs: this.breadcrumbs
            }
        });
    }
}
