import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LwsTableComponent} from '../lws-table/lws-table.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {Company} from '../../models/company/company';
import {LwsDialogComponent} from '../lws-dialog/lws-dialog.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CompanyService} from '../../services/server/company/company.service';
import {ColumnInformation} from '../../models/lws-table/column-information';
import {Version} from '../../models/version/version';
import {Group} from '../../models/group/group';
import {HttpResponse} from '@angular/common/http';
import {YearService} from '../../services/server/year/year.service';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-companies-list',
    templateUrl: './companies-list.component.html',
    styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent implements OnInit, AfterViewInit {
    /**
     *
     */
    @ViewChild(LwsTableComponent) private table: LwsTableComponent;
    /**
     *
     */
    @ViewChild(MatPaginator) private paginator: MatPaginator;
    /**
     * if true, the open column is displayed
     */
    @Input() enableOpenAction: boolean = false;
    /**
     * if true, the open column is displayed
     */
    @Input() enableEditAction: boolean = false;
    /**
     * if true, the open column is displayed
     */
    @Input() enableDeleteAction: boolean = false;
    /**
     *
     */
    @Output() openClick = new EventEmitter();
    /**
     *
     */
    @Output() editClick = new EventEmitter();
    /**
     *
     */
    public isLoading: boolean = false;
    /**
     * the length of the current loading
     */
    public resultsLength = 0;
    /**
     * the datasource
     */
    public dataSource: Company[] = [];
    /**
     * the columns of the table to display
     */
    public displayedColumns: ColumnInformation[] = [
        new ColumnInformation('id', '#', 'number', 'id'),
        new ColumnInformation('groupName', 'Name', 'text', 'group.name'),
    ];

    constructor(public router: Router,
                public dialog: MatDialog,
                public translator: TranslateService,
                public companyService: CompanyService,
                public yearService: YearService) {

    }

    ngOnInit() {

    }

    /**
     *
     */
    ngAfterViewInit() {
        const me = this;

        this.table.loaded.subscribe(() => {
            this.table.sortChange.subscribe((data: { column: string, direction: string }) => {
                this.paginator.pageIndex = 0;
                this.load(data.column, data.direction);
            });
            this.paginator.page.subscribe(() => {
                this.load();
            });

            this.load();
        });

        this.table.openClick.subscribe((company: Company) => {
            this.openClick.emit({company: company});
        });

        this.table.editClick.subscribe((company: Company) => {
            this.editClick.emit({company: company});
        });

        this.table.deleteClick.subscribe((company: Company) => {
            this.deleteCompany(company);
        });

        this.table.exportClick.subscribe((company: Company) => {
            this.exportGroup(company);
        });
    }


    /**
     * deletes the given company
     *
     * @param company the company to delete
     */
    private deleteCompany(company: Company) {
        this.translator.get('companyList_DeleteAction_MessageContent').subscribe((messageTranslation: string) => {
            this.translator.get('companyList_DeleteAction_TitleContent').subscribe((titleTranslation: string) => {
                const dialogRef = this.dialog.open(LwsDialogComponent, {
                    data: {
                        cancelButton: true,
                        okButton: true,

                        message: messageTranslation,
                        title: titleTranslation
                    }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result === 'ok') {
                        this.isLoading = true;

                        this.companyService.delete(company.id).then(success => {
                            this.isLoading = false;

                            this.load();
                        });
                    }
                });
            });
        });
    }

    /**
     * load the table-service content
     */
    private load(column: string = '', direction: string = '') {
        this.isLoading = true;

        const sort: string = LwsTableComponent.getSortColumnByName(this.displayedColumns, column);
        const sortDesc: boolean = ((direction.toLowerCase() === 'desc') ? true : false);

        this.companyService.getAll(this.paginator.pageIndex, this.paginator.pageSize, sort, sortDesc).then(
            (result: { items: Company[], numberOfAllItems: number }) => {
                this.isLoading = false;

                if (result.items) {
                    this.dataSource = result.items;
                    this.resultsLength = result.numberOfAllItems;
                }
            },
            () => {
                this.isLoading = false;
            });
    }

    private exportGroup(company: Company) {
        if (company != null) {
            this.yearService.exportGroup(company.group.id).then(
                (response: HttpResponse<any>) => {
                    const fileName: string = 'export.xlsx';

                    FileSaver.saveAs(response.body, fileName);
                },
                () => {
                });

        }
    }
}
