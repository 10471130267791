import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReportingGadget} from '../../../models/reporting-dashboard/reporting-gadget';
import {AccountService} from '../../../services/server/account/account.service';
import {Privilege} from '../../../models/privilege/privilege';
import {GadgetService} from '../../../services/server/reporting-dashboard/gadget.service';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-reporting-gadget',
    templateUrl: './reporting-gadget.component.html',
    styleUrls: ['./reporting-gadget.component.scss']
})
export class ReportingGadgetComponent implements OnInit {

    @Input('gadget') gadget: ReportingGadget;
    @Output() removeGadgetHandler = new EventEmitter();
    @Output() saveGadgetHandler = new EventEmitter<any>();

    private saveEmitterWithDebounce = new Subject<any>();

    public isConfigure: boolean = false;

    public isConfigureable: boolean = false;
    public isFiltereable: boolean = false;

    constructor(public accountService: AccountService,
                private gadgetService: GadgetService) {
    }

    ngOnInit() {
        this.isConfigureable = this.accountService.User.hasRight(Privilege.REPORTING_DASHBOARD_CONFIGURE);
        this.isFiltereable = this.accountService.User.hasRight(Privilege.REPORTING_DASHBOARD_FILTER);

        this.saveEmitterWithDebounce.pipe(
            debounceTime(250)
        ).subscribe( () => this.saveGadgetHandler.emit(() => this.isConfigure = false));

    }

    public removeItem() {
        this.removeGadgetHandler.emit(this.gadget);
        this.saveGadgetHandler.emit();
    }

    public configure() {
        this.isConfigure = true;
    }

    public saveConfiguration() {
        this.saveEmitterWithDebounce.next(true);
    }


}
