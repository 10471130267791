import {Injectable} from '@angular/core';
import {LockType} from './lock-type.enum';
import {RestService} from '../rest/rest.service';
import {HttpErrorResponse} from '@angular/common/http';
import {RequestBodyType} from '../rest/request-body-type.enum';
import {UserService} from '../user/user.service';
import {ErrorHandlingService} from '../../client/error-handling/error-handling.service';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../models/user/user';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class LockService {
    /**
     * the time between two locks (in seconds)
     */
    public static lockRefreshTimer = 30;

    constructor(public restService: RestService,
                public userService: UserService,
                public snackBar: MatSnackBar,
                public errorHandlingService: ErrorHandlingService,
                public translator: TranslateService) {

    }

    public lock(id: number, type: LockType): Promise<boolean> {
        const parameter: { key: string, value: string }[] = [];
        parameter.push({key: 'datasetId', value: id.toString()});
        parameter.push({key: 'type', value: type.toString()});

        return this.restService.postForm(
            '/lock',
            parameter,
            RequestBodyType.FormUrlEncoded).toPromise()
        .then(() => {
            return true;
        }).catch((httpError: HttpErrorResponse) => {
            const userName: string = httpError.error;

            if (userName.trim().length > 0) {
                this.translator.get('lockService_LockUser_423').subscribe(
                    (translation: string) => {
                        this.snackBar.open(translation + userName, '', {
                            duration: (5 * 1000), // display 5 seconds
                            panelClass: 'snackbar-lws-error'
                        });
                    });

                return false;
            } else {
                this.errorHandlingService.handleError('lockService_Lock', httpError);

                return false;
            }
        });
    }

    public unlock(id: number, type: LockType) {
        const parameter: { key: string, value: string }[] = [];
        parameter.push({key: 'datasetId', value: id.toString()});
        parameter.push({key: 'type', value: type.toString()});

        return this.restService.postForm(
            '/unlock',
            parameter,
            RequestBodyType.FormUrlEncoded).toPromise()
        .then(() => {
            return true;
        }).catch((httpError: HttpErrorResponse) => {
            const userId: number = httpError.error;

            if (userId > 0) {
                return this.userService.getDetail(userId).then(
                    (user: User) => {
                        this.translator.get('lockService_LockUser_423').subscribe(
                            (translation: string) => {
                                this.snackBar.open(translation + user.name, '', {
                                    duration: (5 * 1000), // display 5 seconds
                                    panelClass: 'snackbar-lws-error'
                                });
                            });

                        return false;
                    }
                );
            } else {
                this.errorHandlingService.handleError('lockService_Lock', httpError);

                return false;
            }
        });
    }
}
