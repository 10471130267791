<mat-form-field *ngIf="filters">
  <mat-label>{{ 'Layer' | translate }}</mat-label>
  <mat-select
          [ngModel]="selectedIdentifier"
          [disabled]="gadget.year == null || gadget.version == null"
          (selectionChange)="filterChanged($event.value);">
    <mat-option
            *ngFor="let filter of flatFilters"
            [value]="filter.identifier"
            [ngClass]="{
              'one-indent': filter.level == 1,
              'two-indent': filter.level == 2,
              'three-indent': filter.level == 3,
              'four-indent': filter.level == 4,
              'five-indent': filter.level == 5
            }">
      {{filter.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
