export class CacheItem {
    private _cacheKey: string = '';
    private _cacheValue: any = '';
    
    constructor() {
    
    }
    
    get cacheKey(): string {
        return this._cacheKey;
    }
    
    set cacheKey(value: string) {
        this._cacheKey = value;
    }
    
    get cacheValue(): any {
        return this._cacheValue;
    }
    
    set cacheValue(value: any) {
        this._cacheValue = value;
    }
}