import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {FolderService} from '../../services/server/folder/folder.service';
import {MeasureService} from '../../services/server/measure/measure.service';
import {TranslateService} from '@ngx-translate/core';
import {Measure} from '../../models/measure/measure';
import {LwsDialogComponent} from '../../components/lws-dialog/lws-dialog.component';
import {Question} from '../../models/question/question';
import {LwsTableComponent} from '../../components/lws-table/lws-table.component';

@Component({
    template: ''
})
export abstract class Listing {

    /*
     * value in seconds
     */
    static POPUP_SHOW_DURATION: number = 5000;

    static DEFAULT_PAGE_INDEX: number = 0;

    static DEFAULT_PAGE_ITEMS: number = 25;

    @ViewChild(LwsTableComponent) protected table: LwsTableComponent;
    @ViewChild(MatPaginator) public paginator: MatPaginator;

    @Output() entitySelected = new EventEmitter<number>();

    public isLoading: boolean = false;

    /**
     * the length of the current loading
     */
    public resultsLength = 0;

    /**
     * the datasource
     */
    public dataSource: Measure[] = [];

    /**
     * Holds the last sort direction. Used for refresh
     */
    public lastSortDesc: boolean = false;

    /**
     * Holds the last sort column. Used for refresh
     */
    public lastSortColumn: string = null;

    public isInitialized: boolean = false;

    constructor(public dialog: MatDialog,
                public translator: TranslateService) {
    }

    /**
     * Returns the service which is used to access the listing
     */
    protected abstract getListService();

    /**
     * Returns the service which is used to work with the detail items of the list
     */
    protected abstract getDetailService();

    /**
     * Method which is used to load the data
     */
    protected abstract loadData(pageIndex: number, itemsPerPage: number, sortColumn: string, sortDesc: boolean);

    /**
     * Creates a new model and only returns the model.
     * e.g. return new Measure();
     */
    protected abstract createNewModelInstance();

    /**
     * Returns the visible columns
     */
    protected abstract getDisplayedColumns();

    init() {

            this.isInitialized = true;

            this.table?.loaded.subscribe(() => {
                this.loadData(Listing.DEFAULT_PAGE_INDEX, Listing.DEFAULT_PAGE_ITEMS, null, null);
            });

            this.paginator?.page.subscribe(() => {
                this.loadData(Listing.DEFAULT_PAGE_INDEX, Listing.DEFAULT_PAGE_ITEMS, null, null);
            });

            this.table?.sortChange.subscribe((data: { column: string, direction: string }) => {
                this.paginator.pageIndex = 0;

                this.lastSortColumn = LwsTableComponent.getSortColumnByName(this.getDisplayedColumns(), data.column);
                this.lastSortDesc = ((data.direction.toLowerCase() === 'desc') ? true : false);

                this.loadData(this.paginator.pageIndex, this.paginator.pageSize, this.lastSortColumn, this.lastSortDesc);
            });

            this.table?.editClick.subscribe((entity) => {
                this.entitySelected.emit(entity);
            });

            this.table?.deleteClick.subscribe((selection) => {
                this.onDeleteClicked(selection);
            });

            this.table?.moveClick.subscribe((selection) => {
                this.onMoveClicked(selection);
            });


    }

    /**
     * Show the items in the listing.
     */
    protected showItems(result: { items: Measure[], numberOfAllItems: number }) {
        if (result.items && result.items.length > 0) {
            this.dataSource = result.items;
            this.resultsLength = result.numberOfAllItems;
        }
    }

    /**
     * Refresh the current view data
     */
    public refresh() {
        this.loadData(this.paginator.pageIndex, this.paginator.pageSize, this.lastSortColumn, this.lastSortDesc);
    }

    /**
     * opens the detail view to create a new company
     */
    protected onAddClicked() {
        this.entitySelected.emit(this.createNewModelInstance());
    }

    /**
     * Called if the deletec button was clicked
     *
     * @param selection The selected item
     */
    protected onDeleteClicked(selection) {
        this.translator.get('DeleteAction_MessageContent').subscribe((messageTranslation: string) => {
            this.translator.get('DeleteAction_TitleContent').subscribe((titleTranslation: string) => {
                const dialogRef = this.dialog.open(LwsDialogComponent, {
                    data: {
                        cancelButton: true,
                        okButton: true,

                        message: messageTranslation,
                        title: titleTranslation
                    }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result === 'ok') {
                        this.isLoading = true;

                        this.delete(selection);
                    }
                });
            });
        });
    }

    /**
     * Called if the deletec button was clicked
     *
     * @param selection The selected item
     */
    protected onMoveClicked(selection) {
        this.move(selection);
    }

    /**
     * deletes the given item
     */
    protected abstract delete(selection);

    /**
     * deletes the given item
     */
    protected abstract move(selection);

}
