import {ModelInterface} from '../model-interface';
import {Segment} from '../segment/segment';
import {Version} from '../version/version';
import {Group} from '../group/group';

export class Folder implements ModelInterface {
    private _id: number = 0;
    private _name: string = '';
    private _segment: Segment = null;
    private _version: Version = null;
    private _parentFolder: Folder = null;
    private _subFolders: Folder[] = [];
    
    // a list of properties-keys to ignore at jsonconvert
    _ignoreProperties: string[] = [];
    
    constructor() {
    
    }
    
    get id(): number {
        return this._id;
    }
    
    set id(value: number) {
        this._id = value;
    }
    
    get name(): string {
        return this._name;
    }
    
    set name(value: string) {
        this._name = value;
    }
    
    get segment(): Segment {
        return this._segment;
    }
    
    set segment(value: Segment) {
        this._segment = value;
    }
    
    get version(): Version {
        return this._version;
    }
    
    set version(value: Version) {
        this._version = value;
    }
    
    get parentFolder(): Folder {
        return this._parentFolder;
    }
    
    set parentFolder(value: Folder) {
        this._parentFolder = value;
    }
    
    get subFolders(): Folder[] {
        return this._subFolders;
    }
    
    set subFolders(value: Folder[]) {
        this._subFolders = value;
    }
    
    /**
     * returns the object as a valid json (without the underscore a the property name begin)
     */
    toJSON() {
        return {
            id: this.id,
            name: this.name,
            segment: this.segment,
            version: this.version,
            parentFolder: this.parentFolder,
            subFolders: this.subFolders
        };
    }
    
    /**
     * creates a object from the given data
     *
     * @param data the data to create a model
     */
    static hydrate(data: any): Folder {
        const model: Folder = new Folder();
        
        model.id = data.id;
        model.name = data.name;
        model.segment = data.segment;
        model.version = data.version;
        
        if (data.parentFolder) {
            model.parentFolder = Folder.hydrate(data.parentFolder);
        }
        
        model.subFolders = [];
        if (data.subfolders !== undefined && data.subfolders !== null) {
            for (const subfolder of data.subfolders) {
                model.subFolders.push(Folder.hydrate(subfolder));
            }
        }
        
        return model;
    }
}
