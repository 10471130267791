<section>

    <app-lws-detail-header [backButton]="true" [title]="'versionsCopy_PageTitle'|translate"></app-lws-detail-header>

    <main class="version--copy-form">

        <app-versions-copy-form [version]="version" [folderId]="folderId"></app-versions-copy-form>

    </main>

</section>
