import { Component, OnInit } from '@angular/core';
import {BaseGadget} from '../base-gadget';
import {ReportingGadgetConfig} from '../../../../models/reporting-dashboard/reporting-gadget-config';
import {GadgetService} from '../../../../services/server/reporting-dashboard/gadget.service';
import {CompanyService} from '../../../../services/server/company/company.service';
import {ReportingGadgets} from '../../../../services/client/reporting-gadgets';
import {Data} from '../../../../models/reporting-dashboard/data';
import {Company} from '../../../../models/company/company';
import {TruncateBreakLongStringFormatter} from '../../../../businesslogic/formatter/TruncateBreakLongStringFormatter';
import {Segments} from '../../../../models/segment/segments';
import {CurrencyPipe, formatCurrency} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-answers-risk-compliance',
  templateUrl: './answers-risk-compliance.component.html',
  styleUrls: ['./answers-risk-compliance.component.scss']
})
export class AnswersRiskComplianceComponent  extends BaseGadget implements OnInit {

    public isExistentialThreat: boolean;
    public selectedAmountOfDamage: string;
    public existentialThreat: boolean;

    private damageLabel: string;
    private answerLabel: string;

    readonly relevantSegments = [Segments.RISK, Segments.COMPLIANCE];

    public existentialThreatValues = [
        {title: 'Ja', value: true},
        {title: 'Nein', value: false},
        {title: 'Alle', value: ''}
    ];

    public selectedDamages: { title: string, value: string }[] = [];

    public damageValues: { title: string, value: string }[] = [
        {title: 'Kosten', value: 'damageCosts'},
        {title: 'Umsatzverlust', value: 'damageSales'},
        {title: 'Vermögensschaden', value: 'damagePecuniaryLoss'},
        {title: 'Motivationsverlust der MA', value: 'damageMotivationLoss'},
        {title: 'Sachschaden', value: 'damageProperty'},
        {title: 'Schädigung der Wettbewerbsfähigkeit', value: 'damageCompetitiveness'},
        {title: 'Unzufriedenheit bei Kunden', value: 'damageDissatisfaction'},
        {title: 'Bestandsgefährdung', value: 'damageConsistedHazard'},
        {title: 'Drittschaden', value: 'damageThirdHarm'},
        {title: 'Imageschaden', value: 'damageImage'},
        {title: 'Qualitätsschaden', value: 'damageQuality'},
        {title: 'Strafverfahren', value: 'damageCriminalProceedings'},
        {title: 'Umweltschaden', value: 'damageEnvironmental'},
        {title: 'Personenschaden', value: 'damagePeopleHurt'},
        {title: 'Gewinneinbußen', value: 'damageProfit'},

    ];
    public amountOfDamages: { title: string, value: string }[] = [];

    public chartData: { yAxis: { axisLabel: { formatter: (value, index) => string; interval: number }; data: string[]; axisTick: { show: boolean }; position: string; type: string }[]; xAxis: { axisLabel: { formatter: (value, index) => any; width: string }; min: number; minInterval: number; maxInterval: number; type: string }[]; legend: { data: string[] }; grid: { left: string; bottom: string; right: string; containLabel: boolean }; series: ({ stack: string; color: string; data: number[]; name: string; label: { show: boolean }; type: string } | { stack: string; color: string; data: number[]; name: string; label: { show: boolean; position: string }; type: string })[]; tooltip: { formatter: (params, ticket, callback) => string; axisPointer: { type: string }; trigger: string } } = null;


  constructor(public gadgetService: GadgetService,
              private companyService: CompanyService,
              private reportingGadgetsLogic: ReportingGadgets,
              public companiesService: CompanyService,
              private currencyPipe : CurrencyPipe,
              private translateService: TranslateService) {
      super();
  }

  ngOnInit() {
      super.ngOnInit();
      this.loadAmountOfDamages();
      this.translateService.get('risk_compliance_gadget_answerLabel').subscribe(result => this.answerLabel = result);
      this.translateService.get('risk_compliance_gadget_damageLabel').subscribe(result => this.damageLabel = result);
  }

    protected loadFilters(configs: ReportingGadgetConfig[]) {
      if(configs) {
          this.loadExtentialThreatFilter(configs);
          this.loadNetDamageFilter(configs);
          this.loadDamageFilter(configs);
      }
    }

    private loadAmountOfDamages() {

        this.companiesService.getDetail(this.gadget.companyId).then(
            (company: Company) => {
                this.amountOfDamages = [];
                this.addOptionToList(company.amountOfLoss1, company.amountOfLoss1, this.amountOfDamages);
                this.addOptionToList(company.amountOfLoss2, company.amountOfLoss2, this.amountOfDamages);
                this.addOptionToList(company.amountOfLoss3, company.amountOfLoss3, this.amountOfDamages);
                this.addOptionToList(company.amountOfLoss4, company.amountOfLoss4, this.amountOfDamages);
                this.addOptionToList('Alle', '', this.amountOfDamages);

            });
    }

    protected loadGadgetReport() {

        if (this.gadget.id != null && this.gadget.id > 0) {
            this.gadgetService.getResults(this.gadget.companyId, this.gadget.id, this.gadget.config).then((resultData) => {
                    this.chartData = this.generateChartData(resultData);
                },
                () => {
                });
        }
    }

    protected saveFilters() {
      let existencialThreat = null;
       if(this.existentialThreat != null) {
           existencialThreat = String(this.existentialThreat);
       }

        let selectedDamagesAsString: string = '';
        this.selectedDamages.forEach(item => {
            if (selectedDamagesAsString.length > 0) {
                selectedDamagesAsString += ReportingGadgetConfig.MULTISELECT_SEPARATOR;
            }
            selectedDamagesAsString += item.value;
        })
        this.reportingGadgetsLogic.setConfigValue(this.gadget.config, ReportingGadgetConfig.DAMAGES, selectedDamagesAsString);

        this.reportingGadgetsLogic.setConfigValue(
            this.gadget.config,
            ReportingGadgetConfig.EXTENTIAL_THREAT,
            existencialThreat
        );

        this.reportingGadgetsLogic.setConfigValue(
            this.gadget.config,
            ReportingGadgetConfig.NET_DAMAGE,
            this.selectedAmountOfDamage
        );

    }

    private loadExtentialThreatFilter(configs: ReportingGadgetConfig[]) {
        const configValue = this.reportingGadgetsLogic.getConfigValue(configs, ReportingGadgetConfig.EXTENTIAL_THREAT);
        if (configValue && configValue.value) {
            this.existentialThreat = (configValue.value == 'true');
        } else {
            this.existentialThreat = null;
        }
    }

    private loadNetDamageFilter(configs: ReportingGadgetConfig[]) {

        const configValue = this.reportingGadgetsLogic.getConfigValue(configs, ReportingGadgetConfig.NET_DAMAGE);
        if (configValue) {
            this.selectedAmountOfDamage = configValue.value;
        }
    }

    private loadDamageFilter(configs: ReportingGadgetConfig[]) {
        const configValue = this.reportingGadgetsLogic.getConfigValue(configs, ReportingGadgetConfig.DAMAGES);
        if (configValue) {
            const valueIds: string[] = configValue.value.split(ReportingGadgetConfig.MULTISELECT_SEPARATOR);

            this.damageValues.forEach(value => {
                if (valueIds.includes(value.value + '')) {
                    this.selectedDamages.push(value);
                    return false;
                }
            });
        }

    }



    private generateChartData(dataResults: Data[]) {

        const yAxis: string[] = [];
        const xAxisRating: number[] = [];
        const xAxisDamge: number[] = [];

        let maxDamage = 0;

        dataResults.forEach(data => {

            yAxis.push(data.label);
            xAxisDamge.push(data.secondValue);

            if (data.secondValue > maxDamage) {
                maxDamage = data.secondValue;
            }



        });

        const step = (maxDamage / 5.0) > 0 ? (maxDamage / 5.0) : 1;

        dataResults.forEach(data => {

            xAxisRating.push((data.value -  5) * step);

            if (data.secondValue > maxDamage) {
                maxDamage = data.secondValue;
            }
        });


        return  {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (params, ticket, callback) => {
                    const ratingValue = params[0].data < 0 ?  5 + params[0].data / step : params[0].data;

                    return params[0].name + '<br>' + params[0].seriesName + ': ' + ratingValue +
                                            '<br>' +  params[1].seriesName + ': ' + params[1].data;
                }
            },


            legend: {
                data: [this.answerLabel, this.damageLabel]
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        width: '400px',
                        formatter: (value, index) => {

                            if (value <= 0) {
                                value = 5 + value / step;
                            } else {
                                value =  this.currencyPipe.transform(value, 'EUR', '€', '1.0-0');

                            }
                            return value;
                        }
                    },
                    minInterval : step,
                    maxInterval: step,
                    min: step * (-5)
                }
            ],

            yAxis: [
                {

                    position: 'left',
                    axisLabel: {
                        interval: -1,
                        formatter: (value, index) => {
                            return TruncateBreakLongStringFormatter.format(value);
                        }
                    },
                    type: 'category',
                    axisTick: {
                        show: false
                    },
                    data: yAxis
                }
            ],
            series: [

                {
                    name: this.answerLabel,
                    color: '#CD013D',
                    type: 'bar',
                    stack: 's',
                    label: {
                        show: false
                    },
                    data: xAxisRating
                },
                {
                    name:  this.damageLabel,
                    color: '#626D73',
                    type: 'bar',
                    stack: 's',
                    label: {
                        show: false,
                        position: 'left'
                    },
                    data: xAxisDamge
                }
            ]
        };





    }

    private addOptionToList(title: string, value: string, list) {
        list.push({title, value});
    }



}
