<section>

    <form *ngIf="form != null" class="form" (ngSubmit)="onSubmit()" [formGroup]="form">

        <mat-list>

            <mat-list-item>

                <mat-form-field [floatLabel]="true">
                    <input matInput placeholder="{{'unitDetailForm_NameInput_Placeholder'|translate}}" required
                           type="text" formControlName="name" [maxLength]="80">
                </mat-form-field>

            </mat-list-item>

            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <input matInput placeholder="{{'unitDetailForm_successFactor1Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor1" [maxLength]="80">
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="is--right">
                    <input matInput placeholder="{{'unitDetailForm_successFactor2Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor2" [maxLength]="80">
                </mat-form-field>

            </mat-list-item>

            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <input matInput placeholder="{{'unitDetailForm_successFactor3Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor3" [maxLength]="80">
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="is--right">
                    <input matInput placeholder="{{'unitDetailForm_successFactor4Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor4" [maxLength]="80">
                </mat-form-field>

            </mat-list-item>

            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <input matInput placeholder="{{'unitDetailForm_successFactor5Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor5" [maxLength]="80">
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="is--right">
                    <input matInput placeholder="{{'unitDetailForm_successFactor6Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor6" [maxLength]="80">
                </mat-form-field>

            </mat-list-item>

            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <input matInput placeholder="{{'unitDetailForm_successFactor7Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor7" [maxLength]="80">
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="is--right">
                    <input matInput placeholder="{{'unitDetailForm_successFactor8Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor8" [maxLength]="80">
                </mat-form-field>

            </mat-list-item>

            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <input matInput placeholder="{{'unitDetailForm_successFactor9Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor9" [maxLength]="80">
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="is--right">
                    <input matInput placeholder="{{'unitDetailForm_successFactor10Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor10" [maxLength]="80">
                </mat-form-field>

            </mat-list-item>

            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <input matInput placeholder="{{'unitDetailForm_successFactor11Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor11" [maxLength]="80">
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="is--right">
                    <input matInput placeholder="{{'unitDetailForm_successFactor12Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor12" [maxLength]="80">
                </mat-form-field>

            </mat-list-item>

            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <input matInput placeholder="{{'unitDetailForm_successFactor13Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor13" [maxLength]="80">
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="is--right">
                    <input matInput placeholder="{{'unitDetailForm_successFactor14Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor14" [maxLength]="80">
                </mat-form-field>

            </mat-list-item>

            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <input matInput placeholder="{{'unitDetailForm_successFactor15Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor15" [maxLength]="80">
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="is--right">
                    <input matInput placeholder="{{'unitDetailForm_successFactor16Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor16" [maxLength]="80">
                </mat-form-field>

            </mat-list-item>

            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <input matInput placeholder="{{'unitDetailForm_successFactor17Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor17" [maxLength]="80">
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="is--right">
                    <input matInput placeholder="{{'unitDetailForm_successFactor18Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor18" [maxLength]="80">
                </mat-form-field>

            </mat-list-item>

            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <input matInput placeholder="{{'unitDetailForm_successFactor19Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor19" [maxLength]="80">
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="is--right">
                    <input matInput placeholder="{{'unitDetailForm_successFactor20Input_Placeholder'|translate}}"
                           type="text" formControlName="successFactor20" [maxLength]="80">
                </mat-form-field>

            </mat-list-item>

            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <input matInput placeholder="{{'Sales'|translate}}"
                           type="number" formControlName="sales" [maxLength]="10" min="0">
                </mat-form-field>


            </mat-list-item>

            <mat-list-item>

                <button type="submit" [disabled]="!form.valid" mat-stroked-button class="submit-button" color="primary">
                    {{'formButtons_save'|translate}}
                    <mat-spinner diameter="24" *ngIf="isLoading"></mat-spinner>
                </button>

            </mat-list-item>

        </mat-list>

    </form>

</section>
