<section>

    <app-lws-alert [color]="'warning'" class="measure-readonly-notice" *ngIf="readOnlyByLockingUser == true"
                   [title]="'measureDetailForm_LockedDatasetNotice_Title'|translate"
                   [message]="'measureDetailForm_LockedDatasetNotice_Message'|translate"></app-lws-alert>

    <form *ngIf="form != null" class="form" [formGroup]="form">

        <div>

            <mat-grid-list cols="2" rowHeight="70px">

                <mat-grid-tile>
                    <mat-form-field [floatLabel]="true">
                        <input matInput placeholder="{{'measureDetailForm_NameInput_Placeholder'|translate}}"
                               [readonly]="readOnlyByLockingUser == true" type="text" formControlName="name"
                               [maxLength]="80" (keydown.enter)="$event.preventDefault()">
                    </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile>
                    <mat-form-field [floatLabel]="true"
                                    (keydown.enter)="$event.preventDefault()">
                        <mat-label>{{ 'State' | translate}}</mat-label>
                        <mat-select required formControlName="state"
                                    [disabled]="readOnlyByLockingUser == true">
                            <mat-option [value]="0">{{'STATE_PREPARATION'|translate}}</mat-option>
                            <mat-option [value]="1">{{'STATE_ACTIVE'|translate}}</mat-option>
                            <mat-option [value]="2">{{'STATE_RISK_TIME'|translate}}</mat-option>
                            <mat-option [value]="3">{{'STATE_RISK_RESSOURCE'|translate}}</mat-option>
                            <mat-option [value]="4">{{'STATE_RISK_BUDGET'|translate}}</mat-option>
                            <mat-option [value]="5">{{'STATE_RISK_SCOPE'|translate}}</mat-option>
                            <mat-option [value]="6">{{'STATE_RSIK_QUALITY'|translate}}</mat-option>
                            <mat-option [value]="7">{{'STATE_PASSIV'|translate}}</mat-option>
                            <mat-option [value]="8">{{'STATE_COMPLETED'|translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
        </div>

        <div>
            <mat-grid-list cols="2" rowHeight="70px">

                <mat-grid-tile>
                    <mat-form-field>
                        <input matInput [readonly]="readOnlyByLockingUser == true"
                               (keydown.enter)="$event.preventDefault()"
                               [matDatepicker]="pickerStart"
                               placeholder="{{'StartDate'|translate}}"
                               formControlName="startdate"
                               (dateInput)="dateChanged()"
                               (dateChange)="dateChanged()"
                               (change)="dateChanged()">
                        <mat-datepicker-toggle matSuffix [for]="pickerStart"
                                               [disabled]="readOnlyByLockingUser == true"></mat-datepicker-toggle>
                        <mat-datepicker #pickerStart [disabled]="readOnlyByLockingUser == true"></mat-datepicker>
                    </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile>
                    <mat-form-field>
                        <input matInput [readonly]="readOnlyByLockingUser == true"
                               (keydown.enter)="$event.preventDefault()"
                               [matDatepicker]="pickerEnd"
                               placeholder="{{'EndDate'|translate}}"
                               formControlName="enddate" (change)="dateChanged()"
                               (dateInput)="dateChanged()"
                               (dateChange)="dateChanged()"
                               (change)="dateChanged()">
                        <mat-datepicker-toggle matSuffix [for]="pickerEnd"
                                               [disabled]="readOnlyByLockingUser == true"></mat-datepicker-toggle>
                        <mat-datepicker #pickerEnd [disabled]="readOnlyByLockingUser == true"></mat-datepicker>
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
        </div>

        <div>
            <mat-form-field [floatLabel]="true">
                <textarea matInput [readonly]="readOnlyByLockingUser == true" placeholder="{{'Target'|translate}}"
                          formControlName="targetDescription"></textarea>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field [floatLabel]="true">
                <textarea matInput required [readonly]="readOnlyByLockingUser == true" placeholder="{{'Is'|translate}}"
                          formControlName="isDescription"></textarea>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field [floatLabel]="true">
                <textarea matInput [readonly]="readOnlyByLockingUser == true" placeholder="{{'Use'|translate}}"
                          formControlName="useDescription"></textarea>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field [floatLabel]="true">
                <mat-label>{{ 'Interoperability' | translate}}</mat-label>
                <mat-select formControlName="interoperabilityFolderId"
                            [disabled]="readOnlyByLockingUser == true">
                    <mat-option value="">-</mat-option>
                    <mat-option *ngFor="let subfolder of this.foldersLevel1" [value]="subfolder.id">
                        {{subfolder.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>



        <mat-toolbar color="basic" *ngIf="measure.segmentName.toLowerCase() != 'risiko' && measure.segmentName.toLowerCase() != 'compliance' ">
            <mat-toolbar-row>
                <span>{{'BWA' | translate}}</span>
                <span class="toolbar-spacer"></span>
                <span class="info" *ngIf="this.measure && this.measure.businessAnalysis">{{'ResultUnique' | translate}}: {{ this.measure.businessAnalysis.earningsBeforeTaxes | currency:'EUR'}}</span>
                <span class="toolbar-spacer"></span>
                <span class="info" *ngIf="this.measure && this.measure.businessAnalysis">{{'ResultContinuously' | translate}}: {{ this.measure.businessAnalysis.earningsBeforeTaxesPa | currency:'EUR'}}</span>
                <span class="toolbar-spacer"></span>
                <button mat-stroked-button (click)="changeBwa(); $event.preventDefault();"
                        [disabled]="readOnlyByLockingUser == true">
                    {{'change' | translate}}
                </button>
            </mat-toolbar-row>
        </mat-toolbar>

        <div *ngIf="measure.segmentName.toLowerCase() == 'risiko' || measure.segmentName.toLowerCase() == 'compliance' ">
            <mat-grid-list cols="2" rowHeight="70px">

                <mat-grid-tile>
                    <mat-form-field [floatLabel]="true">
                        <input matInput placeholder="{{'NetDamageMeasure'|translate}}"
                               [readonly]="readOnlyByLockingUser == true" type="number" formControlName="netDamageMeasure"
                               [maxLength]="3" (keydown.enter)="$event.preventDefault()">
                        <span matSuffix>&nbsp;%</span>
                    </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile>
                    <mat-form-field [floatLabel]="true">
                        <input matInput placeholder="{{'ProbabilityOfOccurrence'|translate}}"
                               [readonly]="readOnlyByLockingUser == true" type="number" formControlName="probabilityOfOccurrence"
                               [maxLength]="3" (keydown.enter)="$event.preventDefault()">
                        <span matSuffix>&nbsp;%</span>
                    </mat-form-field>
                </mat-grid-tile>

            </mat-grid-list>

        </div>

        <app-lws-table-inline-edit [tableData]="measure != null ? measure.measurePlanings : null"
                                   [addActionAvailable]="readOnlyByLockingUser == false"
                                   [deleteActionAvailable]="readOnlyByLockingUser == false"
                                   [layout]="'full'" [displayedColumns]="measurePlaningDisplayedColumns"
                                   [title]="planingGridTitle">
        </app-lws-table-inline-edit>

        <app-lws-table-inline-edit #responsiblePersonsTable [title]="responsiblePersonsTitle"
                                   [addActionAvailable]="readOnlyByLockingUser == false"
                                   [deleteActionAvailable]="readOnlyByLockingUser == false"
                                   [tableData]="measure != null ? measure.measureResponsiblePersons : null"
                                   [layout]="'full'" [displayedColumns]="measureResponsiblePersonsDisplayedColumns">
        </app-lws-table-inline-edit>


        <mat-toolbar>
            <button mat-stroked-button
                    class="abort-button"
                    type="button"
                    (click)="abortForm();"
                    [disabled]="readOnlyByLockingUser == true">
                {{'formButtons_abort'|translate}}
            </button>

            <button *ngIf="accountService.User.privilegeMeasureRightValue > 1"
                    (click)="onSubmit()"
                    type="submit"
                    [disabled]="!isValid() || readOnlyByLockingUser == true"
                    mat-stroked-button
                    class="submit-button"
                    color="primary">
                {{'formButtons_save'|translate}}
                <mat-spinner diameter="24" *ngIf="isLoading"></mat-spinner>
            </button>
        </mat-toolbar>
    </form>

</section>
