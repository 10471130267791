
export class ReportingGadgetConfig {

    static readonly HIERARCHY_FILTER: string = 'HIERARCHY_FILTER';
    static readonly RELEVANCE: string = 'RELEVANCE';
    static readonly SUCCESS_FACTOR: string = 'SUCCESS_FACTOR';
    static readonly ANSWER_VALUE: string = 'ANSWER_VALUE';
    static readonly FILTER_TYPE: string = 'FILTER_TYPE';
    static readonly MEASURE_COLUMNS: string = 'MEASURE_COLUMNS';
    static readonly EXTENTIAL_THREAT: string = 'EXTENTIAL_THREAT';
    static readonly NET_DAMAGE: string = 'NET_DAMAGE';
    static readonly DAMAGES: string = 'DAMAGES';

    static readonly MULTISELECT_SEPARATOR: string = ';#;';


    public key: string;

    public value: string;

}
