import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {LwsDetailHeaderComponent} from '../../components/lws-detail-header/lws-detail-header.component';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Location} from '@angular/common';
import {EventService} from '../../services/client/event/event.service';
import {TranslateService} from '@ngx-translate/core';
import {Unit} from '../../models/unit/unit';
import {UnitService} from '../../services/server/unit/unit.service';
import {Company} from '../../models/company/company';
import {CompanyService} from '../../services/server/company/company.service';

@Component({
    selector: 'app-units-detail',
    templateUrl: './units-detail.component.html',
    styleUrls: ['./units-detail.component.scss']
})
export class UnitsDetailComponent implements OnInit, AfterViewInit {
    @ViewChild(LwsDetailHeaderComponent) private header: LwsDetailHeaderComponent;
    public unit: Unit = null;
    public isNew: boolean = true;
    public title: string = '';
    public isLoading: boolean = false;
    public company: Company = null;
    public breadcrumbs: string[] = [];
    
    constructor(public route: ActivatedRoute,
                public unitService: UnitService,
                public router: Router,
                public location: Location,
                public eventService: EventService,
                public companyService: CompanyService,
                public translator: TranslateService) {
        this.translator.setDefaultLang('de');
        this.subscribeEvents();
    }
    
    /**
     * subscribes to needed events
     */
    private subscribeEvents() {
        this.eventService.Subscribe('unit:create:success', (id: number) => {
            this.isNew = false;
            this.loadUnitById(id);
        });
    }
    
    ngOnInit() {
        this.route.queryParams.subscribe((queryParams) => {
            const companyId: number = +queryParams.companyId;
            
            this.companyService.getDetail(companyId).then(
                (company) => {
                    this.company = company;
                    
                    this.translator.get('sidenav_PagesCompanies_Title').subscribe((titleTranslation: string) => {
                        this.breadcrumbs.push(titleTranslation);
                        this.breadcrumbs.push(this.company.groupName);
                        
                        this.route.paramMap.subscribe((params: ParamMap) => {
                            const id: number = +params.get('id');
                            
                            if (id > 0) {
                                this.isNew = false;
                                this.loadUnitById(id);
                            } else {
                                this.translator.get('unitDetail_NewUnitForm_TitleContent').subscribe((translation: string) => {
                                    this.title = translation;
                                    this.breadcrumbs.push(translation);
                                });
                            }
                        });
                    });
                });
        });
    }
    
    ngAfterViewInit() {
        this.addHeaderSubscribes();
    }
    
    /**
     * add the subscribes to the action-buttons
     */
    private addHeaderSubscribes() {
        this.header.backClick.subscribe(() => {
            this.location.back();
        });
    }
    
    /**
     *
     * @param id the id of the unit
     */
    private loadUnitById(id: number) {
        this.isLoading = true;
        
        this.unitService.getDetail(id).then(
            (unit) => {
                this.isLoading = false;
                
                if (unit == null) {
                    this.location.back();
                }
                
                this.unit = unit;
                this.title = this.unit.name;
                this.breadcrumbs.push(this.unit.name);
            },
            () => {
                this.isLoading = false;
            });
    }
}
