import {Md5} from 'ts-md5';
import {ModelInterface} from '../model-interface';
import {Company} from '../company/company';
import {JSONConverter} from '../JSONConverter';
import {Privilege} from '../privilege/privilege';

export class User implements ModelInterface {

    private _id: number = 0;
    private _username: string = '';
    private _firstname: string = '';
    private _lastname: string = '';
    private _name: string = '';
    private _company: Company = null;
    private _password: string = '';
    private _isAdmin: boolean = false;
    private _authorities: { authority: string }[] = [];
    private _privileges: { name: string }[] = [];
    private _privilegeCompanyRightValue: number = 0;
    private _privilegeMarketRightValue: number = 0;
    private _privilegeRiskRightValue: number = 0;
    private _privilegeMeasureRightValue: number = 0;
    private _privilegeComplianceRightValue: number = 0;
    private _privilegeDashboardRightValue: number = 0;

    // a list of properties-keys to ignore at jsonconvert
    _ignoreProperties: string[] = [
        'authorities',
        'privileges'
    ];


    constructor() {

    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get username(): string {
        return this._username;
    }

    set username(value: string) {
        this._username = value;
    }

    get firstname(): string {
        return this._firstname;
    }

    set firstname(value: string) {
        this._firstname = value;

        this.name = this.firstname + ' ' + this.lastname;
    }

    get lastname(): string {
        return this._lastname;
    }

    set lastname(value: string) {
        this._lastname = value;

        this.name = this.firstname + ' ' + this.lastname;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get company(): Company {
        return this._company;
    }

    set company(value: Company) {
        this._company = value;
    }

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    get authorities(): { authority: string }[] {
        return this._authorities;
    }

    set authorities(value: { authority: string }[]) {
        this._authorities = value;

        this.loadPrivilegValueToProperties();
    }

    get privileges(): { name: string }[] {
        return this._privileges;
    }

    set privileges(value: { name: string }[]) {
        this._privileges = value;
    }

    get userHash(): string {
        const md5Hash: string = Md5.hashStr(this.username).toString();

        return md5Hash;
    }

    get privilegeCompanyRightValue(): number {
        return this._privilegeCompanyRightValue;
    }

    set privilegeCompanyRightValue(value: number) {
        this._privilegeCompanyRightValue = value;
    }

    get privilegeMarketRightValue(): number {
        return this._privilegeMarketRightValue;
    }

    set privilegeMarketRightValue(value: number) {
        this._privilegeMarketRightValue = value;
    }

    get privilegeRiskRightValue(): number {
        return this._privilegeRiskRightValue;
    }

    set privilegeRiskRightValue(value: number) {
        this._privilegeRiskRightValue = value;
    }

    get privilegeMeasureRightValue(): number {
        return this._privilegeMeasureRightValue;
    }

    set privilegeMeasureRightValue(value: number) {
        this._privilegeMeasureRightValue = value;
    }

    get privilegeComplianceRightValue(): number {
        return this._privilegeComplianceRightValue;
    }

    set privilegeComplianceRightValue(value: number) {
        this._privilegeComplianceRightValue = value;
    }

    /**
     * returns true if the user is an admin (admin flag and has the ADMIN-authority)
     */
    public isAdmin(): boolean {
        if (this.hasAuthority('ADMIN')) {
            return true;
        } else {
            return false;
        }
    }

    get privilegeDashboardRightValue(): number {
        return this._privilegeDashboardRightValue;
    }

    set privilegeDashboardRightValue(value: number) {
        this._privilegeDashboardRightValue = value;
    }


    /**
     * returns true if the user has the requested autority (ADMIN, USER, etc)
     *
     * @param authority the reuqested authority
     */
    public hasAuthority(authority: string): boolean {
        const authorities = this.authorities.filter(x => x.authority.toLowerCase() === authority.toLowerCase());

        if (authorities.length >= 1) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * returns true if the user has the one of the given authorities
     *
     * @param authorities the reuqested authority
     */
    public hasOneAuthorityOfList(authorities: string[] = []): boolean {
        if (authorities === null || authorities === undefined || authorities.length <= 0) {
            return false;
        }

        for (const authority of authorities) {
            if (this.hasAuthority(authority)) {
                return true;
            }
        }

        return false;
    }

    /**
     * adds the given privilege
     *
     * @param name the name of the privilege to add
     */
    public addPivilege(name: string) {
        if (name.trim().length <= 0) {
            return;
        }

        const privileges = this.privileges.filter(x => x.name.toLocaleUpperCase() == name.toLocaleUpperCase());

        if (privileges.length <= 0) {
            this.privileges.push({name: name.toLocaleUpperCase()});
        }
    }

    /**
     * adds the given privileges
     *
     * @param names the names of the privileges to add
     */
    public addPivileges(names: string[]) {
        for (let name of names) {
            this.addPivilege(name);
        }
    }

    /**
     * load the given authorities to the user model properties (needed for forms)
     */
    public loadPrivilegValueToProperties() {
        if (this.hasRight(Privilege.COMPANY_READ)) {
            this.privilegeCompanyRightValue = 1;
        }
        if (this.hasRight(Privilege.COMPANY_ANSWER)) {
            this.privilegeCompanyRightValue = 2;
        }

        if (this.hasRight(Privilege.RISK_READ)) {
            this.privilegeRiskRightValue = 1;
        }
        if (this.hasRight(Privilege.RISK_ANSWER)) {
            this.privilegeRiskRightValue = 2;
        }

        if (this.hasRight(Privilege.RISK_WRITE)) {
            this.privilegeRiskRightValue = 3;
        }

        if (this.hasRight(Privilege.COMPLIANCE_READ)) {
            this.privilegeComplianceRightValue = 1;
        }
        if (this.hasRight(Privilege.COMPLIANCE_ANSWER)) {
            this.privilegeComplianceRightValue = 2;
        }

        if (this.hasRight(Privilege.COMPLIANCE_WRITE)) {
            this.privilegeComplianceRightValue = 3;
        }


        if (this.hasRight(Privilege.MEASURE_READ)) {
            this.privilegeMeasureRightValue = 1;
        }
        if (this.hasRight(Privilege.MEASURE_WRITE)) {
            this.privilegeMeasureRightValue = 2;
        }

        if (this.hasRight(Privilege.MARKET_READ)) {
            this.privilegeMarketRightValue = 1;
        }
        if (this.hasRight(Privilege.MARKET_ANSWER)) {
            this.privilegeMarketRightValue = 2;
        }

        if (this.hasRight(Privilege.REPORTING_DASHBOARD_READ)) {
            this.privilegeDashboardRightValue = 1;
        }

        if (this.hasRight(Privilege.REPORTING_DASHBOARD_FILTER)) {
            this.privilegeDashboardRightValue = 2;
        }

        if (this.hasRight(Privilege.REPORTING_DASHBOARD_CONFIGURE)) {
            this.privilegeDashboardRightValue = 3;
        }
    }



    /**
     * returns true, if the user has the requested right
     *
     * @param neededRight the right that the user should have
     */
    public hasRight(neededRight: string): boolean {
        if (this.authorities.filter(x => x.authority.toLocaleUpperCase() == neededRight.toLocaleUpperCase()).length > 0) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * returns the object as a valid json (without the underscore a the property name begin)
     */
    toJSON() {
        return new JSONConverter().toJSON(this);
    }

    /**
     * creates a object from the given data
     *
     * @param data the data to create a model
     */
    static hydrate(data: any): User {
        const model: User = new User();

        Object.assign(model, data);

        return model;
    }

}
