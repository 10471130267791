import {Segment} from '../models/segment/segment';
import {Privilege} from '../models/privilege/privilege';
import {User} from '../models/user/user';

export class RightsCheck {


    /**
     * Access right. No right, read, write
     */
    public getAccessRightToSegment(user: User, segment: Segment): number {

        let privilegeRightValue: number = 0;

        if (segment.name.toLowerCase() === 'unternehmen') {
            if (user.hasRight(Privilege.COMPANY_READ)) {
                privilegeRightValue = 1;
            }
            if (user.hasRight(Privilege.COMPANY_ANSWER)) {
                privilegeRightValue = 2;
            }
        }

        if (segment.name.toLowerCase() === 'risiko') {
            if (user.hasRight(Privilege.RISK_READ)) {
                privilegeRightValue = 1;
            }
            if (user.hasRight(Privilege.RISK_ANSWER)) {
                privilegeRightValue = 2;
            }

            if (user.hasRight(Privilege.RISK_WRITE)) {
                privilegeRightValue = 3;
            }
        }

        if (segment.name.toLowerCase() === 'markt') {
            if (user.hasRight(Privilege.MARKET_READ)) {
                privilegeRightValue = 1;
            }
            if (user.hasRight(Privilege.MARKET_ANSWER)) {
                privilegeRightValue = 2;
            }
        }

        if (segment.name.toLowerCase() === 'compliance') {
            if (user.hasRight(Privilege.COMPLIANCE_READ)) {
                privilegeRightValue = 1;
            }
            if (user.hasRight(Privilege.COMPLIANCE_ANSWER)) {
                privilegeRightValue = 2;
            }

            if (user.hasRight(Privilege.COMPLIANCE_WRITE)) {
                privilegeRightValue = 3;
            }
        }

        return privilegeRightValue;
    }

}
