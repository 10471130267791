<h1 mat-dialog-title>{{ 'AddGadget' | translate}}</h1>

<table mat-table [dataSource]="gadgets" class="">

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>{{ 'Gadget' | translate}}</th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button (click)="addGadget(element)">
        <mat-icon>add</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


<mat-toolbar>
  <button mat-button (click)="onNoClick()">{{ 'formButtons_abort' | translate}}</button>
</mat-toolbar>



