import {Question} from "./question";
import {Folder} from "../folder/folder";

export class QuestionWithPath {

    private _id : number;

    private _question: string;

    private _folder1: string;

    private _folder2: string;

    private _segment: string;

    private _unit: string;

    private _folder2Id: number;

    private _path: string;

    get question(): string {
        return this._question;
    }

    set question(value: string) {
        this._question = value;
    }

    get folder1(): string {
        return this._folder1;
    }

    set folder1(value: string) {
        this._folder1 = value;
    }

    get folder2(): string {
        return this._folder2;
    }

    set folder2(value: string) {
        this._folder2 = value;
    }

    get segment(): string {
        return this._segment;
    }

    set segment(value: string) {
        this._segment = value;
    }

    get unit(): string {
        return this._unit;
    }

    set unit(value: string) {
        this._unit = value;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get folder2Id(): number {
        return this._folder2Id;
    }

    set folder2Id(value: number) {
        this._folder2Id = value;
    }


    get path(): string {
        return this.unit + ' -> ' + this.segment + ' -> ' + this.folder1 + ' -> ' + this.folder2;
    }


    static hydrate(data: any): QuestionWithPath {
        const model: QuestionWithPath = new QuestionWithPath();

        model.question = data.question.question;
        model.folder1 = data.folder1;
        model.folder2 = data.folder2.name;
        model.folder2Id = data.folder2.id;
        model.unit = data.unit;
        model.segment = data.segment;
        model.id = data.question.id;

        return model;
    }
}
