import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-lws-dialog',
    templateUrl: './lws-dialog.component.html',
    styleUrls: ['./lws-dialog.component.scss']
})
export class LwsDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<LwsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    // definition
                    title: string,
                    message: string,

                    okButton: boolean,
                    cancelButton: boolean,

                    button1Button: boolean,
                    button1ButtonTranslationKey: string,

                    button2Button: boolean,
                    button2ButtonTranslationKey: string,

                    button3Button: boolean,
                    button3ButtonTranslationKey: string
                } = {
                    // default values
                    title: '',
                    message: '',

                    okButton: false,
                    cancelButton: false,

                    button1Button: false,
                    button1ButtonTranslationKey: '',

                    button2Button: false,
                    button2ButtonTranslationKey: '',

                    button3Button: false,
                    button3ButtonTranslationKey: ''
                }) {

    }

    ngOnInit() {

    }
}
