import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {LwsDetailHeaderComponent} from '../../components/lws-detail-header/lws-detail-header.component';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../services/server/user/user.service';
import {User} from '../../models/user/user';
import {Company} from '../../models/company/company';
import {CompanyService} from '../../services/server/company/company.service';

@Component({
    selector: 'app-users-detail',
    templateUrl: './users-detail.component.html',
    styleUrls: ['./users-detail.component.scss']
})
export class UsersDetailComponent implements OnInit, AfterViewInit {
    @ViewChild(LwsDetailHeaderComponent) private header: LwsDetailHeaderComponent;
    public user: User = null;
    public isNew: boolean = true;
    public title: string = '';
    public company: Company = null;
    public breadcrumbs: string[] = [];
    
    constructor(public location: Location,
                public route: ActivatedRoute,
                public translator: TranslateService,
                public companyService: CompanyService,
                public userService: UserService) {
        
    }
    
    ngOnInit() {
        this.route.queryParams.subscribe((queryParams) => {
            const companyId: number = +queryParams.companyId;
            
            this.companyService.getDetail(companyId).then(
                (company) => {
                    this.company = company;
                    
                    this.translator.get('sidenav_PagesCompanies_Title').subscribe((titleTranslation: string) => {
                        
                        this.route.paramMap.subscribe((params: ParamMap) => {
                            const id: number = +params.get('id');
                            
                            if (id > 0) {
                                this.isNew = false;
                                this.loadUserById(id);
                            } else {
                                this.translator.get('userDetail_NewUserForm_TitleContent').subscribe((translation: string) => {
                                    this.title = translation;
    
                                    this.loadBreadcrumbsAndAdd(translation);
                                });
                            }
                        });
                    });
                });
        });
    }
    
    ngAfterViewInit() {
        this.addHeaderSubscribes();
    }
    
    /**
     * load the latest breadcrumbs and add the given as a new one
     *
     * @param breadcrumb the breadcrumb to add
     */
    private loadBreadcrumbsAndAdd(breadcrumb?: string) {
        const me = this;
        
        if (me.breadcrumbs === null || me.breadcrumbs === undefined) {
            me.breadcrumbs = [];
        }
        
        this.route.queryParams.subscribe((queryParams) => {
            if (typeof queryParams.breadcrumbs === 'string') {
                me.breadcrumbs.push(queryParams.breadcrumbs);
            } else {
                me.breadcrumbs = (queryParams.breadcrumbs as string[]);
            }
            
            if (breadcrumb !== null && breadcrumb !== undefined && breadcrumb.length > 0) {
                me.breadcrumbs.push(breadcrumb);
            }
        });
    }
    
    /**
     * add the subscribes to the action-buttons
     */
    private addHeaderSubscribes() {
        this.header.backClick.subscribe(() => {
            this.location.back();
        });
    }
    
    /**
     *
     * @param id the id of the company
     */
    private loadUserById(id: number) {
        this.userService.getDetail(id).then(user => {
            if (user == null) {
                this.location.back();
            }
            
            this.user = user;
            this.title = this.user.name;
    
            this.loadBreadcrumbsAndAdd(this.user.name);
        });
    }
}
