import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Folder} from '../../models/folder/folder';

@Component({
    selector: 'app-folders-list-dialog',
    templateUrl: './folders-list-dialog.component.html',
    styleUrls: ['./folders-list-dialog.component.scss']
})
export class FoldersListDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<FoldersListDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    // definition
                    folders: Folder[]
                } = {
                    // default values
                    folders: []
                }) {
    }

    ngOnInit() {

    }

    /**
     * closes the dialog and returns the selected folder to the requesting code
     *
     * @param folder the selected folder
     */
    folderSelected(folder: Folder) {
        this.dialogRef.close(folder);
    }
}
