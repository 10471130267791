import {Injectable} from '@angular/core';
import {RestService} from '../rest/rest.service';
import {ReportingGadgetConfig} from '../../../models/reporting-dashboard/reporting-gadget-config';
import {ReportingGadget} from '../../../models/reporting-dashboard/reporting-gadget';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandlingService} from '../../client/error-handling/error-handling.service';

@Injectable({
    providedIn: 'root'
})
export class GadgetService {

    private BASE_URL: string = '/reporting-dashboard';

    constructor(private restService: RestService,
                private errorHandlingService: ErrorHandlingService) {
    }

    /**
     * returns all segments
     */
    public getAllGadgets(companyId: number): Promise<ReportingGadget[]> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.get(this.BASE_URL + '/' + companyId + '/gadgets', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const gadgetConfigs: ReportingGadget[] = [];

                return response;
            },
            () => {
                return null;
            });
    }

    public update(companyId: number, gadgets: ReportingGadget[]) {
        return this.restService.post(this.BASE_URL + '/' + companyId + '/gadgets?companyId=' + companyId, gadgets).toPromise().then(
            (result) => {
                return result;
            },
            () => {
                return null;
            });
    }

    public getHierarchyFilters(companyId: number, gadgetId: number) {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.get(this.BASE_URL + '/' + companyId + '/gadgets/' + gadgetId + '/filters', parameter).toPromise().then(
            (result) => {
                return (result.body as []);
            },
            () => {
                return null;
            });
    }

    getResults(companyId: number, gadgetId: number, config: ReportingGadgetConfig[]) {
        return this.restService.post(this.BASE_URL + '/' + companyId + '/gadgets/' + gadgetId + '/results', config).toPromise().then(
            (result) => {
                return (result as []);
            },
            () => {
                return null;
            });
    }

    public export(companyId: number, gadgetId: number,  config: ReportingGadgetConfig[]) {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.getExportWithBody(this.BASE_URL + '/' + companyId + '/gadgets/' + gadgetId + '/export', parameter, true, false, config).toPromise().then(
            (result) => {
                return (result);
            },
            (httpError: HttpErrorResponse) => {
                this.errorHandlingService.handleError('ErrorOnExport', httpError);

                return null;
            });

    }
}
