<section>

    <header>

        <mat-form-field *ngIf="company && units.length > 0">
            <mat-label>{{'assessmentOverview_Unit_Title'|translate}}</mat-label>
            <mat-select (selectionChange)="unitSelectionChanged($event.value);">
                <mat-option *ngFor="let unit of units" [value]="unit.id">
                    {{unit.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="company && years.length > 0">
            <mat-label>{{'assessmentOverview_Year_Title'|translate}}</mat-label>
            <mat-select (selectionChange)="yearSelectionChanged($event.value);">
                <mat-option *ngFor="let year of years" [value]="year.id">
                    {{year.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="company && versions.length > 0">
            <mat-label>{{'assessmentOverview_Version_Title'|translate}}</mat-label>
            <mat-select (selectionChange)="versionSelectionChanged($event.value);">
                <mat-option *ngFor="let version of versions" [value]="version.id">
                    {{version.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </header>

    <main>

        <app-lws-alert [title]="'assessmentOverview_NoContentAlert_Title'|translate"
                       [message]="'assessmentOverview_NoContentAlert_Message'|translate"
                       [color]="'warning'"
                       *ngIf="units && units.length < 1"></app-lws-alert>

        <mat-tab-group #segmentTabGroup
                       *ngIf="company != null && selectedUnit != null && selectedYear != null && selectedVersion != null && segments.length > 0"
                       [selectedIndex]="activeSegmentIndex"
                       (selectedTabChange)="segmentTabGroupTabChanged()">

            <mat-tab *ngFor="let segment of segments" label="{{segment.name}}">

                <section class="assessment--detail-content">

                    <aside class="border-color-gray">

                        <mat-accordion [displayMode]="flat" *ngIf="folders.length > 0">

                            <mat-expansion-panel *ngFor="let folder of folders">

                                <mat-expansion-panel-header class="assessment--folder-level-1">
                                    <mat-panel-title>{{folder.name}}</mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-button-toggle-group class="is--list" role="list"
                                                         *ngIf="folder.subFolders && folder.subFolders.length > 0">
                                    <mat-button-toggle role="listitem" (click)="folderSelectButtonClicked(subfolder)"
                                                       class="assessment--folder-level-2"
                                                       [matTooltip]="subfolder.name"
                                                       *ngFor="let subfolder of folder.subFolders">
                                        <mat-icon>folder</mat-icon>
                                        {{subfolder.name}}
                                    </mat-button-toggle>
                                </mat-button-toggle-group>

                            </mat-expansion-panel>

                        </mat-accordion>

                    </aside>

                    <main *ngIf="folder && folder.id > 0 && activeSegment && activeSegment.name == segment.name && (selectedQuestion == null && selectedMeasure == null)">

                        <mat-tab-group (selectedTabChange)="questionMeasureTabGroupTabChanged($event)"
                                       [selectedIndex]="activeMeasureQuestionIndex">

                            <mat-tab label="{{'assessmentList_ContentTableTabQuestions_Title'|translate}}">
                                <app-questions-list
                                        *ngIf="folder != null && activeSegment && activeSegment.name == segment.name"
                                        [folder]="folder"
                                        [segment]="segment"
                                        [selectedVersion]="selectedVersion"
                                        (questionSelected)="questionSelected($event);"></app-questions-list>

                                <app-lws-alert [title]=""
                                               [message]="'PleaseSelectAFolder'|translate"
                                               *ngIf="folder == null || !activeSegment || activeSegment.name != segment.name"></app-lws-alert>
                            </mat-tab>

                            <mat-tab label="{{'assessmentList_ContentTableTabMeasures_Title'|translate}}"
                                     *ngIf="accountService.User.privilegeMeasureRightValue > 0">
                                <app-measures-list
                                        *ngIf="folder != null && activeSegment && activeSegment.name == segment.name"
                                        [folder]="folder"
                                        [segment]="segment"
                                        [selectedVersion]="selectedVersion"
                                        (entitySelected)="measureSelected($event);"></app-measures-list>
                            </mat-tab>

                        </mat-tab-group>

                    </main>

                    <main *ngIf="selectedQuestion != null || selectedMeasure != null">

                        <section *ngIf="selectedQuestion != null">
                            <app-question-detail-form *ngIf="selectedQuestion.id == 0 && activeSegment && activeSegment.name == segment.name"
                                                      [folder]="folder"
                                                      [segment]="segment"
                                                      [unit]="selectedUnit"
                                                      [readOnlyByLockingUser]="isReadOnlyView"
                                                      (questionSaved)="questionSaved();"></app-question-detail-form>
                            <app-question-detail-form *ngIf="selectedQuestion.id > 0 && activeSegment && activeSegment.name == segment.name"
                                                      [question]="selectedQuestion"
                                                      [folder]="folder"
                                                      [segment]="segment"
                                                      [unit]="selectedUnit"
                                                      [readOnlyByLockingUser]="isReadOnlyView"
                                                      (questionSaved)="questionSaved();"></app-question-detail-form>
                        </section>

                        <section *ngIf="selectedMeasure != null">
                            <app-measure-detail-form *ngIf="selectedMeasure.id == 0"
                                                     [folder]="folder"
                                                     [company]="company"
                                                     [readOnlyByLockingUser]="isReadOnlyView"
                                                     (detailFormSaved)="measureSaved($event);"
                                                     [defaultSegment]="segment"></app-measure-detail-form>
                            <app-measure-detail-form *ngIf="selectedMeasure.id > 0"
                                                     [measure]="selectedMeasure"
                                                     [folder]="folder"
                                                     [company]="company"
                                                     [readOnlyByLockingUser]="isReadOnlyView"
                                                     (detailFormSaved)="measureSaved($event);"
                                                     [defaultSegment]="segment"></app-measure-detail-form>
                        </section>

                    </main>

                </section>

            </mat-tab>

        </mat-tab-group>

    </main>

</section>
