<h1 mat-dialog-title>{{'BWA' | translate}}</h1>
<div mat-dialog-content>

    <mat-grid-list cols="3" rowHeight="30px" *ngIf="businessAnalysis">

        <!-- Row TITLE-->
        <mat-grid-tile class="highlight">{{'Designation' | translate}}</mat-grid-tile>
        <mat-grid-tile class="highlight">{{'unique' | translate}}</mat-grid-tile>
        <mat-grid-tile class="highlight">{{'continouse' | translate}}</mat-grid-tile>

        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'Revenues' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input
                    matInput
                    type="text"
                    pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                    (blur)="bwaChanged()"
                    [ngModel]="businessAnalysis.revenues | currency:'€':'symbol':'1.2'"
                    [ngModelOptions]="{updateOn:'blur'}"
                    (ngModelChange)="businessAnalysis.revenues = currencyInputChanged($event)"/>
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text"
                       (blur)="bwaChanged()"
                       pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModel]="businessAnalysis.revenuesPa | currency:'€':'symbol':'1.2'"
                       [ngModelOptions]="{updateOn:'blur'}"
                       (ngModelChange)="businessAnalysis.revenuesPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'FeUe' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.feUe | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.feUe = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.feUePa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.feUePa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'OwnWork' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.ownWork | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.ownWork = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.ownWorkPa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.ownWorkPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile class="highlight">{{'OverallPerformance' | translate}}</mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly highlight">{{businessAnalysis.overallPerformance | currency:'EUR'}}</span>
        </mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly highlight">{{businessAnalysis.overallPerformancePa | currency:'EUR'}}</span>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'GoodsHopping' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.goodsHopping | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.goodsHopping = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.goodsHoppingPa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.goodsHoppingPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile class="highlight">{{'GrossProfit' | translate}}</mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly highlight">{{businessAnalysis.grossProfit | currency:'EUR'}}</span>
        </mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly highlight">{{businessAnalysis.grossProfitPa | currency:'EUR'}}</span>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'OtherIncome' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.otherIncome | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.otherIncome = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModel]="businessAnalysis.otherIncomePa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.otherIncomePa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile class="highlight">{{'TotalOperatinggrossProfit' | translate}}</mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly highlight">{{businessAnalysis.totalOperatinggrossProfit | currency:'EUR'}}</span>
        </mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly highlight">{{businessAnalysis.totalOperatinggrossProfitPa | currency:'EUR'}}</span>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'PersonnelCosts' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.personnelCosts | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.personnelCosts = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.personnelCostsPa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.personnelCostsPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'RoomCosts' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.roomCosts | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.roomCosts = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.roomCostsPa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.roomCostsPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'CompanyTax' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.companyTax | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.companyTax = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.companyTaxPa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.companyTaxPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'InsuranceCosts' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.insuranceCosts | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.insuranceCosts = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.insuranceCostsPa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.insuranceCostsPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'SpecialCosts' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.specialCosts | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.specialCosts = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.specialCostsPa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.specialCostsPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'VehicleCosts' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.vehicleCosts | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.vehicleCosts = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.vehicleCostsPa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.vehicleCostsPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'MarketingCosts' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.marketingCosts | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.marketingCosts = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.marketingCostsPa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.marketingCostsPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'DepreciationCosts' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.depreciationCosts | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.depreciationCosts = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.depreciationCostsPa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.depreciationCostsPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'Depreciation' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.depreciation | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.depreciation = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.depreciationPa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.depreciationPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'RepairCosts' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.repairCosts | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.repairCosts = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.repairCostsPa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.repairCostsPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'OtherCosts' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.otherCosts | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.otherCosts = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.otherCostsPa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.otherCostsPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'TotalCosts' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly">{{businessAnalysis.totalCosts | currency:'EUR'}}</span>
        </mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly">{{businessAnalysis.totalCostsPa | currency:'EUR'}}</span>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile class="highlight">{{'OperatingProfit' | translate}}</mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly highlight">{{businessAnalysis.operatingProfit | currency:'EUR'}}</span>
        </mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly highlight">{{businessAnalysis.operatingProfitPa | currency:'EUR'}}</span>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'InterestExpense' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.interestExpense | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.interestExpense = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.interestExpensePa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.interestExpensePa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'OtherExpenditure' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.otherExpenditure | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.otherExpenditure = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.otherExpenditurePa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.otherExpenditurePa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile class="highlight">{{'NeutralExpenses' | translate}}</mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly highlight">{{businessAnalysis.neutralExpenses | currency:'EUR'}}</span>
        </mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly highlight">{{businessAnalysis.neutralExpensesPa | currency:'EUR'}}</span>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'InterestIncome' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.interestIncome | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.interestIncome = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.interestIncomePa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.interestIncomePa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'OtherYield' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.otherYield | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.otherYield = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.otherYieldPa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.otherYieldPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile><span class="row-label">{{'CalcCosts' | translate}}</span></mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.calcCosts | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.calcCosts = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input matInput type="text" (blur)="bwaChanged()" pattern="\-?[0-9]+([\.|,][0-9]{1,2}.?€)?"
                       [ngModelOptions]="{updateOn:'blur'}"
                       [ngModel]="businessAnalysis.calcCostsPa | currency:'€':'symbol':'1.2'"
                       (ngModelChange)="businessAnalysis.calcCostsPa = currencyInputChanged($event)">
            </mat-form-field>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile class="highlight">{{'NeutralYield' | translate}}</mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly highlight">{{businessAnalysis.neutralYield | currency:'EUR'}}</span>
        </mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly highlight">{{businessAnalysis.neutralYieldPa | currency:'EUR'}}</span>
        </mat-grid-tile>


        <!-- Row-->
        <mat-grid-tile class="highlight">{{'EarningsBeforeTaxes' | translate}}</mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly highlight">{{businessAnalysis.earningsBeforeTaxes | currency:'EUR' }}</span>
        </mat-grid-tile>
        <mat-grid-tile>
            <span class="readonly highlight">{{businessAnalysis.earningsBeforeTaxesPa | currency:'EUR'}}</span>
        </mat-grid-tile>


    </mat-grid-list>


</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{'lwsDialogButtons_cancel' | translate}}</button>
    <button mat-button (click)="onSaveClick()" cdkFocusInitial [disabled]="!isBWAValidForSave()">
        {{'lwsDialogButtons_ok' | translate}}
    </button>
</div>
