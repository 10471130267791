<section>

    <app-lws-alert [color]="'warning'" class="question-readonly-notice" *ngIf="readOnlyByLockingUser == true"
                   [title]="'questionDetailForm_LockedDatasetNotice_Title'|translate"
                   [message]="'questionDetailForm_LockedDatasetNotice_Message'|translate"></app-lws-alert>

    <form *ngIf="form != null" class="form" (ngSubmit)="onSubmit()" [formGroup]="form">

        <mat-list>

            <mat-list-item>

                <mat-form-field [floatLabel]="true" class="form-name">
                    <input type="text" matInput
                           [readonly]="isQuestionReadOnly()"
                           placeholder="{{'questionDetailForm_QuestionInput_Placeholder'|translate}}"
                           required formControlName="question"/>
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="form-factor">
                    <input type="number" matInput min="1" max="100"
                           [readonly]="(rightsCheck.getAccessRightToSegment(accountService.User, segment) <= 1 && isQuestionReadOnly())"
                           placeholder="{{'questionDetailForm_FactorInput_Placeholder'|translate}}"
                           required formControlName="factor"/>
                </mat-form-field>

            </mat-list-item>

            <mat-list-item>

                <mat-form-field [floatLabel]="true">
                    <textarea matInput matTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=10
                              [readonly]="isQuestionReadOnly()"
                              placeholder="{{'questionDetailForm_InfotextInput_Placeholder'|translate}}"
                              formControlName="infotext"></textarea>
                </mat-form-field>

            </mat-list-item>

        </mat-list>

        <button mat-stroked-button
                type="button"
                class="abort-button"
                (click)="abortForm();"
                [disabled]="isQuestionReadOnly()">
            {{'formButtons_abort'|translate}}
        </button>

        <button type="submit" [disabled]="!form.valid || isQuestionReadOnly()" mat-stroked-button
                class="submit-button" color="primary" *ngIf="canUserSave()">
            {{'formButtons_save'|translate}}
            <mat-spinner diameter="24" *ngIf="isLoading"></mat-spinner>
        </button>

    </form>

    <section *ngIf="question != null && question.id > 0">

        <!-- segments Unternehmen or Markt -->
        <fieldset style="text-align:center">
            <legend style="padding:20px;text-align:center">
                {{'questionDetailForm_QuestionDetailFieldset_Legend'|translate}}
            </legend>

            <mat-list>

                <mat-list-item class="has--two-columns">

                    <div class="is--column is--left">

                        <div class="icon--group">
                            <div class="icon--group-element c-4">
                                <mat-icon svgIcon="iconmonstr-smiley-4" aria-hidden="false" aria-label="Bad"></mat-icon>
                            </div>
                            <div class="icon--group-element c-4">
                                <mat-icon svgIcon="iconmonstr-smiley-6" aria-hidden="false"
                                          aria-label="Middle"></mat-icon>
                            </div>
                            <div class="icon--group-element c-4">
                                <mat-icon svgIcon="iconmonstr-smiley-2" aria-hidden="false"
                                          aria-label="Good"></mat-icon>
                            </div>
                        </div>

                        <mat-slider color="primary" min="0" max="5" step="1" thumbLabel="true"
                                    [disabled]="answer.isNotRelevant == true || isAnswereReadonly()"
                                    [(ngModel)]="answer.rating"></mat-slider>
                    </div>

                    <div class="is--column is--right">
                        <mat-checkbox color="primary" [(ngModel)]="answer.isNotRelevant"
                                      [disabled]="isAnswereReadonly()"
                                      (change)="answerIsNotRelevantChanged($event)">
                            {{'questionDetailForm_QuestionIsntRelevant_CheckBoxLabel'|translate}}
                        </mat-checkbox>
                    </div>

                </mat-list-item>

                <mat-list-item class="has--two-columns"
                               *ngIf="segment.name.toLowerCase() == 'unternehmen' || segment.name.toLowerCase() == 'markt'">

                    <div class="is--column is--left">
                        <mat-form-field class="question--detail-selection-field">
                            <mat-label>{{'Relevance'|translate}}
                            </mat-label>
                            <mat-select [(ngModel)]="questionRelevanceValue" color="primary"
                                        [disabled]="answer.isNotRelevant == true || isAnswereReadonly()">
                                <mat-option *ngFor="let entry of relevanceValues"
                                            [value]="entry">
                                    {{entry.title|translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="is--column is--right">
                        <mat-form-field class="question--detail-selection-field">
                            <mat-label>{{'questionDetailForm_Success_Label'|translate}}</mat-label>
                            <mat-select [(ngModel)]="answer.selectionSuccessFactor" required
                                        [disabled]="isAnswereReadonly()">
                                <mat-option *ngFor="let successFactor of successFactors" [value]="successFactor">
                                    {{successFactor|translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </mat-list-item>

                <!-- Risk-->
                <div *ngIf="segment.name.toLowerCase() == 'risiko' || segment.name.toLowerCase() == 'compliance' ">

                    <fieldset style="text-align:center" class="fieldset--selections">
                        <legend style="padding:20px;text-align:center">
                            {{'RiskStrategy'|translate}}
                        </legend>
                        <mat-list-item class="has--four-columns risk-strategy">

                            <mat-form-field [floatLabel]="true" class="form-factor is--column is--left">
                                <input type="number" matInput min="0" max="100" [(ngModel)]="answer.riskAvoid"
                                       [formControl]="riskAvoidFormControl" [readonly]="isAnswereReadonly()"
                                       placeholder="{{'questionDetailForm_RiskAvoidInput_Placeholder'|translate}}"/>
                                <span matSuffix>&nbsp;%</span>
                            </mat-form-field>

                            <mat-form-field [floatLabel]="true" class="form-factor is--column is--left">
                                <input type="number" matInput min="0" max="100" [(ngModel)]="answer.riskReduce"
                                       [formControl]="riskReduceFormControl" [readonly]="isAnswereReadonly()"
                                       placeholder="{{'questionDetailForm_RiskReduceInput_Placeholder'|translate}}"/>
                                <span matSuffix>&nbsp;%</span>
                            </mat-form-field>

                            <mat-form-field [floatLabel]="true" class="form-factor is--column is--left">
                                <input type="number" matInput min="0" max="100" [(ngModel)]="answer.riskShuffleOff"
                                       [formControl]="riskShuffleOffFormControl" [readonly]="isAnswereReadonly()"
                                       placeholder="{{'questionDetailForm_RiskPassInput_Placeholder'|translate}}"/>
                                <span matSuffix>&nbsp;%</span>
                            </mat-form-field>

                            <mat-form-field [floatLabel]="true" class="form-factor is--column is--right">
                                <input type="number" matInput min="0" max="100" [(ngModel)]="answer.riskTakeOver"
                                       [formControl]="riskTakeOverFormControl" [readonly]="isAnswereReadonly()"
                                       placeholder="{{'questionDetailForm_RiskAssumeInput_Placeholder'|translate}}"/>
                                <span matSuffix>&nbsp;%</span>
                            </mat-form-field>

                        </mat-list-item>
                    </fieldset>

                    <mat-list-item class="has--three-columns margin-top-small">

                        <div class="is--column is--left">
                            <mat-checkbox color="primary" [disabled]="isAnswereReadonly()"
                                          [(ngModel)]="answer.hazardousExistence">
                                {{'questionDetailForm_QuestionExistentialThreat_CheckBoxLabel'|translate}}
                            </mat-checkbox>
                        </div>

                        <div class="is--column is--right">
                            <mat-form-field [floatLabel]="true" *ngIf="answer && answer != undefined && answer != null">
                                <mat-label>{{ 'NetAmountOfDamage' | translate}}</mat-label>
                                <mat-select [(ngModel)]="answer.amountOfDamage"
                                            [disabled]="isAnswereReadonly()">
                                    <mat-option *ngFor="let amountOfDamage of amountOfDamages" [value]="amountOfDamage">
                                        {{amountOfDamage}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="is--column is--right" *ngIf="segment.name.toLowerCase() == 'risiko' || segment.name.toLowerCase() == 'compliance' ">
                            <mat-form-field [floatLabel]="true" *ngIf="answer && answer != undefined && answer != null">
                                <mat-label>{{ 'ProbabilityOfOccurrence' | translate}}</mat-label>
                                <mat-select [(ngModel)]="answer.probabilityOfOccurrence"
                                            [disabled]="isAnswereReadonly()">
                                    <mat-option *ngFor="let probabilityOfOccurrenceValue of probabilityOfOccurrenceValues" [value]="probabilityOfOccurrenceValue">
                                        {{probabilityOfOccurrenceValue}} %
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </mat-list-item>

                    <fieldset style="text-align:center" class="fieldset--selections">
                        <legend style="padding:20px;text-align:center">
                            {{'Damages'|translate}}
                        </legend>
                        <mat-list-item class="has--three-columns">
                            <mat-checkbox color="primary" class="is--column is--left" [(ngModel)]="answer.damageCosts"
                                          [disabled]="isAnswereReadonly()">{{'Costs'|translate}}</mat-checkbox>
                            <mat-checkbox color="primary" class="is--column is--left"
                                          [disabled]="isAnswereReadonly()"
                                          [(ngModel)]="answer.damageCompetitiveness">{{'Competitiveness'|translate}}</mat-checkbox>
                            <mat-checkbox color="primary" class="is--column is--left"
                                          [disabled]="isAnswereReadonly()"
                                          [(ngModel)]="answer.damageQuality">{{'Quality'|translate}}</mat-checkbox>
                        </mat-list-item>

                        <mat-list-item class="has--three-columns">
                            <mat-checkbox color="primary" class="is--column is--left" [(ngModel)]="answer.damageSales"
                                          [disabled]="isAnswereReadonly()">{{'SalesDamage'|translate}}</mat-checkbox>
                            <mat-checkbox color="primary" class="is--column is--left"
                                          [disabled]="isAnswereReadonly()"
                                          [(ngModel)]="answer.damageDissatisfaction">{{'DissatisfactionCustomers'|translate}}</mat-checkbox>
                            <mat-checkbox color="primary" class="is--column is--left"
                                          [disabled]="isAnswereReadonly()"
                                          [(ngModel)]="answer.damageCriminalProceedings">{{'CriminalProceedings'|translate}}</mat-checkbox>
                        </mat-list-item>

                        <mat-list-item class="has--three-columns">
                            <mat-checkbox color="primary" class="is--column is--left"
                                          [disabled]="isAnswereReadonly()"
                                          [(ngModel)]="answer.damagePecuniaryLoss">{{'PecuniaryLoss'|translate}}</mat-checkbox>
                            <mat-checkbox color="primary" class="is--column is--left"
                                          [disabled]="isAnswereReadonly()"
                                          [(ngModel)]="answer.damageConsistedHazard">{{'ConsistedHazard'|translate}}</mat-checkbox>
                            <mat-checkbox color="primary" class="is--column is--left"
                                          [disabled]="isAnswereReadonly()"
                                          [(ngModel)]="answer.damageEnvironmental">{{'EnvironmentalDamage'|translate}}</mat-checkbox>
                        </mat-list-item>

                        <mat-list-item class="has--three-columns">
                            <mat-checkbox color="primary" class="is--column is--left"
                                          [disabled]="isAnswereReadonly()"
                                          [(ngModel)]="answer.damageMotivationLoss">{{'MotivationLoss'|translate}}</mat-checkbox>
                            <mat-checkbox color="primary" class="is--column is--left"
                                          [disabled]="isAnswereReadonly()"
                                          [(ngModel)]="answer.damageThirdHarm">{{'ThirdHarm'|translate}}</mat-checkbox>
                            <mat-checkbox color="primary" class="is--column is--left"
                                          [disabled]="isAnswereReadonly()"
                                          [(ngModel)]="answer.damagePeopleHurt">{{'PeopleHurt'|translate}}</mat-checkbox>
                        </mat-list-item>

                        <mat-list-item class="has--three-columns">
                            <mat-checkbox color="primary" class="is--column is--left"
                                          [disabled]="isAnswereReadonly()"
                                          [(ngModel)]="answer.damageProperty">{{'PropertyDamage'|translate}}</mat-checkbox>
                            <mat-checkbox color="primary" class="is--column is--left" [(ngModel)]="answer.damageImage"
                                          [disabled]="isAnswereReadonly()">{{'ImageDemage'|translate}}</mat-checkbox>
                            <mat-checkbox color="primary" class="is--column is--left" [(ngModel)]="answer.damageProfit"
                                          [disabled]="isAnswereReadonly()">{{'ProfitDemage'|translate}}</mat-checkbox>
                        </mat-list-item>
                    </fieldset>

                </div>

                <mat-list-item>
                    <button [disabled]="!isQuestionByUserValidForSave(segment) || isAnswereReadonly()"
                            *ngIf="rightsCheck.getAccessRightToSegment(accountService.User, segment) > 1"
                            mat-stroked-button
                            color="primary"
                            (click)="saveAnswerByUser()">
                        {{'formButtons_save'|translate}}
                        <mat-spinner diameter="24" *ngIf="isLoading"></mat-spinner>
                    </button>
                </mat-list-item>

            </mat-list>

        </fieldset>


    </section>

    <section>
        <app-measures-list [folder]="folder" [readOnly]=true></app-measures-list>
    </section>

</section>
