import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-lws-bread-crumb',
    templateUrl: './lws-bread-crumb.component.html',
    styleUrls: ['./lws-bread-crumb.component.scss']
})
export class LwsBreadCrumbComponent implements OnInit {
    /**
     * the list of bread crumbs to display
     */
    @Input() public breadcrumbs: string[] = [];
    
    constructor() {
    
    }
    
    ngOnInit() {
    
    }
}
