import {ModelInterface} from '../model-interface';
import {JSONConverter} from '../JSONConverter';

export class Privilege implements ModelInterface {
    static readonly FORBIDDEN: string = '';
    static readonly ADMIN: string = 'ADMIN';
    static readonly USER: string = 'USER';
    static readonly COMPANY_READ: string = 'COMPANY_READ';
    static readonly COMPANY_ANSWER: string = 'COMPANY_ANSWER';
    static readonly MARKET_READ: string = 'MARKET_READ';
    static readonly MARKET_ANSWER: string = 'MARKET_ANSWER';
    static readonly RISK_READ: string = 'RISK_READ';
    static readonly RISK_ANSWER: string = 'RISK_ANSWER';
    static readonly RISK_WRITE: string = 'RISK_WRITE';
    static readonly MEASURE_READ: string = 'MEASURE_READ';
    static readonly MEASURE_WRITE: string = 'MEASURE_WRITE';
    static readonly COMPLIANCE_READ: string = 'COMPLIANCE_READ';
    static readonly COMPLIANCE_ANSWER: string = 'COMPLIANCE_ANSWER';
    static readonly COMPLIANCE_WRITE: string = 'COMPLIANCE_WRITE';

    static readonly REPORTING_DASHBOARD_READ: string = 'REPORTING_DASHBOARD_READ';
    static readonly REPORTING_DASHBOARD_FILTER: string = 'REPORTING_DASHBOARD_FILTER';
    static readonly REPORTING_DASHBOARD_CONFIGURE: string = 'REPORTING_DASHBOARD_CONFIGURE';


    private _name: string = '';

    // a list of properties-keys to ignore at jsonconvert
    _ignoreProperties: string[] = [];

    constructor() {

    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    /**
     * returns the object as a valid json (without the underscore a the property name begin)
     */
    toJSON() {
        return new JSONConverter().toJSON(this);
    }

    /**
     * creates a object from the given data
     *
     * @param data the data to create a model
     */
    static hydrate(data: any): Privilege {
        const model: Privilege = new Privilege();

        Object.assign(model, data);

        return model;
    }
}
