import {AccountService} from './account/account.service';
import {RestService} from './rest/rest.service';
import {EventService} from '../client/event/event.service';
import {TranslateService} from '@ngx-translate/core';
import {ErrorHandlingService} from '../client/error-handling/error-handling.service';

export abstract class CrudService {

    constructor() {
    }

}
