import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {LwsTableComponent} from '../../components/lws-table/lws-table.component';
import {LwsDetailHeaderComponent} from '../../components/lws-detail-header/lws-detail-header.component';
import {Year} from '../../models/year/year';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {VersionService} from '../../services/server/version/version.service';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {YearService} from '../../services/server/year/year.service';
import {LwsDialogComponent} from '../../components/lws-dialog/lws-dialog.component';
import {Version} from '../../models/version/version';
import {LwsInputDialogComponent} from '../../components/lws-input-dialog/lws-input-dialog.component';
import {ColumnInformation} from '../../models/lws-table/column-information';
import {HttpResponse} from '@angular/common/http';
import * as FileSaver from 'file-saver';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-versions',
    templateUrl: './versions.component.html',
    styleUrls: ['./versions.component.scss']
})
export class VersionsComponent implements OnInit, AfterViewInit {
    @ViewChild(LwsTableComponent) private table: LwsTableComponent;
    @ViewChild(MatPaginator) private paginator: MatPaginator;
    @ViewChild(LwsDetailHeaderComponent) private header: LwsDetailHeaderComponent;
    public isLoading: boolean = false;
    public title: string = '';
    public year: Year = null;
    /**
     * the length of the current loading
     */
    public resultsLength = 0;
    /**
     * the datasource
     */
    public dataSource: Version[] = [];
    /**
     * the columns of the table to display
     */
    public displayedColumns: ColumnInformation[] = [
        new ColumnInformation('id', '#', 'number', 'id'),
        new ColumnInformation('name', 'Name', 'text', 'name'),
    ];
    /**
     * the current list with the breadcrumb titles to display
     */
    public breadcrumbs: string[] = [];

    constructor(public route: ActivatedRoute,
                public versionService: VersionService,
                public router: Router,
                public dialog: MatDialog,
                public translator: TranslateService,
                public location: Location,
                public snackBar: MatSnackBar,
                public yearService: YearService) {

    }

    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            const id: number = +params.get('id');

            if (id > 0) {
                this.loadYearById(id);
            }
        });
    }

    /**
     *
     */
    ngAfterViewInit() {
        const me = this;

        this.header.addClick.subscribe(() => {
            this.createNewVersion();
        });

        this.header.backClick.subscribe(() => {
            this.location.back();
        });

        this.table.editClick.subscribe((version: Version) => {
            me.router.navigate(['/companies/versions/' + version.id], {
                queryParams: {
                    yearId: me.year.id,
                    breadcrumbs: me.breadcrumbs
                }
            });
        });

        this.table.openClick.subscribe((version: Version) => {
            me.router.navigate(['/companies/versions/' + version.id + '/folders'], {
                queryParams: {
                    breadcrumbs: me.breadcrumbs
                }
            });
        });

        this.table.copyClick.subscribe((version: Version) => {
            this.copyVersion(version);
        });

        this.table.deleteClick.subscribe((version: Version) => {
            this.deleteVersion(version);
        });

        this.table.loaded.subscribe(() => {
            this.table.sortChange.subscribe((data: { column: string, direction: string }) => {
                this.paginator.pageIndex = 0;
                this.load(data.column, data.direction);
            });
            this.paginator.page.subscribe(() => {
                this.load();
            });

            this.load();
        });

        this.table.exportClick.subscribe((version: Version) => {
            this.exportVersion(version);
        });
    }

    /**
     * deletes the given version
     *
     * @param version the version to delete
     */
    private deleteVersion(version: Version) {
        this.translator.get('versions_DeleteAction_MessageContent').subscribe((messageTranslation: string) => {
            this.translator.get('versions_DeleteAction_TitleContent').subscribe((titleTranslation: string) => {
                const dialogRef = this.dialog.open(LwsDialogComponent, {
                    data: {
                        cancelButton: true,
                        okButton: true,

                        message: messageTranslation,
                        title: titleTranslation
                    }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result === 'ok') {
                        this.isLoading = true;

                        this.versionService.delete(version.id).then(
                            () => {
                                this.isLoading = false;

                                this.load();
                            });
                    }
                });
            });
        });
    }

    /**
     * copies the given version (the user have to select the target => 'same year', 'new year' or 'copy to new company')
     *
     * @param version the source version to copy
     */
    private copyVersion(version: Version) {
        this.translator.get('versions_CopyAction_MessageContent').subscribe((messageTranslation: string) => {
            this.translator.get('versions_CopyAction_TitleContent').subscribe((titleTranslation: string) => {
                const dialogRef = this.dialog.open(LwsDialogComponent, {
                    data: {
                        cancelButton: false,

                        button1Button: true,
                        button1ButtonTranslationKey: 'versions_CopyActionQuestion_SameYearTitleContent',

                        button2Button: true,
                        button2ButtonTranslationKey: 'versions_CopyActionQuestion_NewYearTitleContent',

                        button3Button: this.isTemplateVersion(version),
                        button3ButtonTranslationKey: 'versions_CopyActionQuestion_FromTemplateTitleContent',

                        message: messageTranslation,
                        title: titleTranslation
                    }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result === 'button1') {
                        // copy to a same year
                        this.copyVersionToSameYear(version);
                    } else if (result === 'button2') {
                        // copy to a new year
                        this.copyVersionToNewYear(version);
                    } else if (result === 'button3') {
                        // copy to a new company
                        this.copyVersionToNewCompany(version);
                    }
                });
            });
        });
    }

    /**
     * returns true if the current version is a template
     *
     * @param version the selected version
     */
    private isTemplateVersion(version: Version): boolean {
        /*
        // @todo: check that the current version based on a muster company (id=1)
        if (this.year.unit.group) {

        }
        /* */

        return true;
    }

    /**
     * load the table-service content
     */
    private load(column: string = '', direction: string = '') {
        // this.versionListService.load(this.year, column, direction, this.paginator.pageIndex);

        this.isLoading = true;

        const sort: string = LwsTableComponent.getSortColumnByName(this.displayedColumns, column);
        const sortDesc: boolean = ((direction.toLowerCase() === 'desc') ? true : false);

        if (this.year != null) {
            this.yearService.getVersions(this.year.id, this.paginator.pageIndex, this.paginator.pageSize, sort, sortDesc).then(
                (result: { items: Version[], numberOfAllItems: number }) => {
                    this.isLoading = false;

                    if (result.items) {
                        this.dataSource = result.items;
                        this.resultsLength = result.numberOfAllItems;
                    }
                },
                (error) => {
                    this.isLoading = false;
                });
        } else {
            this.versionService.getAll().then(
                (units) => {
                    this.isLoading = false;

                    if (units) {
                        this.dataSource = units;
                        this.resultsLength = units.length;
                    }
                },
                (error) => {
                    this.isLoading = false;
                });
        }
    }

    /**
     * copy the current version to the same year
     */
    private copyVersionToSameYear(version: Version) {
        this.versionService.copy(version, this.year.name).then(
            (newVersionId) => {
                if (newVersionId > 0) {
                    this.copyProcessWasSuccessful();
                }
            },
            () => {

            }
        );
    }

    /**
     * copy the current version to a new year
     */
    private copyVersionToNewYear(version: Version) {
        this.translator.get('versions_CopyActionYearName_MessageContent').subscribe((messageTranslation: string) => {
            this.translator.get('versions_CopyActionYearName_TitleContent').subscribe((titleTranslation: string) => {
                const dialogRef = this.dialog.open(LwsInputDialogComponent, {
                    data: {
                        okButton: true,
                        cancelButton: true,

                        message: messageTranslation,
                        title: titleTranslation
                    }
                });

                dialogRef.afterClosed().subscribe(inputValue => {
                    if (inputValue !== undefined) {
                        const yearName: string = inputValue;

                        this.versionService.copy(version, yearName).then(
                            (newVersionId) => {
                                if (newVersionId > 0) {
                                    this.copyProcessWasSuccessful();
                                }
                            },
                            () => {

                            }
                        );
                    }
                });
            });
        });
    }

    /**
     * copy the current version to a new company (current version is a template)
     */
    private copyVersionToNewCompany(version: Version) {
        this.router.navigateByUrl('/companies/versions/' + version.id + '/copy');
    }

    /**
     * this method is executed if the copy process was successful
     */
    private copyProcessWasSuccessful() {
        this.translator.get('versions_CopyRequest_SuccessMessage').subscribe(
            (translation: string) => {
                this.snackBar.open(translation, '', {
                    duration: (5 * 1000), // display 5 seconds
                    panelClass: 'snackbar-lws-success'
                });
            });

        this.load();
    }

    /**
     * loads the year from the given id
     *
     * @param id the id of the year
     */
    private loadYearById(id: number) {
        this.isLoading = true;

        this.yearService.getDetail(id).then(
            (year) => {
                this.isLoading = false;

                if (year == null) {
                    this.location.back();
                }

                this.year = year;

                this.translator.get('versions_PageTitle_TitleText', {yearName: this.year.name}).subscribe((translation: string) => {
                    this.title = translation;

                    this.loadBreadcrumbsAndAdd(this.year.name);
                });
            },
            () => {
                this.isLoading = false;
            });
    }

    /**
     * opens the detail view to create a new company
     */
    private createNewVersion() {
        this.router.navigate(['/companies/versions/0'], {
            queryParams: {
                yearId: this.year.id,
                breadcrumbs: this.breadcrumbs
            }
        });
    }

    /**
     * load the latest breadcrumbs and add the given as a new one
     *
     * @param breadcrumb the breadcrumb to add
     */
    private loadBreadcrumbsAndAdd(breadcrumb?: string) {
        const me = this;

        this.route.queryParams.subscribe((queryParams) => {

            if (me.breadcrumbs === null || me.breadcrumbs === undefined) {
                me.breadcrumbs = [];
            }
            if (typeof queryParams.breadcrumbs === 'string') {
                me.breadcrumbs.push(queryParams.breadcrumbs);
            } else {
                me.breadcrumbs = (queryParams.breadcrumbs as string[]);
            }

            if (breadcrumb !== null && breadcrumb !== undefined && breadcrumb.length > 0) {
                me.breadcrumbs.push(breadcrumb);
            }
        });
    }

    /**
     * exports the generated data to the browser
     *
     * @param {Version} version
     */
    private exportVersion(version: Version) {
        if (version != null) {
            this.yearService.export(version.id).then(
                (response: HttpResponse<any>) => {
                    const fileName: string = 'export.xlsx';

                    FileSaver.saveAs(response.body, fileName);
                },
                () => {
                });
        }
    }
}
