import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ReportingGadget} from '../../../models/reporting-dashboard/reporting-gadget';
import {YearService} from '../../../services/server/year/year.service';
import {VersionService} from '../../../services/server/version/version.service';

@Component({
    selector: 'app-reporting-gadget-config',
    templateUrl: './reporting-gadget-config.component.html',
    styleUrls: ['./reporting-gadget-config.component.scss']
})
export class ReportingGadgetConfigComponent implements OnInit {

    @Input() gadget: ReportingGadget;
    @Output() onConfigurationReady = new EventEmitter();

    public form: FormGroup = this.formBuilder.group({
        name: ['', Validators.compose([
            Validators.required,
            Validators.maxLength(80)
        ])],
        year: ['', Validators.compose([])],
        version: ['', Validators.compose([])],
        released: ['', Validators.compose([])]
    });

    public years: string[];
    public versions: string[];

    constructor(
        public formBuilder: FormBuilder,
        private yearService: YearService,
        private versionService: VersionService) {
    }

    ngOnInit() {
        this.form.patchValue(this.gadget);
        this.loadYears();

        if (this.gadget.year != null) {
            this.loadVersions(this.gadget.year);
        }
    }

    private loadYears() {
        this.yearService.getAllYearNames(this.gadget.companyId).then( (years) => {
                this.years = years;
            },
            () => {

            });
    }

    public loadVersions(yearName: string) {
        this.versionService.getAllVersionNamesUnderYearNames(this.gadget.companyId, yearName).then( (versions) => {
                this.versions = versions;
            },
            () => {

            });
    }

    saveConfiguration() {
        Object.assign(this.gadget, this.form.getRawValue());
        this.onConfigurationReady.emit();
    }

}
