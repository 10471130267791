import {ModelInterface} from '../model-interface';
import {Year} from '../year/year';
import {Unit} from '../unit/unit';

export class Version implements ModelInterface {
    private _id: number = 0;
    private _name: string = '';
    private _year: Year = null;
    
    // a list of properties-keys to ignore at jsonconvert
    _ignoreProperties: string[] = [];
    
    constructor() {
    
    }
    
    get id(): number {
        return this._id;
    }
    
    set id(value: number) {
        this._id = value;
    }
    
    get name(): string {
        return this._name;
    }
    
    set name(value: string) {
        this._name = value;
    }
    
    get year(): Year {
        return this._year;
    }
    
    set year(value: Year) {
        this._year = value;
    }
    
    /**
     * returns the object as a valid json (without the underscore a the property name begin)
     */
    toJSON() {
        return {
            id: this.id,
            name: this.name,
            year: ((this.year == null) ? null : this.year.toJSON()),
        };
    }
    
    /**
     * creates a object from the given data
     *
     * @param data the data to create a model
     */
    static hydrate(data: any): Version {
        const model: Version = new Version();
        
        model.id = data.id;
        model.name = data.name;
    
        if (data.year) {
            model.year = Year.hydrate(data.year);
        }
        
        return model;
    }
}
