import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ColumnInformation} from '../../models/lws-table/column-information';

@Component({
    selector: 'app-lws-table-inline-edit',
    templateUrl: './lws-table-inline-edit.component.html',
    styleUrls: ['./lws-table-inline-edit.component.scss']
})
export class LwsTableInlineEditComponent implements OnInit {
    
    /**
     * Title which is visible in the view
     */
    @Input() title: string;
    
    /**
     * the datasource to bind on the table
     */
    @Input() tableData;
    
    /**
     * a list of columns that should be displayed in the table
     */
    @Input() displayedColumns: ColumnInformation[] = [];
    
    @Input() addActionAvailable: boolean = true;
    
    @Input() deleteActionAvailable: boolean = true;
    
    /**
     * the layout for the table
     *
     * default: no layout
     * full: 100% width
     */
    @Input() layout: string;
    
    @Output() addClick = new EventEmitter();
    
    @Output() deleteClick = new EventEmitter();
    
    public layoutSelected: string = 'default';
    
    public dataSource = new BehaviorSubject<AbstractControl[]>([]);
    public columns = [];
    public rows: FormArray = this.formBuilder.array([]);
    
    /**
     * the concrete list of visible columns in the view
     */
    public visibleOutputColumns: ColumnInformation[] = [];
    
    /**
     * the structure of the form
     */
    public form: FormGroup = this.formBuilder.group({
        formRows: this.rows
    });
    
    constructor(public formBuilder: FormBuilder,
                public translator: TranslateService) {
    }
    
    ngOnInit() {
        let hasRows: boolean = false;
        
        for (const columnEntry of this.displayedColumns) {
            if (!columnEntry.hidden) {
                this.visibleOutputColumns.push(columnEntry);
            }
        }
        
        if (this.tableData != null) {
            for (const row of this.tableData) {
                this.addRow(row);
                hasRows = true;
            }
        }
        
        this.loadLayoutValue(this.layout);
        this.generateColumns();
    }
    
    /**
     * Generate the visible columns of the grid, which are defined in the given columns
     * @param columns contains the columns to display
     */
    private generateColumns() {
        this.columns = [];
        
        for (const columnEntry of this.visibleOutputColumns) {
            this.columns.push(columnEntry.column);
        }
        
        this.columns.push('actions');
    }
    
    /**
     * load the layout-classname for the table
     *
     * @param layoutValue the given layout-value
     */
    private loadLayoutValue(layoutValue: string) {
        if (layoutValue === undefined || layoutValue.length <= 0) {
            return;
        }
        
        switch (layoutValue.toLowerCase()) {
            case 'full': {
                this.layoutSelected = 'full';
            }
                break;
            default: {
                this.layoutSelected = 'default';
            }
                break;
        }
    }
    
    private addRow(rowItem?, noUpdate?: boolean) {
        let alreadyExists: boolean = false;
        try {
            for (const row of this.rows.controls) {
                if (+rowItem.userId == +row.value.userId) {
                    alreadyExists = true;
                }
            }
        } catch (error) {
        
        }
        
        if (alreadyExists) {
            return;
        }
        
        const row = this.formBuilder.group({});
        for (const columnEntry of this.displayedColumns) {

            if (columnEntry.maxValue != null) {
                row.addControl(columnEntry.column, new FormControl(rowItem[columnEntry.column], [
                    Validators.max(columnEntry.maxValue),
                ]));
            } else {
                row.addControl(columnEntry.column, new FormControl(rowItem[columnEntry.column], [
                ]));
            }


        }

        row.addControl('id', new FormControl(rowItem['id']));
        this.rows.push(row);
        
        if (!noUpdate) {
            this.updateView();
        }
    }
    
    private updateView() {
        this.dataSource.next(this.rows.controls);
    }
    
    /**
     * Deletes a row of the grid
     * @param row Model
     * @param index Numeric index
     */
    private deleteAction(row, index: number) {
        this.deleteClick.emit();
        
        this.rows.removeAt(index);
        this.updateView();
    }
    
    /**
     * executed if the user clicked on the open action button
     *
     * @param row the whole dataset for the clicked row
     */
    private addAction() {
        this.addClick.emit((newRow) => {
            this.addRow(newRow);
        });
    }
    
    /**
     * Returns the data in the table
     */
    public getTableData() {
        const tableRows = new Array();
        
        for (const row of this.rows.controls) {
            if (row instanceof FormGroup) {
                
                const dataModel: any = new Object();
                Object.keys(row.controls).forEach(key => {
                    if (key === 'id' && row.controls[key].value === 0) {
                        dataModel[key] = null;
                    } else {
                        dataModel[key] = row.controls[key].value;
                    }
                });
                
                tableRows.push(dataModel);
            }
        }
        
        return tableRows;
    }

    /**
     * Returns true, if all inline inputs are valid
     */
    public isValid() {
        return this.form.valid;
    }
}
