<section>

    <form *ngIf="form != null" class="form" autocomplete="off" (ngSubmit)="onSubmit()" [formGroup]="form">

        <mat-list>

            <mat-list-item>

                <mat-form-field [floatLabel]="true">
                    <input matInput placeholder="{{'userDetailForm_UsernameInput_Placeholder'|translate}}" required
                           type="email" autocomplete="off" formControlName="username" [maxLength]="200">
                </mat-form-field>

            </mat-list-item>

            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <input matInput placeholder="{{'userDetailForm_FirstnameInput_Placeholder'|translate}}" required
                           type="text" formControlName="firstname" [maxLength]="80">
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="is--right">
                    <input matInput placeholder="{{'userDetailForm_LastnameInput_Placeholder'|translate}}" required
                           type="text" formControlName="lastname" [maxLength]="80">
                </mat-form-field>

            </mat-list-item>

            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <input matInput placeholder="{{'userDetailForm_PasswordInput_Placeholder'|translate}}" required
                           *ngIf="user == null"
                           [matTooltip]="'userDetailForm_PasswordNotice_TooltipText'|translate"
                           type="password" autocomplete="off" formControlName="password" [minLength]="10">
                    <input matInput placeholder="{{'userDetailForm_PasswordInput_Placeholder'|translate}}"
                           *ngIf="user != null"
                           [matTooltip]="'userDetailForm_PasswordNotice_TooltipText'|translate"
                           type="password" autocomplete="off" formControlName="password" [minLength]="10">
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="is--right">
                    <input matInput placeholder="{{'userDetailForm_PasswordRepeatInput_Placeholder'|translate}}"
                           required
                           [matTooltip]="'userDetailForm_PasswordNotice_TooltipText'|translate"
                           *ngIf="user == null"
                           type="password" autocomplete="off" formControlName="passwordRepeat" [minLength]="10">
                    <input matInput placeholder="{{'userDetailForm_PasswordRepeatInput_Placeholder'|translate}}"
                           *ngIf="user != null"
                           [matTooltip]="'userDetailForm_PasswordNotice_TooltipText'|translate"
                           type="password" autocomplete="off" formControlName="passwordRepeat" [minLength]="10">
                </mat-form-field>

            </mat-list-item>

            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <mat-label>{{'userDetailForm_RightsCompanies_Label'|translate}}</mat-label>
                    <mat-select [(ngModel)]="companyRightValue" required [ngModelOptions]="{standalone: true}">
                        <mat-option *ngFor="let rightOption of companyRightOptions" [value]="rightOption.value">
                            {{rightOption.title|translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="is--right">
                    <mat-label>{{'userDetailForm_RightsMarket_Label'|translate}}</mat-label>
                    <mat-select [(ngModel)]="marketRightValue" required [ngModelOptions]="{standalone: true}">
                        <mat-option *ngFor="let rightOption of marketRightOptions" [value]="rightOption.value">
                            {{rightOption.title|translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </mat-list-item>

            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <mat-label>{{'userDetailForm_RightsMeasures_Label'|translate}}</mat-label>
                    <mat-select [(ngModel)]="measureRightValue" required [ngModelOptions]="{standalone: true}">
                        <mat-option *ngFor="let rightOption of measureRightOptions" [value]="rightOption.value">
                            {{rightOption.title|translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="is--right">
                    <mat-label>{{'userDetailForm_RightsRisk_Label'|translate}}</mat-label>
                    <mat-select [(ngModel)]="riskRightValue" required [ngModelOptions]="{standalone: true}">
                        <mat-option *ngFor="let rightOption of riskRightOptions" [value]="rightOption.value">
                            {{rightOption.title|translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </mat-list-item>


            <mat-list-item class="has--two-columns">

                <mat-form-field [floatLabel]="true" class="is--left">
                    <mat-label>{{'userDetailForm_RightsCompliance_Label'|translate}}</mat-label>
                    <mat-select [(ngModel)]="complianceRightValue" required [ngModelOptions]="{standalone: true}">
                        <mat-option *ngFor="let rightOption of complianceRightOptions" [value]="rightOption.value">
                            {{rightOption.title|translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field [floatLabel]="true" class="is--right">
                    <mat-label>{{'userDetailForm_RightsDashboard_Label'|translate}}</mat-label>
                    <mat-select [(ngModel)]="privilegeDashboardRightValue" required [ngModelOptions]="{standalone: true}">
                        <mat-option *ngFor="let rightOption of dashboardRightOptions" [value]="rightOption.value">
                            {{rightOption.title|translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </mat-list-item>

            <mat-list-item>

                <button type="submit" [disabled]="!form.valid" mat-stroked-button class="submit-button" color="primary">
                    {{'formButtons_save'|translate}}
                    <mat-spinner diameter="24" *ngIf="isLoading"></mat-spinner>
                </button>

            </mat-list-item>

        </mat-list>

    </form>

</section>
