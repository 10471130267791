import {ModelInterface} from '../model-interface';
import {Group} from '../group/group';

export class Unit implements ModelInterface {
    private _id: number = 0;
    private _name: string = '';
    private _group: Group = null;
    private _successFactor1: string = '';
    private _successFactor2: string = '';
    private _successFactor3: string = '';
    private _successFactor4: string = '';
    private _successFactor5: string = '';
    private _successFactor6: string = '';
    private _successFactor7: string = '';
    private _successFactor8: string = '';
    private _successFactor9: string = '';
    private _successFactor10: string = '';
    private _successFactor11: string = '';
    private _successFactor12: string = '';
    private _successFactor13: string = '';
    private _successFactor14: string = '';
    private _successFactor15: string = '';
    private _successFactor16: string = '';
    private _successFactor17: string = '';
    private _successFactor18: string = '';
    private _successFactor19: string = '';
    private _successFactor20: string = '';
    private _sales: number;
    
    // a list of properties-keys to ignore at jsonconvert
    _ignoreProperties: string[] = [];
    
    constructor() {
    
    }
    
    get id(): number {
        return this._id;
    }
    
    set id(value: number) {
        this._id = value;
    }
    
    get name(): string {
        return this._name;
    }
    
    set name(value: string) {
        this._name = value;
    }
    
    get group(): Group {
        return this._group;
    }
    
    set group(value: Group) {
        this._group = value;
    }
    
    get successFactor1(): string {
        return this._successFactor1;
    }
    
    set successFactor1(value: string) {
        this._successFactor1 = value;
    }
    
    get successFactor2(): string {
        return this._successFactor2;
    }
    
    set successFactor2(value: string) {
        this._successFactor2 = value;
    }
    
    get successFactor3(): string {
        return this._successFactor3;
    }
    
    set successFactor3(value: string) {
        this._successFactor3 = value;
    }
    
    get successFactor4(): string {
        return this._successFactor4;
    }
    
    set successFactor4(value: string) {
        this._successFactor4 = value;
    }
    
    get successFactor5(): string {
        return this._successFactor5;
    }
    
    set successFactor5(value: string) {
        this._successFactor5 = value;
    }
    
    get successFactor6(): string {
        return this._successFactor6;
    }
    
    set successFactor6(value: string) {
        this._successFactor6 = value;
    }
    
    get successFactor7(): string {
        return this._successFactor7;
    }
    
    set successFactor7(value: string) {
        this._successFactor7 = value;
    }
    
    get successFactor8(): string {
        return this._successFactor8;
    }
    
    set successFactor8(value: string) {
        this._successFactor8 = value;
    }
    
    get successFactor9(): string {
        return this._successFactor9;
    }
    
    set successFactor9(value: string) {
        this._successFactor9 = value;
    }
    
    get successFactor10(): string {
        return this._successFactor10;
    }
    
    set successFactor10(value: string) {
        this._successFactor10 = value;
    }
    
    get successFactor11(): string {
        return this._successFactor11;
    }
    
    set successFactor11(value: string) {
        this._successFactor11 = value;
    }
    
    get successFactor12(): string {
        return this._successFactor12;
    }
    
    set successFactor12(value: string) {
        this._successFactor12 = value;
    }
    
    get successFactor13(): string {
        return this._successFactor13;
    }
    
    set successFactor13(value: string) {
        this._successFactor13 = value;
    }
    
    get successFactor14(): string {
        return this._successFactor14;
    }
    
    set successFactor14(value: string) {
        this._successFactor14 = value;
    }
    
    get successFactor15(): string {
        return this._successFactor15;
    }
    
    set successFactor15(value: string) {
        this._successFactor15 = value;
    }
    
    get successFactor16(): string {
        return this._successFactor16;
    }
    
    set successFactor16(value: string) {
        this._successFactor16 = value;
    }
    
    get successFactor17(): string {
        return this._successFactor17;
    }
    
    set successFactor17(value: string) {
        this._successFactor17 = value;
    }
    
    get successFactor18(): string {
        return this._successFactor18;
    }
    
    set successFactor18(value: string) {
        this._successFactor18 = value;
    }
    
    get successFactor19(): string {
        return this._successFactor19;
    }
    
    set successFactor19(value: string) {
        this._successFactor19 = value;
    }
    
    get successFactor20(): string {
        return this._successFactor20;
    }
    
    set successFactor20(value: string) {
        this._successFactor20 = value;
    }

    get sales(): number {
        return this._sales;
    }

    set sales(sales: number) {
        this._sales = sales;
    }
    
    /**
     * returns the object as a valid json (without the underscore a the property name begin)
     */
    toJSON() {
        return {
            id: this.id,
            name: this.name,
            group: ((this.group == null) ? null : this.group.toJSON()),
            successFactor1: this.successFactor1,
            successFactor2: this.successFactor2,
            successFactor3: this.successFactor3,
            successFactor4: this.successFactor4,
            successFactor5: this.successFactor5,
            successFactor6: this.successFactor6,
            successFactor7: this.successFactor7,
            successFactor8: this.successFactor8,
            successFactor9: this.successFactor9,
            successFactor10: this.successFactor10,
            successFactor11: this.successFactor11,
            successFactor12: this.successFactor12,
            successFactor13: this.successFactor13,
            successFactor14: this.successFactor14,
            successFactor15: this.successFactor15,
            successFactor16: this.successFactor16,
            successFactor17: this.successFactor17,
            successFactor18: this.successFactor18,
            successFactor19: this.successFactor19,
            successFactor20: this.successFactor20,
            sales: this.sales
        };
    }
    
    /**
     * creates a object from the given data
     *
     * @param data the data to create a model
     */
    static hydrate(data: any): Unit {
        const model: Unit = new Unit();
        model.id = data.id;
        model.name = data.name;
        model.successFactor1 = data.successFactor1;
        model.successFactor2 = data.successFactor2;
        model.successFactor3 = data.successFactor3;
        model.successFactor4 = data.successFactor4;
        model.successFactor5 = data.successFactor5;
        model.successFactor6 = data.successFactor6;
        model.successFactor7 = data.successFactor7;
        model.successFactor8 = data.successFactor8;
        model.successFactor9 = data.successFactor9;
        model.successFactor10 = data.successFactor10;
        model.successFactor11 = data.successFactor11;
        model.successFactor12 = data.successFactor12;
        model.successFactor13 = data.successFactor13;
        model.successFactor14 = data.successFactor14;
        model.successFactor15 = data.successFactor15;
        model.successFactor16 = data.successFactor16;
        model.successFactor17 = data.successFactor17;
        model.successFactor18 = data.successFactor18;
        model.successFactor19 = data.successFactor19;
        model.successFactor20 = data.successFactor20;
        model.sales = data.sales;
        
        if (data.group) {
            model.group = Group.hydrate(data.group);
        }
        
        return model;
    }
}
