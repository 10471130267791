import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReportingGadget} from '../../../models/reporting-dashboard/reporting-gadget';
import {ReportingGadgetConfig} from '../../../models/reporting-dashboard/reporting-gadget-config';

@Component({
    template: ''
})
export abstract class BaseGadget implements OnInit, AfterViewInit {

    @Input() gadget: ReportingGadget;
    @Input() isFiltereable: boolean;

    @Output() filterChanged = new EventEmitter();

    public filterSelectionChanged() {
        this.saveFilters();
        this.filterChanged.emit();

        this.loadGadgetReport();
    }

    public ngOnInit() {
        this.loadFilters(this.gadget.config);
    }

    public ngAfterViewInit() {
        this.loadGadgetReport();
    }

    protected abstract loadFilters(configs: ReportingGadgetConfig[]);
    protected abstract saveFilters();
    protected abstract loadGadgetReport();
}
