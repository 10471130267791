<section *ngIf="data">
    <h2 matDialogTitle>{{data.title}}</h2>

    <mat-dialog-content>
        <p>{{data.message}}</p>
        <mat-form-field>
            <input matInput [(ngModel)]="data.value">
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">

        <button *ngIf="data.button1Button && data.button1Button == true && data.button1ButtonTranslationKey" mat-button
                matDialogClose="button1"
                [mat-dialog-close]="data.value">{{data.button1ButtonTranslationKey|translate}}</button>

        <button *ngIf="data.cancelButton && data.cancelButton == true" mat-button
                matDialogClose="cancel" (click)="onNoClick()">{{'lwsDialogButtons_cancel'|translate}}</button>

        <button *ngIf="data.button2Button && data.button2Button == true && data.button2ButtonTranslationKey" mat-button
                matDialogClose="button2"
                [mat-dialog-close]="data.value">{{data.button2ButtonTranslationKey|translate}}</button>

        <button *ngIf="data.okButton && data.okButton == true" mat-button color="primary"
                matDialogClose="ok" [mat-dialog-close]="data.value">{{'lwsDialogButtons_ok'|translate}}</button>

    </mat-dialog-actions>
</section>
