<mat-toolbar class="filter-panel" *ngIf="isFiltereable">
    <app-hierarchy-filter [segments]="relevantSegments" [gadget]="gadget" (selectionChanged)="filterSelectionChanged()"></app-hierarchy-filter>

    <mat-form-field [floatLabel]="true">
        <mat-label>{{ 'NetAmountOfDamage' | translate}}</mat-label>
        <mat-select [(ngModel)]="selectedAmountOfDamage"
                    (selectionChange)="filterSelectionChanged()">
            <mat-option *ngFor="let amountOfDamage of amountOfDamages" [value]="amountOfDamage.value">
                {{amountOfDamage.title}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field [floatLabel]="true">
        <mat-label> {{'questionDetailForm_QuestionExistentialThreat_CheckBoxLabel'|translate}}</mat-label>
        <mat-select [(ngModel)]="existentialThreat"
                    (selectionChange)="filterSelectionChanged()">
            <mat-option *ngFor="let existentialThreatValue of existentialThreatValues" [value]="existentialThreatValue.value">
                {{existentialThreatValue.title}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="question--detail-selection-field">
        <mat-label>{{'Damages'|translate}}</mat-label>
        <mat-select [(ngModel)]="selectedDamages"
                    color="primary"
                    (selectionChange)="filterSelectionChanged()"
                    multiple>
            <mat-option *ngFor="let entry of damageValues"
                        [value]="entry">
                {{entry.title|translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>

</mat-toolbar>


<div echarts [options]="chartData" class="chart" *ngIf="chartData"></div>
