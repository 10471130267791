<section *ngIf="data">
    <h2 matDialogTitle>{{data.title}}</h2>

    <mat-dialog-content>
        <p>{{data.message}}</p>
    </mat-dialog-content>

    <mat-dialog-actions align="end">

        <button *ngIf="data.button1Button && data.button1Button == true && data.button1ButtonTranslationKey" mat-button
                matDialogClose="button1">{{data.button1ButtonTranslationKey|translate}}</button>

        <button *ngIf="data.cancelButton && data.cancelButton == true" mat-button
                matDialogClose="cancel">{{'lwsDialogButtons_cancel'|translate}}</button>

        <button *ngIf="data.button2Button && data.button2Button == true && data.button2ButtonTranslationKey" mat-button
                matDialogClose="button2">{{data.button2ButtonTranslationKey|translate}}</button>

        <button *ngIf="data.button3Button && data.button3Button == true && data.button3ButtonTranslationKey" mat-button
                matDialogClose="button3">{{data.button3ButtonTranslationKey|translate}}</button>

        <button *ngIf="data.okButton && data.okButton == true" mat-button color="primary"
                matDialogClose="ok">{{'lwsDialogButtons_ok'|translate}}</button>

    </mat-dialog-actions>
</section>
