<section>

    <app-lws-detail-header></app-lws-detail-header>

    <main>

        <app-assigned-measures></app-assigned-measures>


    </main>

</section>
