import {ReportingGadgetConfig} from './reporting-gadget-config';
import {GridsterItem} from 'angular-gridster2';

export class ReportingGadget implements GridsterItem {

    public id: number;

    public name: string;

    public type: string;

    public year: string;

    public version: string;

    public released: boolean;

    public cols: number;

    public rows: number;

    public x: number;

    public y: number;

    public filterUnitId: number;

    public filterSegmentId: number;

    public filterFolderLevel1Id: number;

    public filterFolderLevel2Id: number;

    public companyId: number;

    public config: ReportingGadgetConfig[] = [];

    constructor() {

    }

}
