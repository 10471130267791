import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {FolderService} from '../../services/server/folder/folder.service';
import {Segment} from '../../models/segment/segment';
import {Folder} from '../../models/folder/folder';
import {Version} from '../../models/version/version';
import {LwsDialogComponent} from '../lws-dialog/lws-dialog.component';
import {LwsTableComponent} from '../lws-table/lws-table.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {VersionService} from '../../services/server/version/version.service';
import {ColumnInformation} from '../../models/lws-table/column-information';

@Component({
    selector: 'app-folders-list',
    templateUrl: './folders-list.component.html',
    styleUrls: ['./folders-list.component.scss']
})
export class FoldersListComponent implements OnInit, AfterViewInit {
    @ViewChild(LwsTableComponent) public table: LwsTableComponent;
    @ViewChild(MatPaginator) public paginator: MatPaginator;
    @Input() public segment: Segment;
    @Input() public version: Version;
    @Input() public parentFolder: Folder;
    @Input() public breadcrumbs: string[];
    public isLoading: boolean = false;
    public resultsLength = 0;
    public dataSource: Folder[] = [];
    public displayedColumns: ColumnInformation[] = [
        new ColumnInformation('id', '#', 'number', 'id'),
        new ColumnInformation('name', 'Name', 'text', 'name'),
    ];

    constructor(public folderService: FolderService,
                public router: Router,
                public translator: TranslateService,
                public dialog: MatDialog,
                public versionService: VersionService) {

    }

    ngOnInit() {

    }

    /**
     *
     */
    ngAfterViewInit() {
        const me = this;

        this.table.editClick.subscribe((folder: Folder) => {
            me.router.navigate(['/companies/folders/' + folder.id], {
                queryParams: {
                    versionId: this.version.id,
                    parentFolderId: ((this.parentFolder && this.parentFolder.id > 0) ? this.parentFolder.id : null),
                    breadcrumbs: this.breadcrumbs
                }
            });
        });

        this.table.openClick.subscribe((folder: Folder) => {
            me.router.navigate(['/companies/folders/' + folder.id + '/subFolders'], {
                queryParams: {
                    versionId: this.version.id,
                    breadcrumbs: this.breadcrumbs
                }
            });
        });

        this.table.deleteClick.subscribe((folder: Folder) => {
            this.deleteFolder(folder);
        });

        this.table.loaded.subscribe(() => {
            this.table.sortChange.subscribe((data: { column: string, direction: string }) => {
                this.paginator.pageIndex = 0;
                this.load(data.column, data.direction);
            });
            this.paginator.page.subscribe(() => {
                this.load();
            });

            this.table.copyClick.subscribe((folder: Folder) => {
                console.log('hier');
                this.copyFolder(folder);
            });

            this.load();
        });
    }

    /**
     * deletes the given folder
     *
     * @param folder the folder to delete
     */
    private deleteFolder(folder: Folder) {
        this.translator.get('folders_DeleteAction_MessageContent').subscribe((messageTranslation: string) => {
            this.translator.get('folders_DeleteAction_TitleContent').subscribe((titleTranslation: string) => {
                const dialogRef = this.dialog.open(LwsDialogComponent, {
                    data: {
                        cancelButton: true,
                        okButton: true,

                        message: messageTranslation,
                        title: titleTranslation
                    }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result === 'ok') {
                        this.isLoading = true;

                        this.folderService.delete(folder.id).then(success => {
                            this.isLoading = false;

                            this.load();
                        });
                    }
                });
            });
        });
    }

    /**
     * load the table-service content
     */
    private load(column: string = '', direction: string = '') {
        this.loadFolders(this.version, this.parentFolder, this.segment, column, direction, this.paginator.pageIndex);
    }

    /**
     *
     * @param version the requested version
     * @param parentFolder the requested parentFolder
     * @param segment the requested segment
     * @param column the
     * @param direction the
     * @param page the page
     */
    public loadFolders(version: Version, parentFolder: Folder, segment: Segment, column: string, direction: string, page: number) {
        this.isLoading = true;

        const sort: string = LwsTableComponent.getSortColumnByName(this.displayedColumns, column);
        const sortDesc: boolean = ((direction.toLowerCase() === 'desc') ? true : false);

        if (parentFolder == null) {
            const versionId: number = version.id;
            const segmentId: number = segment.id;

            this.versionService.getFolders(versionId, segmentId, page, this.paginator.pageSize, sort, sortDesc).then(
                (result: { items: Folder[], numberOfAllItems: number }) => {
                    this.isLoading = false;

                    if (result.items) {
                        this.dataSource = result.items;
                        this.resultsLength = result.numberOfAllItems;
                    }
                },
                (error) => {
                    this.isLoading = false;
                });
        } else {
            this.folderService.getSubfolders(parentFolder.id, page, this.paginator.pageSize, sort, sortDesc).then(
                (result: { items: Folder[], numberOfAllItems: number }) => {
                    this.isLoading = false;

                    if (result.items) {
                        this.dataSource = result.items;
                        this.resultsLength = result.numberOfAllItems;
                    }
                },
                (error) => {
                    this.isLoading = false;
                });
        }
    }

    private copyFolder(folder: Folder) {
        console.log('hier2');

        this.router.navigateByUrl('/companies/folders/' + folder.id + '/copy');
    }
}
