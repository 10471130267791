
<gridster-item [item]="gadget">
    <mat-toolbar>
        <span>{{gadget.name}}</span>
        <span class="spacer"></span>

        <button (mousedown)="configure()" *ngIf="isConfigureable">
            <mat-icon color="primary">settings</mat-icon>
        </button>
        <button (mousedown)="removeItem()" *ngIf="isConfigureable">
            <mat-icon color="primary">delete</mat-icon>
        </button>
    </mat-toolbar>

    <app-answeres-company-market
            *ngIf="gadget.type == 'TYPE_ANSWERES_COMPANY_MARKET' && !isConfigure"
            [gadget]="gadget"
            [isFiltereable]="isFiltereable"
            (filterChanged)="saveConfiguration()"></app-answeres-company-market>

    <app-answer-list
            *ngIf="gadget.type == 'TYPE_ANSWER_LIST' && !isConfigure"
            [gadget]="gadget"
            [isFiltereable]="isFiltereable"
            (filterChanged)="saveConfiguration()"></app-answer-list>

    <app-portfolio
            *ngIf="gadget.type == 'TYPE_PORTFOLIO' && !isConfigure"
            [gadget]="gadget"
            [isFiltereable]="isFiltereable"
            (filterChanged)="saveConfiguration()"></app-portfolio>
    <app-measure-evaluation  *ngIf="gadget.type == 'TYPE_MEASURES' && !isConfigure"
                             [gadget]="gadget"
                             [isFiltereable]="isFiltereable"
                             (filterChanged)="saveConfiguration()"></app-measure-evaluation>
    <app-bwa-evaluation  *ngIf="gadget.type == 'TYPE_BWA' && !isConfigure"
                             [gadget]="gadget"
                             [isFiltereable]="isFiltereable"
                             (filterChanged)="saveConfiguration()"></app-bwa-evaluation>

    <app-question-export  *ngIf="gadget.type == 'TYPE_EXPORT_QUESTIONS' && !isConfigure"
                         [gadget]="gadget"
                         [isFiltereable]="isFiltereable"
                         (filterChanged)="saveConfiguration()"></app-question-export>

    <app-answers-risk-compliance
        *ngIf="gadget.type == 'TYPE_ANSWERES_RISK_COMPLIANCE' && !isConfigure"
        [gadget]="gadget"
        [isFiltereable]="isFiltereable"
        (filterChanged)="saveConfiguration()"></app-answers-risk-compliance>


    <app-reporting-gadget-config *ngIf="isConfigure" (onConfigurationReady)="saveConfiguration()"
                                 [gadget]="gadget"></app-reporting-gadget-config>
</gridster-item>
