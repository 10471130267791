import {Injectable} from '@angular/core';
import {CacheItem} from './cache-item';

@Injectable({
    providedIn: 'root'
})
export class CacheService {
    
    private _cacheItems: CacheItem[] = [];
    
    constructor() {
    
    }
    
    /**
     *
     * @param key
     * @param value
     */
    public addCacheValue(key: string, value: any) {
        this.removeCacheValue(key);
        
        let cacheItem: CacheItem = new CacheItem();
        cacheItem.cacheKey = key;
        cacheItem.cacheValue = value;
        
        this._cacheItems.push(cacheItem);
    }
    
    /**
     * removes the cache-item by the given key
     *
     * @param key
     */
    public removeCacheValue(key: string) {
        let existingCacheItems = this._cacheItems.filter(x => x.cacheKey.toLocaleLowerCase() == key.toLocaleLowerCase());
        if (existingCacheItems.length < 0) {
            return;
        }
        
        const index = this._cacheItems.indexOf(existingCacheItems[0], 0);
        if (index > -1) {
            this._cacheItems.splice(index, 1);
        }
    }
    
    /**
     * returns the value of the given key
     *
     * @param key
     * @returns
     */
    public getCacheValue(key: string): any {
        let existingCacheItems = this._cacheItems.filter(x => x.cacheKey.toLocaleLowerCase() == key.toLocaleLowerCase());
        if (existingCacheItems.length <= 0) {
            return null;
        }
        
        return existingCacheItems[0].cacheValue;
    }
}
