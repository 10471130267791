import {Component, OnInit} from '@angular/core';
import {BaseGadget} from '../base-gadget';
import {ReportingGadgetConfig} from '../../../../models/reporting-dashboard/reporting-gadget-config';
import {GadgetService} from '../../../../services/server/reporting-dashboard/gadget.service';
import {Data} from '../../../../models/reporting-dashboard/data';
import {formatCurrency} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {EChartsOption} from 'echarts';

@Component({
    selector: 'app-portfolio',
    templateUrl: './portfolio.component.html',
    styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent extends BaseGadget implements OnInit {

    public chartData: EChartsOption = null;
    public xAxisLabel: string;
    public yAxisLabel: string;

    constructor(public gadgetService: GadgetService,
                public translator: TranslateService) {
        super();
    }

    ngOnInit() {
        this.translator.get('marktAttractivity').subscribe(result => this.xAxisLabel = result);
        this.translator.get('competitionStrength').subscribe(result => this.yAxisLabel = result);

    }

    protected loadFilters(configs: ReportingGadgetConfig[]) {
        // nothing to do
    }

    protected saveFilters() {
        // nothing to do
    }

    protected loadGadgetReport() {
        if (this.gadget.id != null && this.gadget.id > 0) {
            this.gadgetService.getResults(this.gadget.companyId, this.gadget.id, this.gadget.config).then((resultData) => {
                    this.chartData = this.generateChartData(resultData);
                },
                () => {

                });
        }
    }

    private generateChartData(dataResults: Data[]): EChartsOption {

        const units: string[] = [];
        const series: any[] = [];

        let maxSales: number = 0;
        dataResults.forEach(data => {
            if (data.dimension && data.dimension > maxSales) {
                maxSales = data.dimension;
            }
        });

        let index = 0;
        dataResults.forEach(data => {
            units.push(data.label);
            series.push(this.buildSeries(data, maxSales, this.getColor(index)));

            index++;
        });

        return {
            legend: {
                right: 10,
                data: units
            },
            xAxis: {
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                },
                min: 0,
                max: 5,
                name: this.xAxisLabel
            },
            yAxis: {
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                },
                scale: true,
                min: 0,
                max: 5,
                name: this.yAxisLabel
            },
            series: series,
            grid: {
                left: '10%',
                bottom: '10%',
                right: '15%',
                top: '20%'
            },
            tooltip: {
                show: true,
                // trigger: 'axis',
                triggerOn: 'mousemove',
                formatter: params => {
                    const formattedSales = formatCurrency(params.data[2], 'de-DE', '€');

                    return '' + params.data[3] + ': ' + formattedSales;
                }
            }
        };
    }

    private buildSeries(dataItem: Data, maxSales: number, dataColor: string) {

        const minSize = 10;
        const fullSize = 100;

        return {
            name: dataItem.label,
            data: [[dataItem.value, dataItem.secondValue, dataItem.dimension, dataItem.label, dataItem.label]],
            type: 'scatter',
            symbolSize: (data) => {

                if (maxSales <= 0) {
                    return minSize;
                } else {
                    const size = data[2] / maxSales * fullSize;

                    if (size < minSize) {
                        return minSize;
                    } else {
                        return size;
                    }
                }
            },
            emphasis: {
                label: {
                    show: true,
                    formatter: (param) => {
                        return param.data[3];
                    },
                    position: 'top'
                }
            },
            itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(120, 36, 50, 0.5)',
                shadowOffsetY: 5,
                color: dataColor
            }
        };
    }

    private getColor(index) {
        const color: string[] = [
            'rgb(255, 204, 0)',
            'rgb(231, 64, 17)',
            'rgb(139, 41, 57)',
            'rgb(232, 225, 0)',
            'rgb(0, 48, 86)',
            'rgb(0, 155, 164)',
            'rgb(0, 110, 183)',
            'rgb(217, 77, 21)',
            'rgb(0, 130, 67)',
            'rgb(135, 189, 37)',
            'rgb(224, 132, 10)'
        ];

        return color[index % 11];
    }

}
