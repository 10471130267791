import {ModelInterface} from '../model-interface';
import {JSONConverter} from '../JSONConverter';
import {Folder} from '../folder/folder';
import {States} from './States';
import {FormBuilder} from '@angular/forms';
import {AccountService} from '../../services/server/account/account.service';
import {Router} from '@angular/router';
import {CompanyService} from '../../services/server/company/company.service';
import {CurrencyPipe, Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {MeasurePlaning} from './measure-planing';
import {MeasureResponsiblePerson} from './measure-responsible-person';

export class BusinessAnalysis implements ModelInterface {

    private _id: number = 0;

    private _revenues: number = 0;

    private _feUe: number = 0;

    private _ownWork: number = 0;

    private _goodsHopping: number = 0;

    private _otherIncome: number = 0;

    private _personnelCosts: number = 0;

    private _roomCosts: number = 0;

    private _companyTax: number = 0;

    private _insuranceCosts: number = 0;

    private _specialCosts: number = 0;

    private _vehicleCosts: number = 0;

    private _marketingCosts: number = 0;

    private _depreciationCosts: number = 0;

    private _depreciation: number = 0;

    private _depreciationPa: number = 0;

    private _repairCosts: number = 0;

    private _otherCosts: number = 0;

    private _interestExpense: number = 0;

    private _otherExpenditure: number = 0;

    private _interestIncome: number = 0;

    private _otherYield: number = 0;

    private _calcCosts: number = 0;

    private _revenuesPa: number = 0;

    private _feUePa: number = 0;

    private _ownWorkPa: number = 0;

    private _goodsHoppingPa: number = 0;

    private _otherIncomePa: number = 0;

    private _personnelCostsPa: number = 0;

    private _roomCostsPa: number = 0;

    private _companyTaxPa: number = 0;

    private _insuranceCostsPa: number = 0;

    private _specialCostsPa: number = 0;

    private _vehicleCostsPa: number = 0;

    private _marketingCostsPa: number = 0;

    private _depreciationCostsPa: number = 0;

    private _repairCostsPa: number = 0;

    private _otherCostsPa: number = 0;

    private _interestExpensePa: number = 0;

    private _otherExpenditurePa: number = 0;

    private _interestIncomePa: number = 0;

    private _otherYieldPa: number = 0;

    private _calcCostsPa: number = 0;

    /*
     * Summ fields
     */
    private _overallPerformance: number = 0;
    private _overallPerformancePa: number = 0;

    private _grossProfit: number = 0;
    private _grossProfitPa: number = 0;

    private _totalOperatinggrossProfit: number = 0;
    private _totalOperatinggrossProfitPa: number = 0;

    private _totalCosts: number = 0;
    private _totalCostsPa: number = 0;

    private _operatingProfit: number = 0;
    private _operatingProfitPa: number = 0;

    private _neutralExpenses: number = 0;
    private _neutralExpensesPa: number = 0;

    private _neutralYield: number = 0;
    private _neutralYieldPa: number = 0;

    private _earningsBeforeTaxes: number = 0;
    private _earningsBeforeTaxesPa: number = 0;


    private _revenuesAsText: string;

    // a list of properties-keys to ignore at jsonconvert
    _ignoreProperties: string[] = [];

    private currencyPipe: CurrencyPipe;

    constructor() {
        this.currencyPipe = new CurrencyPipe('de-DE');
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get revenues(): number {
        return this._revenues;
    }

    set revenues(value: number) {
        this._revenues = value;
    }

    get feUe(): number {
        return this._feUe;
    }

    set feUe(value: number) {
        this._feUe = value;
    }

    get ownWork(): number {
        return this._ownWork;
    }

    set ownWork(value: number) {
        this._ownWork = value;
    }

    get goodsHopping(): number {
        return this._goodsHopping;
    }

    set goodsHopping(value: number) {
        this._goodsHopping = value;
    }

    get otherIncome(): number {
        return this._otherIncome;
    }

    set otherIncome(value: number) {
        this._otherIncome = value;
    }

    get personnelCosts(): number {
        return this._personnelCosts;
    }

    set personnelCosts(value: number) {
        this._personnelCosts = value;
    }

    get roomCosts(): number {
        return this._roomCosts;
    }

    set roomCosts(value: number) {
        this._roomCosts = value;
    }

    get companyTax(): number {
        return this._companyTax;
    }

    set companyTax(value: number) {
        this._companyTax = value;
    }

    get insuranceCosts(): number {
        return this._insuranceCosts;
    }

    set insuranceCosts(value: number) {
        this._insuranceCosts = value;
    }

    get specialCosts(): number {
        return this._specialCosts;
    }

    set specialCosts(value: number) {
        this._specialCosts = value;
    }

    get vehicleCosts(): number {
        return this._vehicleCosts;
    }

    set vehicleCosts(value: number) {
        this._vehicleCosts = value;
    }

    get marketingCosts(): number {
        return this._marketingCosts;
    }

    set marketingCosts(value: number) {
        this._marketingCosts = value;
    }

    get depreciationCosts(): number {
        return this._depreciationCosts;
    }

    set depreciationCosts(value: number) {
        this._depreciationCosts = value;
    }

    get repairCosts(): number {
        return this._repairCosts;
    }

    set repairCosts(value: number) {
        this._repairCosts = value;
    }

    get otherCosts(): number {
        return this._otherCosts;
    }

    set otherCosts(value: number) {
        this._otherCosts = value;
    }

    get interestExpense(): number {
        return this._interestExpense;
    }

    set interestExpense(value: number) {
        this._interestExpense = value;
    }

    get otherExpenditure(): number {
        return this._otherExpenditure;
    }

    set otherExpenditure(value: number) {
        this._otherExpenditure = value;
    }

    get interestIncome(): number {
        return this._interestIncome;
    }

    set interestIncome(value: number) {
        this._interestIncome = value;
    }

    get otherYield(): number {
        return this._otherYield;
    }

    set otherYield(value: number) {
        this._otherYield = value;
    }

    get calcCosts(): number {
        return this._calcCosts;
    }

    set calcCosts(value: number) {
        this._calcCosts = value;
    }

    get revenuesPa(): number {
        return this._revenuesPa;
    }

    set revenuesPa(value: number) {
        this._revenuesPa = value;
    }

    get feUePa(): number {
        return this._feUePa;
    }

    set feUePa(value: number) {
        this._feUePa = value;
    }

    get ownWorkPa(): number {
        return this._ownWorkPa;
    }

    set ownWorkPa(value: number) {
        this._ownWorkPa = value;
    }

    get goodsHoppingPa(): number {
        return this._goodsHoppingPa;
    }

    set goodsHoppingPa(value: number) {
        this._goodsHoppingPa = value;
    }

    get otherIncomePa(): number {
        return this._otherIncomePa;
    }

    set otherIncomePa(value: number) {
        this._otherIncomePa = value;
    }

    get personnelCostsPa(): number {
        return this._personnelCostsPa;
    }

    set personnelCostsPa(value: number) {
        this._personnelCostsPa = value;
    }

    get roomCostsPa(): number {
        return this._roomCostsPa;
    }

    set roomCostsPa(value: number) {
        this._roomCostsPa = value;
    }

    get companyTaxPa(): number {
        return this._companyTaxPa;
    }

    set companyTaxPa(value: number) {
        this._companyTaxPa = value;
    }

    get insuranceCostsPa(): number {
        return this._insuranceCostsPa;
    }

    set insuranceCostsPa(value: number) {
        this._insuranceCostsPa = value;
    }

    get specialCostsPa(): number {
        return this._specialCostsPa;
    }

    set specialCostsPa(value: number) {
        this._specialCostsPa = value;
    }

    get vehicleCostsPa(): number {
        return this._vehicleCostsPa;
    }

    set vehicleCostsPa(value: number) {
        this._vehicleCostsPa = value;
    }

    get marketingCostsPa(): number {
        return this._marketingCostsPa;
    }

    set marketingCostsPa(value: number) {
        this._marketingCostsPa = value;
    }

    get depreciationCostsPa(): number {
        return this._depreciationCostsPa;
    }

    set depreciationCostsPa(value: number) {
        this._depreciationCostsPa = value;
    }

    get repairCostsPa(): number {
        return this._repairCostsPa;
    }

    set repairCostsPa(value: number) {
        this._repairCostsPa = value;
    }

    get otherCostsPa(): number {
        return this._otherCostsPa;
    }

    set otherCostsPa(value: number) {
        this._otherCostsPa = value;
    }

    get interestExpensePa(): number {
        return this._interestExpensePa;
    }

    set interestExpensePa(value: number) {
        this._interestExpensePa = value;
    }

    get otherExpenditurePa(): number {
        return this._otherExpenditurePa;
    }

    set otherExpenditurePa(value: number) {
        this._otherExpenditurePa = value;
    }

    get interestIncomePa(): number {
        return this._interestIncomePa;
    }

    set interestIncomePa(value: number) {
        this._interestIncomePa = value;
    }

    get otherYieldPa(): number {
        return this._otherYieldPa;
    }

    set otherYieldPa(value: number) {
        this._otherYieldPa = value;
    }

    get calcCostsPa(): number {
        return this._calcCostsPa;
    }

    set calcCostsPa(value: number) {
        this._calcCostsPa = value;
    }

    get depreciation(): number {
        return this._depreciation;
    }

    set depreciation(value: number) {
        this._depreciation = value;
    }

    get overallPerformance(): number {
        return this._overallPerformance;
    }

    set overallPerformance(value: number) {
        this._overallPerformance = value;
    }

    get overallPerformancePa(): number {
        return this._overallPerformancePa;
    }

    set overallPerformancePa(value: number) {
        this._overallPerformancePa = value;
    }

    get grossProfit(): number {
        return this._grossProfit;
    }

    set grossProfit(value: number) {
        this._grossProfit = value;
    }

    get grossProfitPa(): number {
        return this._grossProfitPa;
    }

    set grossProfitPa(value: number) {
        this._grossProfitPa = value;
    }

    get totalOperatinggrossProfit(): number {
        return this._totalOperatinggrossProfit;
    }

    set totalOperatinggrossProfit(value: number) {
        this._totalOperatinggrossProfit = value;
    }

    get totalOperatinggrossProfitPa(): number {
        return this._totalOperatinggrossProfitPa;
    }

    set totalOperatinggrossProfitPa(value: number) {
        this._totalOperatinggrossProfitPa = value;
    }

    get totalCosts(): number {
        return this._totalCosts;
    }

    set totalCosts(value: number) {
        this._totalCosts = value;
    }

    get totalCostsPa(): number {
        return this._totalCostsPa;
    }

    set totalCostsPa(value: number) {
        this._totalCostsPa = value;
    }

    get operatingProfit(): number {
        return this._operatingProfit;
    }

    set operatingProfit(value: number) {
        this._operatingProfit = value;
    }

    get operatingProfitPa(): number {
        return this._operatingProfitPa;
    }

    set operatingProfitPa(value: number) {
        this._operatingProfitPa = value;
    }

    get neutralExpenses(): number {
        return this._neutralExpenses;
    }

    set neutralExpenses(value: number) {
        this._neutralExpenses = value;
    }

    get neutralExpensesPa(): number {
        return this._neutralExpensesPa;
    }

    set neutralExpensesPa(value: number) {
        this._neutralExpensesPa = value;
    }

    get neutralYield(): number {
        return this._neutralYield;
    }

    set neutralYield(value: number) {
        this._neutralYield = value;
    }

    get neutralYieldPa(): number {
        return this._neutralYieldPa;
    }

    set neutralYieldPa(value: number) {
        this._neutralYieldPa = value;
    }

    get earningsBeforeTaxes(): number {
        return this._earningsBeforeTaxes;
    }

    set earningsBeforeTaxes(value: number) {
        this._earningsBeforeTaxes = value;
    }

    get earningsBeforeTaxesPa(): number {
        return this._earningsBeforeTaxesPa;
    }

    set earningsBeforeTaxesPa(value: number) {
        this._earningsBeforeTaxesPa = value;
    }

    get depreciationPa(): number {
        return this._depreciationPa;
    }

    set depreciationPa(value: number) {
        this._depreciationPa = value;
    }

    public calcOverallPerformance(): void {
        this.overallPerformance = this.revenues + this.feUe + this.ownWork;
    }

    public calcOverallPerformancePa(): void {
        this.overallPerformancePa = this.revenuesPa + this.feUePa + this.ownWorkPa;
    }

    public calcGrossProfit() {
        this.grossProfit = this.overallPerformance - this.goodsHopping;
    }

    public calcGrossProfitPa() {
        this.grossProfitPa = this.overallPerformancePa - this.goodsHoppingPa;
    }

    public calcTotalOperatinggrossProfit() {
        this.totalOperatinggrossProfit = this.grossProfit + this.otherIncome;
    }

    public calcTotalOperatinggrossProfitPa() {
        this.totalOperatinggrossProfitPa = this.grossProfitPa + this.otherIncomePa;
    }

    public calcTotalCosts() {
        this.totalCosts = this.personnelCosts
            + this.roomCosts
            + this.companyTax
            + this.insuranceCosts
            + this.specialCosts
            + this.vehicleCosts
            + this.marketingCosts
            + this.depreciationCosts
            + this.depreciation
            + this.repairCosts
            + this.otherCosts;
    }

    public calcTotalCostsPa() {
        this.totalCostsPa = this.personnelCostsPa
            + this.roomCostsPa
            + this.companyTaxPa
            + this.insuranceCostsPa
            + this.specialCostsPa
            + this.vehicleCostsPa
            + this.marketingCostsPa
            + this.depreciationCostsPa
            + this.depreciationPa
            + this.repairCostsPa
            + this.otherCostsPa;
    }

    public calcOperatingProfit() {
        this.operatingProfit = this.totalOperatinggrossProfit - this.totalCosts;
    }

    public calcOperatingProfitPa() {
        this.operatingProfitPa = this.totalOperatinggrossProfitPa - this.totalCostsPa;
    }

    public calcNeutralExpenses() {
        this.neutralExpenses = this.interestExpense + this.otherExpenditure;
    }

    public calcNeutralExpensesPa() {
        this.neutralExpensesPa = this.interestExpensePa + this.otherExpenditurePa;
    }

    public calcNeutralYield() {
        this.neutralYield = this.interestIncome + this.otherYield + this.calcCosts;
    }

    public calcNeutralYieldPa() {
        this.neutralYieldPa = this.interestIncomePa + this.otherYieldPa + this.calcCostsPa;
    }

    public calcEarningsBeforeTaxes() {
        this.earningsBeforeTaxes = this.operatingProfit + this.neutralYield - this.neutralExpenses;
    }

    public calcEarningsBeforeTaxesPa() {
        this.earningsBeforeTaxesPa = this.operatingProfitPa + this.neutralYieldPa - this.neutralExpensesPa;
    }

    calcSums() {
        this.calcOverallPerformance();
        this.calcOverallPerformancePa();

        this.calcGrossProfit();
        this.calcGrossProfitPa();

        this.calcTotalOperatinggrossProfit();
        this.calcTotalOperatinggrossProfitPa();

        this.calcTotalCosts();
        this.calcTotalCostsPa();

        this.calcOperatingProfit();
        this.calcOperatingProfitPa();

        this.calcNeutralExpenses();
        this.calcNeutralExpensesPa();

        this.calcNeutralYield();
        this.calcNeutralYieldPa();

        this.calcEarningsBeforeTaxes();
        this.calcEarningsBeforeTaxesPa();
    }

    /**
     * returns the object as a valid json (without the underscore a the property name begin)
     */
    toJSON() {
        return new JSONConverter().toJSON(this);
    }

    /**
     * creates a object from the given data
     *
     * @param data the data to create a model
     */
    static hydrate(data: any): BusinessAnalysis {
        const model: BusinessAnalysis = new BusinessAnalysis();

        Object.assign(model, data);

        return model;
    }

    get revenuesAsText(): string {
        if (this.revenues != null) {
            this._revenuesAsText = this.currencyPipe.transform(this.revenues);
            return this._revenuesAsText;
        } else {
            return '';
        }
    }

    set revenuesAsText(value: string) {
        if (value != null) {

            value = value
                .replace(' ', '')
                .replace('.', '')
                .replace(',', '.')
                .replace('€', '.');

            let finalValue = null;
            if (value.match(/([0-9]|\.|-)/) != null) {

                finalValue = Number(value);
            }

            const me = this;
            const interval = window.setTimeout(() => {
                me.revenues = finalValue;
            }, 500);

        } else {
            this.revenues = null;
        }

        this._revenuesAsText = value;
    }
}
