<section>

    <app-lws-detail-header [title]="title"></app-lws-detail-header>

    <main>
        <app-companies-list *ngIf="company == null" [enableOpenAction]="true"></app-companies-list>
        <app-assessment-overview *ngIf="company != null" [company]="company"></app-assessment-overview>

    </main>

</section>
