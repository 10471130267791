export class TruncateBreakLongStringFormatter {

    static format(value: string): string {

        if (value.length > 50) {

            const words: string[] = value.split(' ');
            let formattedLabel: string = '';
            let charCounter = 0;
            let i = 0;
            words.forEach(word => {
                if (i > 0) {
                    formattedLabel += ' ';
                }
                if (charCounter + word.length > 50) {
                    formattedLabel += '\n';
                    charCounter = 0;
                }
                formattedLabel += word;
                charCounter += word.length;
                i++;
            });
            return formattedLabel;
        } else {
            return value;
        }

    }



}
