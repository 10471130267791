<mat-toolbar class="filter-panel" *ngIf="isFiltereable">
  <mat-form-field class="question--detail-selection-field">
    <mat-label>{{'Filter'|translate}}</mat-label>
    <mat-select [(ngModel)]="selectedFilterType"
                color="primary"
                (selectionChange)="filterSelectionChanged()">
      <mat-option *ngFor="let entry of filterType"
                  [value]="entry">
        {{entry.title|translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <app-lws-table [datasource]="dataSource"
                 [displayedColumns]="displayedColumns"
                 [layout]="'full'"
                 [enableActions]="true"
                 [enableOpenAction]="true"
  (openClick)="openQuestionDetail"></app-lws-table>
  <mat-paginator [pageSizeOptions]="[25, 50, 75]" [length]="resultsLength"></mat-paginator>
</mat-toolbar>
