import {Injectable} from '@angular/core';
import {AccountService} from '../account/account.service';
import {User} from '../../../models/user/user';
import {RestService} from '../rest/rest.service';
import {RequestBodyType} from '../rest/request-body-type.enum';
import {EventService} from '../../client/event/event.service';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {ErrorHandlingService} from '../../client/error-handling/error-handling.service';
import {Measure} from '../../../models/measure/measure';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private accountService: AccountService,
                private restService: RestService,
                private eventService: EventService,
                private snackBar: MatSnackBar,
                private translator: TranslateService,
                private errorHandlingService: ErrorHandlingService) {

    }

    /**
     * returns all users
     *
     * @param page the requested page index
     * @param count the number of items per page
     * @param sort the column to sort
     * @param sortDesc should the sort be descending
     */
    public getAll(page: number = 0, count: number = 50, sort: string = null, sortDesc: boolean = false): Promise<User[]> {
        const parameter: { key: string, value: string }[] = [];
        if (page && page > 0) {
            parameter.push({key: 'page', value: page.toString()});
        }
        if (count && count > 0) {
            parameter.push({key: 'count', value: count.toString()});
        }
        if (sort && sort.length > 0) {
            parameter.push({key: 'sortColumn', value: sort.toString()});
            parameter.push({key: 'sortDesc', value: sortDesc.toString()});
        }

        return this.restService.get('/users', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const users: User[] = [];

                for (const responseItem of response) {
                    users.push(User.hydrate(responseItem));
                }

                return users;
            },
            err => {
                return null;
            });
    }

    /**
     * changes the password of the current user
     *
     * @param currentPassword the current password as plain string to validate the action
     * @param password the new password as plainstring
     */
    public changePassword(currentPassword: string, password: string): Promise<boolean> {
        const user: User = this.accountService.User;
        const parameter: { key: string, value: string }[] = [];
        parameter.push({key: 'currentPassword', value: currentPassword});
        parameter.push({key: 'newPassword', value: password});

        return this.restService.postForm(
            '/users/' + user.id + '/changePassword',
            parameter,
            RequestBodyType.FormUrlEncoded,
            true).toPromise()
        .then((response: any) => {
            this.translator.get('changePasswordComponent_PasswordChangeRequest_SuccessMessage').subscribe((translation: string) => {
                this.snackBar.open(translation, '', {
                    duration: (5 * 1000), // display 5 seconds
                    panelClass: 'snackbar-lws-success'
                });
            });

            this.eventService.Trigger('account:changePassword:success');

            return true;
        }).catch((error: HttpErrorResponse) => {
            this.errorHandlingService.handleError('changePasswordComponent_PasswordChangeRequest', error);

            this.eventService.Trigger('account:changePassword:error', error);

            return false;
        });
    }

    /**
     * deletes the user with the given id
     *
     * @param id the id of the user to delete
     */
    public delete(id: number): Promise<boolean> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.delete(
            '/users/' + id,
            parameter,
            true).toPromise()
        .then((response: any) => {
            return true;
        }).catch((httpError: HttpErrorResponse) => {
            this.errorHandlingService.handleError('userService_DeleteRequest', httpError);

            return false;
        });
    }

    /**
     * saves the given company
     *
     * @param user the company to save
     */
    public save(user: User): Promise<number> {
        return this.restService.post(
            '/users/' + user.id,
            user,
            true).toPromise()
        .then((id: number) => {
            this.eventService.Trigger('user:create:success', id);

            this.translator.get('userService_SaveRequest_SuccessMessage').subscribe((translation: string) => {
                this.snackBar.open(translation, '', {
                    duration: (5 * 1000), // display 5 seconds
                    panelClass: 'snackbar-lws-success'
                });
            });

            return id;
        }).catch((httpError: HttpErrorResponse) => {
            this.errorHandlingService.handleError('userService_SaveRequest', httpError);

            return null;
        });
    }

    /**
     * returns the requested user
     *
     * @param id the id of the requested user
     */
    public getDetail(id: number): Promise<User> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.get('/users/' + id, parameter).toPromise().then(
            (result) => {
                const user: User = User.hydrate(result.body);

                return user;
            },
            (httpError: HttpErrorResponse) => {
                this.errorHandlingService.handleError('userService_DetailRequest', httpError);

                return null;
            });
    }

    /**
     * returns all users
     *
     * @param page the requested page index
     * @param count the number of items per page
     * @param sort the column to sort
     * @param sortDesc should the sort be descending
     */
    public getAssignedMeasures(userId: number, page: number = 0, count: number = 25, sort: string = null, sortDesc: boolean = false): Promise<{
        items: Measure[],
        numberOfAllItems: number
    }> {
        const parameter: { key: string, value: string }[] = [];

        if (page && page > 0) {
            parameter.push({key: 'page', value: page.toString()});
        }
        if (count && count > 0) {
            parameter.push({key: 'count', value: count.toString()});
        }
        if (sort && sort.length > 0) {
            parameter.push({key: 'sortColumn', value: sort.toString()});
            parameter.push({key: 'sortDesc', value: sortDesc.toString()});
        }

        return this.restService.get('/users/' + userId + '/assignedMeasures', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const measures: Measure[] = [];

                for (const responseItem of response) {
                    measures.push(Measure.hydrate(responseItem));
                }

                return {
                    items: measures,
                    numberOfAllItems: result.headers.get('x-items-count')
                };
            },
            err => {
                return null;
            });
    }
}
