import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Listing} from '../../../businesslogic/listing/Listing';
import {ColumnInformation} from '../../../models/lws-table/column-information';
import {Router} from '@angular/router';
import {MeasureService} from '../../../services/server/measure/measure.service';
import {AccountService} from '../../../services/server/account/account.service';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../services/server/user/user.service';
import {Measure} from '../../../models/measure/measure';
import {Company} from '../../../models/company/company';
import {LockType} from '../../../services/server/lock/lock-type.enum';
import {LockService} from '../../../services/server/lock/lock.service';

@Component({
    selector: 'app-assigned-measures',
    templateUrl: './assigned-measures.component.html',
    styleUrls: ['./assigned-measures.component.scss']
})
export class AssignedMeasuresComponent extends Listing implements OnInit, AfterViewInit {
    @Input() public company: Company = null;
    public selectedMeasure: Measure = null;
    public isReadOnlyView: boolean = false;

    /**
     * the columns of the table to display
     */
    public displayedColumns: ColumnInformation[] = [
        new ColumnInformation('name', 'Name', 'text', 'name'),
        new ColumnInformation('targetDescription', 'Ziel', 'text', 'targetDescription'),
        new ColumnInformation('stateName', 'Status', 'text', 'state'),
    ];

    constructor(public router: Router,
                public accountService: AccountService,
                public measureService: MeasureService,
                public userService: UserService,
                public dialog: MatDialog,
                public labelTranslator: TranslateService,
                public lockService: LockService) {

        super(dialog, labelTranslator);
    }

    ngAfterViewInit(): void {
        this.init();
        this.table.editClick.subscribe((measure: Measure) => {
            this.lockService.lock(measure.id, LockType.MEASURE).then(
                (isLockSuccess: boolean) => {
                    if (isLockSuccess) {
                        this.isReadOnlyView = false;
                    } else {
                        this.isReadOnlyView = true;
                    }

                    this.measureSelected(measure);
                }
            );
        });
    }

    ngOnInit() {
    }

    /**
     * Returns the service which is used to access the listing
     */
    protected getListService() {
        return this.userService;
    }

    /**
     * Returns the service which is used to work with the detail items of the list
     */
    protected getDetailService() {
        return this.measureService;
    }

    /**
     * Method which is used to load the data
     */
    protected loadData(pageIndex: number, itemsPerPage: number, sortColumn: string, sortDesc: boolean) {
        this.isLoading = true;
        this.getListService().getAssignedMeasures(this.accountService.User.id, pageIndex, itemsPerPage, sortColumn, sortDesc).then(
            (result: { items: Measure[], numberOfAllItems: number }) => {
                this.isLoading = false;
                for (const meeasure of result.items) {

                    this.labelTranslator.get(meeasure.getTechnicalStateName()).subscribe(
                        (translation: string) => {
                            meeasure.stateName = translation;
                        });
                }

                this.showItems(result);
            },
            () => {
                this.isLoading = false;
            }
        );
    }

    /**
     * Creates a new model and only returns the model.
     * e.g. return new Measure();
     */
    protected createNewModelInstance() {
        return null;
    }

    /**
     * Returns the visible columns
     */
    protected getDisplayedColumns() {
        return this.displayedColumns;
    }

    /**
     * deletes the given item
     */
    protected delete(selection) {
        // not implemented
    }

    /**
     * moves the given item
     */
    protected move(selection) {
        // not implemented
    }

    /**
     * executed if the list returns the selected measure
     *
     * @param measure the selected measure model
     */
    private measureSelected(measure: Measure) {
        this.selectedMeasure = measure;
    }

    /**
     * executed if the measure is saved
     */
    private measureSaved() {
        this.selectedMeasure = null;
        this.refresh();
    }

}