import {ModelInterface} from '../model-interface';
import {JSONConverter} from '../JSONConverter';
import {Folder} from '../folder/folder';
import {Question} from '../question/question';

export class Answer implements ModelInterface {

    private _id: number = 0;
    private _folder: Folder = null;
    private _question: Question = null;
    private _rating: number = null;
    private _factor: number = null;
    private _isNotRelevant: boolean = false;
    private _chanceRisk: number = null;
    private _powerWeakness: number = null;
    private _selectionSuccessFactor: string = '';
    private _riskAvoid: number = 0;
    private _riskReduce: number = 0;
    private _riskShuffleOff: number = 0;
    private _riskTakeOver: number = 0;
    private _hazardousExistence: boolean = false;
    private _amountOfDamage: string = '';
    private _relevance: number = 0;
    private _probabilityOfOccurrence: number;
    
    // a list of properties-keys to ignore at jsonconvert
    _ignoreProperties: string[] = [];
    
    private _damageCosts: boolean;
    private _damageCompetitiveness: boolean;
    private _damageQuality: boolean;
    private _damageSales: boolean;
    private _damageDissatisfaction: boolean;
    private _damageCriminalProceedings: boolean;
    private _damagePecuniaryLoss: boolean;
    private _damageConsistedHazard: boolean;
    private _damageEnvironmental: boolean;
    private _damageMotivationLoss: boolean;
    private _damageThirdHarm: boolean;
    private _damagePeopleHurt: boolean;
    private _damageProperty: boolean;
    private _damageImage: boolean;
    private _damageProfit: boolean;
    
    constructor() {
    
    }
    
    get id(): number {
        return this._id;
    }
    
    set id(value: number) {
        this._id = value;
    }
    
    get folder(): Folder {
        return this._folder;
    }
    
    set folder(value: Folder) {
        this._folder = value;
    }
    
    get question(): Question {
        return this._question;
    }
    
    set question(value: Question) {
        this._question = value;
    }
    
    get rating(): number {
        return this._rating;
    }
    
    set rating(value: number) {
        this._rating = value;
    }
    
    get isNotRelevant(): boolean {
        return this._isNotRelevant;
    }
    
    set isNotRelevant(value: boolean) {
        this._isNotRelevant = value;
    }
    
    get chanceRisk(): number {
        return this._chanceRisk;
    }
    
    set chanceRisk(value: number) {
        this._chanceRisk = value;
    }
    
    get powerWeakness(): number {
        return this._powerWeakness;
    }
    
    set powerWeakness(value: number) {
        this._powerWeakness = value;
    }
    
    get selectionSuccessFactor(): string {
        return this._selectionSuccessFactor;
    }
    
    set selectionSuccessFactor(value: string) {
        this._selectionSuccessFactor = value;
    }
    
    get riskAvoid(): number {
        return this._riskAvoid;
    }
    
    set riskAvoid(value: number) {
        this._riskAvoid = value;
    }
    
    get riskReduce(): number {
        return this._riskReduce;
    }
    
    set riskReduce(value: number) {
        this._riskReduce = value;
    }
    
    get riskShuffleOff(): number {
        return this._riskShuffleOff;
    }
    
    set riskShuffleOff(value: number) {
        this._riskShuffleOff = value;
    }
    
    get riskTakeOver(): number {
        return this._riskTakeOver;
    }
    
    set riskTakeOver(value: number) {
        this._riskTakeOver = value;
    }
    
    get hazardousExistence(): boolean {
        return this._hazardousExistence;
    }
    
    set hazardousExistence(value: boolean) {
        this._hazardousExistence = value;
    }
    
    get amountOfDamage(): string {
        return this._amountOfDamage;
    }
    
    set amountOfDamage(value: string) {
        this._amountOfDamage = value;
    }
    
    get ignoreProperties(): string[] {
        return this._ignoreProperties;
    }
    
    set ignoreProperties(value: string[]) {
        this._ignoreProperties = value;
    }
    
    get damageCosts(): boolean {
        return this._damageCosts;
    }
    
    set damageCosts(value: boolean) {
        this._damageCosts = value;
    }
    
    get damageCompetitiveness(): boolean {
        return this._damageCompetitiveness;
    }
    
    set damageCompetitiveness(value: boolean) {
        this._damageCompetitiveness = value;
    }
    
    get damageQuality(): boolean {
        return this._damageQuality;
    }
    
    set damageQuality(value: boolean) {
        this._damageQuality = value;
    }
    
    get damageSales(): boolean {
        return this._damageSales;
    }
    
    set damageSales(value: boolean) {
        this._damageSales = value;
    }
    
    get damageDissatisfaction(): boolean {
        return this._damageDissatisfaction;
    }
    
    set damageDissatisfaction(value: boolean) {
        this._damageDissatisfaction = value;
    }
    
    get damageCriminalProceedings(): boolean {
        return this._damageCriminalProceedings;
    }
    
    set damageCriminalProceedings(value: boolean) {
        this._damageCriminalProceedings = value;
    }
    
    get damagePecuniaryLoss(): boolean {
        return this._damagePecuniaryLoss;
    }
    
    set damagePecuniaryLoss(value: boolean) {
        this._damagePecuniaryLoss = value;
    }
    
    get damageConsistedHazard(): boolean {
        return this._damageConsistedHazard;
    }
    
    set damageConsistedHazard(value: boolean) {
        this._damageConsistedHazard = value;
    }
    
    get damageEnvironmental(): boolean {
        return this._damageEnvironmental;
    }
    
    set damageEnvironmental(value: boolean) {
        this._damageEnvironmental = value;
    }
    
    get damageMotivationLoss(): boolean {
        return this._damageMotivationLoss;
    }
    
    set damageMotivationLoss(value: boolean) {
        this._damageMotivationLoss = value;
    }
    
    get damageThirdHarm(): boolean {
        return this._damageThirdHarm;
    }
    
    set damageThirdHarm(value: boolean) {
        this._damageThirdHarm = value;
    }
    
    get damagePeopleHurt(): boolean {
        return this._damagePeopleHurt;
    }
    
    set damagePeopleHurt(value: boolean) {
        this._damagePeopleHurt = value;
    }
    
    get damageProperty(): boolean {
        return this._damageProperty;
    }
    
    set damageProperty(value: boolean) {
        this._damageProperty = value;
    }
    
    get damageImage(): boolean {
        return this._damageImage;
    }
    
    set damageImage(value: boolean) {
        this._damageImage = value;
    }
    
    get damageProfit(): boolean {
        return this._damageProfit;
    }
    
    set damageProfit(value: boolean) {
        this._damageProfit = value;
    }
    
    get factor(): number {
        return this._factor;
    }
    
    set factor(value: number) {
        this._factor = value;
    }

    get relevance(): number {
        return this._relevance;
    }

    set relevance(value: number) {
        this._relevance = value;
    }

    get probabilityOfOccurrence(): number {
        return this._probabilityOfOccurrence;
    }

    set probabilityOfOccurrence(value: number) {
        this._probabilityOfOccurrence = value;
    }
    
    /**
     * returns the object as a valid json (without the underscore a the property name begin)
     */
    toJSON() {
        return new JSONConverter().toJSON(this);
    }
    
    /**
     * creates a object from the given data
     *
     * @param data the data to create a model
     */
    static hydrate(data: any): Answer {
        const model: Answer = new Answer();
        
        Object.assign(model, data);
        
        return model;
    }

}
