import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LwsTableComponent} from '../lws-table/lws-table.component';
import {Company} from '../../models/company/company';
import {LwsDialogComponent} from '../lws-dialog/lws-dialog.component';
import {UserService} from '../../services/server/user/user.service';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {User} from '../../models/user/user';
import {CompanyService} from '../../services/server/company/company.service';
import {ColumnInformation} from '../../models/lws-table/column-information';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit, AfterViewInit {
    @ViewChild(LwsTableComponent) public table: LwsTableComponent;
    @ViewChild(MatPaginator) public paginator: MatPaginator;
    @Input() public company: Company;
    @Input() public breadcrumbs: string[];
    public isLoading: boolean = false;
    /**
     * the length of the current loading
     */
    public resultsLength = 0;
    /**
     * the datasource
     */
    public dataSource: User[] = [];
    /**
     * the columns of the table to display
     */
    public displayedColumns: ColumnInformation[] = [
        new ColumnInformation('id', '#', 'number', 'id'),
        new ColumnInformation('name', 'Name', 'text', 'name'),
        new ColumnInformation('username', 'Benutzername', 'text', 'firstname'),
    ];

    constructor(public router: Router,
                public userService: UserService,
                public companyService: CompanyService,
                public dialog: MatDialog,
                public route: ActivatedRoute,
                public location: Location,
                public translator: TranslateService) {

    }

    ngOnInit() {

    }

    /**
     *
     */
    ngAfterViewInit() {
        const me = this;

        this.table.deleteClick.subscribe((user: User) => {
            this.delete(user.id);
        });

        this.table.editClick.subscribe((user: User) => {
            me.router.navigate(['/companies/users/' + user.id], {
                queryParams: {
                    companyId: this.company.id,
                    breadcrumbs: this.breadcrumbs
                }
            });
        });

        this.table.loaded.subscribe(() => {
            this.table.sortChange.subscribe((data: { column: string, direction: string }) => {
                this.paginator.pageIndex = 0;
                this.load(data.column, data.direction);
            });
            this.paginator.page.subscribe(() => {
                this.load();
            });

            this.load();
        });
    }

    /**
     * deletes the item with the given id
     *
     * @param id the id of the item to delete
     */
    private delete(id: number) {
        this.translator.get('usersList_DeleteAction_MessageContent').subscribe((messageTranslation: string) => {
            this.translator.get('usersList_DeleteAction_TitleContent').subscribe((titleTranslation: string) => {
                const dialogRef = this.dialog.open(LwsDialogComponent, {
                    data: {
                        cancelButton: true,
                        okButton: true,

                        message: messageTranslation,
                        title: titleTranslation
                    }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result === 'ok') {
                        this.isLoading = true;

                        this.userService.delete(id).then(success => {
                            this.isLoading = false;

                            this.load();
                        });
                    }
                });
            });
        });
    }

    /**
     * load the table-service content
     */
    private load(column: string = '', direction: string = '') {
        this.isLoading = true;

        const sort: string = LwsTableComponent.getSortColumnByName(this.displayedColumns, column);
        const sortDesc: boolean = ((direction.toLowerCase() === 'desc') ? true : false);

        if (this.company != null) {
            this.companyService.getUsers(this.company.id, this.paginator.pageIndex, this.paginator.pageSize, sort, sortDesc).then(
                (result: { items: User[], numberOfAllItems: number }) => {
                    this.isLoading = false;

                    if (result.items && result.items.length > 0) {
                        this.dataSource = result.items;
                        this.resultsLength = result.numberOfAllItems;
                    }
                },
                () => {
                    this.isLoading = false;
                });
        } else {
            this.userService.getAll(this.paginator.pageIndex, this.paginator.pageSize, sort, sortDesc).then(
                (users) => {
                    this.isLoading = false;

                    if (users) {
                        this.dataSource = users;
                        this.resultsLength = users.length;
                    }
                },
                () => {
                    this.isLoading = false;
                });
        }
    }

    /**
     * opens the detail view to create a new company
     */
    public addUserButtonClick() {
        this.router.navigate(['/companies/users/0'], {
            queryParams: {
                companyId: this.company.id,
                breadcrumbs: this.breadcrumbs
            }
        });
    }
}
