import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {LwsDetailHeaderComponent} from '../../components/lws-detail-header/lws-detail-header.component';
import {Version} from '../../models/version/version';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {VersionService} from '../../services/server/version/version.service';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {FolderService} from '../../services/server/folder/folder.service';
import {Folder} from '../../models/folder/folder';
import {SegmentService} from '../../services/server/segment/segment.service';
import {Segment} from '../../models/segment/segment';
import {CacheService} from '../../services/client/cache/cache.service';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-folders',
    templateUrl: './folders.component.html',
    styleUrls: ['./folders.component.scss']
})
export class FoldersComponent implements OnInit, AfterViewInit {
    @ViewChild(LwsDetailHeaderComponent) private header: LwsDetailHeaderComponent;
    @ViewChild(MatTabGroup) private tabGroup: MatTabGroup;
    public isLoading: boolean = false;
    public title: string = '';
    public version: Version = null;
    public parentFolder: Folder = null;
    public segments: Segment[] = [];
    public isSubFolder: boolean = false;
    public breadcrumbs: string[] = [];
    public activeSegmentIndex: number = 0;
    public readonly CACHE_ACTIVE_SEGMENT_KEY = 'folderComponentActiveSegment';

    constructor(public route: ActivatedRoute,
                public folderService: FolderService,
                public router: Router,
                public dialog: MatDialog,
                public translator: TranslateService,
                public location: Location,
                public segmentService: SegmentService,
                public versionService: VersionService,
                public cacheService: CacheService) {

    }

    ngOnInit() {
        this.loadSegments();

        this.route.data.subscribe(
            (data) => {
                this.route.paramMap.subscribe((params: ParamMap) => {
                    const id: number = +params.get('id');
                    this.isSubFolder = data.isSubFolder;

                    if (this.isSubFolder) {
                        // this is the view behind a parent folder
                        this.loadParentFolderById(id);

                        this.route.queryParams.subscribe((queryParams) => {
                            const versionId: number = +queryParams.versionId;
                            this.loadVersionById(versionId);
                        });
                    } else {
                        this.loadVersionById(id);
                    }
                });
            }
        );
    }

    ngAfterViewInit() {
        this.header.addClick.subscribe(() => {
            this.createNewFolder();
        });

        this.header.backClick.subscribe(() => {
            this.location.back();
        });
    }

    /**
     *
     * @param event
     */
    segmentTabGroupTabChanged(event: MatTabChangeEvent) {
        this.cacheService.addCacheValue(this.CACHE_ACTIVE_SEGMENT_KEY, event.index);
    }

    /**
     *
     * @param id the id of the version
     */
    private loadVersionById(id: number) {
        this.isLoading = true;

        this.versionService.getDetail(id).then(
            (version) => {
                this.isLoading = false;

                if (version == null) {
                    this.location.back();
                }

                this.version = version;

                if (!this.isSubFolder) {
                    // this is the view behind a version
                    this.translator.get('folders_PageTitle_TitleText', {versionName: this.version.name})
                    .subscribe((translation: string) => {
                        this.title = translation;
                        this.loadBreadcrumbsAndAdd(this.version.name);
                    });
                }
            },
            () => {
                this.isLoading = false;
            });
    }

    /**
     *
     * @param id the id of the parentfolder
     */
    private loadParentFolderById(id: number) {
        this.isLoading = true;

        this.folderService.getDetail(id).then(
            (parentFolder) => {
                this.isLoading = false;

                if (parentFolder == null) {
                    this.location.back();
                }

                this.parentFolder = parentFolder;

                this.translator.get('folders_SubFolderPageTitle_TitleText', {parentFolderName: this.parentFolder.name})
                .subscribe((translation: string) => {
                    this.title = translation;
                    this.loadBreadcrumbsAndAdd(this.parentFolder.name);
                });
            },
            () => {
                this.isLoading = false;
            });
    }

    /**
     * opens the detail view to create a new folder
     */
    private createNewFolder() {
        let segmentId: number = null;

        if (!this.segments || this.segments === null || this.segments === undefined || (this.segments && this.segments.length <= 0)) {
            return;
        }

        if (this.tabGroup != null) {
            segmentId = this.segments[this.tabGroup.selectedIndex].id;
        } else {
            segmentId = this.parentFolder.segment.id;
        }

        this.router.navigate(['/companies/folders/0'], {
            queryParams: {
                versionId: this.version.id,
                segmentId: segmentId,
                parentFolderId: ((this.parentFolder != null) ? this.parentFolder.id : ''),
                breadcrumbs: this.breadcrumbs
            }
        });
    }

    /**
     * loads the segments for the ui
     */
    private loadSegments() {
        this.segmentService.getAll().then(
            (segments) => {
                this.segments = segments;

                let activeSegmentIndex: number = this.cacheService.getCacheValue(this.CACHE_ACTIVE_SEGMENT_KEY);
                if (activeSegmentIndex != null && activeSegmentIndex > 0) {
                    this.activeSegmentIndex = activeSegmentIndex;
                }
            },
            () => {

            }
        );
    }

    /**
     * load the latest breadcrumbs and add the given as a new one
     *
     * @param breadcrumb the breadcrumb to add
     */
    private loadBreadcrumbsAndAdd(breadcrumb?: string) {
        const me = this;

        if (me.breadcrumbs === null || me.breadcrumbs === undefined) {
            me.breadcrumbs = [];
        }

        this.route.queryParams.subscribe((queryParams) => {
            if (typeof queryParams.breadcrumbs === 'string') {
                me.breadcrumbs.push(queryParams.breadcrumbs);
            } else {
                me.breadcrumbs = (queryParams.breadcrumbs as string[]);
            }

            if (breadcrumb !== null && breadcrumb !== undefined && breadcrumb.length > 0) {
                me.breadcrumbs.push(breadcrumb);
            }
        });
    }
}
