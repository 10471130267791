export class NavigationItem {
    private _name: string = '';
    private _href: string = '';
    private _icon: string = '';
    private _authorities: string[] = [];
    
    get name(): string {
        return this._name;
    }
    
    set name(value: string) {
        this._name = value;
    }
    
    get href(): string {
        return this._href;
    }
    
    set href(value: string) {
        this._href = value;
    }
    
    get icon(): string {
        return this._icon;
    }
    
    set icon(value: string) {
        this._icon = value;
    }
    
    get authorities(): string[] {
        return this._authorities;
    }
    
    set authorities(value: string[]) {
        this._authorities = value;
    }
}
