import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlingService {

    constructor(private snackBar: MatSnackBar,
                private translator: TranslateService) {

    }

    /**
     * handles an http-error from an request
     *
     * @param key the key of the requesting component
     * @param httpError the http exception
     */
    handleError(key: string, httpError: HttpErrorResponse) {
        if (httpError.status === 500) {
            this.snackBar.open(httpError.error.message, '', {
                duration: (5 * 1000), // display 5 seconds
                panelClass: 'snackbar-lws-error'
            });

            return;
        }

        // display the generated translation key for developer
        console.log('error handling translation key: ' + key + '_' + httpError.status);

        this.translator.get(key + '_' + httpError.status).subscribe(
            (translation: string) => {
                this.snackBar.open(translation, '', {
                    duration: (5 * 1000), // display 5 seconds
                    panelClass: 'snackbar-lws-error'
                });
            },
            (error) => {
                console.error('translation with key \'' + key + '_' + httpError.status + '\' not found.');
            });
    }
}
