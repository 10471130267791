import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {LwsDetailHeaderComponent} from '../../components/lws-detail-header/lws-detail-header.component';
import {Location} from '@angular/common';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, AfterViewInit {
    @ViewChild(LwsDetailHeaderComponent) private header: LwsDetailHeaderComponent;
    
    constructor(public location: Location) {
    
    }
    
    ngOnInit() {
    
    }
    
    ngAfterViewInit() {
        this.addHeaderSubscribes();
    }
    
    /**
     * add the subscribes to the action-buttons
     */
    private addHeaderSubscribes() {
        this.header.backClick.subscribe(() => {
            this.location.back();
        });
    }
}
