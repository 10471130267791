import {Group} from '../group/group';
import {ModelInterface} from '../model-interface';
import {Year} from '../year/year';

export class Company implements ModelInterface {
    private _id: number = 0;
    private _amountOfLoss1: string = '';
    private _amountOfLoss2: string = '';
    private _amountOfLoss3: string = '';
    private _amountOfLoss4: string = '';
    private _group: Group = null;
    
    // a list of properties-keys to ignore at jsonconvert
    _ignoreProperties: string[] = [];
    
    constructor() {
    
    }
    
    get id(): number {
        return this._id;
    }
    
    set id(value: number) {
        this._id = value;
    }
    
    get amountOfLoss1(): string {
        return this._amountOfLoss1;
    }
    
    set amountOfLoss1(value: string) {
        this._amountOfLoss1 = value;
    }
    
    get amountOfLoss2(): string {
        return this._amountOfLoss2;
    }
    
    set amountOfLoss2(value: string) {
        this._amountOfLoss2 = value;
    }
    
    get amountOfLoss3(): string {
        return this._amountOfLoss3;
    }
    
    set amountOfLoss3(value: string) {
        this._amountOfLoss3 = value;
    }
    
    get amountOfLoss4(): string {
        return this._amountOfLoss4;
    }
    
    set amountOfLoss4(value: string) {
        this._amountOfLoss4 = value;
    }
    
    get group(): Group {
        return this._group;
    }
    
    set group(value: Group) {
        this._group = value;
    }
    
    /**
     * returns the name of the group - for the table columns
     */
    get groupName(): string {
        if (this.group) {
            return this.group.name;
        }
        
        return '';
    }
    
    /**
     * returns the object as a valid json (without the underscore a the property name begin)
     */
    toJSON() {
        return {
            id: this.id,
            amountOfLoss1: this.amountOfLoss1,
            amountOfLoss2: this.amountOfLoss2,
            amountOfLoss3: this.amountOfLoss3,
            amountOfLoss4: this.amountOfLoss4,
            group: this.group.toJSON()
        };
    }
    
    /**
     * generates an company object from the given data
     */
    static hydrate(data): Company {
        const model: Company = new Company();
        model.id = data.id;
        model.amountOfLoss1 = data.amountOfLoss1;
        model.amountOfLoss2 = data.amountOfLoss2;
        model.amountOfLoss3 = data.amountOfLoss3;
        model.amountOfLoss4 = data.amountOfLoss4;
    
        if (data.group) {
            model.group = Group.hydrate(data.group);
        }
        
        return model;
    }
}
