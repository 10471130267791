<section>

    <form *ngIf="form != null" class="form" (ngSubmit)="onSubmit()" [formGroup]="form">

        <mat-form-field [floatLabel]="true">
            <input matInput placeholder="{{'companyDetailForm_NameInput_Placeholder'|translate}}" required
                   type="text" formControlName="name" [maxLength]="80">
        </mat-form-field>

        <mat-form-field [floatLabel]="true">
            <input matInput placeholder="{{'companyDetailForm_AmountOfLoss1Input_Placeholder'|translate}}"
                   type="text" formControlName="amountOfLoss1" [maxLength]="80">
        </mat-form-field>

        <mat-form-field [floatLabel]="true">
            <input matInput placeholder="{{'companyDetailForm_AmountOfLoss2Input_Placeholder'|translate}}"
                   type="text" formControlName="amountOfLoss2" [maxLength]="80">
        </mat-form-field>

        <mat-form-field [floatLabel]="true">
            <input matInput placeholder="{{'companyDetailForm_AmountOfLoss3Input_Placeholder'|translate}}"
                   type="text" formControlName="amountOfLoss3" [maxLength]="80">
        </mat-form-field>

        <mat-form-field [floatLabel]="true">
            <input matInput placeholder="{{'companyDetailForm_AmountOfLoss4Input_Placeholder'|translate}}"
                   type="text" formControlName="amountOfLoss4" [maxLength]="80">
        </mat-form-field>

        <button type="submit" [disabled]="!form.valid" mat-stroked-button class="submit-button" color="primary">
            {{'formButtons_save'|translate}}
            <mat-spinner diameter="24" *ngIf="isLoading"></mat-spinner>
        </button>

    </form>

</section>
