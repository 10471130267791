import {Injectable} from '@angular/core';
import {User} from 'src/app/models/user/user';
import {Router} from '@angular/router';
import {EventService} from '../../client/event/event.service';
import {RestService} from '../rest/rest.service';
import {RequestBodyType} from '../rest/request-body-type.enum';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    /**
     * the currently authentificated user
     */
    private user: User = null;

    constructor(private router: Router,
                private eventService: EventService,
                private restService: RestService,
                private translator: TranslateService) {
        this.loaded();
    }

    get User(): User {
        return this.user;
    }

    set User(value: User) {
        this.user = value;
    }

    /**
     * returns true if the is currently logged in
     */
    private loaded() {
        const me = this;
        this.translator.setDefaultLang('de');
    }

    /**
     * returns true if the is currently logged in
     */
    public IsAuth(): boolean {
        if (this.User != null && this.User.id > 0) {
            return true;
        }

        return false;
    }

    /**
     * log in with the given email and password
     */
    public login(email: string, password: string): Promise<any> {
        const loginParameter: { key: string, value: string }[] = [];
        loginParameter.push({key: 'username', value: email});
        loginParameter.push({key: 'password', value: password});
        loginParameter.push({key: 'grant_type', value: 'password'});

        return this.restService.login('/oauth/token', loginParameter, RequestBodyType.FormUrlEncoded, false, false).then(() => {
            const userInfoParameter: { key: string, value: string }[] = [];
            userInfoParameter.push({key: 'username', value: email});

            return this.restService.get('/users/userinfo', userInfoParameter).toPromise().then(
                (result) => {
                    const user: User = User.hydrate(result.body);

                    this.user = user;

                    if (user.isAdmin()) {
                        this.router.navigateByUrl('/assessments');
                    } else {
                        this.router.navigateByUrl('/dashboard');
                    }
                },
                (error) => {
                    return error;
                });
        });
    }

    /**
     * logout the current user
     */
    public logout(): Promise<boolean> {
        return this.restService.post(
            '/users/logout',
            null,
            true).toPromise()
        .then((id: number) => {
            this.user = null;
            this.restService.clear();
            this.router.navigateByUrl('/account/login');

            this.eventService.Trigger('account:logout');

            return true;
        }).catch((httpError: HttpErrorResponse) => {
            return false;
        });
    }
}

