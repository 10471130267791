import {Injectable} from '@angular/core';
import {AccountService} from '../account/account.service';
import {RestService} from '../rest/rest.service';
import {EventService} from '../../client/event/event.service';
import {TranslateService} from '@ngx-translate/core';
import {ErrorHandlingService} from '../../client/error-handling/error-handling.service';
import {Folder} from '../../../models/folder/folder';
import {HttpErrorResponse} from '@angular/common/http';
import {Question} from '../../../models/question/question';
import {Measure} from '../../../models/measure/measure';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class FolderService {

    constructor(private accountService: AccountService,
                private restService: RestService,
                private eventService: EventService,
                private snackBar: MatSnackBar,
                private translator: TranslateService,
                private errorHandlingService: ErrorHandlingService) {

    }

    /**
     * returns all folders
     */
    public getAll(): Promise<Folder[]> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.get('/folders', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const folders: Folder[] = [];

                for (const responseItem of response) {
                    folders.push(Folder.hydrate(responseItem));
                }

                return folders;
            },
            () => {
                return null;
            });
    }

    /**
     * deletes the folder with the given id
     *
     * @param id the id of the version to delete
     */
    public delete(id: number): Promise<boolean> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.delete(
            '/folders/' + id,
            parameter,
            true).toPromise()
        .then((response: any) => {
            return true;
        }).catch((httpError: any) => {
            this.errorHandlingService.handleError('folderService_DeleteRequest', httpError);

            return false;
        });
    }

    /**
     * returns the requested folder
     *
     * @param id the id of the requested folder
     */
    public getDetail(id: number): Promise<Folder> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.get('/folders/' + id, parameter).toPromise().then(
            (result) => {
                const folder: Folder = Folder.hydrate(result.body);

                return folder;
            },
            (httpError: HttpErrorResponse) => {
                this.errorHandlingService.handleError('folderService_DetailRequest', httpError);

                return null;
            });
    }

    /**
     * saves the given folder
     *
     * @param folder the folder to save
     */
    public save(folder: Folder): Promise<number> {
        return this.restService.post(
            '/folders/' + folder.id,
            folder,
            true).toPromise()
        .then((id: number) => {
            this.eventService.Trigger('folder:create:success', id);

            this.translator.get('folderDetailForm_SaveRequest_SuccessMessage').subscribe(
                (translation: string) => {
                    this.snackBar.open(translation, '', {
                        duration: (5 * 1000), // display 5 seconds
                        panelClass: 'snackbar-lws-success'
                    });
                });

            return id;
        }).catch((httpError: HttpErrorResponse) => {
            this.errorHandlingService.handleError('folderService_DeleteRequest', httpError);

            return null;
        });
    }

    /**
     * returns all subfolders for the given parentFolderId
     *
     * @param parentFolderId the id of the parent folder
     * @param page the requested page index
     * @param count the number of items per page
     * @param sort the column to sort
     * @param sortDesc should the sort be descending
     */
    public getSubfolders(parentFolderId: number,
                         page: number = 0,
                         count: number = 50,
                         sort: string = null,
                         sortDesc: boolean = false): Promise<{
        items: Folder[],
        numberOfAllItems: number
    }> {
        const parameter: { key: string, value: string }[] = [];
        if (page && page > 0) {
            parameter.push({key: 'page', value: page.toString()});
        }
        if (count && count > 0) {
            parameter.push({key: 'count', value: count.toString()});
        }
        if (sort && sort.length > 0) {
            parameter.push({key: 'sortColumn', value: sort.toString()});
            parameter.push({key: 'sortDesc', value: sortDesc.toString()});
        }

        return this.restService.get('/folders/' + parentFolderId + '/subFolders', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const folders: Folder[] = [];

                for (const responseItem of response) {
                    const subfolder: Folder = Folder.hydrate(responseItem);
                    subfolder.parentFolder = new Folder();
                    subfolder.parentFolder.id = parentFolderId;

                    folders.push(subfolder);
                }

                return {
                    items: folders,
                    numberOfAllItems: result.headers.get('x-items-count')
                };
            },
            () => {
                return null;
            });
    }

    /**
     * returns all questions for the given folder
     *
     * @param folderId the if of the requested folder
     * @param page
     * @param count
     * @param sort
     * @param sortDesc
     */
    public getQuestions(folderId: number,
                        page: number = 0,
                        count: number = 50,
                        sort: string = null,
                        sortDesc: boolean = false): Promise<{
        items: Question[],
        numberOfAllItems: number
    }> {
        const parameter: { key: string, value: string }[] = [];
        if (page && page > 0) {
            parameter.push({key: 'page', value: page.toString()});
        }
        if (count && count > 0) {
            parameter.push({key: 'count', value: count.toString()});
        }
        if (sort && sort.length > 0) {
            parameter.push({key: 'sortColumn', value: sort.toString()});
            parameter.push({key: 'sortDesc', value: sortDesc.toString()});
        }

        return this.restService.get('/folders/' + folderId + '/questions', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const questions: Question[] = [];

                for (const responseItem of response) {
                    questions.push(Question.hydrate(responseItem));
                }

                return {
                    items: questions,
                    numberOfAllItems: result.headers.get('x-items-count')
                };
            },
            () => {
                return null;
            });
    }

    /**
     * returns all measures for the given folder
     *
     * @param folderId the if of the requested folder
     * @param page
     * @param count
     * @param sort
     * @param sortDesc
     */
    public getMeasures(folderId: number,
                        page: number = 0,
                        count: number = 50,
                        sort: string = null,
                        sortDesc: boolean = false): Promise<{
        items: Measure[],
        numberOfAllItems: number
    }> {
        const parameter: { key: string, value: string }[] = [];
        if (page && page > 0) {
            parameter.push({key: 'page', value: page.toString()});
        }
        if (count && count > 0) {
            parameter.push({key: 'count', value: count.toString()});
        }
        if (sort && sort.length > 0) {
            parameter.push({key: 'sortColumn', value: sort.toString()});
            parameter.push({key: 'sortDesc', value: sortDesc.toString()});
        }

        return this.restService.get('/folders/' + folderId + '/measures', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const questions: Measure[] = [];

                for (const responseItem of response) {
                    questions.push(Measure.hydrate(responseItem));
                }

                return {
                    items: questions,
                    numberOfAllItems: result.headers.get('x-items-count')
                };
            },
            () => {
                return null;
            });
    }
}
