<form *ngIf="form != null" class="form" [formGroup]="form">

    <mat-toolbar color="basic">

        <mat-toolbar-row>

            <span>{{title}}</span>

            <span class="toolbar-spacer"></span>

            <button mat-icon-button (click)="addAction()" *ngIf="addActionAvailable"
                    [matTooltip]="'lwsDetailHeader_Add_ToolTipText'|translate">
                <mat-icon class="header-icon">add</mat-icon>
            </button>

        </mat-toolbar-row>

    </mat-toolbar>

    <table mat-table [dataSource]="dataSource" formArrayName="formRows"
           [className]="'lws-table-inline-edit lws-table&#45;&#45;layout-' + layoutSelected + ''">
        <!-- Row definitions -->
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; let i = index; columns: columns;"></tr>

        <!-- Column definitions -->
        <ng-container *ngFor="let displayColumn of visibleOutputColumns" matColumnDef="{{displayColumn.column}}">
            <th mat-header-cell *matHeaderCellDef [innerHTML]="displayColumn.title"></th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">

                <input *ngIf="displayColumn.type == 'text'" matInput type="text"
                       (keydown.enter)="$event.preventDefault()"
                       formControlName="{{displayColumn.column}}" placeholder="{{displayColumn.title}}"
                       [readonly]="displayColumn.readonly">

                <input *ngIf="displayColumn.type == 'currency'"
                       matInput
                       type="number"
                       max="{{displayColumn.maxValue}}"
                       (keydown.enter)="$event.preventDefault()"
                       formControlName="{{displayColumn.column}}"
                       placeholder="{{displayColumn.title}}"
                       [readonly]="displayColumn.readonly">

                <input *ngIf="displayColumn.type == 'number'"
                       matInput
                       type="number"
                       max="{{displayColumn.maxValue}}"
                       (keydown.enter)="$event.preventDefault()"
                       formControlName="{{displayColumn.column}}"
                       placeholder="{{displayColumn.title}}"
                       [readonly]="displayColumn.readonly">

                <textarea *ngIf="displayColumn.type == 'textarea'" matInput formControlName="{{displayColumn.column}}"
                          placeholder="{{displayColumn.title}}" [readonly]="displayColumn.readonly"></textarea>

                <mat-select *ngIf="displayColumn.type == 'select'" formControlName="{{displayColumn.column}}">
                    <mat-option [value]="0">{{'STATE_PREPARATION'|translate}}</mat-option>
                    <mat-option [value]="1">{{'STATE_ACTIVE'|translate}}</mat-option>
                    <mat-option [value]="2">{{'STATE_RISK_TIME'|translate}}</mat-option>
                    <mat-option [value]="3">{{'STATE_RISK_RESSOURCE'|translate}}</mat-option>
                    <mat-option [value]="4">{{'STATE_RISK_BUDGET'|translate}}</mat-option>
                    <mat-option [value]="5">{{'STATE_RISK_SCOPE'|translate}}</mat-option>
                    <mat-option [value]="6">{{'STATE_RSIK_QUALITY'|translate}}</mat-option>
                    <mat-option [value]="7">{{'STATE_PASSIV'|translate}}</mat-option>
                    <mat-option [value]="8">{{'STATE_COMPLETED'|translate}}</mat-option>
                </mat-select>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{'Actions'|translate}}</th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">

                <button mat-icon-button class="row-action" *ngIf="deleteActionAvailable"
                        color="warn" (click)="deleteAction(row, index)">
                    <mat-icon>delete</mat-icon>
                </button>

            </td>
        </ng-container>

    </table>
</form>
