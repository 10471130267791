import {Component, OnInit, ViewChild} from '@angular/core';
import {CompactType, GridsterConfig, GridsterItem, GridType} from 'angular-gridster2';
import {ReportingGadget} from '../../models/reporting-dashboard/reporting-gadget';
import {GadgetCatalogComponent} from '../../components/reporting-dashboard/gadget-catalog/gadget-catalog.component';
import {CompanyService} from '../../services/server/company/company.service';
import {Company} from '../../models/company/company';
import {AccountService} from '../../services/server/account/account.service';
import {GadgetService} from '../../services/server/reporting-dashboard/gadget.service';
import {Privilege} from '../../models/privilege/privilege';
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-reporting-dashboard',
    templateUrl: './reporting-dashboard.component.html',
    styleUrls: ['./reporting-dashboard.component.scss']
})
export class ReportingDashboardComponent implements OnInit {


    public options: GridsterConfig;
    public gadgets: Array<ReportingGadget> = [];
    public companies: Company[] = [];
    public admin: boolean;

    private loadedCompanyId: number;
    public isConfigureable: boolean = false;

    constructor(
        public dialog: MatDialog,
        private companyService: CompanyService,
        private accountService: AccountService,
        private gadgetService: GadgetService) {
    }


    ngOnInit() {

        const me = this;

        this.options = {
            gridType: GridType.ScrollVertical,
            itemChangeCallback: change => {
                me.saveGadgets();
            },
            itemResizeCallback: change => {
            },
            compactType: CompactType.None,
            pushItems: true,
            minCols: 6,
            maxCols: 6,
            draggable: {
                enabled: true
            },
            resizable: {
                enabled: true
            }
        };

        this.admin = this.accountService.User.isAdmin();

        if (this.admin) {
            this.loadCompanies();
        }

        this.loadDashboard();

        this.isConfigureable = this.accountService.User.hasRight(Privilege.REPORTING_DASHBOARD_CONFIGURE);
    }

    private loadCompanies() {
        this.companyService.getAll(0, 1000).then(
            (result: { items: Company[], numberOfAllItems: number }) => {
                this.companies = result.items;
            }
        );
    }

    private loadDashboard() {
        if (this.accountService.User.hasAuthority('USER')) {
            this.loadDashboardForCompany(this.accountService.User.company.id);
        }
    }

    public changedOptions() {
        this.options.api.optionsChanged();
    }

    public removeItem(item) {
        this.gadgets.splice(this.gadgets.indexOf(item), 1);
    }

    public openGadgetSelection() {

        const me = this;

        const dialogRef = this.dialog.open(GadgetCatalogComponent, {
            width: '850px',
            data: { name: 'hello' }
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result.gadget) {
                const reportingGadget = new ReportingGadget();
                reportingGadget.name = result.gadget.name;
                reportingGadget.type = result.gadget.type;
                reportingGadget.cols = 3;
                reportingGadget.rows = 2;
                reportingGadget.companyId = this.loadedCompanyId;

                me.gadgets.push(reportingGadget);
            }
        });
    }

    public loadDashboardForCompany(companyId: number) {

        this.loadedCompanyId = companyId;

        this.gadgetService.getAllGadgets(companyId).then(
            (gadgetConfigs) => {
                this.gadgets = gadgetConfigs;
            },
            () => {

            }
        );
    }

    public saveGadgets(callback?: any) {

        this.gadgetService.update(this.loadedCompanyId, this.gadgets).then(
            (result) => {

                let i: number = 0;
                this.gadgets.forEach(gadget => {
                    if (gadget.id == null || gadget.id <= 0) {
                        gadget.id = result[i].id;

                        console.log('new gadget: ' + result[i].id);
                    }
                    i++;
                });
                if(callback) {
                    callback();
                }

                console.log('gadget saved');
            },
            () => {

            }
        );
    }

}
