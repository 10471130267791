export class MeasureColumns {

    public _name: boolean;
    public _isDescription: boolean;
    public _useDescription: boolean;
    public _probabilityOfOccurrence: boolean;
    public _netDamageMeasure: boolean;
    public _startdate: boolean;
    public _enddate: boolean;
    public _measureResponsiblePersons: boolean;
    public _state: boolean;
    public _progress: boolean;
    public _tasknames: boolean;
    public _notes: boolean;
    public _interoperability: boolean;
    public _budget: boolean;
    public _bwaActual: boolean;
    public _budgetPt: boolean;
    public _bwaActualPt: boolean;
    public _revenues: boolean;
    public _revenuesPa: boolean;
    public _status: boolean;


    get name(): boolean {
        return this._name;
    }

    set name(value: boolean) {
        this._name = value;
    }

    get isDescription(): boolean {
        return this._isDescription;
    }

    set isDescription(value: boolean) {
        this._isDescription = value;
    }

    get useDescription(): boolean {
        return this._useDescription;
    }

    set useDescription(value: boolean) {
        this._useDescription = value;
    }

    get probabilityOfOccurrence(): boolean {
        return this._probabilityOfOccurrence;
    }

    set probabilityOfOccurrence(value: boolean) {
        this._probabilityOfOccurrence = value;
    }

    get netDamageMeasure(): boolean {
        return this._netDamageMeasure;
    }

    set netDamageMeasure(value: boolean) {
        this._netDamageMeasure = value;
    }

    get startdate(): boolean {
        return this._startdate;
    }

    set startdate(value: boolean) {
        this._startdate = value;
    }

    get enddate(): boolean {
        return this._enddate;
    }

    set enddate(value: boolean) {
        this._enddate = value;
    }

    get measureResponsiblePersons(): boolean {
        return this._measureResponsiblePersons;
    }

    set measureResponsiblePersons(value: boolean) {
        this._measureResponsiblePersons = value;
    }

    get state(): boolean {
        return this._state;
    }

    set state(value: boolean) {
        this._state = value;
    }

    get progress(): boolean {
        return this._progress;
    }

    set progress(value: boolean) {
        this._progress = value;
    }

    get tasknames(): boolean {
        return this._tasknames;
    }

    set tasknames(value: boolean) {
        this._tasknames = value;
    }

    get notes(): boolean {
        return this._notes;
    }

    set notes(value: boolean) {
        this._notes = value;
    }

    get interoperability(): boolean {
        return this._interoperability;
    }

    set interoperability(value: boolean) {
        this._interoperability = value;
    }

    get budget(): boolean {
        return this._budget;
    }

    set budget(value: boolean) {
        this._budget = value;
    }

    get bwaActual(): boolean {
        return this._bwaActual;
    }

    set bwaActual(value: boolean) {
        this._bwaActual = value;
    }

    get revenues(): boolean {
        return this._revenues;
    }

    set revenues(value: boolean) {
        this._revenues = value;
    }

    get revenuesPa(): boolean {
        return this._revenuesPa;
    }

    set revenuesPa(value: boolean) {
        this._revenuesPa = value;
    }

    get status(): boolean {
        return this._status;
    }

    set status(value: boolean) {
        this._status = value;
    }

    get budgetPt(): boolean {
        return this._budgetPt;
    }

    set budgetPt(value: boolean) {
        this._budgetPt = value;
    }

    get bwaActualPt(): boolean {
        return this._bwaActualPt;
    }

    set bwaActualPt(value: boolean) {
        this._bwaActualPt = value;
    }
}
