import {Component, Input, OnInit} from '@angular/core';
import {Version} from '../../models/version/version';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../models/folder/folder';
import {AccountService} from '../../services/server/account/account.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {VersionService} from '../../services/server/version/version.service';
import {FolderService} from '../../services/server/folder/folder.service';
import {Segment} from '../../models/segment/segment';
import {Location} from '@angular/common';

@Component({
    selector: 'app-folder-detail-form',
    templateUrl: './folder-detail-form.component.html',
    styleUrls: ['./folder-detail-form.component.scss']
})
export class FolderDetailFormComponent implements OnInit {
    @Input() folder: Folder = null;
    public version: Version = null;
    public segmentId: number = null;
    public parentFolderId: number = null;
    public isLoading: boolean = false;

    /**
     * the structure of the login-form
     */
    public form: FormGroup = this.formBuilder.group({
        name: ['', Validators.compose([
            Validators.required,
            Validators.maxLength(255)
        ])],
    });

    constructor(public formBuilder: FormBuilder,
                public accountService: AccountService,
                public snackBar: MatSnackBar,
                public router: Router,
                public route: ActivatedRoute,
                public folderService: FolderService,
                public versionService: VersionService,
                public location: Location) {

    }

    /**
     * executed if the user clicked on the submit-button
     */
    private onSubmit() {
        if (this.form.valid && this.version != null) {
            this.isLoading = true;

            const folder: Folder = ((this.folder == null) ? new Folder() : this.folder);
            folder.name = this.form.value['name'];

            if (folder.parentFolder == null
                && (this.parentFolderId !== null && this.parentFolderId > 0)) {
                folder.parentFolder = new Folder();
                folder.parentFolder.id = this.parentFolderId;
            }

            if (folder.segment == null) {
                folder.segment = new Segment();
                folder.segment.id = this.segmentId;
            }
            folder.version = ((this.folder != null && this.folder.version != null) ? this.folder.version : this.version);

            this.folderService.save(folder).then(
                (folderId) => {
                    this.isLoading = false;

                    if (folderId > 0) {
                        this.location.back();
                    }
                });
        }
    }

    /**
     * loads the form model
     */
    private loadModel() {
        if (this.folder == null) {
            return;
        }

        this.form.setValue({
            name: this.folder.name
        });
    }

    ngOnInit() {
        this.loadModel();

        this.route.queryParams.subscribe((queryParams) => {
            const versionId: number = +queryParams.versionId;
            const segmentId: number = +queryParams.segmentId;
            const parentFolderId: number = +queryParams.parentFolderId;
            this.isLoading = true;

            this.segmentId = segmentId;
            this.parentFolderId = parentFolderId;
            this.versionService.getDetail(versionId).then(
                (version) => {
                    this.isLoading = false;

                    this.version = version;
                },
                () => {
                    this.isLoading = false;
                });
        });
    }
}
