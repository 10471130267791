<section>

    <app-lws-detail-header [backButton]="true" [title]="title"></app-lws-detail-header>
    <app-lws-bread-crumb [breadcrumbs]="breadcrumbs"></app-lws-bread-crumb>

    <main *ngIf="!isNew && user" class="user--detail-existing">

        <app-user-detail-form *ngIf="user" [user]="user" [company]="company"></app-user-detail-form>

    </main>

    <main *ngIf="isNew" class="user--detail-new">

        <app-user-detail-form [company]="company"></app-user-detail-form>

    </main>

</section>
