import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {AccountService} from '../account/account.service';
import {RestService} from '../rest/rest.service';
import {EventService} from '../../client/event/event.service';
import {TranslateService} from '@ngx-translate/core';
import {ErrorHandlingService} from '../../client/error-handling/error-handling.service';
import {Measure} from '../../../models/measure/measure';
import {CrudService} from '../CrudService';
import {RequestBodyType} from '../rest/request-body-type.enum';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class MeasureService extends CrudService {

    constructor(private accountService: AccountService,
                private restService: RestService,
                private eventService: EventService,
                private snackBar: MatSnackBar,
                private translator: TranslateService,
                private errorHandlingService: ErrorHandlingService) {
        super();
    }

    /**
     * returns the requested measure
     *
     * @param id the id of the requested measure
     */
    public getDetail(id: number): Promise<Measure> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.get('/measures/' + id, parameter).toPromise().then(
            (result) => {
                const measure: Measure = Measure.hydrate(result.body);

                return measure;
            },
            (httpError: HttpErrorResponse) => {
                this.errorHandlingService.handleError('measureService_DetailRequest', httpError);

                return null;
            });
    }

    /**
     * saves the given measure
     *
     * @param measure the measure to save
     */
    public save(measure: Measure): Promise<number> {
        return this.restService.post(
            '/measures/' + measure.id,
            measure,
            true).toPromise()
        .then((id: number) => {
            this.eventService.Trigger('measure:create:success', id);

            return id;
        }).catch((httpError: HttpErrorResponse) => {
            this.errorHandlingService.handleError('measureService_SaveRequest', httpError);

            return null;
        });
    }

    /**
     * deletes the item with the given id
     *
     * @param id the id of the item to delete
     * @param folderId the id of the containing folder
     */
    public delete(id: number, folderId: number): Promise<boolean> {
        const parameter: { key: string, value: string }[] = [];
        parameter.push({key: 'folderId', value: folderId.toString()});

        return this.restService.delete(
            '/measures/' + id,
            parameter,
            true).toPromise()
        .then((response: any) => {
            return true;
        }).catch((httpError: any) => {
            this.errorHandlingService.handleError('questionService_DeleteRequest', httpError);
            return false;
        });
    }


    /**
     * moves the given measure to the given folder
     *
     * @param measureId the id of the measure to move
     * @param targetFolderId the id of the target folder
     */
    public move(measureId: number, targetFolderId: number): Promise<boolean> {
        const parameter: { key: string, value: string }[] = [];
        parameter.push({key: 'targetFolderId', value: targetFolderId.toString()});

        return this.restService.postForm(
            '/measures/' + measureId + '/move',
            parameter,
            RequestBodyType.FormUrlEncoded).toPromise()
        .then((response: any) => {

            this.translator.get('measureService_MoveRequest_SuccessMessage').subscribe(
                (translation: string) => {
                    this.snackBar.open(translation, '', {
                        duration: (5 * 1000), // display 5 seconds
                        panelClass: 'snackbar-lws-success'
                    });
                });
            return true;
        }).catch((httpError: any) => {
            this.errorHandlingService.handleError('measureService_MoveRequest', httpError);

            return false;
        });
    }
}
