<section>

    <!-- the page header -->
    <app-lws-detail-header [backButton]="true" [addButton]="true" [title]="title"></app-lws-detail-header>
    <app-lws-bread-crumb [breadcrumbs]="breadcrumbs"></app-lws-bread-crumb>

    <main>

        <div class="table-loading-shade" *ngIf="isLoading">
            <mat-spinner *ngIf="isLoading" [diameter]="48"></mat-spinner>
        </div>

        <mat-tab-group *ngIf="!isLoading && (segments && segments.length > 0) && (parentFolder == null)"
                       (selectedTabChange)="segmentTabGroupTabChanged($event)" [selectedIndex]="activeSegmentIndex">

            <mat-tab *ngFor="let segment of segments" label="{{segment.name}}">

                <app-folders-list [segment]="segment" [version]="version"
                                  [parentFolder]="parentFolder" [breadcrumbs]="breadcrumbs"></app-folders-list>

            </mat-tab>

        </mat-tab-group>

        <app-folders-list *ngIf="!isLoading && parentFolder && parentFolder.id > 0" [version]="version"
                          [parentFolder]="parentFolder" [breadcrumbs]="breadcrumbs"></app-folders-list>

    </main>

</section>
