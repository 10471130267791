import {ModelInterface} from '../model-interface';
import {JSONConverter} from '../JSONConverter';
import {Folder} from '../folder/folder';
import {States} from './States';
import {FormBuilder} from '@angular/forms';
import {AccountService} from '../../services/server/account/account.service';
import {Router} from '@angular/router';
import {CompanyService} from '../../services/server/company/company.service';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

export class MeasurePlaning implements ModelInterface {
    private _id: number = 0;

    private _taskname: string = '';

    private _state: number = 0;

    private _budget: number = 0;

    private _actual: number = 0;

    private _budgetPt: number = 0;

    private _actualPt: number = 0;

    private _notes: string = '';

    // a list of properties-keys to ignore at jsonconvert
    _ignoreProperties: string[] = [];

    constructor() {

    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get taskname(): string {
        return this._taskname;
    }

    set taskname(value: string) {
        this._taskname = value;
    }

    get state(): number {
        return this._state;
    }

    set state(value: number) {
        this._state = value;
    }

    get budget(): number {
        return this._budget;
    }

    set budget(value: number) {
        this._budget = value;
    }

    get actual(): number {
        return this._actual;
    }

    set actual(value: number) {
        this._actual = value;
    }

    get budgetPt(): number {
        return this._budgetPt;
    }

    set budgetPt(value: number) {
        this._budgetPt = value;
    }

    get actualPt(): number {
        return this._actualPt;
    }

    set actualPt(value: number) {
        this._actualPt = value;
    }

    get notes(): string {
        return this._notes;
    }

    set notes(value: string) {
        this._notes = value;
    }

    /**
     * returns the object as a valid json (without the underscore a the property name begin)
     */
    toJSON() {
        return new JSONConverter().toJSON(this);
    }

    /**
     * creates a object from the given data
     *
     * @param data the data to create a model
     */
    static hydrate(data: any): MeasurePlaning {
        const model: MeasurePlaning = new MeasurePlaning();

        Object.assign(model, data);

        return model;
    }
}
