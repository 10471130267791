<section>

    <!-- the page header -->
    <app-lws-detail-header [backButton]="true" [addButton]="true" [title]="title"></app-lws-detail-header>
    <app-lws-bread-crumb [breadcrumbs]="breadcrumbs"></app-lws-bread-crumb>

    <main>

        <div class="table-container mat-elevation-z8">

            <div class="table-loading-shade"
                 *ngIf="isLoading">
                <mat-spinner *ngIf="isLoading" [diameter]="48"></mat-spinner>
            </div>

            <div class="table-container">
                <app-lws-table [datasource]="dataSource"
                               [displayedColumns]="displayedColumns"
                               [layout]="'full'"
                               [enableActions]="true"
                               [enableOpenAction]="true"
                               [enableEditAction]="true"
                               [enableCopyAction]="true"
                               [enableExportAction]="true"
                               [enableDeleteAction]="true"></app-lws-table>
            </div>

            <div class="footer-bottom fixed">
                <mat-paginator fixed [pageSizeOptions]="[25, 50, 75]" [length]="resultsLength"></mat-paginator>
            </div>

        </div>

    </main>

</section>
