import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {interval, Observable} from 'rxjs';
import {RequestBodyType} from './request-body-type.enum';
import {ErrorHandlingService} from '../../client/error-handling/error-handling.service';
import {AppConfig} from '../../../app.config';

@Injectable({
    providedIn: 'root'
})
export class RestService {
    readonly restServerUrl: string = AppConfig.settings.apiServer.server;
    readonly restApiUrl: string = AppConfig.settings.apiServer.apiUrl;
    readonly appClient: string = AppConfig.settings.apiServer.appClient;
    readonly appSecret: string = AppConfig.settings.apiServer.appSecret;

    private accessToken: string = '';
    private refreshToken: string = '';

    /**
     * the life-time in minutes for every token
     */
    private readonly tokenExpireTimespan: number = 2880; // 2 days
    /**
     * the timestamp as long value where the token was received
     */
    private tokenReceivedTimestamp: number = 0;
    private doRefreshToken: boolean = false;

    constructor(private httpClient: HttpClient,
                private errorHandlingService: ErrorHandlingService) {
        this.loadTokenExpireHandler();
    }

    /**
     * sends an GET request to the given endpoint and returns the result from the HttpClient
     *
     * @param {string} requestUrl url like /api/controller or https://domain.de/api/controller
     * @param {{key: string; value: string}[]} parameter a list of parameters to send it with the request
     * @param {boolean} isApi true if the request should be send to the api
     * @param {boolean} isExternal true if the request should be send to another server
     * @param {boolean} textResponse
     * @returns {Observable<HttpResponse<any>>}
     */
    get(requestUrl: string,
        parameter: { key: string, value: string }[],
        isApi: boolean = true,
        isExternal: boolean = false,
        textResponse: boolean = false): Observable<HttpResponse<any>> {
        let requestString: string = this.getRequestUrl(requestUrl, isApi, isExternal);
        let headers: HttpHeaders = new HttpHeaders();

        if (!isExternal) {
            headers = headers.set('Authorization', 'Bearer ' + this.accessToken);
        }

        requestString += this.getUrlParameterString(parameter);

        if (!textResponse) {
            return this.httpClient.get(requestString, {
                observe: 'response',
                headers: headers
            });
        } else {
            return this.httpClient.get(requestString, {
                observe: 'response',
                headers: headers,
                responseType: 'text'
            });
        }
    }

    /**
     * sends an GET request to the given endpoint and returns the result from the HttpClient
     *
     * @param requestUrl | url like /api/controller or https://domain.de/api/controller
     * @param parameter a list of parameters to send it with the request
     * @param isApi true if the request should be send to the api
     * @param isExternal true if the request should be send to another server
     */
    getExport(requestUrl: string,
              parameter: { key: string, value: string }[],
              isApi: boolean = true,
              isExternal: boolean = false): Observable<HttpResponse<any>> {
        let requestString: string = this.getRequestUrl(requestUrl, isApi, isExternal);
        let headers: HttpHeaders = new HttpHeaders();

        if (!isExternal) {
            headers = headers.set('Authorization', 'Bearer ' + this.accessToken);
        }

        requestString += this.getUrlParameterString(parameter);

        return this.httpClient.get(requestString, {
            observe: 'response',
            headers: headers,
            responseType: 'blob'
        });
    }

    /**
     * sends an GET request to the given endpoint and returns the result from the HttpClient
     *
     * @param requestUrl | url like /api/controller or https://domain.de/api/controller
     * @param parameter a list of parameters to send it with the request
     * @param isApi true if the request should be send to the api
     * @param isExternal true if the request should be send to another server
     */
    getExportWithBody(requestUrl: string,
              parameter: { key: string, value: string }[],
              isApi: boolean = true,
              isExternal: boolean = false,
                      body: any): Observable<HttpResponse<any>> {
        let requestString: string = this.getRequestUrl(requestUrl, isApi, isExternal);
        let headers: HttpHeaders = new HttpHeaders();

        if (!isExternal) {
            headers = headers.set('Authorization', 'Bearer ' + this.accessToken);
        }

        requestString += this.getUrlParameterString(parameter);

        return this.httpClient.post(requestString, body, {
            observe: 'response',
            headers: headers,
            responseType: 'blob'
        });
    }

    /**
     * sends an GET request to the given endpoint and returns the result from the HttpClient
     *
     * @param requestUrl | url like /api/controller or https://domain.de/api/controller
     * @param parameter a list of parameters to send it with the request
     * @param bodyType the request body type
     * @param isApi true if the request should be send to the api
     * @param isExternal true if the request should be send to another server
     */
    postForm(requestUrl: string,
             parameter: { key: string, value: string }[],
             bodyType: RequestBodyType,
             isApi: boolean = true,
             isExternal: boolean = false): Observable<any> {
        const requestString: string = this.getRequestUrl(requestUrl, isApi, isExternal);
        const httpParams = new HttpParams();
        let httpBody;
        let headers: HttpHeaders = new HttpHeaders();

        if (!isExternal) {
            headers = headers.set('Authorization', 'Bearer ' + this.accessToken);
        }
        headers = headers.set('Accept', '*/*');
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');

        switch (bodyType) {
            case RequestBodyType.FormUrlEncoded: {
                httpBody = new URLSearchParams();
                for (const parameterValue of parameter) {
                    httpBody.set(parameterValue.key, parameterValue.value);
                }

                httpBody = httpBody.toString();
            }
                break;
        }

        return this.httpClient.post(requestString, httpBody, {
            headers: headers
        });
    }

    /**
     * sends an GET request to the given endpoint and returns the result from the HttpClient
     *
     * @param requestUrl | url like /api/controller or https://domain.de/api/controller
     * @param parameter an object to send it with the request
     * @param isApi true if the request should be send to the api
     * @param isExternal true if the request should be send to another server
     */
    post(requestUrl: string,
         parameter: any,
         isApi: boolean = true,
         isExternal: boolean = false): Observable<any> {
        const requestString: string = this.getRequestUrl(requestUrl, isApi, isExternal);
        const httpParams = new HttpParams();
        let headers: HttpHeaders = new HttpHeaders();

        if (!isExternal) {
            headers = headers.set('Authorization', 'Bearer ' + this.accessToken);
        }
        headers = headers.set('Accept', '*/*');
        headers = headers.set('Content-Type', 'application/json');

        return this.httpClient.post(requestString, parameter, {
            headers: headers
        });
    }

    /**
     * sends an GET request to the given endpoint and returns the result from the HttpClient
     *
     * @param requestUrl | url like /api/controller or https://domain.de/api/controller
     * @param parameter an object to send it with the request
     * @param isApi true if the request should be send to the api
     * @param isExternal true if the request should be send to another server
     */
    delete(requestUrl: string,
           parameter: any,
           isApi: boolean = true,
           isExternal: boolean = false): Observable<any> {
        let requestString: string = this.getRequestUrl(requestUrl, isApi, isExternal);
        requestString += this.getUrlParameterString(parameter);

        const httpParams = new HttpParams();
        let headers: HttpHeaders = new HttpHeaders();

        if (!isExternal) {
            headers = headers.set('Authorization', 'Bearer ' + this.accessToken);
        }
        headers = headers.set('Accept', '*/*');
        headers = headers.set('Content-Type', 'application/json');


        return this.httpClient.delete(requestString, {
            headers: headers
        });
    }

    /**
     * sends an GET request to the given endpoint and returns the result from the HttpClient
     *
     * @param requestUrl | url like /api/controller or https://domain.de/api/controller
     * @param parameter a list of parameters to send it with the request
     * @param bodyType the request body type
     * @param isApi true if the request should be send to the api
     * @param isExternal true if the request should be send to another server
     */
    login(requestUrl: string,
          parameter: { key: string, value: string }[],
          bodyType: RequestBodyType,
          isApi: boolean = true,
          isExternal: boolean = false): Promise<any> {
        let httpParams = new HttpParams();
        let requestString: string = this.getRequestUrl(requestUrl, isApi, isExternal);
        let httpBody;

        switch (bodyType) {
            case RequestBodyType.FormUrlEncoded: {
                httpBody = new URLSearchParams();
                for (const parameterValue of parameter) {
                    httpParams = httpParams.append(parameterValue.key, parameterValue.value);
                    httpBody.set(parameterValue.key, parameterValue.value);
                }

                httpBody = httpBody.toString();
            }
                break;
            case RequestBodyType.Query: {

                requestString += this.getUrlParameterString(parameter);
            }
                break;
        }
        console.log(httpParams);
        return this.httpClient.post(requestString, httpParams, {
            withCredentials: true,
            headers: new HttpHeaders()
            .set('Accept', '*/*')
            .set('Authorization', 'Basic ' + btoa(this.appClient + ':' + this.appSecret))
        }).toPromise().then((response: any) => {
            this.accessToken = response.access_token;
            this.refreshToken = response.refresh_token;

            this.tokenReceivedTimestamp = this.getCurrentDateTimeAsLong();
        });
    }

    /**
     * returns the current datetime as long value
     */
    private getCurrentDateTimeAsLong(): number {
        const currentDateTime = new Date();
        return currentDateTime.getTime();
    }

    /**
     * clears the rest tokens
     */
    clear() {
        this.accessToken = '';
        this.refreshToken = '';
        this.tokenReceivedTimestamp = 0;
    }

    /**
     * generates the request url
     *
     * @param requestUrl the requested url
     * @param isApi is the request for the own api
     * @param isExternal is the reuqest to another server
     */
    private getRequestUrl(requestUrl: string, isApi: boolean = true, isExternal: boolean = false): string {
        let requestString: string = ((isExternal !== true) ? this.restServerUrl : '');
        requestString += ((isApi === true) ? this.restApiUrl : '');
        requestString += requestUrl;

        return requestString;
    }

    /**
     * generates an url-parameter string from the given parameters
     *
     * @param parameter the url parameter
     */
    private getUrlParameterString(parameter: { key: string, value: string }[]): string {
        let urlQuery: string = '';
        let index: number = 0;

        for (const parameterValue of parameter) {
            if (index === 0) {
                urlQuery += '?';
            } else {
                urlQuery += '&';
            }

            urlQuery += parameterValue.key + '=' + parameterValue.value;

            index++;
        }

        return urlQuery;
    }

    /**
     * loads the token-expire handler
     */
    private loadTokenExpireHandler() {
        const obs = interval(1000);
        obs.subscribe(() => {
            if (!this.doRefreshToken) {
                this.checkTokenExpiration();
            }
        });
    }

    /**
     * checks if the token expires and refreshes it if needed
     */
    private checkTokenExpiration() {
        const currentDateTime: number = this.getCurrentDateTimeAsLong();
        const refreshTimeSpan: number = 60 * 1000; // 1 minute
        const startTimeStamp: number = ((this.tokenExpireTimespan * 60 * 1000) - refreshTimeSpan);

        if ((currentDateTime >= (this.tokenReceivedTimestamp + startTimeStamp)) && this.tokenReceivedTimestamp > 0) {
            this.loadRefreshedTokens();
        }
    }

    /**
     * load refreshed tokens
     */
    private loadRefreshedTokens() {
        this.doRefreshToken = true;
        const httpParams = new HttpParams();
        const requestString: string = this.getRequestUrl('/oauth/token?grant_type=refresh_token', true, false);
        let httpBody;

        const oauthParameter: { key: string, value: string }[] = [];
        oauthParameter.push({key: 'refresh_token', value: this.refreshToken});
        // oauthParameter.push({key: 'grant_type', value: 'refresh_token'});
        httpBody = new URLSearchParams();
        for (const parameterValue of oauthParameter) {
            httpBody.set(parameterValue.key, parameterValue.value);
        }

        httpBody = httpBody.toString();

        this.httpClient.post(requestString, httpBody, {
            withCredentials: true,
            headers: new HttpHeaders()
            .set('Accept', '*/*')
            .set('Authorization', 'Basic ' + btoa(this.appClient + ':' + this.appSecret))
        }).toPromise().then((response: any) => {
            this.accessToken = response.access_token;
            this.refreshToken = response.refresh_token;

            this.tokenReceivedTimestamp = this.getCurrentDateTimeAsLong();
            this.doRefreshToken = false;
        }).catch(() => {
            this.doRefreshToken = false;
        });
    }
}
