import {ModelInterface} from '../model-interface';
import {Folder} from '../folder/folder';
import {Answer} from '../answer/answer';
import {Unit} from '../unit/unit';

export class Question implements ModelInterface {

    public static ANSWERE_STATE_NO_ANSWERES: number = 0;
    public static ANSWERE_STATE_ONLY_PREVIOUS: number = 1;
    public static ANSWERE_STATE_IN_CURRENT_VERSION: number = 2;

    /**
     * id of the unit
     */
    private _id: number = 0;
    private _question: string = '';
    private _infotext: string = '';
    private _factor: number = 0;
    private _folders: Folder[] = [];

    // a list of properties-keys to ignore at jsonconvert
    _ignoreProperties: string[] = [];

    private _answereState: number = 0;

    private _styleClass: string = '';

    /**
     * Stores the answer which belongs to this question and the requested version.
     */
    private _answerOfVersion: Answer;

    private _answerRating: number;

    private _folderLevel2OfVersion: Folder;

    private _unit: Unit;

    constructor() {

    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get question(): string {
        return this._question;
    }

    set question(value: string) {
        this._question = value;
    }

    get infotext(): string {
        return this._infotext;
    }

    set infotext(value: string) {
        this._infotext = value;
    }

    get factor(): number {
        if (this.answerOfVersion != null && this.answerOfVersion.factor != null) {
            return this.answerOfVersion.factor;
        }
        return this._factor;
    }

    set factor(value: number) {
        this._factor = value;
    }

    get folders(): Folder[] {
        return this._folders;
    }

    set folders(value: Folder[]) {
        this._folders = value;
    }

    get answereState(): number {
        return this._answereState;
    }

    set answereState(value: number) {
        this._answereState = value;
    }

    get styleClass(): string {
        return this._styleClass;
    }

    set styleClass(value: string) {
        this._styleClass = value;
    }

    get answerOfVersion(): Answer {
        return this._answerOfVersion;
    }

    set answerOfVersion(value: Answer) {
        this._answerOfVersion = value;
    }

    get answerRating(): string {
        if (this.answerOfVersion != null && this.answerOfVersion.isNotRelevant) {
            return './.';
        } else if (this.answerOfVersion != null) {
            return this.answerOfVersion.rating + '';
        } else {
            return '';
        }
    }


    /**
     * returns the object as a valid json (without the underscore a the property name begin)
     */
    toJSON() {
        return {
            id: this.id,
            question: this.question,
            infotext: this.infotext,
            factor: this.factor,
            folders: this.folders,
            answereState: this.answereState,
            answerOfVersion: this.answerOfVersion,
            folderLevel2OfVersion: this.folderLevel2OfVersion,
            unit: this.unit
        };
    }

    /**
     * creates a object from the given data
     *
     * @param data the data to create a model
     */
    static hydrate(data: any): Question {
        const model: Question = new Question();

        model.id = data.id;
        model.question = data.question;
        model.infotext = data.infotext;
        model.factor = data.factor;
        model.answereState = data.answereState;
        model.answerOfVersion = data.answerOfVersion;
        model.folderLevel2OfVersion = data.folderLevel2OfVersion;
        model.unit = data.unit;

        model.folders = [];
        if (data.folders !== undefined && data.folders !== null) {
            for (const folder of data.folders) {
                model.folders.push(Folder.hydrate(folder));
            }
        }

        return model;
    }

    get folderLevel2OfVersion(): Folder {
        return this._folderLevel2OfVersion;
    }

    set folderLevel2OfVersion(value: Folder) {
        this._folderLevel2OfVersion = value;
    }

    get unit(): Unit {
        return this._unit;
    }

    set unit(value: Unit) {
        this._unit = value;
    }
}
