import {Injectable} from '@angular/core';
import {RestService} from '../rest/rest.service';
import {EventService} from '../../client/event/event.service';
import {Company} from '../../../models/company/company';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../models/user/user';
import {ErrorHandlingService} from '../../client/error-handling/error-handling.service';
import {Unit} from '../../../models/unit/unit';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    constructor(private restService: RestService,
                private eventService: EventService,
                private snackBar: MatSnackBar,
                private translator: TranslateService,
                private errorHandlingService: ErrorHandlingService) {

    }

    /**
     * returns all companies
     *
     * @param page the requested page index
     * @param count the number of items per page
     * @param sort the column to sort
     * @param sortDesc should the sort be descending
     */
    public getAll(page: number = 0, count: number = 50, sort: string = null, sortDesc: boolean = false): Promise<{
        items: Company[],
        numberOfAllItems: number
    }> {
        const parameter: { key: string, value: string }[] = [];
        if (page && page > 0) {
            parameter.push({key: 'page', value: page.toString()});
        }
        if (count && count > 0) {
            parameter.push({key: 'count', value: count.toString()});
        }
        if (sort && sort.length > 0) {
            parameter.push({key: 'sortColumn', value: sort.toString()});
            parameter.push({key: 'sortDesc', value: sortDesc.toString()});
        }

        return this.restService.get('/companies',
            parameter
        ).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const companies: Company[] = [];

                for (const responseItem of response) {
                    companies.push(Company.hydrate(responseItem));
                }

                return {
                    items: companies,
                    numberOfAllItems: result.headers.get('x-items-count')
                };
            },
            (httpError: HttpErrorResponse) => {
                this.errorHandlingService.handleError('companyService_GetAllRequest', httpError);

                return null;
            });
    }

    /**
     * returns the requested company
     *
     * @param id the id of the requested company
     */
    public getDetail(id: number): Promise<Company> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.get('/companies/' + id, parameter).toPromise().then(result => {
            const company: Company = Company.hydrate(result.body);

            return company;
        }, (httpError: HttpErrorResponse) => {
            this.errorHandlingService.handleError('companyService_DetailRequest', httpError);

            return null;
        });
    }

    /**
     * saves the given company
     *
     * @param company the company to save
     */
    public save(company: Company): Promise<number> {
        return this.restService.post(
            '/companies/' + company.id,
            company,
            true).toPromise()
        .then((id: number) => {
            this.eventService.Trigger('company:create:success', id);

            this.translator.get('companyService_SaveRequest_SuccessMessage').subscribe(
                (translation: string) => {
                    this.snackBar.open(translation, '', {
                        duration: (5 * 1000), // display 5 seconds
                        panelClass: 'snackbar-lws-success'
                    });
                });

            return id;
        }).catch((httpError: HttpErrorResponse) => {
            this.errorHandlingService.handleError('unitService_DeleteRequest', httpError);

            return null;
        });
    }

    /**
     * deletes the company with the given id
     *
     * @param id the id of the company to delete
     */
    public delete(id: number): Promise<boolean> {
        const parameter: { key: string, value: string }[] = [];

        return this.restService.delete(
            '/companies/' + id,
            parameter,
            true).toPromise()
        .then((response: any) => {
            return true;
        }).catch((httpError: HttpErrorResponse) => {
            this.errorHandlingService.handleError('companyService_DeleteRequest', httpError);

            return false;
        });
    }

    /**
     * returns all users by the given company-id
     *
     * @param id the id of the company
     * @param page the requested page index
     * @param count the number of items per page
     * @param sort the column to sort
     * @param sortDesc should the sort be descending
     */
    public getUsers(id: number, page: number = 0, count: number = 50, sort: string = null, sortDesc: boolean = false): Promise<{
        items: User[],
        numberOfAllItems: number
    }> {
        const parameter: { key: string, value: string }[] = [];
        if (page && page > 0) {
            parameter.push({key: 'page', value: page.toString()});
        }
        if (count && count > 0) {
            parameter.push({key: 'count', value: count.toString()});
        }
        if (sort && sort.length > 0) {
            parameter.push({key: 'sortColumn', value: sort.toString()});
            parameter.push({key: 'sortDesc', value: sortDesc.toString()});
        }

        return this.restService.get('/companies/' + id + '/users', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const users: User[] = [];

                for (const responseItem of response) {
                    users.push(User.hydrate(responseItem));
                }

                return {
                    items: users,
                    numberOfAllItems: result.headers.get('x-items-count')
                };
            },
            () => {
                return null;
            });
    }

    /**
     * returns all units by the given company-id
     *
     * @param id the id of the company
     * @param page the requested page index
     * @param count the number of items per page
     * @param sort the column to sort
     * @param sortDesc should the sort be descending
     */
    public getUnits(companyId: number, page: number = 0, count: number = 50, sort: string = null, sortDesc: boolean = false): Promise<{
        items: Unit[],
        numberOfAllItems: number
    }> {
        const parameter: { key: string, value: string }[] = [];
        if (page && page > 0) {
            parameter.push({key: 'page', value: page.toString()});
        }
        if (count && count > 0) {
            parameter.push({key: 'count', value: count.toString()});
        }
        if (sort && sort.length > 0) {
            parameter.push({key: 'sortColumn', value: sort.toString()});
            parameter.push({key: 'sortDesc', value: sortDesc.toString()});
        }

        return this.restService.get('/companies/' + companyId + '/units', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const units: Unit[] = [];

                for (const responseItem of response) {
                    units.push(Unit.hydrate(responseItem));
                }

                return {
                    items: units,
                    numberOfAllItems: result.headers.get('x-items-count')
                };
            },
            () => {
                return null;
            });
    }

    /**
     * Returns the company by the unit
     *
     * @param id the id of the company
     * @param page the requested page index
     * @param count the number of items per page
     * @param sort the column to sort
     * @param sortDesc should the sort be descending
     */
    public getCompanyByUnitId(unitId: number): Promise<Company> {
        const parameter: { key: string, value: string }[] = [];
        parameter.push({key: 'unitId', value: unitId.toString()});

        return this.restService.get('/companies/byUnit', parameter).toPromise().then(
            (result) => {
                const company: any = (result.body as []);

                return company;
            },
            () => {
                return null;
            });
    }
}
