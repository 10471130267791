<section *ngIf="data">

    <h2 matDialogTitle>{{'foldersListDialog_TitleText'|translate}}</h2>

    <mat-dialog-content>

        <p>{{'foldersListDialog_ContentText'|translate}}</p>

        <mat-nav-list class="folder--list" *ngIf="data.folders && data.folders.length > 0">

            <ng-container *ngFor="let folderLevel1 of data.folders">


                <mat-list-item
                   class="folder--item">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p>{{folderLevel1.name}}</p>
                </mat-list-item>

                <a mat-list-item (click)="folderSelected(folderLevel2)" *ngFor="let folderLevel2 of folderLevel1.subFolders"
                   class="folder--item">
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{folderLevel2.name}}</p>
                </a>

            </ng-container>

        </mat-nav-list>

    </mat-dialog-content>

</section>
