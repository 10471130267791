import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-lws-alert',
    templateUrl: './lws-alert.component.html',
    styleUrls: ['./lws-alert.component.scss']
})
export class LwsAlertComponent implements OnInit {
    @Input() title: string = '';
    @Input() message: string = '';
    @Input() private _color: string = 'default';
    public cssClass: string = '';
    
    constructor() {
    
    }
    
    get color(): string {
        return this._color;
    }
    
    @Input() set color(value: string) {
        this._color = value;
        
        this.cssClass = 'lws-alert--color-' + this.color;
    }
    
    ngOnInit() {
    
    }
}
