import {Injectable} from '@angular/core';
import {AccountService} from '../account/account.service';
import {RestService} from '../rest/rest.service';
import {Segment} from '../../../models/segment/segment';

@Injectable({
    providedIn: 'root'
})
export class SegmentService {
    
    constructor(private accountService: AccountService,
                private restService: RestService) {
        
    }
    
    /**
     * returns all segments
     */
    public getAll(): Promise<Segment[]> {
        const parameter: { key: string, value: string }[] = [];
        
        return this.restService.get('/segments', parameter).toPromise().then(
            (result) => {
                const response: any[] = (result.body as []);
                const segments: Segment[] = [];
                
                for (const responseItem of response) {
                    segments.push(Segment.hydrate(responseItem));
                }
                
                return segments;
            },
            () => {
                return null;
            });
    }
}
