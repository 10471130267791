<section>

    <app-lws-detail-header [title]=headerTitle [addButton]="true"></app-lws-detail-header>
    <app-lws-bread-crumb [breadcrumbs]="breadcrumbs"></app-lws-bread-crumb>

    <main>

        <app-companies-list [enableEditAction]="true" [enableDeleteAction]="true"></app-companies-list>

    </main>

</section>
