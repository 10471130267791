<mat-toolbar class="filter-panel" *ngIf="isFiltereable">
    <app-hierarchy-filter [gadget]="gadget" (selectionChanged)="filterSelectionChanged(); hierarchyFilterChanged()"></app-hierarchy-filter>

    <mat-form-field class="question--detail-selection-field">
        <mat-label>{{'Relevance'|translate}}</mat-label>
        <mat-select [(ngModel)]="selectedRelevanceValue"
                    color="primary"
                    (selectionChange)="filterSelectionChanged()"
                    multiple>
            <mat-option *ngFor="let entry of relevanceValues"
                        [value]="entry">
                {{entry.title|translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="question--detail-selection-field">
        <mat-label>{{'SuccessFactor'|translate}}</mat-label>
        <mat-select [(ngModel)]="selectedSuccessFactor"
                    (selectionChange)="filterSelectionChanged()"
                    multiple>
            <mat-option *ngFor="let successFactor of successFactors" [value]="successFactor">
                {{successFactor|translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="question--detail-selection-field">
        <mat-label>{{'AnswerValueStrengthWeakness'|translate}}</mat-label>
        <mat-select [(ngModel)]="selectedAnswereValues"
                    (selectionChange)="filterSelectionChanged()"
                    multiple>
            <mat-option *ngFor="let answereValue of answereValues" [value]="answereValue">
                {{answereValue}}
            </mat-option>
        </mat-select>
    </mat-form-field>

</mat-toolbar>

<div echarts  [options]="chartData" class="chart" *ngIf="chartData"></div>
